import {Button, ButtonGroup, Content, Dialog, Flex, Footer, Heading, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {VideoActiveIcon} from 'src/components/icons'
import {EndCallWhiteIcon} from 'src/components/icons/EndCallWhiteIcon'
import {StartAudioCallWhiteIcon} from 'src/components/icons/StartAudioCallWhiteIcon'
import {NumberOfParticipantsCircle} from 'src/components/roster/NumberOfParticipantsCircle'
import {selectMyParticipant, selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithIncomingCall, WithWorkshopId} from 'src/types'

type Props = { rejectCall: () => void } & { acceptCall: (type: 'video' | 'audio') => void }

export const IncomingCallDialog: React.FC<WithWorkshopId & WithIncomingCall & Props> = ({
    workshopId,
    incomingCall,
    rejectCall,
    acceptCall
}) => {
    const {t} = useTranslation()
    const me = useAppSelector(selectMyParticipant)
    const participants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const shouldDisplayNumberOfParticipants = incomingCall.participantIds && incomingCall.participantIds.length > 3 + 1 // +1 = me

    const callers = incomingCall.participantIds
        .filter(participantId => participantId !== me?.id)
        .filter(participantId => participants[participantId])
        .map(participantId => participants[participantId]!)
        .slice(0, shouldDisplayNumberOfParticipants ? 2 : 3)

    return <Dialog size='M' data-testid='receive-call-dialog' UNSAFE_className={'incoming-call-dialog'}>
        <Heading>
            <Flex justifyContent={'center'} alignItems={'center'} gap="size-100">
                <Text>{t('dialog.receive-call.heading')}</Text>
            </Flex>
        </Heading>
        <Content>
            {callers ? callers.map((caller, index) => <ParticipantBadge key={index} participant={caller}/>) : <></>}
            {shouldDisplayNumberOfParticipants ?
                <NumberOfParticipantsCircle label={`+${incomingCall.participantIds.length - 1}`}/> : <></>}
        </Content>
        <Footer>
            <ButtonGroup justifySelf={'center'} UNSAFE_className={'buttons'}>
                <Button
                    onPress={() => acceptCall('video')}
                    variant='secondary'
                    data-testid='receive-call-button-accept-video'>
                    <VideoActiveIcon/>
                </Button>
                <Button
                    onPress={() => acceptCall('audio')}
                    variant='secondary'
                    data-testid='create-workshop-button-accept-voice'>
                    <StartAudioCallWhiteIcon/>
                </Button>
                <Button
                    onPress={rejectCall}
                    variant='secondary'
                    data-testid='create-workshop-button-reject'>
                    <EndCallWhiteIcon/>
                </Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
