import {API} from 'aws-amplify'
import {Response} from '../response'
import {GetInvitationsDto, InvitationDto} from './invitations.dto'

export class InvitationsApi {
    async getInvitationsForWorkshop(workshopId: string): Promise<InvitationDto[]> {
        const result: Response<GetInvitationsDto> = await API.get('backend', `api/workshops/${workshopId}/invitations`, {})
        if(result && result._embedded)
            return result._embedded?.invitations
        return []
    }

    async addInvitation(workshopId: string, email: string, nickname?: string): Promise<InvitationDto> {
        return API.post('backend', `api/workshops/${workshopId}/invitations`, {
            body: {
                nickname,
                email,
            }
        })
    }

    async sendEmails(workshopId: string): Promise<void> {
        return await API.post('backend', `api/workshops/${workshopId}/invitations/sendall`, {})
    }

    async sendEmail(workshopId: string, email: string): Promise<void> {
        return await API.post('backend', `api/workshops/${workshopId}/invitations/sendone`, {
            body: {
                email: email
            }
        })
    }

    async deleteInvitations(workshopId: string, invitations: string[]): Promise<void> {
        return await API.del('backend', `api/workshops/${workshopId}/invitations/`, {
            body: {
                invitations: invitations
            }
        })
    }
}
