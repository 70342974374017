import {createSlice} from '@reduxjs/toolkit'
import {ModeratedQueueUpdated} from 'src/state/moderated-queue/moderated-queue.events'
import {moderatedQueueAdapter} from './moderated-queue.adapter'
import {loadQueue} from './moderated-queue.actions'

export const initialState = moderatedQueueAdapter.getInitialState()
export const moderatedQueueSlice = createSlice({
    name: 'moderated-queue',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(ModeratedQueueUpdated, (state, {payload: moderatedQueue}) => {
                moderatedQueueAdapter.upsertOne(state, {
                    id: moderatedQueue.phaseId,
                    active: moderatedQueue.active,
                    items: moderatedQueue.items
                })
            })
            .addCase(loadQueue.fulfilled, (state, {payload: moderatedQueue}) => {
                moderatedQueueAdapter.upsertOne(state, moderatedQueue)
            })
    }
})
