import {PropsWithChildren} from 'react'
import {toCalcCss, toCss} from 'src/components/layout/util'
import {LayoutConfigState, MeetingLayout} from 'src/state/layout'
import styled from 'styled-components'

export const StyledMeetingContainer = styled.div<PropsWithChildren<{
    layout: LayoutConfigState,
    meetingLayout: MeetingLayout,
}>>`
  display: flex;
  text-align: left;
  padding: 0;
  transition: all 0.3s linear;

  width: ${({layout, meetingLayout}) => {

        if (meetingLayout === 'large') {
            return `calc(${toCalcCss(layout.drawer.meeting)} - ${toCalcCss(layout.drawer.left)} - ${(toCalcCss(layout.drawer.search))} - ${toCalcCss(layout.drawer.notification)})`
        } else {
            return toCss(layout.drawer.meeting)
        }

    }
};
  ${({meetingLayout}) => meetingLayout !== 'large' && meetingLayout !== 'hidden' ? 'border-left: 1px solid #6F6F6F;' : ''}
  
  a {
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #D4D4D4;
    }
  }
`
