import classNames from 'classnames'
import React from 'react'
import {useParams} from 'react-router-dom'
import {ScheduleMenuItem} from 'src/components/layout/menu/ScheduleMenuItem'
import {VerticalLine} from 'src/container/navigation/VerticalLine'
import {selectSchedule} from 'src/state/schedule'
import {useAppSelector} from 'src/state/types'
import {selectWorkshopById} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'

export const WorkshopScheduleMenu: React.FC<{
    variant: 'full' | 'icon'
}> = ({variant}) => {
    const scheduleItems = useAppSelector(selectSchedule)
    const {workshopId} = useParams<WithWorkshopId>()
    const workshop = useAppSelector(selectWorkshopById(workshopId))

    return <>
        {workshop?.status === 'started' &&
        scheduleItems.map((item, index) => <div key={index}>
            <ScheduleMenuItem variant={variant} item={item}/>
            {index < scheduleItems.length - 1 ? <VerticalLine
                className={classNames({
                    'adjust-to-small-icon': item.status === 'finished',
                    'adjust-to-next-time': scheduleItems[index + 1].status !== 'started'
                })}
            /> : <></>}
        </div>)
        }
    </>
}
