import React from 'react'
import {VideoConferenceForPhase} from 'src/components/meetings/VideoConferenceForPhase'
import {PlenaryContent} from './PlenaryContent'
import {useMyLocation} from 'src/hooks'
import {WithPhase, WithWorkshopId} from 'src/types'

export const PlenaryRoom: React.FC<WithWorkshopId & Partial<WithPhase>> = ({workshopId, phase}) => {
    useMyLocation('plenary')
    return <>
        <VideoConferenceForPhase />
        <PlenaryContent workshopId={workshopId} phase={phase}/>
    </>
}
