import {MeetingStatus, useMeetingStatus} from 'amazon-chime-sdk-component-library-react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useVideoControl} from 'src/hooks/devices/video/use-video-control'
import {selectVideoInputDeviceId} from 'src/state/device-settings'
import {errorOccurred} from 'src/state/error'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export function useConfiguredVideoInputDevice(): string | undefined {
    const meetingStatus = useMeetingStatus()
    const videoInputDevice = useAppSelector(selectVideoInputDeviceId)
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [promise, setPromise] = useState<Promise<void>>(Promise.resolve())

    const {selectVideoDevice} = useVideoControl()

    useEffect(() => {
        async function configureVideoDevice() {
            if (videoInputDevice) {
                await selectVideoDevice(videoInputDevice)
            }
        }

        if (meetingStatus === MeetingStatus.Succeeded) {
            setPromise(promise.then(() => configureVideoDevice().catch(error => {
                console.error('Failed to configure video', error)
                const message = t('error.failed-to-configure-video')
                dispatch(errorOccurred({message: message}))
            })))
        }
    }, [
        meetingStatus,
        videoInputDevice,
    ])

    return videoInputDevice
}
