import {Flex, Switch, View} from '@adobe/react-spectrum'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import React, {ReactNode} from 'react'
import {DeviceInput} from './DeviceInput'

export const DeviceSelection: React.FC<{
    icon: ReactNode,
    ariaLabel: string
    devices: DeviceType[]
    selectDevice(deviceId: string): void
    selectedDeviceId?: string
    activeByDefault?: boolean
    setActiveByDefault?(active: boolean): void
    activeByDefaultLabel?: string
    activityMeter?: ReactNode
}> = ({
    icon,
    ariaLabel,
    devices,
    selectDevice,
    selectedDeviceId,
    activeByDefault,
    setActiveByDefault,
    activeByDefaultLabel,
    activityMeter
}
) => {
    return (
        <Flex direction='column' gap={'size-130'} alignItems={'start'}>
            <View width={'size-3000'}>
                <DeviceInput
                    icon={icon}
                    ariaLabel={ariaLabel}
                    onChange={(deviceId) => {
                        selectDevice(deviceId)
                    }}
                    devices={devices}
                    selectedDeviceId={selectedDeviceId}
                    notFoundMsg={'Device not found'}
                />
            </View>
            {activityMeter ? <View UNSAFE_className={'meter-container'}>{activityMeter}</View> : <></>}
            {setActiveByDefault ? <View>
                <Switch
                    isSelected={activeByDefault}
                    onChange={setActiveByDefault}
                >{activeByDefaultLabel || 'Active by default'}</Switch>
            </View> : <></>}
        </Flex>
    )
}
