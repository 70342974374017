import {DimensionValue} from '@react-types/shared/src/dna'
import classNames from 'classnames'
import React, {ReactNode} from 'react'
import {Divider, View} from '@adobe/react-spectrum'
import './Card.css'

interface Props {
    className?: string
    children: {
        title?: ReactNode
        content: ReactNode
        buttons?: ReactNode
        additionalButtons?: ReactNode
        icon?: string | JSX.Element
        width?: DimensionValue
        height?: DimensionValue
        leftHeaderIcon?: ReactNode
        rightHeaderIcon?: ReactNode
    }
}

export const Card: React.FC<Props> = (props: Props) => {
    const {title, content, buttons, icon, width, height, leftHeaderIcon, rightHeaderIcon, additionalButtons} = props.children

    return (
        <div className={classNames('card', {
            [props.className || '']: Boolean(props.className)
        })}>
            {leftHeaderIcon || rightHeaderIcon ? <div className='header-icons'>
                {leftHeaderIcon ? <div className='header-icon-left'>{leftHeaderIcon}</div> : <div/>}
                {rightHeaderIcon ? <div className='header-icon-right'>{rightHeaderIcon}</div> : <div/>}
            </div> : <></>}
            <View borderWidth='thin' borderColor='dark' borderRadius='medium' UNSAFE_className={'card-content'}
                width={width ?? 'auto'} height={height ?? 'auto'} paddingY='size-150'>
                {icon && typeof icon === 'string' &&
                    <img className='card-margin card-image' src={icon} />
                }
                {icon && typeof icon === 'object' &&
                    icon
                }
                {title ? <h3 className='card-margin card-title'>{title}</h3> : <></>}
                <div className='card-margin card-content card-content-body'>{content}</div>
                {buttons && <>
                    <Divider size='S' marginY='size-200' />
                    <div className='card-margin card-buttons'>{buttons}</div>
                </>}
                {additionalButtons && <>
                    <Divider size='S' marginY='size-200' UNSAFE_className={'additional-button-divider'}/>
                    <div className='card-margin card-buttons'>{additionalButtons}</div>
                </>}
            </View>
        </div>
    )
}
