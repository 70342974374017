import {API} from 'aws-amplify'
import {QueueDto, QueueUpdateDto} from 'src/api/phases/moderated-queue.dto'
import {CreateTopicDto} from 'src/api/topics'
import {v4 as uuid} from 'uuid'

export class ModeratedQueueApi {

    enqueueTopic(
        workshopId: string,
        phaseId: string,
        topic: CreateTopicDto
    ): any {
        return API.post('backend', `api/workshops/${workshopId}/phases/${phaseId}/queue`, {
            body: {
                ...topic,
                id: uuid()
            }
        })
    }

    getQueue(workshopId: string, phaseId: string): Promise<QueueDto> {
        return API.get('backend', `api/workshops/${workshopId}/phases/${phaseId}/queue`, {})
    }

    deleteFromQueue(workshopId: string, phaseId: string, topicId: string): Promise<void> {
        return API.del('backend', `api/workshops/${workshopId}/phases/${phaseId}/queue/${topicId}`, {})
    }

    updateQueue(workshopId: string, phaseId: string, queueUpdate: QueueUpdateDto): Promise<void> {
        return API.put('backend', `api/workshops/${workshopId}/phases/${phaseId}/queue`, {
            body: queueUpdate
        })
    }

    acceptTopic(workshopId: string, phaseId: string, topicId: string): Promise<void> {
        return API.put('backend', `api/workshops/${workshopId}/phases/${phaseId}/queue/${topicId}`, {})
    }
}
