import moment from 'moment'
import React, {CSSProperties} from 'react'

interface Props {
    dateTime: string
    style?: CSSProperties
}

export const Date: React.FC<Props> = ({dateTime, style}) => {
    const date = moment.utc(dateTime).local()
    return <span style={style}>
        {date.format('DD.MM.YYYY')}
    </span>
}
