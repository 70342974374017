import {View} from '@adobe/react-spectrum'
import React from 'react'
import {EditableTextArea} from 'src/components/common'
import {DeleteButton} from 'src/components/common/DeleteButton'
import {NoteForTopic} from 'src/state/topic-notes/topic-notes.model'
import 'src/components/meetings/topic-room/topic-breakout/tools/StickyNote.css'

export const StickyNote: React.FC<{
    note: NoteForTopic
    onDelete(note: NoteForTopic): void
    onUpdate(note: NoteForTopic): void
    canEdit: boolean
}> = ({
    note,
    onDelete,
    onUpdate,
    canEdit
}) => {
    return <View key={note.id}>
        <div className='topic-note'>
            <div>{canEdit ? <EditableTextArea
                value={note.note}
                onSave={(value) => onUpdate({...note, note: value})}
                height={'size-2000'}
            /> : note.note}</div>
            {canEdit ? <div className='note-controls'>
                <DeleteButton onDelete={() => onDelete(note)}/>
            </div> : <></>}
        </div>
    </View>
}
