import React from 'react'
import {selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithRoles} from 'src/types'

export const ModeratorControls: React.FC<WithRoles> = ({
    roles,
    children
}) => {
    const me = useAppSelector(selectMyParticipant)
    const isModerator = roles.isModerator(me)
    return isModerator ? <div className={'roster-grid-moderator-controls'}>
        {children}
    </div> : <></>
}
