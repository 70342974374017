import React from 'react'
import {MeetingContainer} from './MeetingContainer'
import { selectMeetingLayout} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import styled from 'styled-components'

const DrawerContent = styled.div`
`

export const MeetingDrawer: React.FC = (props) => {
    const {children} = props
    const status = useAppSelector(selectMeetingLayout)

    return <MeetingContainer className='menu-right'>
        {status !== 'hidden' ? <DrawerContent className='menu-right-content'>
            {children}
        </DrawerContent> : <></>}
    </MeetingContainer>
}

