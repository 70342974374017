import {EntityState} from '@reduxjs/toolkit'
import {DeviceConfig} from 'amazon-chime-sdk-component-library-react/lib/types'
import {Attendee} from 'amazon-chime-sdk-js'
import {ModeOfInteraction} from 'src/state/common.model'
import {ParticipantRoles, WithPhaseId, WithTopicId} from 'src/types'
import {Participant, ParticipantRolesAdapter} from '../participants'

export type ChimeAttendee = Attendee

export interface Meeting {
    id: string
    chimeMeeting: ChimeMeeting
    chimeAttendee: ChimeAttendee
}

export interface MeetingEntity extends Meeting {
    modeOfInteraction: ModeOfInteraction
    topicId?: string,
    phaseId?: string
    moderatorId?: string
    moderatedWithRolesId?: string
    roles?: ParticipantRoles
}

export type MeetingObject = Partial<WithTopicId> & Partial<WithPhaseId>

export interface ChimeMediaPlacement {
    audioHostUrl: string
    audioFallbackUrl: string
    screenDataUrl: string
    screenSharingUrl: string
    screenViewingUrl: string
    signalingUrl: string
    turnControlUrl: string
}

export interface ChimeMeeting {
    meetingId: string
    externalMeetingId: string
    mediaRegion: string,
    mediaPlacement: ChimeMediaPlacement
}

export type MeetingParticipant = Participant & { attendeeId: string }

export type MeetingsState = EntityState<MeetingEntity> & {
    activeMeeting: string | undefined
}

export type WithMeetingsState = {
    meetings: MeetingsState
}

export const deviceConfig: DeviceConfig = {
    additionalDevices: true,
}

export class MeetingAdapter implements Meeting {
    constructor(
      private meeting: MeetingEntity
    ) {
    }

    get chimeAttendee(): ChimeAttendee {
        return this.meeting.chimeAttendee
    }

    get chimeMeeting(): ChimeMeeting {
        return this.meeting.chimeMeeting
    }

    get id(): string {
        return this.meeting.id
    }

    get topicId(): string | undefined {
        return this.meeting.topicId
    }

    hasTopicId(): this is WithTopicId {
        return this.topicId !== undefined
    }

    get phaseId(): string | undefined {
        return this.meeting.phaseId
    }

    hasPhaseId(): this is WithPhaseId {
        return this.phaseId !== undefined
    }

    get modeOfInteraction(): ModeOfInteraction {
        return this.meeting.modeOfInteraction
    }

    hasModeratedRolesId(): this is { moderatedWithRolesId: string } {
        return this.moderatedWithRolesId !== undefined
    }

    get moderatedWithRolesId(): string | undefined {
        return this.meeting.moderatedWithRolesId
    }

    get roles(): ParticipantRolesAdapter | undefined {
        return this.meeting.roles ? new ParticipantRolesAdapter(this.meeting.roles) : undefined
    }

    hasRoles(): this is { roles: ParticipantRolesAdapter } {
        return this.meeting.roles !== undefined
    }
}

export type MeetingDeviceSettings = {
    isAllowedToSpeak: boolean
    isMutedOnJoin: boolean
    isVideoAllowed: boolean
    permissionsToSpeakMightBeGranted: boolean
}
