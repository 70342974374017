import React from 'react'
import Linkify from 'react-linkify'

export const RenderedBiography: React.FC<{ biography: string }> = ({biography}) => {
    const componentDecorator = (href: string, text: string, key: number) => (
        <a href={href} key={key} target="_blank" rel="noreferrer">
            {text}
        </a>
    )
    return <Linkify componentDecorator={componentDecorator}>{biography}</Linkify>
}

