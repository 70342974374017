import React, {useEffect, useMemo, useState} from 'react'
import {RandomPositioningStrategy} from 'src/container/rooms/cafe/model'
import {Size} from 'src/container/rooms/cafe/model/size'
import {ParticipantCafeBadge} from 'src/container/rooms/cafe/ParticipantCafeBadge'
import {selectParticipantsFilteredByLocation} from 'src/state/participant-locations'
import {selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import useResizeObserver from 'use-resize-observer'

export const SinglePeopleInCafe: React.FC<WithWorkshopId> = () => {
    const participants = useAppSelector(selectParticipantsFilteredByLocation({type: 'cafe'}, location => !location.meetingId))
    const me = useAppSelector(selectMyParticipant)

    const positionStrategy = useMemo(() => new RandomPositioningStrategy({
        padding: 50,
        elementSize: 100
    }, []), [])
    const [participantPositions, setParticipantPositions] = useState(positionStrategy.updateParticipants(participants))
    const {ref} = useResizeObserver<HTMLDivElement>({
        onResize: (partialSize: Partial<Size>) => {
            if (partialSize.width && partialSize.height) {
                const size: Size = {width: partialSize.width, height: partialSize.height}
                setParticipantPositions(positionStrategy.calculateNewPositions(size))
            }
        }
    })
    useEffect(() => {
        if (participants.length === participantPositions.length
          && participantPositions.map(p => p.participant).every(p => participants.includes(p))
        ) {
            return
        }

        setParticipantPositions(positionStrategy.updateParticipants(participants))
    }, [participants, participantPositions])


    return <div ref={ref} style={{height: '80vh'}}>
        {participantPositions
            .map((participantPosition, i) => {
                return <div key={i} className='participant-cafe-icon' style={{
                    position: 'absolute',
                    marginLeft: participantPosition.position.x,
                    marginTop: participantPosition.position.y
                }}>
                    <ParticipantCafeBadge
                        active={true}
                        participant={participantPosition.participant}
                        canStartCall={me?.id !== participantPosition.participant.id}/>
                </div>
            })}
    </div>
}
