import {ConsoleLogger, DefaultDeviceController, DeviceController, LogLevel} from 'amazon-chime-sdk-js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MicrophoneActiveIcon} from 'src/components/icons'
import {DeviceSelection} from './DeviceSelection'
import {useAudioInputDevices, useLocalAudioInputActivity} from 'src/hooks'
import {
    selectAudioInputDeviceId,
    selectIsMuted,
    setAudioInputDeviceId, setMute
} from 'src/state/device-settings'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import { Meter} from '@adobe/react-spectrum'

export const AudioInputDeviceSelection: React.FC = () => {
    const videoDevices = useAudioInputDevices()
    const dispatch = useAppDispatch()
    const deviceId = useAppSelector(selectAudioInputDeviceId)
    const muted = useAppSelector(selectIsMuted)
    const [deviceController, setDeviceController] = useState<DefaultDeviceController | undefined>(undefined)
    const {t} = useTranslation()

    useEffect(() => {
        if (deviceId) {
            const defaultDeviceController = new DefaultDeviceController(
                new ConsoleLogger('device-settings', LogLevel.INFO))
            defaultDeviceController.chooseAudioInputDevice(deviceId)
                .catch(error => {
                    console.error('Failed to choose audio', error)
                })
                .then(() => setDeviceController(defaultDeviceController))
        }
    }, [deviceId])

    useEffect(() => {
        return () => {
            deviceController?.destroy().catch(error => {
                console.warn('Failed to cleanup device controller', error)
            })
        }
    }, [deviceController])

    return <><DeviceSelection
        icon={<MicrophoneActiveIcon className={'icon-button'} />}
        ariaLabel={'Microphone'}
        devices={videoDevices}
        selectDevice={(deviceId) => {
            dispatch(setAudioInputDeviceId(deviceId))
        }}
        selectedDeviceId={deviceId}
        activeByDefault={!muted}
        setActiveByDefault={(active) => {
            dispatch(setMute(!active))
        }}
        activityMeter={deviceId && deviceController ? <AudioInputActivity
            deviceController={deviceController}
        /> : undefined}
        activeByDefaultLabel={t('dialog.device-settings.keep-audio-active')}
    />
    </>
}

export const AudioInputActivity: React.FC<{
    deviceController: DeviceController
}> = ({deviceController}) => {

    const activity = useLocalAudioInputActivity(deviceController)
    return <Meter aria-label={'Volume'} value={activity} maxValue={1} variant="positive" UNSAFE_className={'meter'} />
}
