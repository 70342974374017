import {useToggleLocalMute} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {MicrophoneActiveIcon, MicrophoneInactiveIcon} from 'src/components/icons'
import {setMute} from 'src/state/device-settings'
import {selectMeetingDeviceSettings} from 'src/state/meetings'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {DeviceControl} from './DeviceControl'

export const AudioInputControl: React.FC = () => {
    const { muted, toggleMute } = useToggleLocalMute()
    const dispatch = useAppDispatch()
    const config = useAppSelector(selectMeetingDeviceSettings)

    return config.isAllowedToSpeak ? <DeviceControl
        active={!muted}
        activeIcon={<MicrophoneActiveIcon />}
        inactiveIcon={<MicrophoneInactiveIcon />}
        toggle={() => {
            dispatch(setMute(!muted))
            toggleMute()
        }}
    /> : <></>
}
