import {VideoTileState} from 'amazon-chime-sdk-js'
import React from 'react'

export function cloneStreamIfRequired(
    tileState: VideoTileState,
    videoEl: React.MutableRefObject<HTMLVideoElement | null>
): void {
    /**
     * issue showing same video multiple times (especially when changing devices)
     *
     * https://github.com/aws/amazon-chime-sdk-js/issues/1082
     * https://github.com/aws/amazon-chime-sdk-component-library-react/issues/434
     *
     * Workaround: https://github.com/aws/amazon-chime-sdk-js/issues/976
     */
    if (tileState.boundVideoStream && videoEl.current) {
        const clonedStream = tileState.boundVideoStream.clone()
        videoEl.current.srcObject = clonedStream
        videoEl.current.play()
            .catch(error => {
                console.log('Video element did stop playing', error)
            })
    }
}
