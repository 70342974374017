import {createSlice} from '@reduxjs/toolkit'
import {login, logout, updateUserProfile} from './auth.actions'
import {AuthState} from './auth.model'

export const initialState: AuthState = {
    user: undefined
}
export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.user = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = undefined
            })
            .addCase(updateUserProfile.fulfilled, (state, {payload: profile}) => {
                state.user = profile
            })
    }
})
