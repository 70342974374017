import React from 'react'
import { selectLeftDrawerStatus} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import {StyledMenu} from './StyledMenu'

export const LeftMenu: React.FC<{className?: string }> = ({className, children}) => {
    const drawerStatus = useAppSelector(selectLeftDrawerStatus)
    return (
        <div className={className}>
            <StyledMenu drawerStatus={drawerStatus} className={className}>
                {children}
            </StyledMenu>
        </div>
    )
}
