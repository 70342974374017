import {Flex} from '@adobe/react-spectrum'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Prompt} from 'react-router-dom'
import {WorkshopPhaseControls} from 'src/components/control/workshop/WorkshopPhaseControls'
import {Toolbox} from 'src/components/layout/Toolbox'
import {TopicDescription} from './TopicDescription'
import {TopicVideoConference} from './TopicVideoConference'
import {Whiteboard} from './tools/Whiteboard'
import {AddNoteButton} from './AddNoteButton'
import {deleteNote, updateNote,selectTopicNotes} from 'src/state/topic-notes'
import {closeTopicIfVoting, loadTopics, selectIsTopicOwner} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId, WithPhase} from 'src/types'
import { ContentWithToolbox } from 'src/components/layout/ContentWithToolbox'

export const TopicRoomForTopicGeneration: React.FC<WithTopic & WithPhase> = ({
    topic,
    phase
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const isTopicOwner = useAppSelector(selectIsTopicOwner(topic.id))

    const closeTopicIfOwnerAndVoting = async () => {
        if (isTopicOwner) {
            await dispatch(closeTopicIfVoting({workshopId: phase.workshopId, topicId: topic.id}))
            dispatch(loadTopics({workshopId: phase.workshopId}))
        }
    }

    useEffect(() => {
        return () => {
            closeTopicIfOwnerAndVoting().catch(err => console.log(err))
        }
    }, [])

    const isOwner = isTopicOwner && topic.status !== 'CLOSED'

    const toolbox = isOwner ? <Toolbox>
        <AddNoteButton workshopId={phase.workshopId} topicId={topic.id}/>
        {phase ? <WorkshopPhaseControls workshopId={phase.workshopId} phase={phase}/> : <></>}
    </Toolbox> : <></>

    return <ContentWithToolbox
        className={'topic-room'}
        toolbox={toolbox}>
        <Prompt when={topic.status === 'VOTING' && isTopicOwner}
            message={t('workshop-topic-room.leave-will-close-topic')}/>
        {topic.status === 'VOTING' ?
            <TopicVideoConference
                phase={phase}
                topic={topic}
            /> : <></>}
        {topic.status === 'VOTING' ? <ColumnLayout phase={phase} topic={topic} isOwner={isOwner} />
            : <RowLayout phase={phase} topic={topic} isOwner={isOwner}/>}

    </ContentWithToolbox>
}

export const RowLayout: React.FC<WithTopic & WithPhase & {
    isOwner: boolean
}> = ({
    phase,
    topic,
    isOwner
}) => {
    return <Flex direction='row' UNSAFE_className={'row-layout'} wrap={false}>
        <TopicDescription
            workshopId={phase.workshopId}
            phaseId={phase.id}
            topic={topic}
            showVotes={true}
            showStatus={true}
            canOwnerLeaveTopic={true}
        />
        <TopicWhiteBoard
            workshopId={phase.workshopId}
            topic={topic}
            canEditNotes={isOwner}
        />
    </Flex>
}

export const ColumnLayout: React.FC<WithTopic & WithPhase & {
    isOwner: boolean
}> = ({
    phase,
    topic,
    isOwner
}) => {
    return <Flex direction='column' wrap={false} gap={'size-225'}>
        <div className='topic-description-container-row'>
            <TopicDescription
                workshopId={phase.workshopId}
                phaseId={phase.id}
                topic={topic}
                showVotes={true}
                showStatus={true}
                canOwnerLeaveTopic={true}
            />
        </div>
        <div className='whiteboard-container-row'>
            <TopicWhiteBoard
                workshopId={phase.workshopId}
                topic={topic}
                canEditNotes={isOwner}
            />
        </div>
    </Flex>
}



export const TopicWhiteBoard: React.FC<
    WithWorkshopId & 
    WithTopic & 
    {canEditNotes: boolean }
    > = ({
        workshopId,
        topic,
        canEditNotes
    }) => {
        const dispatch = useAppDispatch()
        const notes = useAppSelector(selectTopicNotes(topic.id))

        return <Whiteboard
            notes={notes}
            canEditNotes={canEditNotes}
            onUpdateNote={(note) => dispatch(updateNote({workshopId, topicId: topic.id, note}))}
            onDeleteNote={(note) => dispatch(deleteNote({workshopId, topicId: topic.id, noteId: note.id}))}
        />
    }
