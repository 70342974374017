import React from 'react'
import {VideoConferenceForId} from 'src/components/meetings/VideoConferenceForId'
import {WithTopic, WithPhase} from 'src/types'

export const TopicVideoConference: React.FC<WithPhase & WithTopic> = ({
    topic,
    phase
}) => {
    const meetingId = (phase.id + topic.id).split('-').join('')
    return <VideoConferenceForId
        workshopId={phase.workshopId}
        meetingId={meetingId}
        modeOfInteraction={phase.breakout.currentModeOfInteractions || 'open'} // TODO
        meetingObject={{
            phaseId: phase.id,
            topicId: topic.id,
        }}
    />
}
