export const isOptionActive = (
    meetingManagerDeviceId: string | null,
    currentDeviceId: string
): boolean => {
    if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
        return true
    }
    return currentDeviceId === meetingManagerDeviceId
}

// TODO: Remove this and use DefaultBrowserBehavior.supportsSetSinkId from JS SDK v2.x
export const supportsSetSinkId = (): boolean => {
    return 'setSinkId' in HTMLAudioElement.prototype
}
