import {WithAuthState} from 'src/state/auth'
import {selectModeratedQueueBy, WithModeratedQueueState} from 'src/state/moderated-queue'
import {selectPhaseById, WithPhasesState} from 'src/state/phases'
import {selectIsLeadModerator, WithWorkshopsState} from 'src/state/workshops'
import {MeetingDeviceSettings, WithMeetingsState} from '../meetings.model'
import {AbstractDeviceSettingsStrategy} from './abstract-device-settings-strategy'
import {defaultConfig, silence} from './defaults'

export class ModeratedQueueSettingsDeviceStrategy extends AbstractDeviceSettingsStrategy<WithMeetingsState
  & WithModeratedQueueState
  & WithWorkshopsState
  & WithPhasesState
  & WithAuthState> {
    isResponsible(): boolean {
        return this.modeOfInteraction === 'moderated-queue'
    }

    getDeviceSettings(): MeetingDeviceSettings {
        const meeting = this.activeMeeting
        if (!meeting || !this.hasParticipantId()) {
            return defaultConfig
        }
        if (this.isLeadModerator()) {
            return defaultConfig
        }

        const queue = selectModeratedQueueBy(meeting.id)(this.state)
        if (queue.isTurnOf(this.participantId) || queue.isNextInQueue(this.participantId)) {
            return {
                isVideoAllowed: true,
                isAllowedToSpeak: queue.isTurnOf(this.participantId),
                isMutedOnJoin: !queue.isTurnOf(this.participantId),
                permissionsToSpeakMightBeGranted: true
            }
        }
        return {...silence, permissionsToSpeakMightBeGranted: true}
    }

    private isLeadModerator(): boolean {
        const activeMeeting = this.activeMeeting
        if (!activeMeeting) {
            return false
        }
        const phase = selectPhaseById(activeMeeting.id)(this.state)
        return phase?.workshopId ? selectIsLeadModerator(phase.workshopId)(this.state) : false
    }
}
