import {Flex, TextField} from '@adobe/react-spectrum'
import moment, {Moment} from 'moment'
import TimePicker from 'rc-time-picker'
import React, { useState} from 'react'
import DatePicker from 'react-datepicker'
import {ValidationState} from '@react-types/shared'

import './DateTimePicker.css'

interface Props {
    dateTime: Moment
    dateLabel: string
    timeLabel: string
    validationState?: 'valid' | 'invalid'
    onChangeDateTime(dateTime: Moment): void
    'data-testid': string,
    isReadOnly?: boolean
}

export const DateTimePicker: React.FC<Props> = ({
    dateLabel,
    timeLabel,
    onChangeDateTime,
    dateTime,
    validationState,
    'data-testid': testId,
    isReadOnly
}) => {
    const [currentTime, setCurrentTime] = useState(dateTime)

    function updateTime(newMoment: Moment) {
        setCurrentTime(newMoment)
        onChangeDateTime(newMoment)
    }

    if(isReadOnly) {
        return <>
            <TextField label={dateLabel} value={currentTime.format('DD.MM.YYYY')}
                data-testid={`${testId}-date`}
                isReadOnly={true}
            />
            <div style={{marginLeft: 16 }}/>
            <TextField
                label={timeLabel}
                value={dateTime.format('HH:mm')}
                data-testid={`${testId}-time`}
                isReadOnly={true}
            />
        </>
    }


    return <>
        <Flex alignItems={'end'} gap={'size-225'}>
            <div><DatePicker selected={currentTime.toDate()} value={currentTime.format('DD.MM.YYYY')}
                onChange={date => {
                    const selectedMoment = moment(date as Date)
                    updateTime(moment(dateTime)
                        .year(selectedMoment.year())
                        .month(selectedMoment.month())
                        .date(selectedMoment.date()))
                }}
                customInput={
                    <TextField label={dateLabel} value={currentTime.format('DD.MM.YYYY')}
                        validationState={validationState}
                        data-testid={`${testId}-date`}
                    />
                } />
            </div>
            <InputTimePicker
                label={timeLabel}
                initialTime={currentTime}
                validationState={validationState}
                onChangeTime={newTime => updateTime(moment(dateTime)
                    .hours(newTime.hours())
                    .minutes(newTime.minutes())
                    .seconds(0))}
            />

        </Flex>
    </>

}

export const InputTimePicker: React.FC<{
    label: string
    initialTime: moment.Moment,
    validationState?: ValidationState,
    onChangeTime(newTime: moment.Moment): void
}> = ({
    initialTime,
    onChangeTime
}) => {
    return <>
        <TimePicker
            className={'spectrum-Textfield-input'}
            prefixCls={'timepicker'}
            onChange={onChangeTime}
            defaultOpenValue={moment.utc(initialTime).local()}
            value={moment.utc(initialTime).local()}
            showSecond={false}
            hideDisabledOptions={true}
            clearIcon={<></>}
        />
    </>
}
