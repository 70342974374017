import React from 'react'
import {WithParticipant} from 'src/types'
import {AnonymousIcon} from './AnonymousIcon'
import {AvatarCircle} from './AvatarCircle'

export const AnonymousAvatar: React.FC<WithParticipant & {
    canStartCall: boolean,
    active: boolean
}>
    = ({participant, canStartCall, active}) => {
        return <>
            <AvatarCircle participant={participant} size={70} canStartCall={canStartCall} active={active}>
                <AnonymousIcon circleSize={70}/>
            </AvatarCircle>
        </>
    }
