import {EntityAdapter} from '@reduxjs/toolkit'
import moment from 'moment'
import {ParticipantLocationUpdate} from './participant-locations.events'
import {ParticipantLocation, ParticipantLocationState} from './participant-locations.model'

export const LOCATION_TIMEOUT_IN_SECONDS = 20

export class ParticipantLocationsStateService {

    constructor(
      private adapter: EntityAdapter<ParticipantLocation>
    ) {
    }

    isOutdated(lastSeen: number): boolean {
        const diffInSeconds = moment().diff(moment(lastSeen), 'seconds')
        return diffInSeconds > LOCATION_TIMEOUT_IN_SECONDS
    }

    removeOutdatedLocations(
        state: ParticipantLocationState
    ): void {
        Object.values(state.entities)
            .filter(participantLocation => participantLocation && this.isOutdated(participantLocation!.lastSeen))
            .forEach(participantLocation => {
                this.removeFromLocation(state, participantLocation!)
            })
    }

    addToArrayIfMissing = <T>(array: T[] | undefined, element: T): T[] => {
        if (array === undefined) {
            return [element]
        }
        if (array.includes(element)) {
            return array
        }
        return [...array, element]
    }

    addToLocation(
        state: ParticipantLocationState,
        participantLocation: ParticipantLocation
    ): void {
        const locationType = participantLocation.location.type
        if (locationType === 'topic-breakout' && participantLocation.location.id) {
            state.byLocation[locationType][participantLocation.location.id] = this.addToArrayIfMissing(
                state.byLocation[locationType][participantLocation.location.id],
                this.adapter.selectId(participantLocation).toString())
        } else if (locationType === 'cafe' || locationType === 'plenary') {
            state.byLocation[locationType] = this.addToArrayIfMissing(
                state.byLocation[locationType],
                this.adapter.selectId(participantLocation).toString())
        }
        this.addToByParticipant(state, participantLocation)
    }

    removeFromTopicBreakout(
        state: ParticipantLocationState,
        participantLocation: ParticipantLocation
    ): void {
        const locationType = 'topic-breakout'
        if (!participantLocation.location.id) {
            return
        }
        const byLocation = state.byLocation[locationType]
        byLocation[participantLocation.location.id] = byLocation[participantLocation.location.id]
            .filter(id => id !== this.adapter.selectId(participantLocation).toString())
        if (byLocation[participantLocation.location.id].length === 0) {
            delete byLocation[participantLocation.location.id]
        }
    }

    removeFromCafeOrPlenary(
        state: ParticipantLocationState,
        locationType: 'cafe' | 'plenary',
        participantLocation: ParticipantLocation
    ): void {
        state.byLocation[locationType] = state.byLocation[locationType]
            .filter(id => id !== this.adapter.selectId(participantLocation).toString())
    }

    removeFromLocation(
        state: ParticipantLocationState,
        participantLocation: ParticipantLocation
    ): void {
        const locationType = participantLocation.location.type
        if (locationType === 'topic-breakout') {
            this.removeFromTopicBreakout(state, participantLocation)
        } else if (locationType === 'cafe' || locationType === 'plenary') {
            this.removeFromCafeOrPlenary(state, locationType, participantLocation)
        }
        this.removeFromByParticipant(state, participantLocation)
        this.adapter.removeOne(state, this.adapter.selectId(participantLocation).toString())
    }

    removeParticipantLocation(
        state: ParticipantLocationState,
        participantLocation: ParticipantLocationUpdate,
    ): void {
        const previousLocation = state.entities[this.adapter.selectId(participantLocation as ParticipantLocation)]
        if (previousLocation) {
            this.removeFromLocation(state, previousLocation)
        }
        this.adapter.removeOne(state, this.adapter.selectId(participantLocation as ParticipantLocation))
    }

    updateParticipantLocation(
        state: ParticipantLocationState,
        participantLocation: ParticipantLocation,
    ): void {
        const previousLocation = state.entities[this.adapter.selectId(participantLocation)]
        if (this.isDifferentLocation(previousLocation, participantLocation)) {
            this.removeFromLocation(state, previousLocation)
        }
        this.adapter.upsertOne(state, participantLocation)
        this.addToLocation(state, participantLocation)
    }

    private isDifferentLocation(
        previousLocation: ParticipantLocation | undefined,
        participantLocation: ParticipantLocation
    ): previousLocation is ParticipantLocation {
        return previousLocation !== undefined && (
            previousLocation.location.type !== participantLocation.location.type
          || previousLocation.location.id !== participantLocation.location.id)
    }

    toParticipantLocation(
        participantLocationUpdate: ParticipantLocationUpdate
    ): ParticipantLocation | undefined {
        if (!participantLocationUpdate.location) {
            return undefined
        }
        return {
            location: participantLocationUpdate.location,
            lastSeen: participantLocationUpdate.lastSeen,
            participantId: participantLocationUpdate.participantId,
            meetingId: participantLocationUpdate.meetingId,
            sessionId: participantLocationUpdate.sessionId
        }
    }

    restoreInitialState(
        state: ParticipantLocationState,
        initialState: ParticipantLocationState
    ): void {
        state.entities = initialState.entities
        state.ids = initialState.ids
        state.byLocation = initialState.byLocation
        state.myLocation = initialState.myLocation
    }

    private removeFromByParticipant(state: ParticipantLocationState, participantLocation: ParticipantLocation) {
        if (!state.byParticipant[participantLocation.participantId]) {
            return
        }
        state.byParticipant[participantLocation.participantId] = state.byParticipant[participantLocation.participantId]
            .filter(id => id !== this.adapter.selectId(participantLocation))
    }

    private addToByParticipant(state: ParticipantLocationState, participantLocation: ParticipantLocation) {
        const id = this.adapter.selectId(participantLocation).toString()
        if (!state.byParticipant[participantLocation.participantId]) {
            state.byParticipant[participantLocation.participantId] = [id]
        } else if (!state.byParticipant[participantLocation.participantId].includes(id)) {
            state.byParticipant[participantLocation.participantId].push(id)
        }
    }
}
