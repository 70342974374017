import {Flex, Text} from '@adobe/react-spectrum'
import React from 'react'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {WithParticipant} from 'src/types'
import './ParticipantCircleWithLabel.css'

export const ParticipantCircleWithLabel: React.FC<WithParticipant> = ({participant}) => {
    return <Flex direction={'row'} UNSAFE_className={'participant-circle-with-label'} alignItems={'center'}>
        <ParticipantIconWithCircle participant={participant}/>
        <div className={'label'}><Text>{participant.name}</Text></div>
    </Flex>
}
