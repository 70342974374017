import React from 'react'
import {ActionButton} from '@adobe/react-spectrum'
import {LeaveCallIcon} from 'src/components/icons/LeaveCallIcon'
import {useTranslation} from 'react-i18next'
import {useAppDispatch} from 'src/state/types'
import {leaveCall} from 'src/state/calls'
import {WithWorkshopId} from 'src/types'
import './LeaveCallButton.css'

export const LeaveCallButton: React.FC<WithWorkshopId> = ({workshopId}) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const leaveCallPressed = () => dispatch(leaveCall({workshopId}))
    return <ActionButton UNSAFE_className='leave-call-button' marginStart='size-200' onPress={leaveCallPressed}>
        <LeaveCallIcon />
        {t('button.leave-call')}
    </ActionButton>
}
