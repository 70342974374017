import {meetingsAdapter} from './meetings.adapter'
import {MeetingAdapter, MeetingEntity, WithMeetingsState} from './meetings.model'
import {WithWorkshopsState} from 'src/state/workshops'
import {selectCurrentPhase, selectPhaseById, WithPhasesState} from 'src/state/phases'

const meetingsSelectors = meetingsAdapter.getSelectors<WithMeetingsState>(
    (state: WithMeetingsState) => state.meetings
)
export const selectMeetingById: (id: string) => (state: WithMeetingsState) => MeetingEntity | undefined = id => state =>
    meetingsSelectors.selectById(state, id)

export const selectActiveMeeting: (state: WithMeetingsState & WithWorkshopsState & WithPhasesState) => MeetingAdapter | undefined = state => {
    if (state.meetings.activeMeeting) {
        const meeting = selectMeetingById(state.meetings.activeMeeting)(state)
        if(meeting) {
            const meetingAdapter = new MeetingAdapter(meeting)
            if (meetingAdapter.phaseId) {
                const meetingPhase = selectPhaseById(meetingAdapter.phaseId)(state)
                const currentPhase = selectCurrentPhase(meetingPhase!.workshopId)(state)
                return (currentPhase?.id === meetingPhase?.id) ? meetingAdapter : undefined
            }
            return meetingAdapter
        }
    }
}
