import {CSSProperties} from 'react'
import styled from 'styled-components'
import {Size} from '../../state/layout'
import {toCss} from '../layout/util'

export type IconSize = 'small' | 'medium'

export type IconProps = {
    className?: string
    size?: IconSize
    onClick?(): void
    style?: CSSProperties
}

const sizes: Record<IconSize, {width: Size, height: Size}> = {
    small: {
        width: {value: 17, unit: 'px'},
        height: {value: 17, unit: 'px'},
    },
    medium: {
        width: {value: 40, unit: 'px'},
        height: {value: 40, unit: 'px'},
    }
}

export const Icon = styled.div<IconProps & { icon: string }>`
display: inline-block;
width: ${({size}) => toCss(sizes[size || 'medium'].width)};
height: ${({size}) => toCss(sizes[size || 'medium'].height)};
background: url(${({icon}) => icon});
background-repeat: no-repeat;
background-position: center center;
${({size}) => size === 'small' ? 'background-size: contain;' : ''};
`
