import {Button, ButtonGroup, Content, Dialog, Divider, Flex, Header, Heading, Text} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {selectAuthenticatedUser, UserProfileUpdateModel} from 'src/state/auth'
import {ProfileChange, UserProfileForm} from './UserProfileForm'

interface Props {
  close(): void
  save(userProfileModel: UserProfileUpdateModel): void
}

export const UserProfileDialog: React.FC<Props> = ({close, save}) => {
    const user = useSelector(selectAuthenticatedUser)
    const {t} = useTranslation()
    const [profile, setProfile] = useState<ProfileChange>({valid: false, profile: {
        biography: user.biography || ''
    }})

    return <Dialog size='M'>
        <Heading>
            <Flex alignItems="center" gap="size-100">
                <Text>{t('dialog.profile.headline')}</Text>
            </Flex>
        </Heading>
        <Header>
            {user.name}
        </Header>
        <Divider/>
        <Content>
            <UserProfileForm profile={user} onProfileChange={setProfile} />
        </Content>
        <ButtonGroup>
            <Button
                variant='secondary'
                onPress={close}
            >{t('button.cancel')}</Button>
            <Button
                variant='cta'
                onPress={() => profile.valid && save(profile.profile)}
            >{t('button.save')}</Button>
        </ButtonGroup>
    </Dialog>
}
