import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {topicRoomEventDispatcher} from 'src/state/workshop-event-dispatcher'
import {TopicRoomEventType} from 'src/state/workshop-event.model'
import {ModeratedWithRolesRoleChangeEventDto} from 'src/websocket/topic-room-event-types.dto'
import {TopicAssignments} from './topic-assignments.model'
import {TopicAssignmentsApiService} from './topic-assignments.service'

export const TopicAssignmentsUpdated = createAction<TopicAssignments>('topic-assignments/update')

topicRoomEventDispatcher.registerActionCreator({
    type: 'ModeratedRolesUpdated',
    actionCreator: createAsyncThunk<void, TopicRoomEventType>(
        '',
        (event, thunkAPI) => {
            const updateEvent = event as ModeratedWithRolesRoleChangeEventDto
            const topicAssignments: TopicAssignments = new TopicAssignmentsApiService().toModel(
                event.meta.phaseId!,
                event.meta.topicId,
                updateEvent.payload.content
            )
            thunkAPI.dispatch(TopicAssignmentsUpdated(topicAssignments))
        }
    )
})

