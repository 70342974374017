import {UserProfile, WithAuthState} from 'src/state/auth'
import {ModeOfInteraction} from 'src/state/common.model'
import {WithParticipantId} from 'src/types'
import {MeetingAdapter, MeetingDeviceSettings, WithMeetingsState} from '../meetings.model'
import {selectActiveMeeting} from '../meetings.selectors'
import {MeetingDeviceSettingsStrategy} from './meeting-device-settings-strategy'
import {WithWorkshopsState} from 'src/state/workshops'
import {WithPhasesState} from 'src/state/phases'

export abstract class AbstractDeviceSettingsStrategy<STATE extends  WithMeetingsState & WithWorkshopsState & WithPhasesState & WithAuthState> implements MeetingDeviceSettingsStrategy {
    public constructor(protected state: STATE) {
    }

    abstract getDeviceSettings(): MeetingDeviceSettings

    abstract isResponsible(): boolean

    protected get activeMeeting(): MeetingAdapter | undefined {
        return selectActiveMeeting(this.state)
    }

    protected get modeOfInteraction(): ModeOfInteraction | undefined {
        return this.activeMeeting?.modeOfInteraction
    }

    protected hasParticipantId(): this is Readonly<WithParticipantId> {
        return this.participantId !== undefined
    }

    protected get me(): UserProfile | undefined {
        return this.state.auth.user
    }

    get participantId(): string | undefined {
        return this.me?.id
    }
}
