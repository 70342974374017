import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {RxStomp} from '@stomp/rx-stomp'
import {JoinGroupRequestHandledPayload} from 'src/components/meetings/calls/GroupCall'
import {stompClient} from 'src/provider/StompClientProvider'
import {leaveMeeting} from 'src/state/meetings'
import {WithParticipantId, WithWorkshopId} from 'src/types'
import {CallsApi, MeetingsApi} from 'src/api'
import {selectAuthenticatedUser, WithAuthState} from 'src/state/auth'
import {CallEntity, WithCallsState} from 'src/state/calls/calls.model'
import {selectCurrentCall, selectOutgoingCall} from 'src/state/calls/calls.selectors'
import {v4 as uuid} from 'uuid'

async function startNewCall(
    state: WithAuthState & WithCallsState,
    participantId: string,
    workshopId: string): Promise<CallEntity> {
    const me = selectAuthenticatedUser(state).id
    const callId = uuid()
    const meetingsApi = new MeetingsApi()
    const chimeMeeting = await meetingsApi.requestMeeting(callId)
    await meetingsApi.createAttendee(chimeMeeting.externalMeetingId)
    await new CallsApi().startCall(workshopId, participantId, callId)
    return {
        id: callId,
        chimeMeeting,
        participantIds: [me, participantId]
    }
}

async function addParticipant(
    state: WithAuthState & WithCallsState,
    currentCall: CallEntity,
    participantId: string,
    workshopId: string) {
    await new CallsApi().addParticipant(workshopId, participantId, currentCall.id)
    return {
        ...currentCall,
        participantIds: currentCall.participantIds.concat(participantId)
    }
}

async function startCall(
    state: WithAuthState & WithCallsState,
    participantId: string,
    workshopId: string)
    : Promise<CallEntity> {
    const currentCall = selectCurrentCall(state)
    return currentCall
        ? addParticipant(state, currentCall, participantId, workshopId)
        : startNewCall(state, participantId, workshopId)
}

export const joinExistingCall = createAsyncThunk<CallEntity, CallEntity>(
    'calls/join-call',
    async (call: CallEntity) => {
        const meetingsApi = new MeetingsApi()
        const chimeMeeting = await meetingsApi.requestMeeting(call.id)
        await meetingsApi.createAttendee(chimeMeeting.externalMeetingId)

        return call
    }
)

export const startVideoCall = createAsyncThunk<CallEntity, WithParticipantId & WithWorkshopId>(
    'calls/start-video-call',
    async ({participantId, workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithCallsState & WithAuthState
        return await startCall(state, participantId, workshopId)
    })

export const startAudioCall = createAsyncThunk<CallEntity, WithParticipantId & WithWorkshopId>(
    'calls/start-audio-call',
    async ({participantId, workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithCallsState & WithAuthState
        return await startCall(state, participantId, workshopId)
    })

export const leaveCall = createAsyncThunk<void, WithWorkshopId>(
    'calls/leave-call',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithCallsState & WithAuthState
        const meetingId = selectCurrentCall(state)?.id
        const myId = selectAuthenticatedUser(state).id
        if(!myId || !meetingId)
            return
        await thunkAPI.dispatch(leaveMeeting(meetingId))
        await new CallsApi().leaveCall(workshopId, meetingId, myId)
    }
)

export const loadAllCalls = createAsyncThunk<CallEntity[], WithWorkshopId>(
    'calls/load-all',
    async ({workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithAuthState
        const myId = selectAuthenticatedUser(state).id
        const calls = (await new CallsApi().getAllCalls(workshopId))
            .map(dto => <CallEntity>{
                id: dto.meetingId,
                participantIds: dto.participantIds,
                status: dto.status
            })
        const myCall = calls.find(call => call.participantIds.includes(myId))
        if(myCall) {
            thunkAPI.dispatch(joinExistingCall(myCall))
        }
        return calls
    })

function sendCallRequestToGroupCallParticipants(client: RxStomp, workshopId: string, call: CallEntity, myId: string): void {
    client.publish({
        destination: `/app/workshops/${workshopId}/calls/${call.id}`,
        body: JSON.stringify({
            type: 'JoinGroupCall',
            payload: {
                caller: myId
            }
        })
    })
}

export const joinGroupCall = createAsyncThunk<void, { call: CallEntity } & WithWorkshopId>(
    'calls/join-group-call',
    async ({call, workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithAuthState
        const myId = selectAuthenticatedUser(state).id
        thunkAPI.dispatch(setOutgoingCall(call.id))
        await new CallsApi().addParticipant(workshopId, myId, call.id)
        const client = await stompClient()
        sendCallRequestToGroupCallParticipants(client, workshopId, call, myId)
    }
)

export const abortOutgoingCall = createAsyncThunk<void, WithWorkshopId>(
    'calls/abort-outgoing-call',
    async ({workshopId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithAuthState & WithCallsState
        const meetingId = selectOutgoingCall(state)!
        thunkAPI.dispatch(setOutgoingCall(undefined))
        const myId = selectAuthenticatedUser(state).id
        await new CallsApi().leaveCall(workshopId, meetingId, myId)
        const client = await stompClient()
        sendRequestToJoinGroupCallHandled(client, workshopId, meetingId, myId)
    }
)

export function sendRequestToJoinGroupCallHandled(
    client: RxStomp,
    workshopId: string,
    meetingId: string,
    participantId: string
): void {
    const payload: JoinGroupRequestHandledPayload = {
        incomingCallId: meetingId + participantId,
        meetingId: meetingId,
        participantId: participantId
    }

    client.publish({
        destination: `/app/workshops/${workshopId}/calls/${meetingId}`,
        body: JSON.stringify({
            type: 'JoinGroupRequestHandled',
            payload: payload
        })
    })
}


export const setOutgoingCall = createAction<string | undefined>('calls/set-outgoing-call')
