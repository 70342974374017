import {useAudioVideo, useLocalVideo} from 'amazon-chime-sdk-component-library-react'
import {BaseSdkProps} from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base'
import {VideoTileState} from 'amazon-chime-sdk-js'
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import {cloneStreamIfRequired} from 'src/components/video/utils'

interface Props extends BaseSdkProps {
    id?: string
    nameplate?: string
    className?: string
}

export const CustomLocalVideo: React.FC<Props> = ({className, ...rest}) => {
    const {tileId, isVideoEnabled} = useLocalVideo()
    const audioVideo = useAudioVideo()
    const videoEl: MutableRefObject<HTMLVideoElement | null> = useRef<HTMLVideoElement>(null)
    const [active, setActive] = useState(() =>
        audioVideo?.hasStartedLocalVideoTile()
    )

    useEffect(() => {
        if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
            return
        }

        audioVideo.bindVideoElement(tileId, videoEl.current)

        return () => {
            const tile = audioVideo.getVideoTile(tileId)
            if (tile) {
                audioVideo.unbindVideoElement(tileId)
            }
        }
    }, [audioVideo, tileId, isVideoEnabled])

    useEffect(() => {
        if (!audioVideo) {
            return
        }

        const observer = {
            videoTileDidUpdate: (tileState: VideoTileState) => {
                if (
                    !tileState.boundAttendeeId ||
                  !tileState.localTile ||
                  !tileState.tileId ||
                  !videoEl.current
                ) {
                    return
                }
                cloneStreamIfRequired(tileState, videoEl)

                if (tileState.active !== active) {
                    setActive(tileState.active)
                }
            },
        }

        audioVideo.addObserver(observer)

        return () => audioVideo.removeObserver(observer)
    }, [audioVideo, active])

    return (
        <video
            className={`${className} ${isVideoEnabled ? 'local-video-active' : 'local-video-inactive'}`}
            ref={videoEl}
            {...rest}
        />
    )
}

export default CustomLocalVideo
