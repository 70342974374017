import {useEffect} from 'react'
import {selectActiveMeeting} from 'src/state/meetings'
import {LocationType, updateLocation} from 'src/state/participant-locations'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export function useMyLocation(locationType: LocationType, locationId?: string): void {
    const activeMeeting = useAppSelector(selectActiveMeeting)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(updateLocation({
            location: {
                type: locationType,
                id: locationId
            },
            meetingId: activeMeeting?.id
        }))

        return () => {
            dispatch(updateLocation({
                location: undefined,
                meetingId: activeMeeting?.id
            }))
        }
    }, [activeMeeting?.id, locationType, locationId])
}
