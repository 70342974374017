import {createAsyncThunk} from '@reduxjs/toolkit'
import {CreateNoteModel, NoteForTopic} from './topic-notes.model'
import {NotesForTopicsApiService} from './topic-notes.service'
import {WithTopicId, WithWorkshopId} from 'src/types'

export const addNote = createAsyncThunk<void, CreateNoteModel>(
    'topics/createNoteForTopic',
    async (model) => {
        await new NotesForTopicsApiService().createNote(model)
    })

export const updateNote = createAsyncThunk<void, WithWorkshopId & WithTopicId & { note: NoteForTopic }>(
    'topics/updateNoteForTopic',
    async ({workshopId, topicId, note}) => {
        await new NotesForTopicsApiService().updateNote(workshopId, topicId, note)
    }
)
export const deleteNote = createAsyncThunk<void, WithWorkshopId & WithTopicId & { noteId: string }>(
    'topics/createNoteForTopic',
    async ({workshopId, topicId, noteId}) => {
        await new NotesForTopicsApiService().deleteNote(workshopId, topicId, noteId)
    })
export const loadNotesForTopic = createAsyncThunk<{ workshopId: string, topicId: string, notes: NoteForTopic[] }, { workshopId: string, topicId: string }>(
    'topics/loadNotesForTopic',
    async ({workshopId, topicId}) => ({
        workshopId, topicId, notes: await new NotesForTopicsApiService().loadNotes(workshopId, topicId)
    })
)
