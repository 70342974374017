import {createAction} from '@reduxjs/toolkit'
import {LocationModel} from './participant-locations.model'
import {workshopEventDispatcher} from 'src/state/workshop-event-dispatcher'
import {WithMeetingId, WithParticipantId} from 'src/types'

export type ParticipantLocationDto = WithParticipantId & Partial<WithMeetingId> & {
    sessionId: string
    location?: LocationModel
}

export type ParticipantLocationUpdate = ParticipantLocationDto & {
    lastSeen: number
}

workshopEventDispatcher.registerActionCreator({
    type: 'ParticipantLocationReceived',
    actionCreator: event => ParticipantLocationReceived({
        ...event.payload,
        lastSeen: new Date().getTime(),
    } as ParticipantLocationUpdate)
})

export const ParticipantLocationReceived = createAction<ParticipantLocationUpdate>('participant-locations/events/ParticipantLocationReceived')
