import React, {useEffect} from 'react'
import {ModeratedWithRolesMeeting} from './ModeratedWithRolesMeeting'
import {loadTopicAssignments, selectTopicAssignmentRoles} from 'src/state/topic-assignments'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithMeetingId, WithWorkshopId, WithPhaseId, WithTopicId} from 'src/types'
import {MeetingSidebar} from 'src/components/meetings/MeetingSidebar'

export const TopicModeratedWithRolesMeeting: React.FC<WithMeetingId & WithWorkshopId & WithPhaseId & WithTopicId> = ({
    workshopId,
    phaseId,
    topicId,
    meetingId
}) => {
    const roles = useAppSelector(selectTopicAssignmentRoles(phaseId, topicId))
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadTopicAssignments({workshopId, phaseId, topicId}))
    }, [workshopId, phaseId, topicId])

    return <MeetingSidebar>
        <ModeratedWithRolesMeeting
            meetingId={meetingId}
            workshopId={workshopId}
            phaseId={phaseId}
            topicId={topicId}
            moderatedWithRolesId={`${phaseId}-${topicId}`}
            roles={roles}
        />
    </MeetingSidebar>
}

