import {ActionButton} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {DeleteConfirmDialog} from './DeleteConfirmDialog'
import {DeleteIcon} from 'src/components/icons/DeleteIcon'

export const DeleteButton: React.FC<{
    onDelete(): void
}> = ({onDelete}) => {
    const [open, setOpen] = useState<boolean>(false)

    return <>
        <ActionButton onPress={() => setOpen(true)}>
            <DeleteIcon/>
        </ActionButton>
        <DeleteConfirmDialog open={open} setOpen={setOpen} deleteConfirmed={onDelete}/>
    </>
}

