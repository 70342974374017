import classNames from 'classnames'
import React, {ReactNode} from 'react'

export const ContentWithToolbox: React.FC<{
    className?: string
    toolbox?: ReactNode
}> = ({children, toolbox, className}) => {
    return <div className={classNames({
        'content-with-toolbox': true,
        [className || '']: Boolean(className)
    })}>
        <div className={'content-with-toolbox-content'}>
            {children}
        </div>
        {toolbox}
    </div>
}
