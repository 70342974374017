import {useRosterState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useQueuedParticipants} from 'src/hooks'
import {WithPhaseId, WithWorkshopId} from 'src/types'

export const ModeratedQueueMeetingHeader: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const {roster} = useRosterState()
    const queuedParticipants = useQueuedParticipants(workshopId, phaseId)
    const {t} = useTranslation()
    return <>{t('moderated-queue.roster-header', {
        numParticipants: Object.keys(roster).length,
        queueSize: queuedParticipants.length
    }
    )}</>
}
