import moment from 'moment'
import React from 'react'

export const Duration: React.FC<{
    start: string
    end: string
}> = ({start, end}) => {
    const duration = moment.duration(moment.utc(end).diff(moment.utc(start)))
    const addLeadingZeros = (num: number) => ('' + num).padStart(2, '0')
    return <span>
        {addLeadingZeros(duration.hours())}:{addLeadingZeros(duration.minutes())}
    </span>
}
