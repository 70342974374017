import {API} from 'aws-amplify'
import {validate} from 'src/tools/validation'
import {Response} from '../response'
import {GetPhaseDto, GetPhasesDto, PhaseDto} from './phases.dto'
import {validatePhaseDto} from './phases.validation'

export class PhasesApi {
    async getPhases(workshopId: string): Promise<PhaseDto[]> {
        const phasesResponse: Response<GetPhasesDto> = await API.get('backend', `api/workshops/${workshopId}/phases`, {})
        if(phasesResponse && phasesResponse._embedded) {
            const phases = phasesResponse._embedded.phases
            phases.forEach(phase => this.validatePhase(phase))
            return phases
        }
        return []
    }

    validatePhase(phase: PhaseDto): void {
        validate<PhaseDto>(phase, validatePhaseDto())
    }


    async updatePhase(workshopId: string, phase: PhaseDto): Promise<PhaseDto> {
        const response: GetPhaseDto = await API.put('backend', `api/workshops/${workshopId}/phases/${phase.id}`, {
            body: phase
        })
        return response || Promise.reject('Unable to update phase')
    }

    async addPhase(workshopId: string, phase: PhaseDto): Promise<PhaseDto> {
        const response: GetPhaseDto = await API.post('backend', `api/workshops/${workshopId}/phases`, {
            body: phase
        })
        return response || Promise.reject('Unable to add phase')
    }

    async deletePhase(workshopId: string, id: string): Promise<void> {
        await API.del('backend', `api/workshops/${workshopId}/phases/${id}`, {})
    }
}
