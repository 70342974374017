import React, {PropsWithChildren} from 'react'
import './tab.css'

export interface TabItemProps {
    tabKey: string
    label: string
    route?: string
}

export const TabItem : React.FC<TabItemProps> = (props: PropsWithChildren<TabItemProps>) => {
    const {label, children} = props
    return <div className='tab-item' aria-label={label}>
        {children}
    </div>
}
