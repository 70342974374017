import React, {useState} from 'react'
import {WithTopic, WithWorkshopId} from 'src/types'
import {useTranslation} from 'react-i18next'
import {Button, DialogTrigger,} from '@adobe/react-spectrum'
import {Topic, updateTopic} from 'src/state/topics'
import {useDispatch} from 'react-redux'
import {EditTopicTitleDialog} from './EditTopicTitleDialog'

export const EditTopicButton: React.FC<WithWorkshopId & WithTopic> = ({
    workshopId,
    topic
}) => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const update = async (topic: Topic) => {
        dispatch(updateTopic({workshopId, topic}))
        setOpen(false)
    }

    return (
        <DialogTrigger isDismissable
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}>
            <Button variant='primary'>{t('button.edit-topic')}</Button>
            <EditTopicTitleDialog
                topic={topic}
                close={() => {
                    setOpen(false)
                }}
                update={update}/>
        </DialogTrigger>
    )
}

