import {useAttendeeStatus, useLocalVideo, useRemoteVideoTileState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {MicrophoneActiveIcon, MicrophoneInactiveIcon} from 'src/components/icons'
import {MeetingParticipant} from 'src/state/meetings'
import {selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import CustomLocalVideo from '../../video/CustomLocalVideo'
import CustomRemoteVideo from '../../video/CustomRemoteVideo'
import './ParticipantPictureOrVideo.css'
import {ParticipantVideoAvatar} from './ParticipantVideoAvatar'

type Props = {
    participant: MeetingParticipant,
    size: 'roster' | 'presenter' | 'stage'
    role?: string
}

const sizeToClasses = {
    roster: {
        videoContainer: 'video-container-participants',
    },
    stage: {
        videoContainer: 'video-container-stage',
    },
    presenter: {
        videoContainer: 'video-container-presenter',
    }
}

export const ParticipantPictureOrVideo: React.FC<Props> = ({
    participant,
    size,
    role,
}) => {
    const attendeeStatus = useAttendeeStatus(participant.attendeeId)

    return <div className={sizeToClasses[size].videoContainer}>
        {role ? <div className={'video-role-container'}><div className={'video-role'}>{role}</div></div> : <></>}
        <div className={'video-controls'}>
            <div className='video-control-icon-container'>{attendeeStatus.muted ? <MicrophoneInactiveIcon /> : <MicrophoneActiveIcon />}</div>
        </div>
        <VideoOrAvatar participant={participant} size={size} />
        <div className={'video-nameplate'}>{participant.name}</div>
    </div>
}

export const VideoOrAvatar: React.FC<Props> = ({participant}) => {
    const {attendeeIdToTileId} = useRemoteVideoTileState()
    const localVideo = useLocalVideo()
    const me = useAppSelector(selectMyParticipant)

    const isMe = participant.id === me?.id
    if (isMe && localVideo.isVideoEnabled) {
        return <CustomLocalVideo />
    }
    if (attendeeIdToTileId[participant.attendeeId]) {
        return <CustomRemoteVideo
            tileId={attendeeIdToTileId[participant.attendeeId]}
            className={'video'}
        />
    }
    return <ParticipantVideoAvatar participant={participant} />
}
