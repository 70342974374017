import React from 'react'
import {useParams} from 'react-router-dom'
import {WithWorkshopId} from 'src/types'
import {VideoConferenceForId} from './VideoConferenceForId'
import {useAppSelector} from 'src/state/types'
import {selectCurrentCall} from 'src/state/calls'

export const VideoCall: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const call = useAppSelector(selectCurrentCall)
    return <>
        {call && <VideoConferenceForId
            workshopId={workshopId}
            meetingId={call.id}
            modeOfInteraction={'group-call'}
        />}
    </>
}
