import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Footer,
    Form,
    Heading,
    TextArea,
    TextField
} from '@adobe/react-spectrum'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

export interface CreatedTopic {
    title: string,
    description: string
}

interface Props {
    enableDescription: boolean
    saveLabel: string
    close(): void

    create(createTopicModel: CreatedTopic): void
}

export const CreateTopicDialog: React.FC<Props> = ({
    close,
    create,
    saveLabel,
    enableDescription
}) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const isValidTitle = useMemo(() => title.length > 0, [title])
    const isValidDescription = useMemo(() => !enableDescription || description.length > 0, [description])
    const isValid = isValidTitle && isValidDescription
    const {t} = useTranslation()
    const handleSubmit = () => isValid && create({
        title: title,
        description,
    })

    return <Dialog size='L' data-testid='create-topic-dialog'>
        <Heading>{t('dialog.create-topic.headline')}</Heading>
        <Divider/>
        <Content>
            <Form isRequired necessityIndicator='label' onSubmit={handleSubmit}>
                <TextField label={t('dialog.create-topic.name-label')}
                    placeholder={t('dialog.create-topic.name-placeholder')}
                    onChange={setTitle}
                    validationState={isValidTitle ? 'valid' : 'invalid'}
                    autoFocus
                    data-testid='create-topic-dialog-title'
                />
                {enableDescription ? <TextArea label={t('dialog.create-topic.description-label')}
                    placeholder={t('dialog.create-topic.description-placeholder')}
                    onChange={setDescription}
                    validationState={isValidDescription ? 'valid' : 'invalid'}
                    data-testid='create-topic-dialog-description'
                /> : <></>}
            </Form>
        </Content>
        <Footer>
            <ButtonGroup>
                <Button
                    variant='secondary'
                    onPress={close}
                    data-testid='create-topic-button-cancel'>{t('button.cancel')}</Button>
                <Button
                    variant='primary'
                    onPress={handleSubmit}
                    data-testid='create-topic-button-create'>{saveLabel}</Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
