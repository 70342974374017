import {Button, Text} from '@adobe/react-spectrum'
import StopIcon from '@spectrum-icons/workflow/Stop'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {finishCurrentPhase} from 'src/state/phases'
import {useAppDispatch} from 'src/state/types'

export const FinishCurrentPhaseButton: React.FC<{ workshopId: string }> = ({workshopId}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <Button variant='primary' onPress={() => dispatch(finishCurrentPhase(workshopId))}>
        <StopIcon/>
        <Text>{t('button.finish-phase')}</Text>
    </Button>
}
