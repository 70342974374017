import {createSlice} from '@reduxjs/toolkit'
import {loadWorkshop} from 'src/state/workshops'
import {v4} from 'uuid'
import {removeOutdatedParticipantLocations, updateLocation} from './participant-locations.actions'
import {participantLocationsAdapter} from './participant-locations.adapter'
import {ParticipantLocationReceived} from './participant-locations.events'
import {ParticipantLocationState} from './participant-locations.model'
import {ParticipantLocationsStateService} from './participant-locations.service'

const sessionId = getSessionId()

function getSessionId(): string {
    let id = window.sessionStorage.getItem('sessionId')
    if (!id) {
        id = v4()
        window.sessionStorage.setItem('sessionId', id)
    }
    return id
}

export function createInitialState(): ParticipantLocationState {
    return {
        ...participantLocationsAdapter.getInitialState(),
        sessionId: sessionId,
        myLocation: undefined,
        byLocation: {
            plenary: [],
            cafe: [],
            'topic-breakout': {}
        },
        byParticipant: {}
    }
}

export const initialState: ParticipantLocationState = createInitialState()

export const stateService = new ParticipantLocationsStateService(participantLocationsAdapter)

export const participantLocationsSlice = createSlice({
    name: 'participant-locations',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadWorkshop.pending, (state) => {
                stateService.restoreInitialState(state, createInitialState())
            })
            .addCase(ParticipantLocationReceived, (state, {payload: participantLocationUpdate}) => {
                const participantLocation = stateService.toParticipantLocation(participantLocationUpdate)
                if (participantLocation) {
                    stateService.updateParticipantLocation(state, participantLocation)
                } else {
                    stateService.removeParticipantLocation(state, participantLocationUpdate)
                }
            })
            .addCase(updateLocation.fulfilled, (state, {payload: participantLocationUpdate}) => {
                state.myLocation = stateService.toParticipantLocation(participantLocationUpdate)
            })
            .addCase(removeOutdatedParticipantLocations, (state) => {
                stateService.removeOutdatedLocations(state)
            })

    }
})
