import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {DeviceSettings} from './components/control/meeting/settings/DeviceSettings'
import {UserProfilePage} from './container/UserProfilePage'
import {WorkshopLandingPage} from './container/workshops/WorkshopLandingPage'
import {WorkshopsPage} from './container/workshops/WorkshopsPage'
import {WorkshopConfiguration} from 'src/container/workshops/WorkshopConfiguration'

export const AppRouting: React.FC = () => {
    return <>
        <Switch>
            <Route path='/settings'><DeviceSettings/></Route>
            <Route path='/profile'><UserProfilePage/></Route>
            <Route path='/workshops/:workshopId/configure'><WorkshopConfiguration/></Route>
            <Route path='/workshops/:workshopId'><WorkshopLandingPage /></Route>
            <Route path='/workshops'><WorkshopsPage/></Route>
            <Route><WorkshopsPage/></Route>
        </Switch>
    </>
}
