import {createSlice} from '@reduxjs/toolkit'
import {attachModeratedWithRolesToMeeting, attachRolesToMeeting, leaveMeeting, requestMeeting} from './meetings.actions'
import {meetingsAdapter} from './meetings.adapter'
import { MeetingsState} from './meetings.model'

export const initialState: MeetingsState = {
    ...meetingsAdapter.getInitialState(),
    activeMeeting: undefined as string | undefined,
}

export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestMeeting.pending, (state) => {
                state.activeMeeting = undefined
            })
            .addCase(requestMeeting.fulfilled, (state, {payload: meeting}) => {
                if (meeting) {
                    meetingsAdapter.upsertOne(state, meeting)
                    state.activeMeeting = meeting.id
                }
            })
            .addCase(leaveMeeting.pending, (state) => {
                state.activeMeeting = undefined
            })
            .addCase(leaveMeeting.fulfilled, (state, {payload: meetingId}) => {
                if (meetingId === state.activeMeeting) {
                    state.activeMeeting = undefined
                }
            })
            .addCase(attachModeratedWithRolesToMeeting, (state, {payload: {meetingId, moderatedWithRolesId}}) => {
                const meeting = state.entities[meetingId]
                if (meeting) {
                    meeting.moderatedWithRolesId = moderatedWithRolesId
                }
            })
            .addCase(attachRolesToMeeting, (state, {payload: {meetingId, roles}}) => {
                const meeting = state.entities[meetingId]
                if (meeting) {
                    meeting.roles = roles
                }
            })
    }
})
