import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {DefaultTopicGenerationContent} from './open/content/DefaultTopicGenerationContent'
import {ModeratedQueueTopicGenerationContent} from './moderated-queue/content/ModeratedQueueTopicGenerationContent'
import {loadTopics, selectTopicsForWorkshop} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId, WithPhase} from 'src/types'
import './TopicGeneration.css'

export const TopicGenerationContent: React.FC<WithPhase> = ({
    phase
}) => {
    const {workshopId} = useParams<WithWorkshopId>()
    const dispatch = useAppDispatch()
    const topics = useAppSelector(selectTopicsForWorkshop(workshopId))

    useEffect(() => {
        dispatch(loadTopics({workshopId}))
    }, [workshopId])

    switch (phase.meetingVariant.currentModeOfInteraction) {
    case 'moderated-queue':
        return <ModeratedQueueTopicGenerationContent
            workshopId={workshopId}
            phase={phase}
            topics={topics}
        />
    default:
        return <DefaultTopicGenerationContent
            workshopId={workshopId}
            phase={phase}
            topics={topics}
        />
    }
}

