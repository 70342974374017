import {createSlice, EntityAdapter, EntityState} from '@reduxjs/toolkit'
import {topicsAdapter} from 'src/state/topics/topics.adapter'
import {Topic} from 'src/state/topics/topics.model'
import {
    closeTopic, createPredefinedTopic,
    createTopic,
    loadTopics,
    loadTopicWithNotes
} from './topics.actions'
import {TopicCreated, TopicUpdated} from './topics.events'
import {voteForTopic} from 'src/state/topic-votes'

export class TopicsStateService {
    constructor(private topicsAdapter: EntityAdapter<Topic>) {
    }

    addTopic(topic: Topic, state: EntityState<Topic>): void {
        this.topicsAdapter.upsertOne(state, topic)
    }

    loadTopics(topics: Topic[], state: EntityState<Topic>): void {
        this.topicsAdapter.setAll(state, topics)
    }

    voteForTopic(topic: Topic, state: EntityState<Topic>): void {
        return this.updateTopic(topic, state)
    }

    createTopic(topic: Topic, state: EntityState<Topic>): void {
        if(state.entities[topic.id]) {
            this.updateTopic(topic, state)
        } else {
            this.topicsAdapter.addOne(state, topic)
        }
    }

    updateTopic(topic: Topic, state: EntityState<Topic>): void {
        this.topicsAdapter.updateOne(state, {
            changes: topic,
            id: topic.id
        })
    }
}

const stateService = new TopicsStateService(topicsAdapter)

export const initialState = topicsAdapter.getInitialState()
export const topicsSlice = createSlice({
    name: 'topics',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(createTopic.fulfilled, (state, {payload: topic}) => {
                stateService.addTopic(topic, state)
            })
            .addCase(createPredefinedTopic.fulfilled, (state, {payload: topic}) => {
                stateService.addTopic(topic, state)
            })
            .addCase(closeTopic.fulfilled, (state, {payload: topic}) => {
                stateService.updateTopic(topic, state)
            })
            .addCase(loadTopics.fulfilled, (state, {payload: topics}) => {
                stateService.loadTopics(topics, state)
            })
            .addCase(loadTopicWithNotes.fulfilled, (state, {payload: topic}) => {
                stateService.addTopic(topic, state)
            })
            .addCase(TopicCreated, (state, {payload: topic}) => {
                stateService.createTopic(topic, state)
            })
            .addCase(TopicUpdated, (state, {payload: topic}) => {
                stateService.updateTopic(topic, state)
            })
            // topic might change its state when topic got enough votes
            .addCase(voteForTopic.fulfilled, (state, {payload: topic}) => {
                stateService.voteForTopic(topic, state)
            })
    }
})
