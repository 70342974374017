import {Flex, Item, Picker, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {MeetingVariantType} from 'src/state/common.model'

export const MeetingVariantSelection: React.FC<{
    selected: MeetingVariantType,
    options: MeetingVariantType[]
    onSelect(meetingType: MeetingVariantType): void
}> = ({selected, options, onSelect}) => {
    const {t} = useTranslation()

    return <Flex direction='row' alignContent='baseline'>
        <Text UNSAFE_className='mode-of-interaction-label'>{t('workshop-designer.meeting-variant-label')}</Text>
        <Picker
            selectedKey={selected}
            onSelectionChange={selected => { onSelect(selected as MeetingVariantType) }}>
            {options.map(meetingVariant => <Item key={meetingVariant}>{meetingVariant}</Item>)}
        </Picker>
    </Flex>
}
