import {NoteDto, NotesForTopicApi} from 'src/api'
import {CreateNoteModel, NoteForTopic} from './topic-notes.model'

export function toNoteForTopicModel(dto: NoteDto): NoteForTopic {
    return {
        id: dto.id,
        note: dto.note
    }
}

export function toNoteDto(note: NoteForTopic): NoteDto {
    return {
        ...note
    }
}

export class NotesForTopicsApiService {

    constructor(private api: NotesForTopicApi = new NotesForTopicApi()) {
    }

    async createNote(model: CreateNoteModel): Promise<void> {
        await this.api.createNote({
            workshopId: model.workshopId,
            topicId: model.topicId,
            note: model.note
        })
    }

    async loadNotes(workshopId: string, topicId: string): Promise<NoteForTopic[]> {
        const dtos = await this.api.loadNotes(workshopId, topicId)
        return dtos.map(dto => toNoteForTopicModel(dto))
    }

    async deleteNote(workshopId: string, topicId: string, noteId: string): Promise<void> {
        await this.api.deleteNote(workshopId, topicId, noteId)
    }

    async updateNote(workshopId: string, topicId: string, noteForTopic: NoteForTopic): Promise<void> {
        await this.api.updateNote(workshopId, topicId, toNoteDto(noteForTopic))
    }
}
