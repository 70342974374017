import React from 'react'
import {useFilteredParticipants} from 'src/hooks'
import {WithRoles, WithWorkshopId} from 'src/types'
import {NumberOfParticipantsCircle} from 'src/components/roster/NumberOfParticipantsCircle'

export type NetworkersRosterProps = WithWorkshopId & WithRoles & {
    includeRoles?: string[]
    excludeRoles?: string[]
}
export const NetworkersRoster: React.FC<NetworkersRosterProps> = (
    {
        workshopId,
        roles,
        includeRoles,
        excludeRoles,
    }) => {
    const activeParticipants = useFilteredParticipants(workshopId, roles, includeRoles, excludeRoles)
    return <NumberOfParticipantsCircle label={activeParticipants.length} title={activeParticipants.map(participant => participant.name).join(', ')}/>
}

