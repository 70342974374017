import {WithModeratedWithRoleState} from './moderated-with-roles.model'
import {TopicAssignmentRole} from 'src/state/topic-assignments'

export const selectModeratedWithRolesActiveRole: (moderatedWithRolesId: string) => (state: WithModeratedWithRoleState) => TopicAssignmentRole = moderatedWithRolesId => state => {
    return state.moderatedWithRoles.entities[moderatedWithRolesId]?.activeRole || 'debater'
}

export const selectModeratedWithRolesInActiveRole: (moderatedWithRolesId: string) => (state: WithModeratedWithRoleState) => TopicAssignmentRole = moderatedWithRolesId => state => {
    return selectModeratedWithRolesActiveRole(moderatedWithRolesId)(state) === 'debater' ? 'evaluator' : 'debater'
}
