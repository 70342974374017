import {Flex} from '@adobe/react-spectrum'
import {useLocalVideo} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import CustomLocalVideo from '../../video/CustomLocalVideo'

export const MyVideoIfStarted: React.FC = () => {
    const {isVideoEnabled} = useLocalVideo()
    return isVideoEnabled ? <div className={'roster-grid-local-video'}>
        <Flex justifyContent={'center'}><CustomLocalVideo/></Flex>
    </div> : <></>
}
