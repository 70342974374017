import {Comparer, EntityAdapter, EntityState} from '@reduxjs/toolkit'
import moment from 'moment'
import {TopicAssignmentsApi} from 'src/api'
import {StatusDto} from 'src/api/common.dto'
import {invisiblePhases, LoadWorkshopPhasesResult, Phase} from 'src/state/phases'
import {
    createScheduleItemForBreak, createScheduleItemForLeadModerator,
    createScheduleItemForPhase,
    createScheduleItemForTopic,
    ScheduleItem
} from './schedule.model'

export class ScheduleApiService {
    constructor(
        private api = new TopicAssignmentsApi()
    ) {
    }

    async loadPersonalSchedule(workshopId: string): Promise<ScheduleItem[]> {
        return (await this.api.loadMyTopicAssignments(workshopId))
            .map(assignment => createScheduleItemForTopic(workshopId, assignment))
    }
}

export class ScheduleStateService {
    constructor(
        private scheduleAdapter: EntityAdapter<ScheduleItem>
    ) {
    }

    setPersonalSchedule(personalSchedule: ScheduleItem[], state: EntityState<ScheduleItem>): void {
        personalSchedule.forEach(scheduleItem => this.updateScheduleItem(scheduleItem, state))
    }

    private updateScheduleItem = (scheduleItem: ScheduleItem, state: EntityState<ScheduleItem>) => {
        const existing = state.entities[scheduleItem.phaseId]
        this.scheduleAdapter.updateOne(state, {
            id: scheduleItem.phaseId,
            changes: {
                name: scheduleItem.name,
                phase: existing?.name,
                role: scheduleItem.role,
                url: scheduleItem.url,
                type: scheduleItem.type
            }
        })
    }

    setGlobalSchedule(phases: LoadWorkshopPhasesResult, state: EntityState<ScheduleItem>): void {
        this.scheduleAdapter.removeAll(state)
        const relevantPhases = phases.phases
            .filter(phase => this.isVisible(phase) || phases.isWorkshopOwner)
        relevantPhases
            .map(phase => this.isVisible(phase) ? createScheduleItemForPhase(phase) : createScheduleItemForLeadModerator(phase))
            .forEach((scheduleItem, index) => {
                this.addBreakIfEndOfPhaseBeforeStartOfNext(phases.workshopId, index, scheduleItem, relevantPhases, state)
                this.scheduleAdapter.addOne(state, scheduleItem)
            })
    }

    private isVisible(phase: Phase) {
        return !invisiblePhases.includes(phase.meetingVariant.type)
    }

    private addBreakIfEndOfPhaseBeforeStartOfNext(
        workshopId: string,
        index: number,
        scheduleItem: ScheduleItem,
        phases: Phase[],
        state: EntityState<ScheduleItem>
    ) {
        if(index === 0 || scheduleItem.start === phases[index - 1].endTime)
            return

        const status = this.getStatusOfBreak(scheduleItem.status, phases[index - 1].status)
        this.addBreak(phases[index - 1].endTime, workshopId, status, state)
    }

    private getStatusOfBreak(statusAfter: StatusDto, statusBefore: StatusDto) {
        if (statusAfter !== 'not-started') {
            return 'finished'
        } else if (statusBefore === 'finished') {
            return 'started'
        }
        return 'not-started'
    }

    private addBreak(start: string, workshopId: string, status: StatusDto, state: EntityState<ScheduleItem>) {
        this.scheduleAdapter.addOne(state, createScheduleItemForBreak(workshopId, start, status))
    }

    updateSchedule(phase: Phase, state: EntityState<ScheduleItem>): void {
        const oldItem = state.entities[phase.id]
        if (!oldItem) return
        this.addOrUpdateBreak(oldItem, state, phase)

        this.scheduleAdapter.updateOne(state, {
            id: phase.id,
            changes: {
                start: phase.startTime,
                status: phase.status
            }
        })
        if(oldItem.status !== 'finished' && phase.status === 'finished') {
            this.startNextBreak(phase.endTime, state)
        }
        if(oldItem.status !== 'started' && phase.status === 'started') {
            this.finishBreaksBefore(phase.startTime, state)
        }
    }

    private addOrUpdateBreak(oldItem: ScheduleItem, state: EntityState<ScheduleItem>, phase: Phase) {
        const nextItem = this.findNextAfter(oldItem.start, state)
        if (nextItem && nextItem.type === 'Break') {
            this.updateBreak(nextItem, phase.endTime, phase.workshopId, state)
        }
        if (nextItem && nextItem.type !== 'Break' && this.isBefore(phase.endTime, nextItem.start)) {
            const status = this.getStatusOfBreak(nextItem.status, phase.status)
            this.addBreak(phase.endTime, phase.workshopId, status, state)
        }
    }

    private updateBreak(oldItem: ScheduleItem, newStartTime: string, workshopId: string, state: EntityState<ScheduleItem>): void {
        const oldStartTime = oldItem.start
        this.scheduleAdapter.removeOne(state, oldStartTime)
        const nextItem = this.findNextAfter(oldItem.start, state)
        if (!nextItem || moment.utc(newStartTime).valueOf() < moment.utc(nextItem.start).valueOf())
            this.addBreak(newStartTime, workshopId, oldItem.status, state)
    }

    private findNextAfter(start: string, state: EntityState<ScheduleItem>): ScheduleItem | undefined {
        const allEntities = Object.values(state.entities)
            .map(scheduleItem => scheduleItem!)
            .sort(this.scheduleAdapter.sortComparer as Comparer<ScheduleItem>) as ScheduleItem[]
        const currentIndex = allEntities.findIndex((item: ScheduleItem) => item.start === start)
        if(currentIndex === -1 || (currentIndex + 1) >= allEntities.length) return undefined

        return allEntities[currentIndex + 1]
    }

    private isBefore(first: string, second: string): boolean {
        const firstTime = moment.utc(first)
        const secondTime = moment.utc(second)

        return firstTime.valueOf() < secondTime.valueOf()
    }

    private startNextBreak(endTime: string, state: EntityState<ScheduleItem>) {
        const currentBreak = state.entities[endTime]
        if(currentBreak && currentBreak.status === 'not-started')
            this.scheduleAdapter.updateOne(state, {
                id: endTime,
                changes: {
                    status: 'started'
                }
            })
    }

    private finishBreaksBefore(startTime: string, state: EntityState<ScheduleItem>) {
        Object.values(state.entities)
            .map(scheduleItem => scheduleItem!)
            .filter(scheduleItem => scheduleItem.type === 'Break')
            .filter(scheduleItem => this.isBefore(scheduleItem.start, startTime))
            .forEach(scheduleItem => this.scheduleAdapter.updateOne(state, {
                id: scheduleItem.phaseId,
                changes: {
                    status: 'finished'
                }
            }))
    }
}
