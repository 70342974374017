import {ActionButton, ButtonGroup, Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {Card} from 'src/components/common'
import {StartAudioCallIcon} from 'src/components/icons/StartAudioCallIcon'
import {StartVideoCallIcon} from 'src/components/icons/StartVideoCallIcon'
import {ParticipantCafeBadge} from './ParticipantCafeBadge'
import {sortParticipantRosterItems} from 'src/hooks'
import {joinGroupCall, selectCallById} from 'src/state/calls'
import {BreakoutMeeting} from 'src/state/participant-locations'
import {selectAllParticipants, selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const CafeMeeting: React.FC<{ meeting: BreakoutMeeting, canStartCall: boolean } & WithWorkshopId> = ({
    meeting,
    canStartCall,
    workshopId
}) => {
    const dispatch = useAppDispatch()
    const call = useAppSelector(selectCallById(meeting.meetingId))
    const me = useAppSelector(selectMyParticipant)

    const participants = meeting.participants.sort(sortParticipantRosterItems(me))
    const participantIds = participants.map(participant => participant.id)
    const allParticipants = useAppSelector(selectAllParticipants)

    const callParticipants = call ? call.participantIds : []
    const missingParticipants = callParticipants
        .filter(participantId => !participantIds.includes(participantId))
        .filter(participantId => allParticipants[participantId])
        .map(participantId => allParticipants[participantId]!)

    const dispatchJoinCall = () => {
        if (call) {
            dispatch(joinGroupCall({call: call, workshopId: workshopId}))
        }
    }

    return <Card className={'group-call-box'}>
        {{
            title: '',
            content: <>
                <Flex UNSAFE_className='group-call-box-participants' direction='row' wrap gap='size-200'>
                    <>{participants.map(participant => <View key={participant.id}>
                        <ParticipantCafeBadge active={true} participant={participant} canStartCall={false}/>
                    </View>)}</>
                    <>{missingParticipants.map(participant => <View key={participant.id}>
                        <ParticipantCafeBadge active={false} participant={participant} canStartCall={false}/>
                    </View>)}</>
                </Flex>
            </>,
            buttons: canStartCall ? <ButtonGroup>
                <ActionButton onPress={dispatchJoinCall}><StartVideoCallIcon/></ActionButton>
                <ActionButton onPress={dispatchJoinCall}><StartAudioCallIcon/></ActionButton>
            </ButtonGroup> : undefined
        }}
    </Card>
}
