import {API} from 'aws-amplify'
import {Response} from '../response'
import {GetParticipantsDto, ParticipantDto} from './participant.dto'

export class ParticipantsApi {
    async getParticipantsForWorkshop(workshopId: string): Promise<ParticipantDto[]> {
        const participantsResponse: Response<GetParticipantsDto> =
            await API.get('backend', `api/workshops/${workshopId}/participants`, {})
        if(participantsResponse._embedded)
            return participantsResponse._embedded?.profiles
        return []
    }

    async getMe(workshopId: string): Promise<ParticipantDto> {
        return API.get('backend', `api/workshops/${workshopId}/participants/me`, {})
    }
}
