import classNames from 'classnames'
import React, {useEffect} from 'react'
import { Route, Switch} from 'react-router-dom'
import {PeopleIcon} from 'src/components/icons/PeopleIcon'
import {NotificationBar} from './NotificationBar'
import {
    NotificationDrawerStatus,
    selectNotificationDrawerStatus,
    selectSearchDrawerStatus, setNotificationDrawer,
    setSearchDrawer
} from 'src/state/layout'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export const NotificationDrawer: React.FC = () => {
    const status = useAppSelector(selectNotificationDrawerStatus)
    const searchDrawerStatus = useAppSelector(selectSearchDrawerStatus)
    const notificationBarSize = '60px'
    const dispatch = useAppDispatch()

    return status === 'open' ? <div className='menu-right'>
        <NotificationBar className='menu-right-notifications' width={notificationBarSize}>
            <Switch>
                <Route path='/workshops/:workshopId'>
                    <PeopleIcon className={classNames({
                        'clickable-icon': true,
                        'icon-active': searchDrawerStatus === 'open'
                    })} onClick={() => {
                        dispatch(setSearchDrawer(searchDrawerStatus === 'hidden' ? 'open' : 'hidden'))
                    }}/>
                </Route>
                <Route>
                    <SetNotificationDrawer status={'hidden'}/>
                </Route>
            </Switch>

        </NotificationBar>
    </div> : <></>
}

export const SetNotificationDrawer: React.FC<{status: NotificationDrawerStatus}> = ({status}) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setNotificationDrawer(status))
    }, [])
    return <></>
}

