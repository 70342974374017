import {createAsyncThunk} from '@reduxjs/toolkit'
import {EnqueueTopicModel} from 'src/state/topics'
import {
    ModeratedTopicQueue
} from './moderated-queue.model'
import {ModeratedQueueApiService} from './moderated-queue.service'
import {WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'

export const enqueueTopic = createAsyncThunk<void, EnqueueTopicModel>(
    'moderated-queue/enqueue',
    enqueueModel => new ModeratedQueueApiService().enqueueTopic(enqueueModel)
)

export const activateQueue = createAsyncThunk<void, WithWorkshopId & WithPhaseId>(
    'moderated-queue/activate',
    ({workshopId, phaseId}) => new ModeratedQueueApiService().updateQueue({
        workshopId,
        phaseId,
        active: true
    })
)

export const pauseQueue = createAsyncThunk<void, WithWorkshopId & WithPhaseId>(
    'moderated-queue/pause',
    ({workshopId, phaseId}) => new ModeratedQueueApiService().updateQueue({
        workshopId,
        phaseId,
        active: false
    })
)

export const loadQueue = createAsyncThunk<ModeratedTopicQueue, WithWorkshopId & WithPhaseId>(
    'moderated-queue/load',
    ({workshopId, phaseId}) => new ModeratedQueueApiService().getQueue(workshopId, phaseId)
)

export const acceptQueuedTopic = createAsyncThunk<void, WithWorkshopId & WithPhaseId & WithTopicId>(
    'moderated-queue/accept-topic',
    ({workshopId, phaseId, topicId}) => new ModeratedQueueApiService().acceptTopic({
        workshopId,
        phaseId,
        topicId
    })
)

export const deleteQueuedTopic = createAsyncThunk<void, WithWorkshopId & WithPhaseId & WithTopicId>(
    'moderated-queue/delete-topic',
    ({workshopId, phaseId, topicId}) => new ModeratedQueueApiService().closeTopic({
        workshopId,
        phaseId,
        topicId
    })
)

