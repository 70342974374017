import {MeetingStatus, useMeetingManager} from 'amazon-chime-sdk-component-library-react'
import {useEffect, useState} from 'react'
import {leaveMeeting, Meeting} from 'src/state/meetings'
import {useAppDispatch} from 'src/state/types'
import {useConfiguredAudioInput, useConfiguredAudioOutput, useConfiguredVideoInput} from 'src/hooks/devices'

export type UseMeetingResponse = boolean

export const useMeeting = (meeting: Meeting): UseMeetingResponse => {
    const meetingManager = useMeetingManager()
    useConfiguredVideoInput()
    useConfiguredAudioInput()
    useConfiguredAudioOutput()

    const dispatch = useAppDispatch()
    const [connected, setConnected] = useState(meetingManager.meetingStatus === MeetingStatus.Succeeded)

    const [promise, setPromise] = useState<Promise<void>>(Promise.resolve())

    useEffect(() => {
        setPromise(promise.then(() => joinMeeting(meeting)))
        return () => {
            dispatch(leaveMeeting(meeting?.id))
            promise.then(() => leave())
        }
    }, [])

    const joinMeeting = async (meeting: Meeting): Promise<void> => {
        try {
            await tryToJoinMeeting(meeting)
        } catch (error) {
            console.error('Failed to join meeting', error)
        }
    }

    async function tryToJoinMeeting(meeting: Meeting) {
        const joinData = {
            meetingInfo: meeting.chimeMeeting,
            attendeeInfo: meeting.chimeAttendee
        }

        if (meetingManager.meetingSession
          && meetingManager.meetingSession.configuration.externalMeetingId !== meeting.id) {
            console.log('leave meeting to join another')
            await leave()
            setConnected(false)
        }
        if (!meetingManager.meetingSession) {
            await meetingManager.join(joinData)
            await meetingManager.start()
            setConnected(true)
        }
    }

    const leave = async (): Promise<void> => {
        try {
            await meetingManager.leave()
        } catch (error) {
            console.error('Failed to leave meeting', error)
        }
    }

    return connected
}
