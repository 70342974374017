import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {meetingVariantConfig, meetingVariants} from 'src/state/phases'
import {setPhaseMeetingVariantType, setPhaseModeOfInteraction} from 'src/state/phases/phases.actions'
import {useAppDispatch} from 'src/state/types'
import {WithPhase} from 'src/types'
import {MeetingVariantSelection} from '../selection/MeetingVariantSelection'
import {ModeOfInteractionSelection} from '../selection/ModeOfInteractionSelection'
import {RoomConfigurationForm} from './RoomConfigurationForm'
import {RoomConfigurationHeader} from './RoomConfigurationHeader'

export const PlenaryColumn: React.FC<WithPhase> = ({phase}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <div className='plenary-column'>
        <Flex direction='column'>
            <RoomConfigurationHeader>{t('workshop-designer.room-plenary-label')}</RoomConfigurationHeader>
            <RoomConfigurationForm>
                <MeetingVariantSelection
                    selected={phase.meetingVariant.type}
                    options={meetingVariants}
                    onSelect={meetingVariantType =>
                        dispatch(setPhaseMeetingVariantType({phase, meetingVariantType}))
                    }
                />

                <ModeOfInteractionSelection
                    selected={phase.meetingVariant.currentModeOfInteraction}
                    options={meetingVariantConfig[phase.meetingVariant.type].modesOfInteraction}
                    onSelect={modeOfInteraction => {
                        dispatch(setPhaseModeOfInteraction({phase, modeOfInteraction}))
                    }}
                />
            </RoomConfigurationForm>
        </Flex>
    </div>
}


