import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {LayoutConfigState, selectIsToolbarRequired, selectLayoutConfigState} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import styled from 'styled-components'
import {toCss} from '../util'
import {selectIsWorkshopFinished} from 'src/state/phases'

export const FooterToolboxIfRequired: React.FC = ({children}) => {
    const isToolbarRequired = useAppSelector(selectIsToolbarRequired)
    const workshopHasEnded = useAppSelector(selectIsWorkshopFinished)
    return (isToolbarRequired || workshopHasEnded) ? <FooterToolbox>{children}</FooterToolbox> : <></>
}

export const FooterToolbox: React.FC = ({
    children
}) => {
    const layout = useAppSelector(selectLayoutConfigState)

    return (
        <StyledToolbar layout={layout}>
            <div className='toolbar-container toolbox-controls'>
                <Flex gap={'size-130'} direction={'row'}><>{children}</></Flex>
            </div>
        </StyledToolbar>
    )
}

interface Props {
    layout: LayoutConfigState
}

const StyledToolbar = styled.div<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>`
display: flex;
flex-direction: row;
flex: none;
justify-content: center;
padding:0;
margin-top: 0;
margin-bottom: 0;
margin-left: 0;
transition: none;
overflow: hidden;
background-color: #F1F2F3;
border: 1px solid #E0E0E0;
bottom: 0;
height: ${({layout}) => toCss(layout.footer.height)};
width: calc(100vw - ${({layout}) => toCss(layout.drawer.left)});
-webkit-box-shadow: 0px -1px 4px #00000026;; 
box-shadow: 0px -1px 4px #00000026;;

div.toolbar-container {
    display: flex;
}

div.toolbar-container > div {
    display: flex;
}
`
