import {useAudioVideo} from 'amazon-chime-sdk-component-library-react'
import React, {useEffect, useRef} from 'react'
import {ContentTile} from './ContentTile'

export const ContentShareTile: React.FC<{
    tileId: number,
    className?: string
}> = ({
    tileId,
    className,
    ...rest
}) => {
    const audioVideo = useAudioVideo()
    const videoEl = useRef<HTMLVideoElement | null>(null)

    useEffect(() => {
        if (!audioVideo || !videoEl.current) {
            return
        }
        audioVideo.bindVideoElement(tileId, videoEl.current)
        return () => {
            const tile = audioVideo.getVideoTile(tileId)
            if (tile) {
                audioVideo.unbindVideoElement(tileId)
            }
        }
    }, [audioVideo, tileId])

    return tileId ? (
        <ContentTile
            className={className || ''}
            {...rest}
            ref={videoEl}
        />
    ) : null

    return <ContentTile/>
}
