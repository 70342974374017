import {AddTopicToolbarAction} from './add-topic-toolbar-action'
import {ManageWorkshopToolbarAction} from './manage-workshop-toolbar-action'
import {ToolbarAction} from './toolbar-action'
import {RootState} from 'src/state/types'

export const toolbarActions: ToolbarAction[] = [
    new ManageWorkshopToolbarAction(),
    new AddTopicToolbarAction()
]

export const selectIsToolbarRequired: (state: RootState) => boolean = state => {
    return toolbarActions.some(toolbarAction => toolbarAction.canBeUsed(state))
}

export const selectIsWorkshopControlsRequired: (state: RootState) => boolean = state => {
    return new ManageWorkshopToolbarAction().canBeUsed(state)
}
