import {RxStomp, RxStompState} from '@stomp/rx-stomp'
import {useContext, useEffect, useState} from 'react'
import {StompContext} from 'src/provider/StompClientProvider'

export const useStomp = (): RxStomp | undefined => {
    const [connected, setConnected] = useState<boolean>(false)
    const stompClient = useContext(StompContext)
    useEffect(() => {
        const subscription = stompClient?.connected$.subscribe(status => {
            setConnected(status === RxStompState.OPEN)
        })
        return () => {
            subscription?.unsubscribe()
        }
    }, [stompClient])
    return connected ? stompClient : undefined
}
