import {EntityState} from '@reduxjs/toolkit'
import {ParticipantRole, ParticipantRoles} from 'src/types'

export interface Participant {
    id: string
    name: string
    biography?: string
    imageUrl?: string
    workshopId: string
}

export type ParticipantsState = EntityState<Participant> & {
    byWorkshop: { [workshopId: string]: string[] }
}

export type WithParticipantsState = {
    participants: ParticipantsState
}

export class ParticipantRolesAdapter {
    constructor(
      public readonly roles: ParticipantRoles
    ) {
    }

    hasRole(participant: Participant | string | undefined, role: ParticipantRole): boolean {
        return this.getRolesFor(participant).includes(role)
    }

    hasAtLeastOneRole(participant: Participant | string | undefined, roles: string[]): boolean {
        return this.getRolesFor(participant).some(role => roles.includes(role))
    }

    isAllowedToJoin(participant: Participant | string | undefined): boolean {
        return this.getRolesFor(participant).length > 0
    }

    findParticipantWithRole(role: ParticipantRole): string | undefined {
        return Object.keys(this.roles)
            .find(participantId => this.roles[participantId].includes(role))
    }

    getRolesFor(participant: Participant | string | undefined): ParticipantRole[] {
        const participantId = this.idOf(participant)
        return participantId ? this.roles[participantId] || [] : []
    }

    isModerator(participant: Participant | string | undefined): boolean {
        return this.hasRole(this.idOf(participant), 'moderator')
    }

    private idOf(participant: Participant | string | undefined): string | undefined {
        return typeof participant === 'string' ? participant : participant?.id
    }

    getAllParticipantIds(): string[] {
        return Object.keys(this.roles)
    }
}
