import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ParticipantMap} from 'src/container/rooms/participants/ParticipantMap'
import {selectCurrentPhase} from 'src/state/phases'
import {useAppSelector} from 'src/state/types'
import {LeadModeratorControlCenter} from '../rooms/control-center/LeadModeratorControlCenter'
import {Workshop} from 'src/state/workshops'
import {CafeRoom} from '../rooms/cafe/CafeRoom'
import {PlenaryRoom} from '../rooms/plenary/PlenaryRoom'
import {TopicRoom} from '../rooms/topic/TopicRoom'

interface Props {
    workshop: Workshop
}

export const WorkshopRouting: React.FC<Props> = ({workshop}) => {
    const phase = useAppSelector(selectCurrentPhase(workshop.id))

    if(workshop.status === 'not-started')
        return <Switch>
            <Route path='/workshops/:workshopId/cafe'><CafeRoom /></Route>
            <Route path='/workshops/:workshopId/control-center'><LeadModeratorControlCenter /></Route>
            <Route path='/workshops/:workshopId/participants'><ParticipantMap /></Route>
            <Route path='/workshops/:workshopId'>
                <Redirect to={`/workshops/${workshop.id}/cafe`} />
            </Route>
        </Switch>

    return <Switch>
        <Route path='/workshops/:workshopId/topics/:topicId'><TopicRoom /></Route>
        <Route path='/workshops/:workshopId/cafe'><CafeRoom /></Route>
        <Route path='/workshops/:workshopId/plenary'><PlenaryRoom workshopId={workshop.id} phase={phase}/></Route>
        <Route path='/workshops/:workshopId/control-center'><LeadModeratorControlCenter phase={phase}/></Route>
        <Route path='/workshops/:workshopId/participants'><ParticipantMap /></Route>
        <Route path='/workshops/:workshopId'>
            {phase && phase.meetingVariant ?
                <PlenaryRoom workshopId={workshop.id} phase={phase} /> : <Redirect to={`/workshops/${workshop.id}/cafe`} />
            }
        </Route>
    </Switch>
}

