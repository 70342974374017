import {createAction} from '@reduxjs/toolkit'
import {phaseEventDispatcher} from 'src/state/workshop-event-dispatcher'
import {WithWorkshopId, WithPhaseId} from 'src/types'
import {ModeratedQueueDto} from 'src/websocket/phase-event-types.dto'

export const ModeratedQueueUpdated = createAction<ModeratedQueueDto &
  WithWorkshopId &
  WithPhaseId>('moderated-queue/event/ModeratedQueueUpdated')

phaseEventDispatcher.registerActionCreator({
    type: 'ModeratedQueueUpdated',
    actionCreator: event => ModeratedQueueUpdated({
        ...event.payload,
        workshopId: event.meta.workshopId,
        phaseId: event.meta.phaseId
    })
})
