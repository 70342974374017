import {useRosterState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {OfflineParticipantVideo} from 'src/components/roster/OfflineParticipantVideo'
import {Participant, ParticipantRolesAdapter, selectParticipantsForWorkshop} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import { WithRoles, WithWorkshopId} from 'src/types'
import {ParticipantPictureOrVideo} from './ParticipantPictureOrVideo'

export const findFirstModerator: (participants: Participant[], roles: ParticipantRolesAdapter) => Participant | undefined =
  (participants, roles) => participants.find(participant => roles.isModerator(participant.id))

export const ModeratorVideo: React.FC<WithWorkshopId & WithRoles & {
    displayAbsent: boolean
}> = ({
    workshopId,
    roles,
    displayAbsent
}) => {
    const participants = useAppSelector(selectParticipantsForWorkshop(workshopId))
    const {roster} = useRosterState()
    const participant: Participant | undefined = findFirstModerator(participants, roles)
    if (participant) {
        const attendee = Object.values(roster).find(attendee => attendee.externalUserId === participant.id)
        if (attendee) {
            return <ParticipantPictureOrVideo
                participant={{...participant, attendeeId: attendee.chimeAttendeeId}}
                size='stage'
                role='Moderator'
            />
        } else if (displayAbsent) {
            return <OfflineParticipantVideo
                participant={{...participant}}
                size='stage'
                role='Moderator'
            />
        }
    }
    return <></>
}
