import {ParticipantRosterSortComparator, sortParticipantRosterItems} from 'src/hooks/meeting/sort'
import {useSortedAndFilteredParticipants} from './use-sorted-and-filtered-participants'
import {MeetingParticipant} from 'src/state/meetings'
import {
    Participant,
    ParticipantRolesAdapter,
    selectMyParticipant,
    selectParticipantsForWorkshopAsMap
} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'

export function useActiveAndInactiveParticipants(
    workshopId: string,
    roles: ParticipantRolesAdapter,
    includeRoles: string[] | undefined,
    excludeRoles: string[] | undefined,
    showAbsentParticipants: boolean,
    participantSortComparator: ParticipantRosterSortComparator | undefined
): Array<Participant | MeetingParticipant> {
    const activeParticipants = useSortedAndFilteredParticipants(workshopId,
        roles, includeRoles, excludeRoles,
        participantSortComparator)

    const workshopParticipants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const inactiveParticipants = showAbsentParticipants ? roles.getAllParticipantIds()
        .filter(participantId => !includeRoles || roles.hasAtLeastOneRole(participantId, includeRoles))
        .filter(participantId => !excludeRoles || !roles.hasAtLeastOneRole(participantId, excludeRoles))
        .filter(participantId => !activeParticipants.some(participant => participant.id === participantId))
        .filter(participantId => workshopParticipants[participantId])
        .map(participantId => workshopParticipants[participantId]!) : []

    const me = useAppSelector(selectMyParticipant)
    const participants: Array<MeetingParticipant | Participant> = [...activeParticipants, ...inactiveParticipants]
    participants.sort(sortParticipantRosterItems(me))
    return participants
}
