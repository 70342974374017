import {MeetingStatus, useMeetingStatus} from 'amazon-chime-sdk-component-library-react'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {isDeviceAvailable} from 'src/hooks/devices/utils'
import {selectHasDeviceDialogBeenShown, selectIsVideoActive} from 'src/state/device-settings'
import {errorOccurred} from 'src/state/error'
import {selectMeetingDeviceSettings} from 'src/state/meetings'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {useConfiguredVideoInputDevice} from './use-configured-video-input-device'
import {useVideoControl} from './use-video-control'

function userAllowsToEnableVideo(
    videoActive: boolean,
    videoInputDevice: string | undefined,
    availableVideoDevices: DeviceType[]
): boolean {
    return videoActive
      && Boolean(videoInputDevice)
      && isDeviceAvailable(availableVideoDevices, videoInputDevice!)
}


export function useConfiguredVideoInput(): void {
    const meetingStatus = useMeetingStatus()
    const videoActive = useAppSelector(selectIsVideoActive)
    const meetingDeviceSettings = useAppSelector(selectMeetingDeviceSettings)
    const hasDeviceDialogBeenShown = useAppSelector(selectHasDeviceDialogBeenShown)

    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const [videoEnabled, setVideoEnabled] = useState<boolean>(false)
    const [promise, setPromise] = useState<Promise<void>>(Promise.resolve())

    const {
        enableVideo,
        disableVideo,
        videoDevices: availableVideoDevices,
        selectedVideoDevice
    } = useVideoControl()

    const videoInputDevice = useConfiguredVideoInputDevice()

    useEffect(() => {
        if (meetingStatus === MeetingStatus.Succeeded
          && meetingDeviceSettings
          && videoInputDevice
          && availableVideoDevices
        ) {
            setVideoEnabled(
                hasDeviceDialogBeenShown
                && userAllowsToEnableVideo(videoActive, videoInputDevice, availableVideoDevices)
                && meetingDeviceSettings.isVideoAllowed
            )
        }
    }, [
        meetingStatus,
        meetingDeviceSettings,
        videoInputDevice,
        videoActive,
        availableVideoDevices,
        hasDeviceDialogBeenShown
    ])

    useEffect(() => {
        async function enableVideoDeviceIfAllowed() {
            if (videoEnabled && selectedVideoDevice === videoInputDevice) {
                await enableVideo()
            } else {
                await disableVideo()
            }
        }

        setPromise(promise.then(() => enableVideoDeviceIfAllowed().catch(error => {
            console.error('Failed to enable video', error)
            const message = t('error.failed-to-start-video')
            dispatch(errorOccurred({message: message}))
        })))
    }, [videoEnabled, selectedVideoDevice, videoInputDevice])
}
