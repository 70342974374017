import React, {useEffect, useState} from 'react'
import {WithIncomingCall, WithWorkshopId} from 'src/types'
import {useAppDispatch} from 'src/state/types'
import { DialogContainer} from '@adobe/react-spectrum'
import {acceptCall, rejectCall} from 'src/state/incoming-calls'
import {IncomingCallDialog} from 'src/components/incoming-calls/IncomingCallDialog'
import './IncomingCallsDialogTrigger.css'

export const IncomingCallDialogContainer: React.FC<WithIncomingCall & WithWorkshopId> = ({incomingCall, workshopId}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setIsDialogOpen(true)
    }, [incomingCall])

    const reject = () => {
        setIsDialogOpen(false)
        dispatch(rejectCall({ incomingCall, workshopId }))
    }
    const accept = (type: 'video' | 'audio') => {
        setIsDialogOpen(false)
        dispatch(acceptCall({workshopId, incomingCall, type}))
    }

    return <DialogContainer onDismiss={() => setIsDialogOpen(false)}>
        {isDialogOpen && <IncomingCallDialog workshopId={workshopId} incomingCall={incomingCall} rejectCall={reject} acceptCall={accept}/>}
    </DialogContainer>
}
