import {Button, ButtonGroup, Divider} from '@adobe/react-spectrum'
import React from 'react'
import {Link} from 'react-router-dom'
import {Card} from 'src/components/common'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {selectParticipantsAtLocation} from 'src/state/participant-locations'
import {useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId} from 'src/types'

export const TopicBreakoutCard: React.FC<WithWorkshopId & WithTopic> = ({workshopId, topic}) => {
    const participants = useAppSelector(selectParticipantsAtLocation('topic-breakout', topic.id))

    return participants.length > 0 ? <Card className={'breakout-card'}>
        {{
            title: topic.title,
            content: <>
                <Divider size={'M'} marginBottom={'size-130'}/>
                <ScrollContainer>
                    <>{participants.map((participant, index) => <ParticipantBadge key={index}
                        participant={participant}/>)}</>
                </ScrollContainer>
            </>,
            buttons: <ButtonGroup>
                <Link to={`/workshops/${workshopId}/topics/${topic.id}`}>
                    <Button variant='primary'>Enter Breakout</Button>
                </Link>
            </ButtonGroup>
        }}
    </Card> : <></>
}
