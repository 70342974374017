import {Dictionary} from '@reduxjs/toolkit'
import {WithAuthState} from 'src/state/auth'
import {
    Participant,
    selectParticipant,
    selectParticipantsForWorkshop,
    WithParticipantsState
} from 'src/state/participants'
import {Topic} from 'src/state/topics'
import {WithParticipant, WithParticipantLocations} from 'src/types'
import {LocationType, ParticipantLocation, WithParticipantLocationState} from './participant-locations.model'

export const selectMyLocation: (state: WithParticipantLocationState) => ParticipantLocation | undefined = state => {
    return state.participantLocations.myLocation
}

export const selectMySessionId: (state: WithParticipantLocationState) => string = state => {
    return state.participantLocations.sessionId
}

export const selectParticipantLocationIdsAtLocation: (location: LocationType, locationId?: string) => (state: WithParticipantLocationState) => string[] = (location, locationId) => state => {
    if (location === 'cafe' || location === 'plenary') {
        return state.participantLocations.byLocation[location]
    } else if (location === 'topic-breakout' && locationId) {
        return state.participantLocations.byLocation[location][locationId] || []
    }
    return []
}

export const selectParticipantIdsAtLocation: (location: LocationType, locationId?: string) => (state: WithParticipantLocationState) => string[] = (location, locationId) => state => {
    return [...new Set(selectParticipantLocationIdsAtLocation(location, locationId)(state)
        .filter(participantLocationId => state.participantLocations.entities[participantLocationId])
        .map(participantLocationId => state.participantLocations.entities[participantLocationId]!.participantId))]
}

export const selectParticipantsAtLocation: (location: LocationType, locationId?: string) =>
  (state: WithParticipantLocationState & WithParticipantsState) => Participant[]
  = (location, locationId) => state => {
      return selectParticipantIdsAtLocation(location, locationId)(state)
          .filter(participantId => selectParticipant(participantId)(state))
          .map(participantId => selectParticipant(participantId)(state)!)
          .sort((a, b) => a.name.localeCompare(b.name))
  }

export const selectParticipantsFilteredByLocation: (location: { type: LocationType, id?: string }, filter: (participantLocation: ParticipantLocation) => boolean) =>
  (state: WithParticipantLocationState & WithParticipantsState) => Participant[]
  = (location, filter) => state => {
      return [...new Set(selectParticipantLocationIdsAtLocation(location.type, location.id)(state)
          .filter(participantLocationId => state.participantLocations.entities[participantLocationId]
        && filter(state.participantLocations.entities[participantLocationId]!))
          .map(participantLocationId => state.participantLocations.entities[participantLocationId]!.participantId)
          .map(participantId => selectParticipant(participantId)(state)!)
          .filter(participant => participant)
      )]
  }

export const selectParticipantLocationsByParticipantId: (participantId: string) =>
  (state: WithParticipantLocationState) => ParticipantLocation[]
  = (participantId) => state => {
      return (state.participantLocations.byParticipant[participantId] || [])
          .filter(locationId => state.participantLocations.entities[locationId])
          .map(locationId => state.participantLocations.entities[locationId]!)
  }

export const selectLocationsByMeeting: (location: LocationType) => (state: WithParticipantLocationState & WithParticipantsState) => Dictionary<Participant[]>  = location => state => {
    return selectParticipantLocationIdsAtLocation(location)(state)
        .filter(participantLocationId => state.participantLocations.entities[participantLocationId]?.participantId)
        .map(participantLocationId => ({
            participant: selectParticipant(state.participantLocations.entities[participantLocationId]!.participantId)(state),
            meetingId: state.participantLocations.entities[participantLocationId]!.meetingId
        }))
        .reduce((map: Dictionary<Participant[]>, participantAndMeeting) => {
            if (!participantAndMeeting.participant || !participantAndMeeting.meetingId) {
                return map
            }
            const current = map[participantAndMeeting.meetingId]
            map[participantAndMeeting.meetingId] = current ? [...current, participantAndMeeting.participant] : [participantAndMeeting.participant]
            return map
        }, {})
}

export const selectNumberOfMeetingsAt: (location: LocationType) =>
  (state: WithParticipantLocationState & WithParticipantsState) => number
  = (location) => state => {
      const meetingParticipantMap = selectLocationsByMeeting(location)(state)
      return Object.keys(meetingParticipantMap).length
  }

export const selectHasActiveTopicBreakouts: (topics: Topic[]) => (state: WithParticipantLocationState) => boolean = topics => state => {
    return topics.some(topic => selectParticipantIdsAtLocation('topic-breakout', topic.id)(state).length > 0)
}

export function isParticipantMatching(participant: Participant, search: string): boolean {
    return Boolean(participant.name.toLocaleLowerCase().match(search.toLocaleLowerCase()))
}

export const selectParticipantLocations: (participant: Participant) => (state: WithParticipantLocationState) => ParticipantLocation[] = (participant) => state => {
    return selectParticipantLocationsByParticipantId(participant.id)(state)
}

export const selectSearchParticipantsResult: (workshopId: string, search: string)
  => (state: WithParticipantLocationState
  & WithParticipantsState
  & WithAuthState
) => Array<WithParticipant & WithParticipantLocations>
  = (workshopId, search) => state => {
      return selectParticipantsForWorkshop(workshopId)(state)
          .filter(participant => participant.id !== state.auth.user?.id && isParticipantMatching(participant, search))
          .map(participant => ({
              participant,
              locations: selectParticipantLocations(participant)(state)
          }))
  }


export type BreakoutMeeting = {
    meetingId: string
    participants: Participant[]
}
export const selectCafeBreakoutMeetings: (state: WithParticipantLocationState
  & WithParticipantsState
) => BreakoutMeeting[] = state => {
    const byMeeting = selectLocationsByMeeting('cafe')(state)
    const meetingIds = Object.keys(byMeeting)
    meetingIds.sort()
    return meetingIds
        .map(meetingId => {
            return {
                meetingId,
                participants: byMeeting[meetingId]!
            }
        })
}
