import {LoadInvitationsPayload} from 'src/state/invitations/invitations.model'
import {loadParticipants} from '../participants'
import {InvitationsApiService} from './invitations.service'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {WithWorkshopId} from 'src/types'

export const loadInvitations = createAsyncThunk<LoadInvitationsPayload, string>(
    'invitations/loadInvitations', (workshopId) => {
        return new InvitationsApiService().getInvitationsForWorkshop(workshopId)
    })

export const addInvitation = createAsyncThunk<void, { workshopId: string, email: string, nickname: string }>(
    'invitations/addInvitation', async (addInvitation, thunkAPI) => {
        await new InvitationsApiService().addInvitation(addInvitation)
        await thunkAPI.dispatch(loadInvitations(addInvitation.workshopId))
        await thunkAPI.dispatch(loadParticipants(addInvitation.workshopId))
    })

export const deleteInvitations = createAsyncThunk<void, { workshopId: string, invitations: string[] }>(
    'invitations/deleteInvitations',async (deleteInvitation, thunkAPI) => {
        await new InvitationsApiService().deleteInvitations(deleteInvitation)
        await thunkAPI.dispatch(loadInvitations(deleteInvitation.workshopId))
        await thunkAPI.dispatch(loadParticipants(deleteInvitation.workshopId))
    })

export const uploadInvitationsFile = createAsyncThunk<void, { workshopId: string, file: File }>(
    'invitations/uploadInvitationsFile',async (invitationUpload, thunkAPI) => {
        await new InvitationsApiService().uploadInvitationsFile(invitationUpload)
        await thunkAPI.dispatch(loadInvitations(invitationUpload.workshopId))
        await thunkAPI.dispatch(loadParticipants(invitationUpload.workshopId))
    })

export const sendInvitationEmails = createAsyncThunk<void, string>(
    'invitations/sendInvitationEmails', async (workshopId) => new InvitationsApiService().sendInvitationEmails(workshopId)
)

export const sendSingleInvitationsEmail = createAsyncThunk<void, WithWorkshopId & {email: string}>(
    'invitations/sendSingleInvitationEmail',
    async ({workshopId, email}) =>
        new InvitationsApiService().sendSingleInvitationsEmail(workshopId, email)
)
