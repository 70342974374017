import {ActionButton, DialogTrigger} from '@adobe/react-spectrum'
import classNames from 'classnames'
import React, {ReactNode, useState} from 'react'
import {ParticipantIcon} from './ParticipantIcon'
import {ParticipantInfoPopover} from '../ParticipantInfoPopover'
import {WithParticipant} from 'src/types'
import './ParticipantIconWithCircle.css'

interface WithDataTestId {
    'data-testid'?: string
}

export const ParticipantIconWithCircle: React.FC<WithParticipant & {
    iconSymbol?: ReactNode,
    className?: string
} & WithDataTestId> = ({
    participant,
    iconSymbol,
    className,
    'data-testid': dataTestid
}) => {
    const [openInfo, setOpenInfo] = useState(false)
    const ref = React.useRef(null)

    return <>
        <DialogTrigger type='popover' isOpen={openInfo} targetRef={ref} isDismissable onOpenChange={setOpenInfo}>
            <ActionButton isHidden={true}/>
            <ParticipantInfoPopover
                close={() => setOpenInfo(false)}
                participant={participant}/>
        </DialogTrigger>
        {iconSymbol ? <div className='icon-symbol'>{iconSymbol}</div>: <></>}
        <div className={classNames({
            'participant-circle': true,
            [className || '']: Boolean(className)
        })} data-testid={dataTestid}>
            <ParticipantIcon
                onClick={() => setOpenInfo(!openInfo)}
                participant={participant}/>
        </div>
        <div style={{visibility: 'hidden'}} ref={ref} />
    </>
}
