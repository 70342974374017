import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {InactiveTopicRoom} from 'src/components/meetings/topic-room/topic-breakout/InactiveTopicRoom'
import {TopicRoomWithActivePhase} from 'src/container/rooms/topic/TopicRoomWithActivePhase'
import {useWebsocketSubscription} from 'src/hooks'
import {Phase, selectCurrentPhase} from 'src/state/phases'
import {loadTopicWithNotes, selectTopic} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {TopicRoomEvent} from 'src/state/workshop.event'
import {WithTopicId, WithWorkshopId} from 'src/types'
import {TopicRoomEventTypeDto} from 'src/websocket/topic-room-event-types.dto'
import {topicTopic} from 'src/websocket/workshop-topics'
import './TopicRoom.css'

export const TopicRoom: React.FC = () => {
    const dispatch = useAppDispatch()
    const {workshopId, topicId} = useParams<WithWorkshopId & WithTopicId>()
    const topic = useAppSelector(selectTopic(topicId))
    const phase: Phase | undefined = useAppSelector(selectCurrentPhase(workshopId))

    useEffect(() => {
        const loadData = async () => {
            await dispatch(loadTopicWithNotes({workshopId, topicId}))
        }
        loadData()
    }, [workshopId, topicId])

    useWebsocketSubscription<TopicRoomEventTypeDto>(topicTopic(workshopId, topicId),
        payload => {
            dispatch(TopicRoomEvent({
                ...payload,
                meta: {
                    workshopId,
                    phaseId: phase?.id,
                    topicId
                }
            }))
        })

    if (!topic)
        return <></>

    if (phase) {
        return <TopicRoomWithActivePhase
            topic={topic}
            phase={phase}/>
    }
    return <InactiveTopicRoom workshopId={workshopId} topic={topic}/>
}
