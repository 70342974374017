import {useRosterState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const NumberOfParticipantsHeader: React.FC = () => {
    const {t} = useTranslation()
    const {roster} = useRosterState()
    return <>{t('roster.header', {
        numberOfParticipants: Object.keys(roster).length
    })}</>
}
