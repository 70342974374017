import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route} from 'react-router-dom'
import {Breadcrumb, Item} from 'src/components/layout/breadcrumb/Breadcrumb'
import {WorkshopsBreadcrumb} from './WorkshopsBreadcrumb'

export const ToolBarTitle: React.FC = () => {
    const {t} = useTranslation()
    return <>
        <Breadcrumb>
            <Item>{t('breadcrumb.start')}</Item>
            <Route path={'/workshops'}><WorkshopsBreadcrumb /></Route>
        </Breadcrumb>
    </>
}
