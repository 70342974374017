import {Divider, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Card} from 'src/components/common'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {selectParticipant} from 'src/state/participants'
import {selectTopic} from 'src/state/topics'
import {selectUndefined, useAppSelector} from 'src/state/types'
import {WithTopicId} from 'src/types'

export const TopicPreview: React.FC<WithTopicId> = ({
    topicId
}) => {
    const topic = useAppSelector(selectTopic(topicId))
    const owner = useAppSelector(topic ? selectParticipant(topic?.owner) : selectUndefined)
    const {t} = useTranslation()

    return topic && owner ? <div className={'topic-preview'}>
        <Text>{t('moderated-queue.you-are-next-label')}</Text>
        <Divider size='S' marginY='size-40' marginBottom={'size-250'}/>
        <Card className={'topic-card'}>{{
            rightHeaderIcon: owner ?
                <ParticipantIconWithCircle participant={owner} className={'card-icon-participant'}/> : <></>,
            title: topic.title,
            content: <></>,
        }}</Card></div> : <></>
}
