import {Dictionary} from '@reduxjs/toolkit'
import {useActiveSpeakersState, useRosterState} from 'amazon-chime-sdk-component-library-react'
import {RosterType} from 'amazon-chime-sdk-component-library-react/lib/types'
import React from 'react'
import {MeetingParticipant} from 'src/state/meetings'
import {Participant, ParticipantRolesAdapter, selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {ParticipantRole, WithRoles, WithWorkshopId} from 'src/types'
import {ParticipantPictureOrVideo} from './ParticipantPictureOrVideo'
import './ActiceSpeakerVideo.css'

export const getParticipantWithAttendeeId: (
  roster: RosterType,
  attendeeId: string,
  participants: Dictionary<Participant>
) => MeetingParticipant | undefined =
  (roster, attendeeId, participants) => {
      const rosterElement = roster[attendeeId]
      if (rosterElement) {
          const participant = participants[rosterElement.externalUserId!]
          if (participant) {
              return {
                  ...participant,
                  attendeeId
              }
          }
      }
  }

function findFirstAcceptableSpeaker(
    activeAttendeeIds: string[],
    participants: Dictionary<Participant>,
    roster: RosterType,
    roles: ParticipantRolesAdapter,
    excludeRoles: string[]
) {
    return activeAttendeeIds.find(attendeeId => {
        if (!roster[attendeeId]) {
            return false
        }
        const participantId = roster[attendeeId].externalUserId
        if (!participantId) {
            return false
        }
        return !roles.hasAtLeastOneRole(participantId, excludeRoles)
    })
}

export const ActiveSpeakerVideo: React.FC<WithWorkshopId & WithRoles & {
    excludeRoles: ParticipantRole[]
}> = ({
    workshopId,
    roles,
    excludeRoles
}) => {
    const activeAttendeeIds = useActiveSpeakersState()
    const participants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const {roster} = useRosterState()
    const activeSpeakerAttendeeId = findFirstAcceptableSpeaker(activeAttendeeIds, participants, roster, roles, excludeRoles)
    if (activeSpeakerAttendeeId) {
        const participant = getParticipantWithAttendeeId(roster, activeSpeakerAttendeeId, participants)
        if (participant) {
            return <ParticipantPictureOrVideo
                participant={participant}
                size='stage'
            />
        }
    }
    return <div className={'video-placeholder-stage'}/>
}
