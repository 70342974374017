import {useToggleLocalMute} from 'amazon-chime-sdk-component-library-react'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {useAudioInputDevices} from './use-audio-input-devices'

export function useAudioInputControl(): {
    muted: boolean
    toggleMute(): void
    mute(): void
    unMute(): void
    setMute(muted: boolean): void
    audioInputDevices: DeviceType[]
    } {
    const {muted, toggleMute} = useToggleLocalMute()
    const audioInputDevices = useAudioInputDevices()

    const unMute = () => {
        if (muted) {
            toggleMute()
        }
    }
    const mute = () => {
        if (!muted) {
            toggleMute()
        }
    }
    const setMute = (isMute: boolean) => isMute ? mute() : unMute()
    return {muted, toggleMute, mute, unMute, setMute, audioInputDevices}
}
