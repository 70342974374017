import React, {useState} from 'react'
import {closeTopic, Topic} from 'src/state/topics'
import {AlertDialog, Button, DialogTrigger} from '@adobe/react-spectrum'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PayloadAction} from '@reduxjs/toolkit'

export const CloseTopicButton: React.FC<{workshopId: string, topic: Topic}> = ({workshopId, topic}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const history = useHistory()
    const closeTopicAndRedirect = async () => {
        const result: PayloadAction<Topic> = (await dispatch(closeTopic({
            topicId: topic.id,
            workshopId
        }))) as unknown as PayloadAction<Topic>
        if(result.type === closeTopic.fulfilled.type) {
            history.push(`/workshops/${workshopId}/plenary`)
        }
        setOpen(false)
    }

    return  <DialogTrigger
        onOpenChange={setOpen}
        isOpen={open}>
        <Button variant='primary'>{t('button.close-topic')}</Button>
        <AlertDialog
            variant="confirmation"
            title={t('workshop-topic-room.close.title')}
            primaryActionLabel={t('button.close-topic')}
            cancelLabel={t('button.cancel')}
            onCancel={() => setOpen(false)}
            onPrimaryAction={() => closeTopicAndRedirect()}>
            {t('workshop-topic-room.close.description')}
        </AlertDialog>
    </DialogTrigger>
}
