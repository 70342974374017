import {Form, Text, TextArea} from '@adobe/react-spectrum'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {UserImageUpload} from 'src/components/profile/UserImageUpload'
import {UserProfile, UserProfileUpdateModel} from 'src/state/auth'

export type ProfileChange = {
    profile: UserProfileUpdateModel
    valid: boolean
}
export const UserProfileForm: React.FC<{
    profile: UserProfile
    onProfileChange(change: ProfileChange): void
}> = ({profile, onProfileChange}) => {
    const [image, setImage] = useState<string | undefined>(undefined)
    const [biography, setBiography] = useState(profile.biography)
    const isValidBiography = (bio: string | undefined) => (!!bio && bio.length > 0)
    const isValid = useMemo(() => Boolean(biography && biography.length > 0), [biography])
    const {t} = useTranslation()

    const onChanged = (image: string | undefined, bio: string | undefined) => {
        onProfileChange({
            profile: {
                biography: bio || '',
                image
            },
            valid: isValidBiography(bio)
        })
    }

    useEffect(() => {
        onChanged(image, biography)
    }, [image, biography])

    return <Form isRequired necessityIndicator='label' data-testid='user-profile-form'>
        <TextArea label={t('dialog.profile.biography')}
            placeholder='Please write a few words about yourself! You may also copy-paste a link to your online profile (e.g. LinkedIn).'
            onChange={bio => {
                setBiography(bio)
            }}
            validationState={isValid ? 'valid' : 'invalid'}
            data-testid='create-workshop-dialog-title'
            minHeight={'size-1600'}
            defaultValue={biography}
        />
        <Text>{t('dialog.profile.upload-label')}</Text>
        <div style={{display: 'flex', width: 390}}>
            <UserImageUpload image={image} setImage={setImage} />
        </div>
    </Form>
}


