import React, {useEffect} from 'react'
import {MeetingControls} from 'src/components/control/meeting/active-meeting/MeetingControls'
import {NumberOfParticipantsHeader} from 'src/components/roster/NumberOfParticipantsHeader'
import {RosterGrid} from 'src/components/roster/RosterGrid'
import {RosterStage} from 'src/components/roster/RosterStage'
import {attachModeratedWithRolesToMeeting} from 'src/state/meetings'
import {selectModeratedWithRolesActiveRole, selectModeratedWithRolesInActiveRole} from 'src/state/moderated-with-roles'
import {selectMyParticipant} from 'src/state/participants'
import {TopicAssignmentRole} from 'src/state/topic-assignments'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {
    WithMeetingId,
    WithModeratedWithRolesId,
    WithRoles,
    WithTopicId,
    WithWorkshopId,
    WithPhaseId
} from 'src/types'
import {ModeratedWithRolesControls} from './ModeratedWithRolesControls'
import './ModeratedWithRolesMeeting.css'
import {ModeratedWithRolesParticipantRoster} from './ModeratedWithRolesParticipantRoster'

export const ModeratedWithRolesMeeting: React.FC<WithMeetingId & WithWorkshopId & WithPhaseId & WithTopicId & WithRoles & WithModeratedWithRolesId> = ({
    workshopId,
    phaseId,
    topicId,
    moderatedWithRolesId,
    roles,
    meetingId
}) => {
    const activeRole: TopicAssignmentRole = useAppSelector(selectModeratedWithRolesActiveRole(moderatedWithRolesId))
    const inactiveRole: TopicAssignmentRole = useAppSelector(selectModeratedWithRolesInActiveRole(moderatedWithRolesId))
    const me = useAppSelector(selectMyParticipant)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(attachModeratedWithRolesToMeeting({meetingId, moderatedWithRolesId}))
    }, [])

    const moderatorVideo = <RosterStage
        workshopId={workshopId}
        roles={roles}
        displayActiveSpeaker={false}
        displayAbsentParticipants={true}
    />

    return <div className='moderated-with-roles'>
        <RosterGrid
            stage={moderatorVideo}
            displayActiveSpeaker={false}
            displayLocalVideo={false}
            roles={roles}
            header={<NumberOfParticipantsHeader />}
            participantRoster={<ModeratedWithRolesParticipantRoster
                activeRole={activeRole}
                inactiveRole={inactiveRole}
                workshopId={workshopId}
                roles={roles}
            />}
            moderatorControls={<ModeratedWithRolesControls
                workshopId={workshopId}
                phaseId={phaseId}
                topicId={topicId}
                moderatedWithRolesId={moderatedWithRolesId}
            />}
            meetingControls={
                roles.isModerator(me) || roles.hasRole(me?.id, activeRole) ?
                    <MeetingControls/> : undefined
            }
        />
    </div>
}
