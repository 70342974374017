import {DialogContainer} from '@adobe/react-spectrum'
import React from 'react'
import {UserAvatarDialog} from 'src/components/profile/UserAvatarDialog'
import {updateUserProfile} from 'src/state/auth'
import {useAppDispatch} from 'src/state/types'

export const UserAvatarDialogContainer: React.FC<{ open: boolean, setOpen(open: boolean): void }> = ({open, setOpen}) => {
    const dispatch = useAppDispatch()

    return <DialogContainer type='modal' onDismiss={() => setOpen(false)}>
        {open && <UserAvatarDialog
            close={() => setOpen(false)}
            save={(userProfileModel) => {
                dispatch(updateUserProfile(userProfileModel))
                setOpen(false)
            }}/>}
    </DialogContainer>
}
