import React from 'react'
import {PitchTopicCard} from 'src/components/meetings/plenary/topic-generation/moderated-queue/content/PitchTopicCard'
import {selectFirstTopicInQueue, selectModeratedQueueBy} from 'src/state/moderated-queue'
import {useAppSelector} from 'src/state/types'
import { WithPhaseId} from 'src/types'

export const PitchTopicContent: React.FC<WithPhaseId> = ({
    phaseId
}) => {
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    const presentedTopic = useAppSelector(selectFirstTopicInQueue(phaseId))
    return <div className={'pitch-topic-content'}>
        {(queue.active && presentedTopic) ? <PitchTopicCard topic={presentedTopic} /> : <></>}
    </div>
}
