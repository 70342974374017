import {createAsyncThunk} from '@reduxjs/toolkit'
import {WithWorkshopId} from 'src/types'
import {CreateWorkshopModel, Workshop, WorkshopTemplate} from './workshops.model'
import {WorkshopsApiService} from './workshops.service'

const apiService = new WorkshopsApiService()
export const loadOwnWorkshops = createAsyncThunk<Workshop[]>(
    'workshops/loadOwnWorkshops',
    () => apiService.getMyWorkshops())

export const loadParticipatingWorkshops = createAsyncThunk<Workshop[]>(
    'workshops/loadParticipatingWorkshops',
    () => apiService.getParticipatingWorkshops())

export const loadWorkshop = createAsyncThunk<Workshop, string>(
    'workshops/loadWorkshop',
    async (workshopId) => {
        return await apiService.getWorkshop(workshopId)
    })

export const createWorkshop = createAsyncThunk<Workshop, CreateWorkshopModel>(
    'workshops/create',
    (workshop) => apiService.createWorkshop(workshop))

export const updateWorkshop = createAsyncThunk<Workshop, Workshop>(
    'workshops/updateWorkshop',
    (workshop) => apiService.updateWorkshop(workshop))

export const startWorkshop = createAsyncThunk<WithWorkshopId, string>(
    'workshops/start', apiService.startWorkshop)

export const loadWorkshopTemplates = createAsyncThunk<WorkshopTemplate[]>(
    'workshops/loadWorkshopTemplates',
    () => apiService.getWorkshopTemplates())
