import {Button, Flex} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {WithParticipant, WithWorkshopId} from 'src/types'
import {ParticipantCircleWithLabel} from './ParticipantCircleWithLabel'

export const UpNextParticipant: React.FC<WithWorkshopId
  & WithParticipant
  & {
    label?: string
    onPressNext(): void
}> = ({
    participant,
    label,
    onPressNext
}) => {
    const {t} = useTranslation()
    return <Flex direction={'row'} gap={'size-130'} alignItems={'center'}>
        <Button variant={'primary'} onPress={onPressNext}>{label || t('moderated-queue.button.pass-mic-to')}</Button>
        <ParticipantCircleWithLabel participant={participant}/>
    </Flex>
}
