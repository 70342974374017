import {PropsWithChildren} from 'react'
import {drawerSize, LeftDrawerStatus} from 'src/state/layout'
import styled from 'styled-components'
import {toCss} from '../util'

export const StyledMenu = styled.nav<PropsWithChildren<{ drawerStatus: LeftDrawerStatus }>>`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  padding: 0;
  transition: all 0.3s linear;
  width: ${({drawerStatus}) => `calc(${toCss(drawerSize.left[drawerStatus])} - 1px)`};

  a {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: #D4D4D4;
    }
  }
`
