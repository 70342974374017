import {API} from 'aws-amplify'
import {ProfileDto, ProfileUpdateDto} from './profile.dto'

export class ProfileApi {
    async getProfile(): Promise<ProfileDto> {
        return await API.get('backend', 'api/profiles', {})
    }

    async updateProfile(profile: ProfileUpdateDto): Promise<ProfileDto> {
        return await API.put('backend', 'api/profiles', {
            body: profile
        })
    }
}
