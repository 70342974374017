import {
    ModeratedTopicQueueAdapter,
    WithModeratedQueueState
} from './moderated-queue.model'
import {Participant, selectLeadModerator, WithParticipantsState} from 'src/state/participants'
import {WithWorkshopsState} from 'src/state/workshops'
import {selectTopic, Topic, WithTopicsState} from 'src/state/topics'

export const selectModeratedQueueBy: (id: string) => (state: WithModeratedQueueState) => ModeratedTopicQueueAdapter = id => state => {
    return new ModeratedTopicQueueAdapter(state.moderatedQueue.entities[id] || {id: id, active: false, items: []})
}

export const selectActiveSpeakerInQueue:
    (workshopId: string, queueId: string)  => (state: WithWorkshopsState & WithParticipantsState & WithModeratedQueueState) => Participant | undefined =
    (workshopId, queueId) => state => {
        const moderator = selectLeadModerator(workshopId)(state)
        const queue = selectModeratedQueueBy(queueId)(state)
        if (!queue.hasCurrent()) return moderator
        const participantId = queue.first.participantId
        const firstParticipantInQueue = state.participants.entities[participantId]
        return firstParticipantInQueue || moderator
    }

export const selectFirstTopicInQueue:
    (queueId: string) => (state: WithModeratedQueueState & WithTopicsState) => Topic | undefined =
    (queueId) => state => {
        const queue = selectModeratedQueueBy(queueId)(state)
        if(!queue.hasItems()) return
        return selectTopic(queue.first.topicId)(state)
    }
