import {Dictionary} from '@reduxjs/toolkit'
import {WithAuthState} from 'src/state/auth'
import {WithWorkshopsState} from 'src/state/workshops'
import {participantsAdapter} from './participants.adapter'
import {Participant, WithParticipantsState} from './participants.model'

const participantsSelectors = participantsAdapter.getSelectors<WithParticipantsState>(
    (state) => state.participants
)

export const selectParticipantsForWorkshop: (workshopId: string) => (state: WithParticipantsState) => Participant[] =
    workshopId => state => (state.participants.byWorkshop[workshopId] || [])
        .map(participantId => participantsSelectors.selectById(state, participantId)!)

export const selectParticipantsForWorkshopAsMap: (workshopId: string) => (state: WithParticipantsState) => Dictionary<Participant> =
    workshopId => state => {
        const result: Dictionary<Participant> = {}
        const participants = state.participants.byWorkshop[workshopId] || []
        participants.forEach(participantId => {
            result[participantId] = participantsSelectors.selectById(state, participantId)!
        })
        return result
    }

export const selectMyParticipant: (state: WithParticipantsState & WithAuthState) => Participant | undefined =
    state => participantsSelectors.selectById(state, state.auth.user!.id)

export const selectParticipant: (participantId: string) => (state: WithParticipantsState) => Participant | undefined = (participantId) =>
    state => participantsSelectors.selectById(state, participantId)

export const selectAllParticipants: (state: WithParticipantsState) => Dictionary<Participant> = state =>
    state.participants.entities

export const selectLeadModerator: (workshopId: string) => (state: WithWorkshopsState & WithParticipantsState) => Participant | undefined = (workshopId) => state => {
    const workshop = state.workshops.entities[workshopId]
    if (!workshop) return
    return state.participants.entities[workshop.owner]
}
