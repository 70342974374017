import {createSlice} from '@reduxjs/toolkit'
import {CallEntity, CallsState} from 'src/state/calls/calls.model'
import {callsAdapter} from 'src/state/calls/calls.adapter'
import {
    joinExistingCall,
    leaveCall,
    loadAllCalls,
    setOutgoingCall,
    startAudioCall,
    startVideoCall
} from 'src/state/calls/calls.actions'
import {acceptCall} from 'src/state/incoming-calls'
import {CallUpdated} from 'src/state/calls/calls.events'
import {CallDto} from 'src/api'

export const initialState: CallsState = {
    ...callsAdapter.getInitialState(),
    currentCall: undefined as string | undefined,
    outgoingCall: undefined as string | undefined,
}

export class CallsService {
    constructor(private adapter = callsAdapter) {
    }

    finishCall: (state: CallsState) => void = (state: CallsState) => {
        state.currentCall = undefined
    }

    startCall: (state: CallsState, call: CallEntity) => void = (state : CallsState, call: CallEntity) => {
        this.adapter.upsertOne(state, call)
        state.currentCall = call.id
    }

    replaceCalls: (state: CallsState, calls: CallEntity[]) => void = (state : CallsState, calls: CallEntity[]) => {
        this.adapter.setAll(state, calls)
        if(calls.every(call => call.id !== state.currentCall)) {
            state.currentCall = undefined
        }
    }

    update: (state: CallsState, call: CallEntity) => void = (state : CallsState, call: CallEntity) => {
        this.adapter.upsertOne(state, call)
        if(call.status === 'ENDED' && call.id === state.currentCall) {
            state.currentCall = undefined
        }
    }
}

function toModel(dto: CallDto): CallEntity {
    return {
        id: dto.meetingId,
        status: dto.status,
        participantIds: dto.participantIds,
    }
}

export const callsSlice = createSlice({
    name: 'calls',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(startVideoCall.fulfilled, (state, {payload}) => {
                new CallsService().startCall(state, payload)
            })
            .addCase(startAudioCall.fulfilled, (state, {payload}) => {
                new CallsService().startCall(state, payload)
            })
            .addCase(leaveCall.fulfilled, (state) => {
                new CallsService().finishCall(state)
            })
            .addCase(acceptCall.fulfilled, ((state, {payload}) => {
                new CallsService().startCall(state, payload.call)
            }))
            .addCase(loadAllCalls.fulfilled, ((state, {payload}) => {
                new CallsService().replaceCalls(state, payload)
            }))
            .addCase(joinExistingCall.fulfilled, ((state, {payload}) => {
                new CallsService().startCall(state, payload)
            }))
            .addCase(CallUpdated, ((state, {payload}) => {
                new CallsService().update(state, toModel(payload))
            }))
            .addCase(setOutgoingCall , (state, {payload: callId}) => {
                state.outgoingCall = callId
            })
    }
})
