import {useLocalVideo, useSelectVideoInputDevice, useVideoInputs} from 'amazon-chime-sdk-component-library-react'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {deviceConfig} from 'src/state/meetings'

export type VideoControl = {
    isVideoEnabled: boolean
    toggleVideo(): Promise<void>
    enableVideo(): Promise<void>
    disableVideo(): Promise<void>
    selectVideoDevice(deviceId: string): Promise<void>
    selectedVideoDevice?: string
    videoDevices: DeviceType[]
}

export function useVideoControl(): VideoControl {
    const {isVideoEnabled, toggleVideo} = useLocalVideo()
    const selectVideoDevice = useSelectVideoInputDevice()
    const {devices: videoDevices, selectedDevice: selectedVideoDevice} = useVideoInputs(deviceConfig)

    async function enableVideo(): Promise<void> {
        if (!isVideoEnabled) {
            await toggleVideo()
        }
    }

    async function disableVideo(): Promise<void> {
        if (isVideoEnabled) {
            await toggleVideo()
        }
    }

    return {
        isVideoEnabled,
        toggleVideo,
        enableVideo,
        disableVideo,
        selectedVideoDevice: selectedVideoDevice || undefined,
        selectVideoDevice,
        videoDevices
    }
}
