import React from 'react'
import {StyledMeetingContainer} from 'src/components/layout/drawer/StyledMeetingContainer'
import {selectLayoutConfigState, selectMeetingLayout} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'

export const MeetingContainer: React.FC<{ className?: string }> = ({
    className,
    children
}) => {
    const meetingLayout = useAppSelector(selectMeetingLayout)
    const layout = useAppSelector(selectLayoutConfigState)

    return (
        <StyledMeetingContainer
            layout={layout}
            meetingLayout={meetingLayout}
            className={className}>
            {children}
        </StyledMeetingContainer>
    )
}
