import React from 'react'
import {TopicStatus} from 'src/state/topics'

export const TopicStatusText: React.FC<{ topicStatus: TopicStatus }> = ({topicStatus}) => {
    switch (topicStatus) {
    case 'CLOSED': {
        return <span className='topic-room-header-info'>Topic closed by owner!</span>
    }
    case 'ACCEPTED': {
        return <span className='topic-room-header-info'>Topic already accepted!</span>
    }
    default:
        return <></>
    }
}
