import {createSlice} from '@reduxjs/toolkit'
import {AnyAction} from 'redux'
import {dismissErrors, errorOccurred} from './error.actions'
import {ErrorState} from './error.model'

export const initialState: ErrorState = {
    errors: []
}
export const errorsSlice = createSlice({
    name: 'errors',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => builder
        .addCase(dismissErrors, (state) => {
            state.errors = []
        })
        .addCase(errorOccurred, (state, {payload: error}) => {
            state.errors.push(error)
        })
        .addDefaultCase((state, action: AnyAction) => {
            if (action.type.endsWith('/rejected')) {
                state.errors.push({message: (action as unknown as { error: Error }).error.message})
                console.error(action)
            }
        })
})
