import React from 'react'
import {MeetingControls} from 'src/components/control/meeting/active-meeting/MeetingControls'
import {NumberOfParticipantsHeader} from 'src/components/roster/NumberOfParticipantsHeader'
import {ParticipantRoster} from 'src/components/roster/ParticipantRoster'
import {RosterGrid} from 'src/components/roster/RosterGrid'
import {WithRoles, WithWorkshopId} from 'src/types'
import {RosterStage} from '../../roster/RosterStage'
import './OpenMeetingWithRoles.css'

export const OpenMeetingWithRoles: React.FC<WithWorkshopId & WithRoles> = ({
    workshopId,
    roles
}) => {
    return <RosterGrid
        roles={roles}
        header={<NumberOfParticipantsHeader />}
        stage={<RosterStage
            workshopId={workshopId}
            roles={roles}
            displayActiveSpeaker={true}
            displayAbsentParticipants={false}
        />}
        participantRoster={<ParticipantRoster
            className="open-meeting-roster"
            workshopId={workshopId}
            direction='row'
            roles={roles}
            excludeRoles={['moderator']}
            wrap/>}
        displayLocalVideo={false}
        meetingControls={<MeetingControls/>}
    />
}

