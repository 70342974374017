import {Invitation, LoadInvitationPayload, LoadInvitationsPayload} from './invitations.model'
import {InvitationDto, InvitationsApi} from 'src/api'

export function toInvitation(dto: InvitationDto): Invitation {
    return {
        ...dto,
        nickname: dto.nickname || ''
    }
}

export class InvitationsApiService {
    constructor(
        private api: InvitationsApi = new InvitationsApi()
    ) {
    }

    async getInvitationsForWorkshop(workshopId: string): Promise<LoadInvitationsPayload> {
        const invitations = await this.api.getInvitationsForWorkshop(workshopId)

        return {workshopId, invitations: invitations.map(dto => toInvitation(dto))}
    }

    async addInvitation({workshopId, email, nickname}: { workshopId: string, email: string, nickname: string }): Promise<LoadInvitationPayload> {
        const invitation = await this.api.addInvitation(workshopId, email, nickname)
        return {
            workshopId: workshopId,
            invitation: toInvitation(invitation)
        }
    }

    async deleteInvitations({workshopId, invitations}: { workshopId: string, invitations: string[]}): Promise<void> {
        await this.api.deleteInvitations(workshopId, invitations)
    }

    async uploadInvitationsFile({workshopId, file}: { workshopId: string, file: File }): Promise<{ workshopId: string, invitations: Invitation[] }> {
        return new Promise<{ workshopId: string, invitations: Invitation[] }>(resolve => {
            const parseLine = (line: string): Invitation | null => {
                const parts = line.split(';')
                if (parts.length !== 2) {
                    return null
                }
                return {
                    email: parts[0],
                    nickname: parts[1]
                }
            }
            const parseFile = (data?: string | ArrayBuffer | null) => {
                if (!data) {
                    return []
                }
                const stringData = data as string
                return stringData.split('\n')
                    .map(parseLine)
                    .filter(invitation => invitation !== null)
                    .map(invitation => invitation!)
            }

            const reader = new FileReader()
            reader.onload = (event => {
                const invitations = parseFile(event.target?.result)
                this.createInvitations(workshopId, invitations)
                    .then(() => {
                        resolve({
                            workshopId: workshopId,
                            invitations: invitations
                        })
                    })
            })
            reader.readAsText(file)
        })
    }

    async createInvitations(workshopId: string, invitations: Invitation[]): Promise<void> {
        for (const inv of invitations) {
            await this.api.addInvitation(workshopId, inv.email, inv.nickname)
        }
    }

    sendInvitationEmails(workshopId: string): Promise<void> {
        return this.api.sendEmails(workshopId)
    }

    sendSingleInvitationsEmail(workshopId: string, email: string): Promise<void> {
        return this.api.sendEmail(workshopId, email)
    }
}
