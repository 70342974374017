import {ParticipantRolesAdapter} from 'src/state/participants'
import {ParticipantRoles} from 'src/types'
import {topicAssignmentsAdapter} from './topic-assignments.adapter'
import {TopicAssignment, WithTopicAssignmentsState} from './topic-assignments.model'

export const selectTopicAssignmentRoles: (phaseId: string, topicId: string) => (state: WithTopicAssignmentsState) => ParticipantRolesAdapter = (phaseId, topicId) => state => {
    return selectTopicAssignmentRolesById(`${phaseId}-${topicId}`)(state)
}

export const selectTopicAssignmentRolesById: (phaseAndTopicId: string) => (state: WithTopicAssignmentsState) => ParticipantRolesAdapter = (phaseAndTopicId) => state => {
    const assignmentIds = state.topicAssignments.byPhaseAndTopic[phaseAndTopicId] || []
    return new ParticipantRolesAdapter(assignmentIds.reduce((map, assignmentId) => {
        const assignment = state.topicAssignments.entities[assignmentId]
        if (assignment) {
            map[assignment.participantId] = map[assignment.participantId] ? [...map[assignment.participantId], assignment.role] : [assignment.role]
        }
        return map
    }, <ParticipantRoles>{}))
}

const topicAssignmentsSelectors = topicAssignmentsAdapter.getSelectors<WithTopicAssignmentsState>(
    (state) => state.topicAssignments
)
export const selectAllTopicAssignments: (state: WithTopicAssignmentsState) => TopicAssignment[] = state => {
    return topicAssignmentsSelectors.selectAll(state)
}
