import {WithAuthState} from 'src/state/auth'
import {workshopsAdapter, workshopTemplatesAdapter} from './workshops.adapter'
import {WithWorkshopsState, WithWorkshopTemplatesState, Workshop, WorkshopTemplate} from './workshops.model'

const workshopsSelectors = workshopsAdapter.getSelectors<WithWorkshopsState>(
    (state) => state.workshops
)

const workshopTemplatesSelectors = workshopTemplatesAdapter.getSelectors<WithWorkshopTemplatesState>(
    (state) => state.workshopTemplates
)

export const selectMyWorkshops: (state: WithWorkshopsState) => Workshop[] = state =>
    state.workshops.myWorkshops.map(id => state.workshops.entities[id]!)

export const selectParticipatingWorkshops: (state: WithWorkshopsState) => Workshop[] = state =>
    state.workshops.participatingWorkshops.map(id => state.workshops.entities[id]!)

export const selectWorkshopById: (workshopId: string) => (state: WithWorkshopsState) => Workshop | undefined = id => state =>
    workshopsSelectors.selectById(state, id)

export const selectWorkshopTemplates: (state: WithWorkshopTemplatesState) => WorkshopTemplate[] = state =>
    workshopTemplatesSelectors.selectAll(state)

export const selectIsLeadModerator: (workshopId: string) => (state: WithWorkshopsState & WithAuthState) => boolean = (workshopId) => state => {
    return state.workshops.entities[workshopId]?.owner === state.auth.user?.id
}

