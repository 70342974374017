import React from 'react'
import {ParticipantRolesAdapter, selectLeadModerator} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithMeetingId, WithWorkshopId} from 'src/types'
import './PresentationMeeting.css'
import {PresentationMeetingWithRoles} from './PresentationMeetingWithRoles'

export const LeadModeratorPresentationMeeting: React.FC<WithMeetingId & WithWorkshopId> = ({
    meetingId,
    workshopId
}) => {
    const moderator = useAppSelector(selectLeadModerator(workshopId))
    const roles = new ParticipantRolesAdapter(moderator ? {
        [moderator.id]: ['moderator']
    } : {})

    return <PresentationMeetingWithRoles
        meetingId={meetingId}
        workshopId={workshopId}
        roles={roles}/>
}
