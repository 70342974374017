import {createAsyncThunk} from '@reduxjs/toolkit'
import {RxStomp} from '@stomp/rx-stomp'
import {CallsApi, MeetingsApi} from 'src/api'
import {JoinGroupRequestHandledPayload} from 'src/components/meetings/calls/GroupCall'
import {stompClient} from 'src/provider/StompClientProvider'
import {CallEntity, WithCallsState} from 'src/state/calls'
import {IncomingCallDto} from 'src/state/incoming-calls/incoming-calls.events'
import {IncomingCallEntity} from 'src/state/incoming-calls/incoming-calls.model'
import { WithIncomingCall, WithIncomingCallId, WithWorkshopId} from 'src/types'
import {selectAuthenticatedUser, WithAuthState} from 'src/state/auth'

export function incomingCallIdFromDto(incomingCallDto: IncomingCallDto): string {
    return incomingCallDto.requestToJoinGroup ? incomingCallDto.meetingId + incomingCallDto.participantIds[0]  : incomingCallDto.meetingId
}

export function sendRequestToJoinGroupCallHandled(
    client: RxStomp,
    workshopId: string,
    incomingCall: IncomingCallEntity
): void {
    const payload: JoinGroupRequestHandledPayload = {
        incomingCallId: incomingCall.id,
        meetingId: incomingCall.meetingId,
        participantId: incomingCall.participantIds[0]
    }

    client.publish({
        destination: `/app/workshops/${workshopId}/calls/${incomingCall.meetingId}`,
        body: JSON.stringify({
            type: 'JoinGroupRequestHandled',
            payload: payload
        })
    })
}

function sendMessageToCallerToAcceptToJoinGroupCall(client: RxStomp, workshopId: string, incomingCall: IncomingCallEntity): void {
    client.publish({
        destination: `/app/workshops/${workshopId}/participants/${incomingCall.participantIds[0]}`,
        body: JSON.stringify({
            type: 'JoinGroupAccepted',
            payload: {
                meetingId: incomingCall.meetingId
            }
        })
    })
}

export const acceptCall = createAsyncThunk<WithIncomingCall & {call: CallEntity}, WithWorkshopId & WithIncomingCall & { type: 'video' | 'audio' }>(
    'calls/accept-call',
    async  ({incomingCall, workshopId}, thunkAPI) => {
        if (incomingCall.requestToJoinGroup) {
            const client = await stompClient()
            sendMessageToCallerToAcceptToJoinGroupCall(client, workshopId, incomingCall)
            sendRequestToJoinGroupCallHandled(client, workshopId, incomingCall)
        } else {
            const meetingsApi = new MeetingsApi()
            const chimeMeeting = await meetingsApi.requestMeeting(incomingCall.meetingId)
            await meetingsApi.createAttendee(chimeMeeting.externalMeetingId)
        }
        const state = thunkAPI.getState() as WithCallsState
        const call = state.calls.entities[incomingCall.meetingId]!
        return {incomingCall, call}
    }
)

function sendMessageToCallerToRejectToJoinGroupCall(client: RxStomp, workshopId: string, incomingCall: IncomingCallEntity): void {
    client.publish({
        destination: `/app/workshops/${workshopId}/participants/${incomingCall.participantIds[0]}`,
        body: JSON.stringify({
            type: 'JoinGroupRejected',
            payload: {
                meetingId: incomingCall.meetingId
            }
        })
    })
}

export const rejectCall = createAsyncThunk<WithIncomingCallId, WithIncomingCall & WithWorkshopId>(
    'calls/reject-call',
    async ({incomingCall, workshopId}, thunkAPI) => {
        if (incomingCall.requestToJoinGroup) {
            const client = await stompClient()
            sendMessageToCallerToRejectToJoinGroupCall(client, workshopId, incomingCall)
            sendRequestToJoinGroupCallHandled(client, workshopId, incomingCall)
        }
        const participantId = incomingCall.requestToJoinGroup?
            incomingCall.participantIds[0]
            : selectAuthenticatedUser(thunkAPI.getState() as WithAuthState).id
        await new CallsApi().leaveCall(workshopId, incomingCall.meetingId, participantId)
        return {incomingCallId: incomingCall.id}
    }
)
