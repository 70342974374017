import {Button, DialogTrigger} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {enqueueTopic} from 'src/state/moderated-queue'
import {CreatedTopic, CreateTopicDialog} from 'src/components/meetings/plenary/topic-generation/open/content/controls/CreateTopicDialog'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {WithWorkshopId, WithPhaseId} from 'src/types'

export const CreateAndEnqueueTopicButton: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const dispatchTopicCreation = async (model: CreatedTopic) => {
        dispatch(enqueueTopic({
            workshopId,
            phaseId,
            topic: model,
        }))
        setOpen(false)
    }
    return (
        <DialogTrigger isDismissable
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}>
            <Button variant='primary'>{t('button.add-topic-and-enqueue')}</Button>
            <CreateTopicDialog
                enableDescription={false}
                saveLabel={t('button.submit-topic-and-enqueue')}
                close={() => {
                    setOpen(false)
                }}
                create={dispatchTopicCreation}/>
        </DialogTrigger>
    )
}
