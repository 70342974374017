import {scheduleAdapter} from './schedule.adapter'
import {ScheduleItem, WithScheduleState} from './schedule.model'

const scheduleSelectors = scheduleAdapter.getSelectors<WithScheduleState>(
    (state) => state.schedule
)

export const selectSchedule: (state: WithScheduleState) => ScheduleItem[] = state =>
    scheduleSelectors.selectAll(state)

