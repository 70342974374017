import {ActionButton, DialogTrigger} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {ParticipantInfoPopover} from 'src/components/ParticipantInfoPopover'
import {WithParticipant} from 'src/types'
import {InnerCircle} from './InnerCircle'
import {OuterCircle} from './OuterCircleRoom'
import {CenterCirclePosition, StatusCircle} from './StatusCircle'

export const AvatarCircle: React.FC<WithParticipant & {
    size: number
    canStartCall: boolean
    active: boolean
}>
    = ({participant, size, children, canStartCall, active}) => {
        const innerCircleSize = size
        const outerCircleSize = innerCircleSize + 4
        const [openInfo, setOpenInfo] = useState(false)
        const ref = React.useRef(null)
        return <>
            <DialogTrigger type='popover' isOpen={openInfo} targetRef={ref} isDismissable onOpenChange={setOpenInfo}>
                <ActionButton isHidden={true}/>
                <ParticipantInfoPopover
                    close={() => setOpenInfo(false)}
                    participant={participant}
                    canStartCall={canStartCall}
                />
            </DialogTrigger>
            <OuterCircle size={outerCircleSize} backgroundColor='white' borderColor='#D3D3D3'
                onClick={() => setOpenInfo(!openInfo)} ref={ref}>
                <InnerCircle size={innerCircleSize} backgroundColor='#D3D3D3'>
                    {children}
                </InnerCircle>
                <CenterCirclePosition size={10} circleSize={outerCircleSize}>
                    <StatusCircle size={10} backgroundColor={active ? 'green' : 'red'} borderColor='white' circleSize={outerCircleSize}/>
                </CenterCirclePosition>
            </OuterCircle>
        </>
    }
