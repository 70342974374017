import {Divider, Flex} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {NetworkersRoster} from './NetworkersRoster'
import {ParticipantRoster} from 'src/components/roster/ParticipantRoster'
import {TopicAssignmentRole} from 'src/state/topic-assignments'
import {WithRoles, WithWorkshopId} from 'src/types'
import {ParticipantBadgeRoster} from 'src/components/roster/ParticipantBadgeRoster'

export const ModeratedWithRolesParticipantRoster: React.FC<WithWorkshopId & WithRoles & {
    activeRole: TopicAssignmentRole,
    inactiveRole: TopicAssignmentRole
}> = ({
    workshopId,
    activeRole,
    inactiveRole,
    roles
}) => {
    const {t} = useTranslation()

    const roleLabel: Record<TopicAssignmentRole, string> = {
        debater: t('moderated-with-roles.role-debaters-label'),
        evaluator: t('moderated-with-roles.role-evaluators-label'),
        moderator: t('moderated-with-roles.role-moderator-label'),
        networker: t('moderated-with-roles.role-networkers-label'),
    }

    return <Flex direction='row' wrap={false} gap={'size-130'} UNSAFE_className={'moderated-with-roles'}>
        <div className='active-role-column'>
            <div className={'moderated-with-roles-role-label'}>
                {roleLabel[activeRole]}
            </div>
            <Divider size='M' marginTop='size-40' marginBottom='size-200' />
            <ParticipantRoster
                workshopId={workshopId}
                direction='row'
                roles={roles}
                showAbsentParticipants={true}
                includeRoles={[activeRole]}
                excludeRoles={['moderator']}
                wrap
                className='active-role-roster'
            />
        </div>
        <Flex direction={'column'} UNSAFE_className='inactive-role-column'>
            <div className={'moderated-with-roles-role-label'}>
                {roleLabel[inactiveRole]}
            </div>
            <Divider size='M' marginTop='size-40' marginBottom='size-200'/>
            <div className={'inactive-role-roster'}>
                <ParticipantBadgeRoster
                    workshopId={workshopId}
                    roles={roles}
                    showAbsentParticipants={true}
                    includeRoles={[inactiveRole]}
                    excludeRoles={['moderator']}
                />
            </div>
            <div className={'networkers-role-roster-label'}>
                <div className={'moderated-with-roles-role-label'}>
                    {roleLabel.networker}
                </div>
            </div>
            <Divider size='M' marginTop='size-40' marginBottom='size-200'/>
            <div className={'networkers-role-roster'}>
                <NetworkersRoster
                    workshopId={workshopId}
                    roles={roles}
                    excludeRoles={['moderator', 'debater', 'evaluator']}
                />
            </div>
        </Flex>
    </Flex>
}

