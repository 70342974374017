import React, {ReactNode} from 'react'
import {RosterGridMeetingControls} from 'src/components/roster/RosterGridMeetingControls'
import {WithRoles} from 'src/types'
import {ModeratorControls} from './ModeratorControls'
import './RosterGrid.css'
import {MyVideoIfStarted} from './video/MyVideoIfStarted'

export const RosterGrid: React.FC<WithRoles & {
    displayActiveSpeaker?: boolean
    displayLocalVideo?: boolean
    header?: ReactNode
    stage?: ReactNode
    participantRoster: ReactNode
    moderatorControls?: ReactNode
    meetingControls?: ReactNode
}> = ({
    displayLocalVideo,
    roles,
    header,
    stage,
    participantRoster,
    moderatorControls,
    meetingControls
}) => {
    const shouldDisplayLocalVideo = displayLocalVideo === undefined ? true : displayLocalVideo
    return <div className={'roster-grid'}>
        {header ? <div className={'roster-grid-header'}>
            {header}
        </div> : <></>}
        {stage ? <div className={'roster-grid-stage'}>
            {stage}
        </div> : <></>}
        <div className={'roster-grid-participants'}>
            {participantRoster}
        </div>
        {shouldDisplayLocalVideo ? <MyVideoIfStarted /> :<></>}
        {moderatorControls ? <ModeratorControls roles={roles}>{moderatorControls}</ModeratorControls> : <></> }
        {meetingControls ? <RosterGridMeetingControls>{meetingControls}</RosterGridMeetingControls> : <></>}
    </div>
}

