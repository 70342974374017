import styled from 'styled-components'


const pointOnCircle = (
    radius: number,
    angle: number
) => {
    return {
        x: (radius * Math.cos(angle)),
        y: (radius * Math.sin(angle))
    }
}

const centerOfCircle = (
    circleSize: number,
) => {
    return {
        x: (circleSize) / 2,
        y: (circleSize) / 2
    }
}


// TODO fix circle positioning
export const CenterCirclePosition = styled.div<{
  size: number
  circleSize: number
}>`
  width: 0;
  height: 0;
  left: ${({circleSize}) => centerOfCircle(circleSize).x}px;
  top: ${({circleSize}) => - centerOfCircle(circleSize).y}px;
  position: relative;
`

export const StatusCircle = styled.div<{
  size: number
  circleSize: number
  backgroundColor: string
  borderColor: string
}>`
  background-color: ${({backgroundColor}) => backgroundColor};
  display: inline-block;
  border-radius: 50%;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  border: 2px solid ${({borderColor}) => borderColor};
  -webkit-box-shadow: -4px 5px 6px -5px #000000; 
  box-shadow: -4px 5px 6px -5px #000000;
  left: ${({circleSize}) => pointOnCircle(circleSize / 2, -45).x - 5}px;
  top: ${({circleSize}) => - pointOnCircle(circleSize / 2, -45).y - 15}px;
  position: absolute;
`
