import {BaseProps} from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base'
import React, {forwardRef, HTMLAttributes} from 'react'

export interface VideoTileProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
        BaseProps {
}

// eslint-disable-next-line react/display-name
export const CustomVideoTile = forwardRef(
    (props: VideoTileProps, ref: React.Ref<HTMLVideoElement>) => {
        const { className } = props
        return <video ref={ref} className={className}/>
    }
)

export default CustomVideoTile
