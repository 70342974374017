import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Footer,
    Form,
    Heading,
    TextField
} from '@adobe/react-spectrum'
import React, {FormEventHandler, useMemo, useState} from 'react'

export interface AddParticipantModel {
    email: string
    nickname: string
}

interface Props {
    close(): void

    add(createWorkshopModel: AddParticipantModel): void
}

export const AddInvitationDialog: React.FC<Props> = ({close, add}) => {
    // eslint-disable-next-line no-control-regex, no-useless-escape
    const emailValidationRegEx = new RegExp(/^(((?:(?![<>()\[\]\\.,;:\s@"])[\x00-\x7F])+(\.(?:(?![<>()\[\]\\.,;:\s@"])[\x00-\x7F])+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [email, setEmail] = useState('')
    const [nickname, setNickname] = useState('')
    const isValidEmail = useMemo(() => email.match(emailValidationRegEx), [email])
    const isValid = isValidEmail
    const formSubmit: FormEventHandler = (event) => {
        event.preventDefault()
        return handleSubmit()
    }
    const handleSubmit = () => isValid && add({email, nickname})

    return <Dialog size='M' data-testid='add-participant-dialog'>
        <Heading>Add participant</Heading>
        <Divider/>
        <Content>
            <Form isRequired necessityIndicator='label' onSubmit={formSubmit}>
                <TextField label='Email'
                    placeholder='email of participant'
                    onChange={setEmail}
                    validationState={isValidEmail ? 'valid' : 'invalid'}
                    autoFocus
                    data-testid='add-participant-dialog-email'
                />
            </Form>
            <Form onSubmit={formSubmit}>
                <TextField label='Nickname'
                    placeholder='Name of participant'
                    onChange={setNickname}
                    data-testid='add-participant-dialog-nickname'
                />
            </Form>
        </Content>
        <Footer>
            <ButtonGroup>
                <Button variant='secondary' onPress={close} data-testid='add-participant-button-cancel'>
                    Cancel
                </Button>
                <Button variant='cta' onPress={handleSubmit} data-testid='add-participant-button-add'>
                    Create
                </Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
