import React from 'react'
import styled from 'styled-components'

export const ContentRow: React.FC = ({children}) => {
    return <StyledDiv className='content-row'>
        {children}
    </StyledDiv>
}

const StyledDiv = styled.div`
display: flex;
flex: 1 1 auto;
overflow-y: hidden;
transition: all 0.3s linear;
min-height: 0px;
`
