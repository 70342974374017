import React from 'react'
import {LeadModeratorOpenMeeting} from 'src/components/meetings/plenary/LeadModeratorOpenMeeting'
import {LeadModeratorPresentationMeeting} from 'src/components/meetings/plenary/presentation/LeadModeratorPresentationMeeting'
import {TopicBreakoutMeeting} from 'src/components/meetings/topic-room/TopicBreakoutMeeting'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import './Meeting.css'
import {ModeratedQueueMeeting} from 'src/components/meetings/plenary/topic-generation/moderated-queue/meeting/ModeratedQueueMeeting'
import {selectActiveMeeting} from 'src/state/meetings'
import {GroupCall} from 'src/components/meetings/calls/GroupCall'

export const ActiveMeeting: React.FC<WithWorkshopId> = ({workshopId}) => {
    const activeMeeting = useAppSelector(selectActiveMeeting)

    if (activeMeeting?.hasTopicId()) {
        return <TopicBreakoutMeeting
            workshopId={workshopId}
            meeting={activeMeeting}/>
    }

    switch (activeMeeting?.modeOfInteraction) {
    case 'presentation':
        return <LeadModeratorPresentationMeeting meetingId={activeMeeting.id} workshopId={workshopId} />
    case 'open':
        return <LeadModeratorOpenMeeting workshopId={workshopId} />
    case 'moderated-queue':
        return activeMeeting.hasPhaseId() ? <ModeratedQueueMeeting workshopId={workshopId} phaseId={activeMeeting.phaseId}/> : <>There is no running phase</>
    case 'group-call':
        return <GroupCall workshopId={workshopId} />
    default:
        return <>Unknown Meeting type</>
    }
}


