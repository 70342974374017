import React from 'react'
import {WithParticipant} from 'src/types'
import {AvatarCircle} from './AvatarCircle'

export const PictureAvatar: React.FC<WithParticipant & {
    canStartCall: boolean,
    active: boolean
}>
    = ({participant, canStartCall, active}) => {
        return <>
            <AvatarCircle participant={participant} size={70} canStartCall={canStartCall} active={active}>
                <img alt={participant.name} src={participant.imageUrl} style={{width: '70px', height: '70px'}}/>
            </AvatarCircle>
        </>
    }
