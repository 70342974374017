import {Topic} from 'src/state/topics'
import React, {useMemo, useState} from 'react'
import {WithTopic} from 'src/types'
import {useTranslation} from 'react-i18next'
import {Button, ButtonGroup, Content, Dialog, Divider, Footer, Form, Heading, TextField} from '@adobe/react-spectrum'

interface Props {
    close(): void

    update(topic: Topic): void
}

export const EditTopicTitleDialog: React.FC<Props & WithTopic> = ({
    close,
    update,
    topic
}) => {
    const [topicTitle, setTopicTitle] = useState<string>(topic.title)
    const isValidTitle = useMemo(() => topicTitle.length > 0, [topicTitle])
    const {t} = useTranslation()

    const handleSubmit = () => isValidTitle && update({...topic, title: topicTitle})

    return <Dialog size='L'
        data-testid='edit-topic-dialog'>
        <Heading>{t('dialog.edit-topic.headline')}</Heading>
        <Divider/>
        <Content>
            <Form isRequired necessityIndicator='label' onSubmit={handleSubmit}
                data-testid='edit-topic-dialog-form'>
                <TextField label={t('dialog.edit-topic.name-label')}
                    defaultValue={topicTitle}
                    onChange={setTopicTitle}
                    validationState={isValidTitle ? 'valid' : 'invalid'}
                    autoFocus
                    data-testid='edit-topic-dialog-title'
                />
            </Form>
        </Content>
        <Footer>
            <ButtonGroup>
                <Button
                    variant='secondary'
                    onPress={close}
                    data-testid='edit-topic-button-cancel'>{t('button.cancel')}</Button>
                <Button
                    variant='primary'
                    onPress={handleSubmit}
                    data-testid='edit-topic-button-save'>{t('button.save')}</Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
