import {Heading} from '@adobe/react-spectrum'
import {Dictionary} from '@reduxjs/toolkit'
import {useMeetingManager} from 'amazon-chime-sdk-component-library-react'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {UserProfileDialogTrigger} from 'src/container/workshops/UserProfileDialogTrigger'
import {WorkshopRouting} from 'src/container/workshops/WorkshopRouting'
import {useWebsocketSubscription} from 'src/hooks'
import {useLocationPublisher} from 'src/hooks/participant-locations/use-location-publisher'
import {loadWorkshopAndPhases} from 'src/state/actions'
import {setNotificationDrawer} from 'src/state/layout'
import {
    loadMyParticipant,
    loadParticipants,
    Participant,
    selectAllParticipants,
    selectMyParticipant
} from 'src/state/participants'
import {loadParticipantName} from 'src/state/participants/load-participant-name'
import {loadPersonalSchedule} from 'src/state/schedule'
import {loadTopics} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {PersonalEvent, WorkshopEvent} from 'src/state/workshop.event'
import { selectWorkshopById} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'
import {WorkshopEventDto} from 'src/websocket/workshop-event-types.dto'
import {workshopMyTopic, workshopTopic} from 'src/websocket/workshop-topics'

export function useAttendeeNameMapping(workshopId: string): void {
    const meetingManager = useMeetingManager()
    const participants: Dictionary<Participant> = useAppSelector(selectAllParticipants)
    const {t} = useTranslation()

    useEffect(() => {
        meetingManager.getAttendee = loadParticipantName(
            workshopId,
            participants,
            t('participant.unknown-participant'))
    }, [meetingManager, workshopId, participants])
}

export const WorkshopLandingPage: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const workshop = useAppSelector(selectWorkshopById(workshopId))
    const me: Participant | undefined = useAppSelector(selectMyParticipant)
    const dispatch = useAppDispatch()

    useAttendeeNameMapping(workshopId)
    useLocationPublisher(workshopId)

    useWebsocketSubscription<WorkshopEventDto>(workshopTopic(workshopId),
        payload => dispatch(WorkshopEvent({...payload, workshopId})))
    useWebsocketSubscription<WorkshopEventDto>(workshopMyTopic(workshopId, me?.id),
        payload => dispatch(PersonalEvent({...payload, workshopId})))

    useEffect(() => {
        const loadData = async () => {
            await dispatch(loadWorkshopAndPhases(workshopId))
            await dispatch(loadMyParticipant(workshopId))
            await dispatch(loadParticipants(workshopId))
            await dispatch(loadPersonalSchedule({workshopId}))
            await dispatch(loadTopics({workshopId}))
        }
        loadData()
        dispatch(setNotificationDrawer('open'))
    }, [dispatch, workshopId])


    if (!workshop) {
        return <Heading level={2}>Loading...</Heading>
    }
    return <>
        <UserProfileDialogTrigger />
        <WorkshopRouting workshop={workshop} />
    </>

}

