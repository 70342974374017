import {RxStomp} from '@stomp/rx-stomp'
import useInterval from '@use-it/interval'
import {useEffect} from 'react'
import {useStomp} from 'src/hooks/websocket'
import {
    removeOutdatedParticipantLocations,
    selectMyLocation,
    selectMySessionId
} from 'src/state/participant-locations'
import {ParticipantLocationDto} from 'src/state/participant-locations/participant-locations.events'
import {selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'


export function publishLocation(
    stomp: RxStomp,
    workshopId: string,
    participantLocation: ParticipantLocationDto
): void {
    stomp.publish({
        destination: `/app/workshops/${workshopId}/location`,
        body: JSON.stringify({
            participantId: participantLocation.participantId,
            location: participantLocation?.location,
            meetingId: participantLocation.meetingId,
            sessionId: participantLocation.sessionId
        })
    })
}

export function useLocationPublisher(workshopId: string): void {
    const me = useAppSelector(selectMyParticipant)
    const myLocation = useAppSelector(selectMyLocation)
    const sessionId = useAppSelector(selectMySessionId)
    const stomp = useStomp()
    const dispatch = useAppDispatch()

    useInterval(() => {
        dispatch(removeOutdatedParticipantLocations())
    }, 15000)

    useInterval(() => {
        if (!stomp || !me) {
            return
        }
        publishLocation(stomp, workshopId, myLocation || {participantId: me.id, sessionId})
    }, 5000)

    useEffect(() => {
        if (!stomp || !me) {
            return
        }

        publishLocation(stomp, workshopId, myLocation || {participantId: me.id, sessionId})
    }, [myLocation, stomp, me])

}
