import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {Toolbox} from 'src/components/layout/Toolbox'
import {TopicDescription} from './TopicDescription'
import {
    TopicWhiteBoard
} from './TopicRoomForTopicGeneration'
import {AddNoteButton} from './AddNoteButton'
import {WithTopic, WithWorkshopId} from 'src/types'
import {useAppSelector} from 'src/state/types'
import {selectMyParticipant} from 'src/state/participants'
import { ContentWithToolbox } from 'src/components/layout/ContentWithToolbox'

export const InactiveTopicRoom: React.FC<WithWorkshopId & WithTopic> = ({
    workshopId,
    topic,
}) => {
    const me = useAppSelector(selectMyParticipant)
    const canEditNotes = topic.owner === me?.id && topic.status !== 'CLOSED'
    return <ContentWithToolbox
        toolbox={canEditNotes ? <Toolbox>
            <AddNoteButton workshopId={workshopId} topicId={topic.id}/>
        </Toolbox> : <></>}>
        <Flex direction='row' wrap={false} gap={'size-225'}>
            <div className='topic-description-container-row'>
                <TopicDescription
                    workshopId={workshopId}
                    topic={topic}
                    showVotes={false}
                    showStatus={false}
                    canOwnerLeaveTopic={true}
                />
            </div>
            <div className='whiteboard-container-row'>
                <TopicWhiteBoard
                    workshopId={workshopId}
                    topic={topic}
                    canEditNotes={canEditNotes}
                />
            </div>
        </Flex>
    </ContentWithToolbox>
}
