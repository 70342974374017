
import {layoutConfig} from 'src/state/layout/config'
import {selectIsToolbarRequired} from 'src/state/layout/footer'
import {WithMeetingsState} from 'src/state/meetings'
import {RootState} from 'src/state/types'
import {
    LayoutConfigState,
    LeftDrawerStatus,
    MeetingLayout,
    NotificationDrawerStatus,
    SearchDrawerStatus,
    WithLayoutState
} from './layout.model'
import {selectIsWorkshopFinished} from 'src/state/phases'

export const selectIsLeftDrawerOpen: (state: WithLayoutState & WithMeetingsState) => boolean = state =>
    state.layout.drawer.left === 'open'

export const selectIsLeftDrawerCollapsed: (state: WithLayoutState) => boolean = state =>
    state.layout.drawer.left === 'collapsed'

export const selectLeftDrawerStatus: (state: WithLayoutState) => LeftDrawerStatus = state =>
    state.layout.drawer.left

export const selectIsMeetingDrawerOpen: (state: WithLayoutState) => boolean = state =>
    selectMeetingLayout(state) === 'small'
  || selectMeetingLayout(state) === 'medium'
  || selectMeetingLayout(state) === 'large'

export const selectIsMeetingDrawerHidden: (state: WithLayoutState) => boolean = state =>
    selectMeetingLayout(state) === 'hidden'

export const selectMeetingLayout: (state: WithLayoutState) => MeetingLayout = state => {
    return state.layout.drawer.meeting
}

export const selectSearchDrawerStatus: (state: WithLayoutState) => SearchDrawerStatus = state => {
    return state.layout.drawer.search
}

export const selectNotificationDrawerStatus: (state: WithLayoutState) => NotificationDrawerStatus = state => {
    return state.layout.drawer.notification
}

export const selectLayoutConfigState: (state: RootState) => LayoutConfigState = state => {
    const isToolbarRequired = selectIsToolbarRequired(state)
        || selectIsWorkshopFinished(state)
    return {
        header: {
            height: layoutConfig.header.height,
        },
        drawer: {
            left: layoutConfig.left[state.layout.drawer.left],
            meeting: layoutConfig.meeting[state.layout.drawer.meeting],
            search: layoutConfig.search[state.layout.drawer.search],
            notification: layoutConfig.notification[state.layout.drawer.notification]
        },
        footer: {
            height: layoutConfig.footer[isToolbarRequired ? 'visible' : 'hidden'].height
        }
    }
}

export * from './footer/toolbar-actions'
