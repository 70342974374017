import {Grid, View} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Workshop} from 'src/state/workshops'
import {DateTime} from 'src/components/common/time/DateTime'

interface Props {
    workshop: Workshop
}

export const WorkshopCardContent: React.FC<Props> = ({workshop}) => {
    const {t} = useTranslation()
    return <Grid columns={['size-500', 'size-1600']} autoRows='size-200'
        gap='size-75' autoFlow='row' justifyContent='left'>
        <View>
            <div style={{textAlign: 'left'}}>{t('workshop-card.start-time-label')}</div>
        </View>
        <View>
            <div style={{textAlign: 'left'}}><DateTime dateTime={workshop.start}/></div>
        </View>
        <View>
            <div style={{textAlign: 'left'}}>{t('workshop-card.end-time-label')}</div>
        </View>
        <View>
            <div style={{textAlign: 'left'}}><DateTime dateTime={workshop.end}/></div>
        </View>
    </Grid>
}
