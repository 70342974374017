import {DefaultDeviceController} from 'amazon-chime-sdk-js'
import {useEffect, useMemo} from 'react'
import {deviceControllerFactory} from './device-controller-factory'

export function useDeviceController(): DefaultDeviceController {
    const controller = useMemo(() => deviceControllerFactory(), [])
    useEffect(() => {
        return () => {
            controller.destroy()
                .catch(error => {
                    console.warn('Failed to cleanup device controller', error)
                })
        }
    }, [controller])
    return controller
}
