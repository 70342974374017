import {createAsyncThunk} from '@reduxjs/toolkit'
import {API} from 'aws-amplify'
import moment from 'moment'
import {Dispatch} from 'react'
import {WithAuthState} from 'src/state/auth'
import {selectWorkshopById, WithWorkshopsState, Workshop} from 'src/state/workshops'
import {WithPhase, WithWorkshop, WithWorkshopId} from 'src/types'
import {v4} from 'uuid'
import {MeetingVariantType, ModeOfInteraction} from '../common.model'
import {PhaseDeleted, PhaseUpdated} from './phases.events'
import {meetingVariantConfig, Phase, WithPhasesState} from './phases.model'
import {selectPhasesForWorkshop} from './phases.selectors'
import {PhasesService} from './phases.service'

export type LoadWorkshopPhasesResult = WithWorkshopId & {
    phases: Phase[],
    isWorkshopOwner: boolean
}

export const loadPhases = createAsyncThunk<LoadWorkshopPhasesResult, string>(
    'phases/load-for-workshop',
    async (workshopId, thunkAPI) => {
        const phases = await new PhasesService().getPhases(workshopId)
        const state = thunkAPI.getState() as WithAuthState & WithWorkshopsState
        return {workshopId, phases, isWorkshopOwner: state.auth.user?.id === selectWorkshopById(workshopId)(state)?.owner}
    })

export const nextPhase = createAsyncThunk<WithWorkshopId, string>(
    'workshops/next-phase',
    async (workshopId) => {
        await API.post('backend', `api/workshops/${workshopId}/next-phase`, {})
        return {workshopId}
    })

export const finishCurrentPhase = createAsyncThunk<WithWorkshopId, string>(
    'workshops/finish-phase',
    async (workshopId) => {
        await API.post('backend', `api/workshops/${workshopId}/finish-phase`, {})
        return {workshopId}
    })

export const setBreakoutEnabledConfiguration = createAsyncThunk<void, WithPhase & {enabled: boolean}>(
    'workshops/phases/set-breakout-enabled',
    async ({phase, enabled}, thunkAPI) => {
        await thunkAPI.dispatch(setBreakoutModeOfInteraction({phase, modeOfInteraction: enabled ? 'open' : 'none'}))
    })

export const setBreakoutModeOfInteraction = createAsyncThunk<void, WithPhase & {modeOfInteraction: ModeOfInteraction}>(
    'workshops/phases/set-breakout-mode-of-interaction',
    async ({phase, modeOfInteraction}, thunkAPI) => {
        await updatePhase({
            ...phase,
            breakout: {
                ...phase.breakout,
                currentModeOfInteractions: modeOfInteraction
            }
        }, thunkAPI)
    })

export const setPhaseModeOfInteraction = createAsyncThunk<void, WithPhase & {modeOfInteraction: ModeOfInteraction}>(
    'workshops/phases/set-phase-mode-of-interaction',
    async ({phase, modeOfInteraction}, thunkAPI) => {
        await updatePhase({
            ...phase,
            meetingVariant: {
                ...phase.meetingVariant,
                currentModeOfInteraction: modeOfInteraction
            },
        }, thunkAPI)
    })

export const setPhaseMeetingVariantType = createAsyncThunk<void, WithPhase & {meetingVariantType: MeetingVariantType}>(
    'workshops/phases/set-phase-meeting-variant',
    async ({phase, meetingVariantType}, thunkAPI) => {
        await updatePhase({
            ...phase,
            meetingVariant: {
                ...phase.meetingVariant,
                type: meetingVariantType,
            },
            breakout: {
                currentModeOfInteractions: meetingVariantConfig[meetingVariantType]
                    .breakoutModesOfInteraction.includes(phase.breakout.currentModeOfInteractions) ? phase.breakout.currentModeOfInteractions : meetingVariantConfig[meetingVariantType]
                        .breakoutModesOfInteraction[0]
            }
        }, thunkAPI)
    })

async function updatePhase(newPhase: Phase, thunkAPI: {
    dispatch: Dispatch<any>
    getState(): unknown
}) {
    const workshopPhase = await new PhasesService().updatePhase(newPhase)
    const state = thunkAPI.getState() as WithPhasesState & WithWorkshopsState
    thunkAPI.dispatch(PhaseUpdated({phase: workshopPhase, currentPhases: selectPhasesForWorkshop(newPhase.workshopId)(state)}))
}

export const setPhaseStartTime = createAsyncThunk<void, WithPhase & {startTime: string}>(
    'workshops/phases/set-phase-start-time',
    async ({phase, startTime}, thunkAPI) => {
        await updatePhase({
            ...phase,
            startTime
        }, thunkAPI)
    })

export const setPhaseEndTime = createAsyncThunk<void, WithPhase & {endTime: string}>(
    'workshops/phases/set-phase-end-time',
    async ({phase, endTime}, thunkAPI) => {
        await updatePhase({
            ...phase,
            endTime
        }, thunkAPI)
    })

export const setPhaseName = createAsyncThunk<void, WithPhase & {name: string}>(
    'workshops/phases/set-phase-name',
    async ({phase, name}, thunkAPI) => {
        await updatePhase({
            ...phase,
            name
        }, thunkAPI)
    })

export const setPhaseDescription = createAsyncThunk<void, WithPhase & {description: string}>(
    'workshops/phases/set-phase-description',
    async ({phase, description}, thunkAPI) => {
        await updatePhase({
            ...phase,
            description
        }, thunkAPI)
    })

function determineStartTime(workshop: Workshop, state: WithPhasesState): string {
    const startOfOfficeDay = moment.utc().hours(9).minutes(0).seconds(0).milliseconds(0).toISOString()
    if (workshop.phases.length > 0) {
        const lastPhaseId = workshop.phases[workshop.phases.length - 1]
        return state.phases.entities[lastPhaseId]?.endTime || startOfOfficeDay
    }
    return workshop.start
}

function determineEndTime(startTime: string) {
    return moment.utc(startTime).add(30, 'minutes').toISOString()
}

export const addNewPhase = createAsyncThunk<void, WithWorkshop & {partialPhase: Pick<Phase, 'name' | 'description'>}>(
    'workshops/phases/add-phase',
    async ({workshop, partialPhase}, thunkAPI) => {
        const state = thunkAPI.getState() as WithPhasesState & WithWorkshopsState
        const startTime = determineStartTime(workshop, state)
        const endTime = determineEndTime(startTime)
        const newWorkshopPhase: Phase = {
            id: v4(),
            workshopId: workshop.id,
            ...partialPhase,
            startTime,
            endTime: endTime,
            meetingVariant: {
                type: 'none',
                currentModeOfInteraction: 'none',
                availableModeOfInteractions: ['none']
            },
            breakout: {
                currentModeOfInteractions: 'none',
            },
            status: 'not-started'
        }
        const workshopPhase = await new PhasesService().addPhase(newWorkshopPhase)
        thunkAPI.dispatch(PhaseUpdated({phase: workshopPhase, currentPhases: selectPhasesForWorkshop(workshop.id)(state)}))
    })


export const deletePhase = createAsyncThunk<void, WithWorkshopId & WithPhase>(
    'workshops/phases/delete-phase',
    async ({phase, workshopId}, thunkAPI) => {
        await new PhasesService().deletePhase(phase)
        thunkAPI.dispatch(PhaseDeleted({workshopId, phaseId: phase.id}))
    })
