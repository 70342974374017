import React from 'react'
import {Flex} from '@adobe/react-spectrum'
import 'src/components/roster/NumberOfParticipantsCircle.css'

export const NumberOfParticipantsCircle: React.FC<{ label: string | number, title?: string }> = (
    {label, title}) => {
    return <Flex direction='column' justifyContent={'center'} alignItems={'center'}>
        <div className='number-of-participants-circle'>
            <div><span title={title}>{label}</span></div>
        </div>
    </Flex>
}
