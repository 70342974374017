import {createSlice} from '@reduxjs/toolkit'
import {TopicNoteCreated, TopicNoteDeleted, TopicNoteUpdated} from 'src/state/topic-notes/topic-notes.events'
import {loadNotesForTopic} from './topic-notes.actions'
import {topicNotesAdapter} from './topic-notes.adapter'
import {TopicNotesState} from './topic-notes.model'

export const initialState: TopicNotesState = {
    ...topicNotesAdapter.getInitialState(),
    byTopic: {}
}
export const topicNotesSlice = createSlice({
    name: 'topics',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadNotesForTopic.fulfilled, (state, {payload: {topicId, notes}}) => {
                topicNotesAdapter.upsertMany(state, notes)
                state.byTopic[topicId] = notes.map(note => note.id)
            })
            .addCase(TopicNoteCreated, (state, {payload: {topicId, note}}) => {
                topicNotesAdapter.upsertOne(state, note)
                state.byTopic[topicId] = state.byTopic[topicId] ? [...state.byTopic[topicId], note.id] : [note.id]
            })
            .addCase(TopicNoteUpdated, (state, {payload: {note}}) => {
                topicNotesAdapter.upsertOne(state, note)
            })
            .addCase(TopicNoteDeleted, (state, {payload: {topicId, noteId}}) => {
                topicNotesAdapter.removeOne(state, noteId)
                if (state.byTopic[topicId]) {
                    state.byTopic[topicId] = state.byTopic[topicId].filter(id => id !== noteId)
                }
            })
    }
})
