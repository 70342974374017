import React from 'react'
import './NoActivePhasePlaceholder.css'
import {Svg} from 'src/components/common/Svg'
import logoSvg from './logo.svg'

export const NoActivePhasePlaceholder: React.FC = () => {
    return <div className={'no-active-phase'}>
        <Svg icon={logoSvg} className={'logo'} />
        <h1>No active session</h1>
    </div>
}
