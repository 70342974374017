import {Size} from 'src/state/layout'

export function toCss(size: Size): string | number {
    return size.unit ? size.value + size.unit : size.value
}

export function toCalcCss(size: Size): string {
    const css = toCss(size)
    return css + (css === 0 ? 'px' : '')
}
