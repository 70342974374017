import {Button, ButtonGroup, Content, Dialog, Divider, Flex, Header, Heading, Text} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {UserImageUpload} from 'src/components/profile/UserImageUpload'
import {selectAuthenticatedUser, UserProfileUpdateModel} from 'src/state/auth'

interface Props {
  close(): void
  save(userProfileModel: UserProfileUpdateModel): void
}

export const UserAvatarDialog: React.FC<Props> = ({close, save}) => {
    const user = useSelector(selectAuthenticatedUser)
    const {t} = useTranslation()
    const [profile, setProfile] = useState<UserProfileUpdateModel>({
        biography: user.biography || ''

    })

    return <Dialog size='M'>
        <Heading>
            <Flex alignItems="center" gap="size-100">
                <Text>{t('dialog.profile.headline')}</Text>
            </Flex>
        </Heading>
        <Header>
            {user.name}
        </Header>
        <Divider/>
        <Content>
            <UserImageUpload image={user.imageUrl} setImage={(image) => {
                setProfile({
                    biography: profile.biography,
                    image: image
                })
            }} />
        </Content>
        <ButtonGroup>
            <Button
                variant='secondary'
                onPress={close}
            >{t('button.cancel')}</Button>
            <Button
                variant='cta'
                onPress={() => save(profile)}>{t('button.save')}</Button>
        </ButtonGroup>
    </Dialog>
}
