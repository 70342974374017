import React from 'react'
import {PersonIcon} from 'src/components/icons'

export const AnonymousIcon: React.FC<{
  circleSize: number
}> = ({circleSize}) => {
    const iconSize = 27 // default by react-spectrum (mobile 22)
    const margin = (circleSize - iconSize) / 2

    return <div style={{marginTop: margin, marginBottom: margin}}>
        <PersonIcon/>
    </div>
}
