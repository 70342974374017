import React from 'react'
import {Phase} from 'src/state/phases'
import {Redirect} from 'react-router-dom'
import {DefineTopicsBeforeScheduler} from 'src/components/workshops/control-center/DefineTopicsBeforeScheduler'
import {ShowSchedulerResults} from 'src/components/workshops/control-center/ShowSchedulerResults'

export const InfoPageBeforeWorkshopStart: React.FC = () => {
    return <span>Lead Moderator Control Center - please start the workshop! ... WIP</span>
}

export const LeadModeratorControlCenter: React.FC<{phase?: Phase}> = ({phase}) => {
    if (!phase)
        return <InfoPageBeforeWorkshopStart />
    switch (phase.meetingVariant.type) {
    case 'define-topics':
        return <DefineTopicsBeforeScheduler phase={phase} />
    case 'scheduler':
        return <ShowSchedulerResults phase={phase} />
    }
    return <Redirect to={`/workshops/${phase.workshopId}/plenary`} />
}
