import {createSlice} from '@reduxjs/toolkit'
import {ModeOfInteraction} from 'src/state/common.model'
import {loadPersonalSchedule, TopicAssignmentScheduled} from 'src/state/schedule'
import {leaveMeeting, requestMeeting} from '../meetings'
import {setLeftDrawer, setMeetingLayout, setNotificationDrawer, setSearchDrawer} from './layout.actions'
import {LayoutState, MeetingLayout} from './layout.model'

export const initialState: LayoutState = {
    drawer: {
        left: 'collapsed',
        meeting: 'hidden',
        search: 'hidden',
        notification: 'open'
    }
}

const modeOfInteractionToDrawerStatus: Record<ModeOfInteraction, MeetingLayout> = {
    none: 'hidden',
    silence: 'medium',
    open: 'medium',
    presentation: 'large',
    'moderated-queue': 'small',
    'moderated-with-roles': 'medium',
    'group-call': 'medium'
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
    },
    extraReducers: builder => builder
        .addCase(setLeftDrawer, (state, {payload: status}) => {
            state.drawer.left = status
        })
        .addCase(setMeetingLayout, (state, {payload: status}) => {
            state.drawer.meeting = status
        })
        .addCase(setSearchDrawer, (state, {payload: status}) => {
            state.drawer.search = status
        })
        .addCase(setNotificationDrawer, (state, {payload: status}) => {
            state.drawer.notification = status
        })
        .addCase(requestMeeting.fulfilled, (state, {payload: meeting}) => {
            if (meeting) {
                state.drawer.meeting = modeOfInteractionToDrawerStatus[meeting.modeOfInteraction]
            }
        })
        .addCase(leaveMeeting.pending, (state) => {
            state.drawer.meeting = 'hidden'
        })
        .addCase(loadPersonalSchedule.fulfilled, (state,  {payload: scheduleItems}) => {
            if (scheduleItems.length > 0) {
                state.drawer.left = 'open'
            }
        })
        .addCase(TopicAssignmentScheduled, (state, {payload: scheduleItems}) => {
            if (scheduleItems.length > 0) {
                state.drawer.left = 'open'
            }
        })
})
