import {createEntityAdapter} from '@reduxjs/toolkit'
import moment from 'moment'
import {Workshop, WorkshopTemplate} from './workshops.model'

export const workshopsAdapter = createEntityAdapter<Workshop>({
    selectId: (workshop) => workshop.id,
    sortComparer: (a, b) => moment.utc(b.start).valueOf() - moment.utc(a.start).valueOf()
})

export const workshopTemplatesAdapter = createEntityAdapter<WorkshopTemplate>({
    selectId: (workshop) => workshop.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
})
