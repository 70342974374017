import {Button, Flex} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {Toolbox} from 'src/components/layout/Toolbox'
import {addNewPhase} from 'src/state/phases/phases.actions'
import {useAppDispatch} from 'src/state/types'
import {WorkshopPhaseDesigner} from './WorkshopPhaseDesigner'
import {WithWorkshop} from 'src/types'
import './WorkshopDesigner.css'

export const WorkshopDesigner: React.FC<WithWorkshop> = ({workshop}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    return <>
        <ScrollContainer>
            <Flex direction='column'
                gap={'size-130'}
                justifyContent={'start'}
                UNSAFE_className='workshop-designer content-with-toolbox'>
                {workshop.phases.map(phaseId => <WorkshopPhaseDesigner key={phaseId} phaseId={phaseId}/>)}
            </Flex>
        </ScrollContainer>
        <Toolbox>
            <Button variant='primary' onPress={() => {
                dispatch(addNewPhase({
                    workshop,
                    partialPhase: {
                        name: t('workshop-designer.name-of-new-phase'),
                        description: t('workshop-designer.description-of-new-phase'),
                    }
                }))
            }}>{t('button.create-phase')}</Button>
        </Toolbox>
    </>
}
