import React, {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {loadTopics, selectTopicsForWorkshop} from 'src/state/topics'
import {Flex, View} from '@adobe/react-spectrum'
import {Card} from 'src/components/common'
import {WithPhase} from 'src/types'

export const DefineTopicsBeforeScheduler: React.FC<WithPhase> = ({phase}) => {
    const topics = useAppSelector(selectTopicsForWorkshop(phase.workshopId))
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadTopics({workshopId: phase.workshopId}))
    }, [])
    return <div>
        <h2>Topics for next sessions ({phase.name})</h2>
        <span>{phase.description}</span>
        <Flex wrap gap='size-200'>
            {topics.map(topic => <View key={topic.id}>
                <Card className={'topic-card'}>{{
                    title: topic.title,
                    width: 'size-3600',
                    content: <Flex direction='column' height='size-3000'>
                        <View flex UNSAFE_className={'description'}>{topic.description}</View>
                    </Flex>,
                }}</Card>
            </View>)}
        </Flex>
    </div>
}
