import React from 'react'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {CafeMeetings} from 'src/container/rooms/cafe/CafeMeetings'
import {SinglePeopleInCafe} from 'src/container/rooms/cafe/SinglePeopleInCafe'
import {WithWorkshopId} from 'src/types'

export const CafeTwoColumnLayout: React.FC<WithWorkshopId> = ({workshopId}) => {
    return <div className={'two-column'}>
        <SinglePeopleInCafe workshopId={workshopId}/>
        <ScrollContainer><CafeMeetings workshopId={workshopId}/></ScrollContainer>
    </div>
}
