import React, {useEffect} from 'react'
import {MeetingControls} from 'src/components/control/meeting/active-meeting/MeetingControls'
import {ModeratedQueueMeetingHeader} from 'src/components/meetings/plenary/topic-generation/moderated-queue/meeting/ModeratedQueueMeetingHeader'
import {RosterGrid} from 'src/components/roster/RosterGrid'
import {useWebsocketSubscription} from 'src/hooks'
import {selectMeetingDeviceSettings} from 'src/state/meetings'
import {loadQueue, selectModeratedQueueBy} from 'src/state/moderated-queue'
import {ParticipantRolesAdapter, selectLeadModerator, selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {PhaseEvent} from 'src/state/workshop.event'
import {WithPhaseId, WithWorkshopId} from 'src/types'
import {PhaseEventTypeDto} from 'src/websocket/phase-event-types.dto'
import {workshopPhaseTopic} from 'src/websocket/workshop-topics'
import './ModeratedQueueMeeting.css'
import {ModeratedQueueParticipantRoster} from './ModeratedQueueParticipantsRoster'
import {ModeratedQueueRosterStage} from './ModeratedQueueRosterStage'
import {ModeratedQueueModeratorControls} from './moderator-controls/ModeratedQueueModeratorControls'

export const ModeratedQueueMeeting: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const dispatch = useAppDispatch()
    const moderator = useAppSelector(selectLeadModerator(workshopId))
    const roles = new ParticipantRolesAdapter(moderator ? {
        [moderator.id]: ['moderator']
    } : {})

    const subscription = useWebsocketSubscription<PhaseEventTypeDto>(workshopPhaseTopic(workshopId, phaseId),
        payload => {
            dispatch(PhaseEvent({
                ...payload,
                meta: {
                    phaseId,
                    workshopId
                }
            }))
        }
    )

    useEffect(() => {
        if (subscription) {
            subscription.then(() => {
                dispatch(loadQueue({workshopId, phaseId}))
            })
        }
    }, [subscription])

    const me = useAppSelector(selectMyParticipant)
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    const meetingSettingsConfig = useAppSelector(selectMeetingDeviceSettings)

    const shouldShowMeetingControls = meetingSettingsConfig.isVideoAllowed || meetingSettingsConfig.isAllowedToSpeak
    const isMyVideoDisplayedInStage = roles.isModerator(me) || queue.isTurnOf(me)

    return <div className='moderated-queue'>
        <RosterGrid
            roles={roles}
            header={<ModeratedQueueMeetingHeader workshopId={workshopId} phaseId={phaseId} />}
            stage={<ModeratedQueueRosterStage
                workshopId={workshopId}
                phaseId={phaseId}
            />}
            participantRoster={<ModeratedQueueParticipantRoster workshopId={workshopId} phaseId={phaseId}/>}
            moderatorControls={<ModeratedQueueModeratorControls workshopId={workshopId} phaseId={phaseId} />}
            meetingControls={shouldShowMeetingControls ? <MeetingControls/> : undefined}
            displayLocalVideo={!isMyVideoDisplayedInStage && shouldShowMeetingControls}
        />
    </div>
}

