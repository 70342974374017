import React, {useEffect} from 'react'
import {useWebsocketSubscription} from 'src/hooks'
import {leaveCall, selectCurrentCall} from 'src/state/calls'
import {CallReceived, JoinGroupRequestHandled} from 'src/state/incoming-calls'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import {ParticipantRolesAdapter} from 'src/state/participants'
import {ParticipantRoster} from 'src/components/roster/ParticipantRoster'
import {RosterGrid} from 'src/components/roster/RosterGrid'
import {VideoInputControl} from 'src/components/control/meeting/active-meeting/VideoInputControl'
import {AudioInputControl} from 'src/components/control/meeting/active-meeting/AudioInputControl'
import {SettingsControl} from 'src/components/control/meeting/active-meeting/SettingsControl'
import {LeaveCallButton} from 'src/components/meetings/calls/LeaveCallButton'
import './GroupCall.css'

export type JoinGroupCallPayload = {
    caller: string
}

export type JoinGroupRequestHandledPayload = {
    incomingCallId: string
    meetingId: string
    participantId: string
}

export type GroupCallEvent = {
    type: 'JoinGroupCall' | 'JoinGroupRequestHandled'
    payload: JoinGroupCallPayload | JoinGroupRequestHandledPayload
}

export const GroupCall: React.FC<WithWorkshopId> = ({workshopId}) => {
    const roles = new ParticipantRolesAdapter({})
    const call = useAppSelector(selectCurrentCall)
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(leaveCall({workshopId}))
        }
    }, [workshopId])

    useWebsocketSubscription<GroupCallEvent>(call ? `/topic/workshops/${workshopId}/calls/${call.id}` : undefined, (groupCallEvent) => {
        if (groupCallEvent.type === 'JoinGroupCall') {
            dispatch(CallReceived({
                meetingId: call!.id,
                participantIds: [(groupCallEvent.payload as JoinGroupCallPayload).caller],
                requestToJoinGroup: true
            }))
        } else if (groupCallEvent.type === 'JoinGroupRequestHandled') {
            const payload = groupCallEvent.payload as JoinGroupRequestHandledPayload
            dispatch(JoinGroupRequestHandled({
                incomingCallId: payload.incomingCallId,
                meetingId: call!.id,
                participantId: payload.participantId,
            }))
        }
    })

    return <RosterGrid
        roles={roles}
        participantRoster={<ParticipantRoster
            workshopId={workshopId}
            direction='row'
            roles={roles}
            className="group-call-roster"
            wrap/>}
        displayLocalVideo={false}
        meetingControls={<>
            <div className='meeting-controls'>
                <VideoInputControl />
                <AudioInputControl />
                <SettingsControl />
            </div>
            <LeaveCallButton workshopId={workshopId} />
        </>}
    />
}
