import {Participant, selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {selectModeratedQueueBy} from 'src/state/moderated-queue'
import {useActiveParticipants} from 'src/hooks/meeting'
import {Dictionary} from '@reduxjs/toolkit'
import {MeetingParticipant} from 'src/state/meetings'

export function useQueuedParticipants(workshopId: string, phaseId: string): Array<Participant & { attendeeId?: string }> {
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    const activeParticipants = useActiveParticipants(workshopId)
        .reduce((map: Dictionary<MeetingParticipant>, participant) => {
            map[participant.id] = participant
            return map
        }, {})
    const participants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const queuedParticipants = queue.items.map(item => activeParticipants[item.participantId] ?
        activeParticipants[item.participantId]! : participants[item.participantId]!)
    return queue.active ? queuedParticipants.slice(1) : queuedParticipants
}
