import {Flex, Item, Picker, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ModeOfInteraction} from 'src/state/common.model'

export const ModeOfInteractionSelection: React.FC<{
    selected: ModeOfInteraction,
    options: ModeOfInteraction[]
    onSelect(modeOfInteraction: ModeOfInteraction): void
}> = ({selected, options, onSelect}) => {
    const {t} = useTranslation()

    return options.length > 1 ? <Flex direction='row' alignContent='baseline'>
        <Text UNSAFE_className='mode-of-interaction-label'>{t('workshop-designer.mode-of-interaction-label')}</Text>
        <Picker
            selectedKey={selected}
            onSelectionChange={selected => { onSelect(selected as ModeOfInteraction) }}>
            {options.map(meetingVariant => <Item key={meetingVariant}>{meetingVariant}</Item>)}
        </Picker>
    </Flex> : <></>
}
