import {callsAdapter} from './calls.adapter'
import {CallEntity, WithCallsState} from './calls.model'

const callsSelectors = callsAdapter.getSelectors<WithCallsState>(
    (state: WithCallsState) => state.calls
)

export const selectCurrentCall: (state: WithCallsState) => CallEntity | undefined = state =>
    state.calls.currentCall ? callsSelectors.selectById(state, state.calls.currentCall) : undefined

export const selectCallById: (id: string) => (state: WithCallsState) => CallEntity | undefined = id =>  state =>
    callsSelectors.selectById(state, id)

export const selectIsOutgoingCall: (id: string) => (state: WithCallsState) => boolean =
    id => (state) => state.calls.outgoingCall === id

export const selectHasOutgoingCall: (state: WithCallsState) => boolean =
    (state) => state.calls.outgoingCall !== undefined

export const selectOutgoingCall: (state: WithCallsState) => string | undefined =
    (state) => state.calls.outgoingCall
