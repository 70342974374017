
export interface Queue<T> {
    items: T[]
}

export type ActiveQueue<T> = Queue<T> & {
    active: boolean
}

export class QueueAdapter<ITEM, QUEUE extends Queue<ITEM> = Queue<ITEM>> {
    constructor(protected queue: QUEUE) {
    }

    hasItems(): this is QueueWithFirst<ITEM> {
        return this.items.length > 0
    }

    hasNextItem(): this is QueueWithNextItem<ITEM> {
        return this.items.length >= 2
    }

    get first(): ITEM | undefined {
        return this.items[0]
    }

    get next(): ITEM | undefined {
        return this.items[1]
    }

    get items(): ITEM[] {
        return this.queue.items
    }

    get state(): QUEUE {
        return this.queue
    }
}

export class ActiveQueueAdapter<ITEM, QUEUE extends ActiveQueue<ITEM> = ActiveQueue<ITEM>>
    extends QueueAdapter<ITEM, QUEUE>{
    get active(): boolean {
        return this.queue.active
    }

    get current(): ITEM | undefined {
        return this.active ? this.first : undefined
    }

    hasCurrent(): this is QueueWithCurrentItem<ITEM> {
        return this.current !== undefined
    }

    get next(): ITEM | undefined {
        return this.active ? this.items[1] : this.items[0]
    }

    hasNextItem(): this is QueueWithNextItem<ITEM> {
        return this.active ? this.items.length >= 2 : this.items.length >= 1
    }
}

export interface QueueWithFirst<T> {
    readonly first: T
}

export interface QueueWithNextItem<T> extends QueueWithFirst<T> {
    readonly next: T
}

export interface QueueWithCurrentItem<T> extends QueueWithFirst<T> {
    readonly active: true
    readonly current: T
}
