import {Text} from '@adobe/react-spectrum'
import classNames from 'classnames'
import React from 'react'
import {ParticipantIconWithCircle} from './ParticipantIconWithCircle'
import {WithParticipant} from 'src/types'
import './ParticipantBadge.css'

export function optionalClassName(className?: string): { [className: string]: boolean } {
    return {[className || '']: Boolean(className)}
}

export const ParticipantBadge: React.FC<WithParticipant & {
    active?: boolean
    className?: string
}> = ({
    participant,
    className,
    active
}) => {
    const isActive = active === undefined ? true : active
    return <div className={classNames({
        'participant-badge': true,
        ...optionalClassName(className)
    })}>
        <ParticipantIconWithCircle className={classNames({
            'participant-badge-icon': true,
            'participant-icon': true,
            'inactive': !isActive
        })}
        iconSymbol={!isActive ? '?' : undefined}
        participant={participant}/>
        <Text UNSAFE_className={'participant-badge-name'}>{participant.name}</Text>
    </div>
}
