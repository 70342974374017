import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {TabItem} from 'src/components/layout/tab/TabItem'
import {Tabs} from 'src/components/layout/tab/Tabs'
import {WorkshopDesigner} from 'src/components/workshops/designer/WorkshopDesigner'
import {ParticipantAndInvitationManager} from 'src/components/workshops/participants/ParticipantAndInvitationManager'
import {WorkshopDetailsGeneral} from 'src/components/workshops/WorkshopDetailsGeneral'
import {loadWorkshopAndPhases} from 'src/state/actions'
import {invitationsForWorkshop, loadInvitations} from 'src/state/invitations'
import {loadParticipants, selectParticipantsForWorkshop} from 'src/state/participants'
import {selectRouterPath} from 'src/state/selectors'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {selectWorkshopById} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'
import {useWebsocketSubscription} from 'src/hooks'
import {InvitationEvent} from 'src/state/workshop.event'
import {InvitationEventTypeDto} from 'src/websocket/invitation-event-type.dto'

export const WorkshopConfiguration: React.FC = () => {
    const routerPath = useAppSelector(selectRouterPath)

    const {workshopId} = useParams<WithWorkshopId>()
    const workshop = useAppSelector(selectWorkshopById(workshopId))
    const invitations = useAppSelector(invitationsForWorkshop)
    const participants = useAppSelector(selectParticipantsForWorkshop(workshopId))
    const dispatch = useAppDispatch()

    const subscription = useWebsocketSubscription<InvitationEventTypeDto>(invitationsTopic(workshopId), payload => {
        dispatch(InvitationEvent({
            ...payload,
            meta: {
                workshopId
            }
        }))
    })

    useEffect(() => {
        if (subscription) {
            subscription.then(() => {
                dispatch(loadInvitations(workshopId))
            })
        }
    }, [subscription])

    useEffect(() => {
        dispatch(loadWorkshopAndPhases(workshopId))
        dispatch(loadParticipants(workshopId))
    }, [dispatch, workshopId])

    if (!workshop) {
        return <div/>

    }

    function invitationsTopic(workshopId: string) {
        return '/topic/workshops/' + workshopId + '/invitations'

    }

    return (
        <Tabs routerPath={routerPath}>
            <TabItem tabKey='General Settings' label='General Settings' route={`/workshops/${workshopId}/configure`}>
                <WorkshopDetailsGeneral workshop={workshop} invitations={invitations} participants={participants}/>
            </TabItem>
            <TabItem tabKey='Attendee Manager' label='Attendee Manager'
                route={`/workshops/${workshopId}/configure/participants`}>
                <ParticipantAndInvitationManager workshop={workshop} invitations={invitations} participants={participants}/>
            </TabItem>
            <TabItem tabKey='Workshop Designer' label='Workshop Designer'
                route={`/workshops/${workshopId}/configure/phases`}>
                <WorkshopDesigner workshop={workshop}/>
            </TabItem>
        </Tabs>
    )
}
