import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {Icon} from 'src/components/common'
import CafeIcon from 'src/components/icons/svg/cafe.svg'
import PlenaryIcon from 'src/components/icons/svg/radar.svg'
import {MenuItem} from 'src/components/layout/menu/MenuItem'
import {WithWorkshopId} from 'src/types'

export const WorkshopMenu: React.FC<{
    variant: 'full' | 'icon'
}> = ({variant}) => {
    const {t} = useTranslation()
    const {workshopId} = useParams<WithWorkshopId>()
    const location = useLocation()

    return <>
        <MenuItem variant={variant} to={`/workshops/${workshopId}/cafe`} clickable
            active={/.*\/cafe$/.test(location.pathname)}>
            {{
                icon: <Icon icon={CafeIcon}/>,
                content: t('navigation.cafe-room')
            }}
        </MenuItem>
        <MenuItem variant={variant} to={`/workshops/${workshopId}/plenary`} clickable
            active={/.*\/plenary$/.test(location.pathname)}>
            {{
                icon: <Icon icon={PlenaryIcon}/>,
                content: t('navigation.plenary-room')
            }}
        </MenuItem>
    </>
}
