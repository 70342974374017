import {WithAuthState} from 'src/state/auth'
import {MeetingDeviceSettings, WithMeetingsState} from '../meetings.model'
import {AbstractDeviceSettingsStrategy} from './abstract-device-settings-strategy'
import {defaultConfig, silence} from './defaults'
import {WithWorkshopsState} from 'src/state/workshops'
import {WithPhasesState} from 'src/state/phases'

export class PresentationDeviceSettingsStrategy extends AbstractDeviceSettingsStrategy<WithMeetingsState & WithWorkshopsState & WithPhasesState & WithAuthState> {
    isResponsible(): boolean {
        return this.modeOfInteraction === 'presentation'
    }

    getDeviceSettings(): MeetingDeviceSettings {
        const meeting = this.activeMeeting
        if (!meeting || !this.hasParticipantId()) {
            return defaultConfig
        }

        if (meeting.hasRoles()) {
            if (meeting.roles.hasRole(this.participantId, 'moderator')) {
                return defaultConfig
            }
            return silence
        }
        return defaultConfig
    }
}
