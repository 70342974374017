import {MeetingStatus, useMeetingManager, useMeetingStatus} from 'amazon-chime-sdk-component-library-react'
import {useEffect, useState} from 'react'
import {
    selectAudioInputDeviceId,
    selectHasDeviceDialogBeenShown,
    selectIsMuted,
    setAudioInputDeviceId
} from 'src/state/device-settings'
import {errorOccurred} from 'src/state/error'
import {selectMeetingDeviceSettings} from 'src/state/meetings'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {isDeviceAvailable} from '../utils'
import {useAudioInputControl} from './use-audio-input-control'

function logError(error: Error) {
    return console.error('Failed to configure audio input device', error)
}

export function useConfiguredAudioInputDevice(): void {
    const meetingManager = useMeetingManager()
    const meetingStatus = useMeetingStatus()
    const {audioInputDevices} = useAudioInputControl()
    const configuredDevice = useAppSelector(selectAudioInputDeviceId)
    const dispatch = useAppDispatch()
    const [deviceToUse, setDeviceToUse] = useState<string | undefined>()

    useEffect(() => {
        function selectConfiguredOrFirstDevice() {
            const firstDevice = audioInputDevices[0]?.deviceId
            if (configuredDevice && isDeviceAvailable(audioInputDevices, configuredDevice)) {
                setDeviceToUse(configuredDevice)
            } else if (firstDevice) {
                dispatch(setAudioInputDeviceId(deviceToUse))
                setDeviceToUse(firstDevice)
            }
        }

        if (meetingStatus === MeetingStatus.Succeeded) {
            selectConfiguredOrFirstDevice()
        }
    }, [
        meetingStatus,
        audioInputDevices,
        configuredDevice,
        meetingManager
    ])

    useEffect(() => {
        async function selectDevice(deviceId: string): Promise<void> {
            if (meetingManager.selectedAudioInputDevice !== deviceId) {
                await meetingManager.selectAudioInputDevice(deviceId)
            }
        }

        if (deviceToUse) {
            selectDevice(deviceToUse)
                .catch(error => {
                    logError(error)
                    dispatch(errorOccurred({message: 'Failed to configure audio input device'}))
                })
        }
    }, [deviceToUse])
}

export function useConfiguredAudioInput(): void {
    const meetingStatus = useMeetingStatus()
    const {
        muted,
        setMute,
    } = useAudioInputControl()
    const isMutedConfigured = useAppSelector(selectIsMuted)
    const meetingDeviceSettings = useAppSelector(selectMeetingDeviceSettings)
    const hasDeviceDialogBeenShown = useAppSelector(selectHasDeviceDialogBeenShown)

    useConfiguredAudioInputDevice()

    useEffect(() => {
        if (meetingStatus === MeetingStatus.Succeeded) {
            const shouldBeMuted = meetingDeviceSettings.isMutedOnJoin || isMutedConfigured || !hasDeviceDialogBeenShown
            if (muted !== shouldBeMuted) {
                setMute(shouldBeMuted)
            }
        }
    }, [
        muted,
        meetingStatus,
        meetingDeviceSettings,
        isMutedConfigured,
        hasDeviceDialogBeenShown
    ])
}
