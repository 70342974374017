import React from 'react'
import {MeetingSidebar} from 'src/components/meetings/MeetingSidebar'
import {OpenMeetingWithRoles} from 'src/components/meetings/open/OpenMeetingWithRoles'
import {WithRoles, WithWorkshopId} from 'src/types'

export const SidebarOpenMeeting: React.FC<WithWorkshopId & WithRoles> = ({workshopId, roles}) => {
    return <MeetingSidebar>
        <OpenMeetingWithRoles workshopId={workshopId} roles={roles} />
    </MeetingSidebar>
}
