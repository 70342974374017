import {EntityState} from '@reduxjs/toolkit'
import {StatusDto} from 'src/api/common.dto'
import {MeetingVariantType, ModeOfInteraction} from '../common.model'

export const modesOfInteraction: ModeOfInteraction[] = [
    'none', 'silence', 'open', 'presentation', 'moderated-queue', 'moderated-with-roles'
]

export interface MeetingVariant {
    type: MeetingVariantType
    availableModeOfInteractions: ModeOfInteraction[]
    currentModeOfInteraction: ModeOfInteraction
}

export const meetingVariants: MeetingVariantType[] = ['none', 'presentation', 'topic-generation', 'define-topics', 'scheduler', 'topic-session']

export const invisiblePhases: MeetingVariantType[] = ['define-topics', 'scheduler']

export interface Phase {
    id: string
    workshopId: string
    name: string
    description: string
    startTime: string
    endTime: string
    status: StatusDto
    meetingVariant: MeetingVariant
    breakout: Breakout
}

export interface Breakout {
    currentModeOfInteractions: ModeOfInteraction
}

export type PhasesState = EntityState<Phase>

export type WithPhasesState = {
    phases: PhasesState
}

export const meetingVariantConfig: Record<MeetingVariantType, {
    modesOfInteraction: ModeOfInteraction[]
    breakoutModesOfInteraction: ModeOfInteraction[]
}> = {
    none: {
        modesOfInteraction: ['none'],
        breakoutModesOfInteraction: ['none']
    },
    'topic-generation': {
        modesOfInteraction: ['none', 'open', 'moderated-queue', 'silence'],
        breakoutModesOfInteraction: ['none', 'open', 'moderated-queue', 'silence']
    },
    'define-topics': {
        modesOfInteraction: ['none'],
        breakoutModesOfInteraction: ['none']
    },
    'topic-session': {
        modesOfInteraction: ['none'],
        breakoutModesOfInteraction: ['open', 'moderated-queue', 'moderated-with-roles', 'silence']
    },
    scheduler: {
        modesOfInteraction: ['none'],
        breakoutModesOfInteraction: ['none']
    },
    presentation: {
        modesOfInteraction: ['presentation'],
        breakoutModesOfInteraction: ['none']
    },
}
