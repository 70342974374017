import React from 'react'
import ThumbUp from '@spectrum-icons/workflow/ThumbUp'
import {Flex} from '@adobe/react-spectrum'

interface Props {
    current: number
    needed: number
}

export const TopicVotingState: React.FC<Props> = (props: Props) => {
    const {current, needed}= props
    return <Flex direction='row' justifyContent="space-between" alignItems="center">
        <span style={{verticalAlign: 'middle'}}><ThumbUp /></span>
        <span style={{verticalAlign: 'middle'}}>{current} / {needed}</span>
    </Flex>
}
