import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {Participant} from 'src/state/participants'
import {WithMeetingId} from 'src/types'

export const BreakoutParticipantIcons: React.FC<WithMeetingId & { participants: Participant[] }> = ({participants}) => {
    return <Flex direction={'row'} UNSAFE_className={'topic-participant-icons'} wrap gap={'size-100'}>
        <>{participants.map(participant => <ParticipantIconWithCircle key={participant.id} participant={participant}
            className={'topic-participant'}/>)}</>
    </Flex>
}
