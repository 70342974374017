import {API} from 'aws-amplify'
import {v4 as uuid} from 'uuid'
import {validate} from 'src/tools/validation'
import {Response} from '../response'
import {WorkshopCreateDto} from './workshop-create.dto'
import {GetWorkshopsDto, GetWorkshopTemplateDto, WorkshopDto, WorkshopTemplateDto} from './workshops.dto'
import {validateWorkshopDto, validateWorkshopTemplateDto} from './workshops.validation'

export class WorkshopsApi {

    getMyWorkshops(): Promise<WorkshopDto[]> {
        return this.getWorkshopsFromPath('api/workshops')
    }

    async getWorkshopsFromPath(path: string): Promise<WorkshopDto[]> {
        const workshopsResponse: Response<GetWorkshopsDto> = await API.get('backend', path, {})
        if (workshopsResponse && workshopsResponse._embedded) {
            const workshops = workshopsResponse._embedded.workshops
            workshops.forEach(workshop => this.validateWorkshop(workshop))
            return workshops
        }
        return []
    }

    async getParticipatingWorkshops(): Promise<WorkshopDto[]> {
        return this.getWorkshopsFromPath('api/workshops/participating')
    }

    async getWorkshopTemplates(): Promise<WorkshopTemplateDto[]> {
        const templatesResponse: Response<GetWorkshopTemplateDto> = await API.get('backend', 'api/workshops/templates', {})
        if (templatesResponse && templatesResponse._embedded) {
            const templates = templatesResponse._embedded.workshopTemplates
            templates.forEach(template => this.validateWorkshopTemplate(template))
            return templates
        }
        return []
    }

    async getWorkshop(workshopId: string): Promise<WorkshopDto> {
        const workshop = await API.get('backend', `api/workshops/${workshopId}`, {})
        this.validateWorkshop(workshop)
        return workshop
    }

    async putWorkshop(workshop: WorkshopDto): Promise<WorkshopDto> {
        this.validateWorkshop(workshop)
        return await API.put('backend', `api/workshops/${workshop.id}`, {
            body: workshop
        })
    }

    async createWorkshop(workshopCreateDto: WorkshopCreateDto): Promise<WorkshopDto> {
        const workshop = await API.post('backend', 'api/workshops', {
            body: {
                ...workshopCreateDto,
                id: uuid()
            }
        })
        this.validateWorkshop(workshop)
        return workshop
    }

    async startWorkshop(workshopId: string): Promise<void> {
        await API.post('backend', 'api/workshops/' + workshopId + '/start', {})
    }

    validateWorkshop(workshop: WorkshopDto): void {
        validate<WorkshopDto>(workshop, validateWorkshopDto())
    }

    validateWorkshopTemplate(template: WorkshopTemplateDto): void {
        validate<WorkshopTemplateDto>(template, validateWorkshopTemplateDto())
    }
}
