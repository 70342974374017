import {ActionButton, Item, Menu, MenuTrigger, Section} from '@adobe/react-spectrum'
import {
    useAudioInputs,
    useAudioOutputs,
    useLocalVideo,
    useMeetingManager,
    useSelectVideoInputDevice,
    useVideoInputs
} from 'amazon-chime-sdk-component-library-react'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import React, {ReactText, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {SettingsIcon} from 'src/components/icons'
import {setAudioInputDeviceId, setAudioOutputDeviceId, setVideoInputDeviceId} from 'src/state/device-settings'
import {deviceConfig} from 'src/state/meetings'
import {useAppDispatch} from 'src/state/types'
import {isOptionActive, supportsSetSinkId} from '../utils'

export type DeviceOption = {
    type: string
    deviceId: string
    label: string
}

function createDeviceOptions(deviceTypes: DeviceType[], type: DeviceGroup) {
    return deviceTypes.map((device: DeviceType) => ({
        type: type,
        deviceId: device.deviceId,
        label: device.label,
    }))
}

type DeviceGroup = 'microphone' | 'video' | 'speaker'

function createMicrophoneOptions(microphoneDevices: DeviceType[]) {
    return createDeviceOptions(microphoneDevices, 'microphone')
}

function createVideoOptions(videoDevices: DeviceType[]) {
    return createDeviceOptions(videoDevices, 'video')
}

function createSpeakerOptions(speakerDevices: DeviceType[]) {
    return createDeviceOptions(speakerDevices, 'speaker')
}

function devicesByType(selectedDevices: string[][], deviceType: DeviceGroup) {
    return selectedDevices
        .filter(([type]) => type === deviceType)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_, deviceId]) => deviceId)
}

function findActive(options: DeviceOption[], selectedDevice: string | null) {
    return options.filter(option => isOptionActive(selectedDevice, option.deviceId)).map(option => `${option.type}:${option.deviceId}`)
}

export const SettingsControl: React.FC = () => {
    const meetingManager = useMeetingManager()
    const {devices: videoDevices, selectedDevice: selectedVideoDevice} = useVideoInputs(deviceConfig)
    const {isVideoEnabled} = useLocalVideo()
    const selectVideoDevice = useSelectVideoInputDevice()

    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    // VIDEO
    const videoOptions: DeviceOption[] = useMemo(
        () => createVideoOptions(videoDevices),
        [isVideoEnabled, selectedVideoDevice, videoDevices])

    // MICROPHONE
    const { devices: microphoneDevices, selectedDevice: selectedMicrophoneDevice } = useAudioInputs(deviceConfig)
    const microphoneOptions: DeviceOption[] = useMemo(
        () => createMicrophoneOptions(microphoneDevices),
        [isVideoEnabled, selectedMicrophoneDevice, microphoneDevices])

    // SPEAKER
    const { devices: speakerDevices, selectedDevice: selectedSpeakerDevice } = useAudioOutputs()
    const speakerOptions: DeviceOption[] = useMemo(
        () => createSpeakerOptions(speakerDevices),
        [isVideoEnabled, selectedSpeakerDevice, speakerDevices])

    function selectMicrophone(selectedDevices: React.ReactText[]): void {
        const selectedOption = selectedMicrophoneDevice ? selectedMicrophoneDevice : 'none'

        const selectedMicrophoneOption = microphoneOptions
            .filter(option => selectedDevices.includes(option.deviceId))
            .find(option => option.deviceId !== selectedOption)
        if (selectedMicrophoneOption) {
            dispatch(setAudioInputDeviceId(selectedMicrophoneOption.deviceId))
            meetingManager.selectAudioInputDevice(selectedMicrophoneOption.deviceId)
                .catch(error => {
                    console.error('Failed to select audio input device', selectedMicrophoneOption, error)
                })
        }
    }

    function selectVideo(selectedDevices: React.ReactText[]): void {
        const selectedOption = selectedVideoDevice ? selectedVideoDevice : 'none'
        const selectedVideoOption = videoOptions
            .filter(option => selectedDevices.includes(option.deviceId))
            .find(option => option.deviceId !== selectedOption)

        if (selectedVideoOption) {
            dispatch(setVideoInputDeviceId(selectedVideoOption.deviceId))
            selectVideoDevice(selectedVideoOption.deviceId)
                .catch(error => {
                    console.error('Failed to select video device', selectVideoDevice, error)
                })
        }
    }

    function selectSpeaker(selectedDevices: React.ReactText[]): void {
        const selectedOption = selectedSpeakerDevice ? selectedSpeakerDevice : 'none'
        const selectedSpeakerOption = speakerOptions
            .filter(option => selectedDevices.includes(option.deviceId))
            .find(option => option.deviceId !== selectedOption)

        if (selectedSpeakerOption && supportsSetSinkId()) {
            dispatch(setAudioOutputDeviceId(selectedSpeakerOption.deviceId))
            meetingManager.selectAudioOutputDevice(selectedSpeakerOption.deviceId)
                .catch(error => {
                    console.error('Failed to select audio output device', selectedSpeakerOption, error)
                })
        }
    }

    const select = (selection: 'all' | Set<ReactText>) => {
        const selectedDevices =
          [...selection as Set<ReactText>].map(selectedKey => (selectedKey as string).split(':'))
        selectVideo(devicesByType(selectedDevices, 'video'))
        selectMicrophone(devicesByType(selectedDevices, 'microphone'))
        selectSpeaker(devicesByType(selectedDevices, 'speaker'))
    }

    return <>
        <MenuTrigger>
            <ActionButton isQuiet><SettingsIcon size={'medium'}/></ActionButton>
            <Menu
                selectedKeys={[
                    ...(findActive(videoOptions, selectedVideoDevice)),
                    ...(findActive(microphoneOptions, selectedMicrophoneDevice)),
                    ...(findActive(speakerOptions, selectedSpeakerDevice))]}
                onSelectionChange={selection => select(selection)}
                selectionMode="multiple"
            >
                <Section title={t('controls.speaker-label')}>
                    {speakerOptions.map(option => <Item key={option.type + ':' + option.deviceId}>{option.label}</Item>)}
                </Section>
                <Section title={t('controls.microphone-label')}>
                    {microphoneOptions.map(option => <Item key={option.type + ':' + option.deviceId}>{option.label}</Item>)}
                </Section>
                <Section title={t('controls.video-label')}>
                    {videoOptions.map(option => <Item key={option.type + ':' + option.deviceId}>{option.label}</Item>)}
                </Section>
            </Menu>
        </MenuTrigger>
    </>
}
