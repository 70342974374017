import {Flex} from '@adobe/react-spectrum'
import {BaseSdkProps} from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base'
import {DefaultDeviceController, DefaultVideoTile, Device} from 'amazon-chime-sdk-js'
import React, {useEffect, useRef} from 'react'
import CustomVideoTile from 'src/components/video/CustomVideoTile'
import { useDeviceController} from 'src/hooks'
import {selectVideoInputDeviceId} from 'src/state/device-settings'
import {useAppSelector} from 'src/state/types'
import styled from 'styled-components'
import {AudioInputDeviceSelection} from './AudioInputDeviceSelection'
import {VideoDeviceSelection} from './VideoDeviceSelection'

export const DeviceSettings: React.FC = () => {
    return <Flex direction='row' gap={'size-250'} justifyContent={'space-around'}>
        <VideoPreview />
        <Flex direction='column' gap={'size-130'}>
            <VideoDeviceSelection />
            <AudioInputDeviceSelection />
        </Flex>
    </Flex>
}

export const VideoPreview: React.FC = () => {
    return <Flex justifyContent={'center'} alignItems={'center'} UNSAFE_className={'preview-video-container'}>
        <PreviewVideo />
    </Flex>
}


const StyledPreview = styled(CustomVideoTile)`
  height: auto;
  background: unset;
  video {
    position: static;
  }
`

export const videoInputSelectionToDevice = (deviceId: string | null): Device => {
    if (deviceId === 'blue') {
        return DefaultDeviceController.synthesizeVideoDevice('blue')
    }
    if (deviceId === 'smpte') {
        return DefaultDeviceController.synthesizeVideoDevice('smpte')
    }
    if (deviceId === 'none') {
        return null
    }
    return deviceId
}

export const PreviewVideo: React.FC<BaseSdkProps> = (props) => {
    const videoEl = useRef<HTMLVideoElement>(null)
    const deviceController = useDeviceController()
    const selectedDevice = useAppSelector(selectVideoInputDeviceId)

    useEffect(() => {
        if (!deviceController || !selectedDevice || !videoEl.current) {
            return
        }

        let mounted = true

        function startPreviewVideo(mediaStream: MediaStream, element: HTMLVideoElement) {
            deviceController.releaseMediaStream(element.srcObject as MediaStream | null)
            DefaultVideoTile.disconnectVideoStreamFromVideoElement(element, false)
            DefaultVideoTile.connectVideoStreamToVideoElement(mediaStream, element, true)
        }

        async function startPreview() {
            if (!deviceController) {
                return
            }
            const device = videoInputSelectionToDevice(selectedDevice || null)
            await deviceController.chooseVideoInputDevice(device)

            if (selectedDevice === 'blue' ||  selectedDevice === 'smpte') {
                if (videoEl.current && mounted) {
                    startPreviewVideo(device as MediaStream, videoEl.current)
                }
            } else {
                if (videoEl.current && mounted) {
                    deviceController.startVideoPreviewForVideoInput(videoEl.current)
                }
            }
        }

        startPreview()

        return () => {
            mounted = false

            if (videoEl.current) {
                deviceController.stopVideoPreviewForVideoInput(videoEl.current)
            }
        }
    }, [deviceController, selectedDevice])

    return <StyledPreview {...props} ref={videoEl} />
}

export default PreviewVideo
