import {Button, Flex, TextArea} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ContentWithToolbox} from 'src/components/layout/ContentWithToolbox'
import {Toolbox} from 'src/components/layout/Toolbox'
import {UserAvatarDialogContainer} from 'src/components/profile/UserAvatarDialogTrigger'
import {selectAuthenticatedUser, updateUserProfile} from '../state/auth'
import {useAppDispatch} from '../state/types'
import './UserProfilePage.css'

export const UserProfilePage: React.FC = () => {
    const user = useSelector(selectAuthenticatedUser)
    const [bio, setBio] = useState<string>(user.biography || '')
    const [openAvatarUpload, setAvatarUploadOpen] = useState<boolean>(false)
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const save = () => {
        dispatch(updateUserProfile({
            biography: bio
        }))
    }

    return <ContentWithToolbox className={'user-profile'} toolbox={<Toolbox>
        <Button
            variant='primary'
            onPress={() => bio.length > 0 && save()}
            data-testid='user-profile-button-create'>{t('button.save')}</Button>
    </Toolbox>}>
        <Flex alignSelf={'stretch'} justifyContent={'start'} gap={'size-1600'}>
            <Flex alignItems={'center'} direction={'column'} UNSAFE_className={'two-column'}>
                <img src={user.imageUrl || 'empty.png'} alt='Current Profile Image' style={{width: 100}}/>
                <div><a onClick={() => {
                    setAvatarUploadOpen(true)
                    return false
                }} href={'javascript:void(0)'}>Take picture</a>
                </div>
                <UserAvatarDialogContainer open={openAvatarUpload} setOpen={setAvatarUploadOpen}/>
                <h2>
                    {user.name}
                </h2>
            </Flex>
            <Flex UNSAFE_className={'two-column'}>
                <TextArea label={t('dialog.profile.biography')}
                    placeholder='Please write a few words about yourself! You may also copy-paste a link to your online profile (e.g. LinkedIn).'
                    onChange={bio => {
                        setBio(bio)
                    }}
                    validationState={bio.length > 0 ? 'valid' : 'invalid'}
                    data-testid='create-workshop-dialog-title'
                    minHeight={'size-1600'}
                    width={'size-6000'}
                    defaultValue={bio}
                />
            </Flex>
        </Flex>

    </ContentWithToolbox>
}
