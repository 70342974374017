import React from 'react'
import {useAppSelector} from 'src/state/types'
import {selectIncomingCalls} from 'src/state/incoming-calls'
import {IncomingCallDialogContainer} from 'src/components/incoming-calls/IncomingCallDialogContainer'
import {WithWorkshopId} from 'src/types'

export const IncomingCallsDialogTrigger: React.FC<WithWorkshopId> = ({workshopId}) => {
    const incomingCalls = useAppSelector(selectIncomingCalls)

    return <>
        {incomingCalls.length > 0 && <audio src="/ringtone.mp3" autoPlay loop />}
        {incomingCalls.map(call => <IncomingCallDialogContainer key={call.id} incomingCall={call} workshopId={workshopId} />)}
    </>
}

