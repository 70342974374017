import {Divider, Flex} from '@adobe/react-spectrum'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {NumberOfParticipantsCircle} from 'src/components/roster/NumberOfParticipantsCircle'
import {useQueuedParticipants} from 'src/hooks'
import {WithPhaseId, WithWorkshopId} from 'src/types'

export const ModeratedQueueParticipantRoster: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const NUMBER_OF_DISPLAYED_QUEUE_PARTICIPANTS = 7
    const {t} = useTranslation()
    const queuedParticipants = useQueuedParticipants(workshopId, phaseId)
    const numberOfNotDisplayedParticipants = queuedParticipants.length - NUMBER_OF_DISPLAYED_QUEUE_PARTICIPANTS
    return <div>
        <div className={'up-next-label'}>
            {t('moderated-queue.up-next-label')}
        </div>
        <Divider size='M' marginTop='size-40' marginBottom='size-200'/>
        <div className={'queued-participants'}>
            <Flex direction='column' wrap={false} gap={'size-65'} justifyContent={'center'}>
                <>
                    {queuedParticipants.slice(0, NUMBER_OF_DISPLAYED_QUEUE_PARTICIPANTS).map((participant, index) =>
                        <ParticipantBadge key={index}
                            participant={participant} className={classNames({
                                'participant-not-present': !participant.attendeeId
                            })}/>)}
                    {queuedParticipants.length > NUMBER_OF_DISPLAYED_QUEUE_PARTICIPANTS ?
                        <NumberOfParticipantsCircle
                            label={`+${numberOfNotDisplayedParticipants}`}/> : <></>
                    }
                </>
            </Flex>
        </div>
    </div>
}

