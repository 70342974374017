import {createSlice} from '@reduxjs/toolkit'
import {IncomingCallsState} from './incoming-calls.model'
import {incomingCallsAdapter} from 'src/state/incoming-calls/incoming-calls.adapter'
import {acceptCall, incomingCallIdFromDto, rejectCall} from './incoming-calls.actions'
import {CallReceived, JoinGroupRequestHandled} from 'src/state/incoming-calls/incoming-calls.events'

export const initialState: IncomingCallsState = {
    ...incomingCallsAdapter.getInitialState()
}

export const incomingCallsSlice = createSlice({
    name: 'incoming-calls',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(CallReceived, (state, {payload}) => {
                incomingCallsAdapter.addOne(state, {
                    id: incomingCallIdFromDto(payload),
                    meetingId: payload.meetingId,
                    participantIds: payload.participantIds,
                    requestToJoinGroup: Boolean(payload.requestToJoinGroup)
                })
            })
            .addCase(acceptCall.fulfilled, (state, {payload}) => {
                incomingCallsAdapter.removeOne(state, payload.incomingCall.id)
            })
            .addCase(rejectCall.fulfilled, (state, {payload}) => {
                incomingCallsAdapter.removeOne(state, payload.incomingCallId)
            })
            .addCase(JoinGroupRequestHandled, (state, {payload}) => {
                incomingCallsAdapter.removeOne(state, payload.incomingCallId)
            })
    }
})
