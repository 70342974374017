import {WithAuthState} from 'src/state/auth'
import {selectHasDeviceDialogBeenShown, WithDeviceSettingsState} from 'src/state/device-settings'
import {WithModeratedQueueState} from 'src/state/moderated-queue'
import {WithModeratedWithRoleState} from 'src/state/moderated-with-roles'
import {WithPhasesState} from 'src/state/phases'
import {WithTopicAssignmentsState} from 'src/state/topic-assignments'
import {WithWorkshopsState} from 'src/state/workshops'
import {
    defaultConfig,
    MeetingDeviceSettingsStrategy,
    ModeratedQueueSettingsDeviceStrategy,
    ModeratedWithRolesDeviceSettingsStrategy,
    PresentationDeviceSettingsStrategy
} from './device-settings'
import {MeetingDeviceSettings, WithMeetingsState} from './meetings.model'

export const selectMeetingDeviceSettings: (
  state: WithMeetingsState
    & WithModeratedWithRoleState
    & WithModeratedQueueState
    & WithTopicAssignmentsState
    & WithWorkshopsState
    & WithPhasesState
    & WithAuthState
)
  => MeetingDeviceSettings = state => {
      const strategies: MeetingDeviceSettingsStrategy[] = [
          new ModeratedWithRolesDeviceSettingsStrategy(state),
          new ModeratedQueueSettingsDeviceStrategy(state),
          new PresentationDeviceSettingsStrategy(state)
      ]

      const strategy = strategies.find(strategy => strategy.isResponsible())
      if (strategy) {
          return strategy.getDeviceSettings()
      }
      return defaultConfig
  }


export const selectShouldShowDeviceSettingsDialog: (state: WithMeetingsState
  & WithModeratedWithRoleState
  & WithModeratedQueueState
  & WithTopicAssignmentsState
  & WithWorkshopsState
  & WithPhasesState
  & WithAuthState
  & WithDeviceSettingsState
) => boolean = state => {
    const settings = selectMeetingDeviceSettings(state)
    return settings.permissionsToSpeakMightBeGranted && !selectHasDeviceDialogBeenShown(state)
}
