import {SearchField} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {SearchResult} from './SearchResult'
import {selectSearchParticipantsResult} from 'src/state/participant-locations'
import {selectParticipantsForWorkshop} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const SearchParticipants: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const [search, setSearch] = useState<string>('')
    const searchResults = useAppSelector(selectSearchParticipantsResult(workshopId, search))
    const numberOfParticipants = useAppSelector(selectParticipantsForWorkshop(workshopId)).length

    return <div className={'search-participants'}>
        <SearchField
            aria-label={'Search'}
            placeholder={`Search ${numberOfParticipants} participants`}
            onSubmit={setSearch}
            onChange={setSearch}
        />
        <div className={'search-results'}>
            {searchResults.map((searchResult, index) =>
                <SearchResult key={index} participant={searchResult.participant} locations={searchResult.locations}/>)}
        </div>
    </div>
}
