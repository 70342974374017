import {createSlice} from '@reduxjs/toolkit'
import {loadMyParticipant, loadParticipants} from './participants.actions'
import {participantsAdapter} from './participants.adapter'
import {ParticipantUpdated} from './participants.events'
import {ParticipantsState} from './participants.model'
import {ParticipantStateService} from './participants.service'

export const initialState: ParticipantsState = {
    ...participantsAdapter.getInitialState(),
    byWorkshop: {},
}

const stateService = new ParticipantStateService(participantsAdapter)

export const participantsSlice = createSlice({
    name: 'participants',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadParticipants.pending, (state) => {
                stateService.setParticipants([], state)
            })
            .addCase(loadParticipants.fulfilled, (state, {payload: participants}) => {
                stateService.setParticipants(participants, state)
            })
            .addCase(loadMyParticipant.fulfilled, (state, {payload: participant}) => {
                stateService.addMe(participant, state)
            })
            .addCase(ParticipantUpdated, (state, {payload: participant}) => {
                stateService.updateParticipant(participant, state)
            })
    }
})
