import {useAudioVideo, useSelectVideoInputDevice} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {VideoActiveIcon} from 'src/components/icons'
import {DeviceSelection} from './DeviceSelection'
import {useVideoDevices} from 'src/hooks'
import {
    selectIsVideoActive,
    selectVideoInputDeviceId,
    setVideoActive,
    setVideoInputDeviceId
} from 'src/state/device-settings'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export const VideoDeviceSelection: React.FC = () => {
    const videoDevices = useVideoDevices()
    const dispatch = useAppDispatch()
    const deviceId = useAppSelector(selectVideoInputDeviceId)
    const activeByDefault = useAppSelector(selectIsVideoActive)
    const audioVideo = useAudioVideo()
    const selectVideoDevice = useSelectVideoInputDevice()
    const {t} = useTranslation()

    return <DeviceSelection
        icon={<VideoActiveIcon className={'icon-button'} />}
        ariaLabel={'Video'}
        devices={videoDevices}
        selectDevice={(deviceId) => {
            dispatch(setVideoInputDeviceId(deviceId))
            if (audioVideo) {
                selectVideoDevice(deviceId)
                    .catch(error => console.error('Failed to select device', error))
            }
        }}
        selectedDeviceId={deviceId}
        activeByDefault={activeByDefault}
        setActiveByDefault={(active) => {
            dispatch(setVideoActive(active))
        }}
        activeByDefaultLabel={t('dialog.device-settings.keep-video-active')}
    />
}
