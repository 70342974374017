import {Divider, Flex} from '@adobe/react-spectrum'
import React, {ReactNode} from 'react'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {LocationType, selectParticipantsAtLocation} from 'src/state/participant-locations'
import {useAppSelector} from 'src/state/types'

export const ParticipantsAtLocation: React.FC<{
    location: LocationType,
    header: ReactNode
    buttons: ReactNode
}> = ({
    location,
    header,
    buttons
}) => {
    const participants = useAppSelector(selectParticipantsAtLocation(location))

    return <div className={'two-rows room-info'}>
        <Flex direction={'column'} UNSAFE_className={'content-box'}>
            <div className={'header'}>
                {header}
                <Divider size={'M'} marginBottom={'size-130'}/>
            </div>
            <ScrollContainer>
                <div>
                    <>{participants.map((participant, index) => <ParticipantBadge key={index} participant={participant}/>)}</>
                </div>
            </ScrollContainer>
            <Divider size={'M'} marginBottom={'size-130'} marginTop={'size-130'}/>
            <div className={'buttons'}>
                {buttons}
            </div>
        </Flex>

    </div>
}
