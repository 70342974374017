import React, {PropsWithChildren} from 'react'
import './tab.css'

interface Props {
    onClick: (tab: string, route: string | undefined) => void
    activeTab: string
    tabKey: string
    label: string
    route?: string
}

export const Tab : React.FC<Props> = (props: PropsWithChildren<Props>) => {
    const {activeTab, label, tabKey, route, onClick} = props
    let className = 'tab-list-item'
    if (activeTab === tabKey) {
        className += ' tab-list-active'
    }
    return (
        <li
            className={className}
            onClick={() => onClick(tabKey, route)}
        >
            {label}
        </li>
    )
}
