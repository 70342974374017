import {CreateTopicDto, TopicDto, TopicsApi, UpdateTopicDto} from 'src/api/topics'
import {Votes} from 'src/state/topic-votes'
import {CreateTopicModel, Topic, UpdateTopicModel} from './topics.model'

export function toTopicWithVotes(dto: TopicDto): Topic & Votes {
    return {
        id: dto.id,
        title: dto.title,
        description: dto.description,
        status: dto.status,
        owner: dto.owner,
        currentVotes: dto.currentVotes,
        neededVotes: dto.neededVotes
    }
}

function toCreateTopicDto(model: CreateTopicModel): CreateTopicDto {
    return  {
        ...model.topic
    }
}

function toUpdateTopicDto(model: UpdateTopicModel): UpdateTopicDto {
    return {
        ...model.topic
    }
}

export class TopicsApiService {

    constructor(
        private api: TopicsApi = new TopicsApi(),
    ) {
    }

    async createTopic(model: CreateTopicModel): Promise<Topic & Votes> {
        return toTopicWithVotes(await this.api.createTopic(model.workshopId, model.phaseId, toCreateTopicDto(model)))
    }

    async createPredefinedTopic(model: CreateTopicModel): Promise<Topic & Votes> {
        return toTopicWithVotes(await this.api.createPredefinedTopic(model.workshopId, model.phaseId, toCreateTopicDto(model)))
    }

    async loadTopics(workshopId: string): Promise<Array<Topic & Votes>> {
        const dtos = await this.api.loadTopics(workshopId)
        return dtos.map(dto => toTopicWithVotes(dto))
    }

    async loadTopic(workshopId: string, topicId: string): Promise<Topic & Votes> {
        const dto = await this.api.loadTopic(workshopId, topicId)
        return toTopicWithVotes(dto)
    }

    async closeTopic(workshopId: string, topicId: string): Promise<Topic & Votes> {
        const dto = await this.api.closeTopic(workshopId, topicId)
        return toTopicWithVotes(dto)
    }

    async updateTopic(model: UpdateTopicModel): Promise<Topic & Votes> {
        return await this.api.updateTopic(model.workshopId, toUpdateTopicDto(model))
    }
}

