import moment from 'moment'
import React, {CSSProperties} from 'react'

interface Props {
    title?: string
    dateTime: string
    style?: CSSProperties
}

export const Time: React.FC<Props> = ({dateTime, title, style}) => {
    const date = moment.utc(dateTime).local()
    return <span style={style} title={title}>
        {date.format('HH:mm')}
    </span>
}
