import {Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {selectPhaseById} from 'src/state/phases'
import {useAppSelector} from 'src/state/types'
import {WithPhaseId} from 'src/types'
import {PlenaryColumn, TopicRoomColumn} from './room'
import {TimeColumn} from './time/TimeColumn'
import {TitleColumn} from './title/TitleColumn'

export const WorkshopPhaseDesigner: React.FC<WithPhaseId> = ({phaseId}) => {
    const phase = useAppSelector(selectPhaseById(phaseId))
    return phase ? <View borderWidth='thin' borderColor='dark' borderRadius='medium' UNSAFE_className='phase'>
        <Flex direction='row' justifyContent={'start'} alignContent={'stretch'}>
            <TimeColumn phase={phase}/>
            <TitleColumn phase={phase}/>
            <PlenaryColumn phase={phase}/>
            <TopicRoomColumn phase={phase}/>
        </Flex>
    </View> : <></>
}
