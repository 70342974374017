import {Button, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PauseCircleIcon} from 'src/components/icons/PauseCircleIcon'
import {pauseQueue} from 'src/state/moderated-queue'
import {useAppDispatch} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'

export const PauseQueueButton: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <Button UNSAFE_className={'moderator-button'} flexShrink={0} variant='overBackground' onPress={() => {
        dispatch(pauseQueue({
            workshopId,
            phaseId,
        }))
    }}>
        <PauseCircleIcon className={'hover-button-icon'} /><Text>{t('moderated-queue.button.pause-queue')}</Text>
    </Button>
}
