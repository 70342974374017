import {RouterState} from 'connected-react-router'
import {TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector} from 'react-redux'
import {store} from './store'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector
export type WithRouterState = {
    router: RouterState<unknown>
}
export const selectUndefined: () => undefined = () => undefined
