import React from 'react'
import {useParams} from 'react-router-dom'
import {WithWorkshopId} from 'src/types'
import {selectIsMeetingDrawerOpen} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import {ActiveMeeting} from 'src/container/meetings/ActiveMeeting'
import {selectActiveMeeting} from 'src/state/meetings'

export const WorkshopCommunication: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const drawerOpen = useAppSelector(selectIsMeetingDrawerOpen)
    const activeMeeting = useAppSelector(selectActiveMeeting)

    return <>
        {drawerOpen && activeMeeting ?
            <ActiveMeeting workshopId={workshopId} /> : <></>}
    </>
}
