import React from 'react'
import {TabItem} from 'src/components/layout/tab/TabItem'
import {Tabs} from 'src/components/layout/tab/Tabs'
import {CafeMeetings} from 'src/container/rooms/cafe/CafeMeetings'
import {SinglePeopleInCafe} from 'src/container/rooms/cafe/SinglePeopleInCafe'
import {selectCafeBreakoutMeetings, selectParticipantsFilteredByLocation} from 'src/state/participant-locations'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const CafeTabLayout: React.FC<WithWorkshopId> = ({workshopId}) => {
    const participants = useAppSelector(selectParticipantsFilteredByLocation({type: 'cafe'}, location => !location.meetingId))
    const meetings = useAppSelector(selectCafeBreakoutMeetings)

    return <Tabs>
        <TabItem tabKey='people' label={`People (${participants.length})`}>
            <SinglePeopleInCafe workshopId={workshopId}/>
        </TabItem>
        <TabItem tabKey='meetings' label={`Meetings (${meetings.length})`}>
            <CafeMeetings workshopId={workshopId}/>
        </TabItem>
    </Tabs>
}
