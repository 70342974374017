import {selectWorkshopById, WithWorkshopsState, Workshop} from 'src/state/workshops'
import {phasesAdapter} from './phases.adapter'
import {Phase, WithPhasesState} from './phases.model'
import {WithRouterState} from 'src/state/types'
import {matchPath} from 'react-router-dom'
import {WithWorkshopId} from 'src/types'

export const phasesSelectors = phasesAdapter.getSelectors<WithPhasesState>(
    (state) => state.phases
)

export const selectPhaseById: (id: string) => (state: WithPhasesState) => Phase | undefined = (id) => state =>
    phasesSelectors.selectById(state, id)

export const selectWorkshopPhases: (state: WithPhasesState) => Phase[] = state =>
    phasesSelectors.selectAll(state)

export const selectPhasesForWorkshop: (workshopId: string) => (state: WithPhasesState & WithWorkshopsState) => Phase[] = (workshopId) => (state) => {
    const workshop = state.workshops.entities[workshopId]
    if (!workshop) {
        return []
    }
    return workshop.phases.map(phaseId => state.phases.entities[phaseId]!)
}

export const selectCurrentPhase: (workshopId: string) => (state: WithWorkshopsState & WithPhasesState) => Phase | undefined = id => state => {
    const workshop: Workshop | undefined = selectWorkshopById(id)(state)
    if (!workshop) {
        return
    }
    const phaseId = workshop.phases.find(phaseId => state.phases.entities[phaseId]?.status === 'started')
    return phaseId ? selectPhaseById(phaseId)(state) : undefined
}

export const selectHasNextPhase: (id: string) => (state: WithWorkshopsState & WithPhasesState) => boolean = id => state => {
    const workshop: Workshop | undefined = selectWorkshopById(id)(state)
    if (!workshop) {
        return false
    }
    const phases = workshop.phases.map(phaseId => state.phases.entities[phaseId]!)
    return phases.findIndex(phase => phase?.status === 'not-started') >= 0
}

export const selectIsWorkshopFinished: (state: WithRouterState & WithWorkshopsState & WithPhasesState) => boolean = state => {
    const match = matchPath<WithWorkshopId>(state.router.location.pathname, '/workshops/:workshopId/')
    if(!match){
        return false
    }
    const id = match.params.workshopId
    const workshop: Workshop | undefined = selectWorkshopById(id)(state)
    if (!workshop) {
        return false
    }
    const phases = workshop.phases.map(phaseId => state.phases.entities[phaseId]!)
    return phases.every(phase => phase?.status === 'finished')
}
