import {Dictionary} from '@reduxjs/toolkit'
import {ParticipantsApi} from 'src/api'
import {Participant} from './participants.model'
import {toParticipant} from './participants.service'

export function loadParticipantName(
    workshopId: string,
    participants: Dictionary<Participant>,
    fallbackName: string
) {
    return async (chimeAttendeeId: string, externalUserId?: string): Promise<{name: string}> => {
        const loadParticipants = async () => {
            const profiles = await new ParticipantsApi().getParticipantsForWorkshop(workshopId)
            const newParticipants: { [id: string]: Participant } = {}
            profiles.forEach(participant => {
                newParticipants[participant.id] = toParticipant(workshopId, participant)
            })
            return newParticipants
        }

        if (externalUserId) {
            let participantMap = participants
            if (!participantMap[externalUserId]) {
                participantMap = await loadParticipants()
            }

            return {
                name: participantMap[externalUserId]?.name || fallbackName
            }
        } else {
            return {
                name: fallbackName
            }
        }
    }
}
