import {Checkbox, CheckboxGroup} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const BreakoutToolSelection: React.FC = () => {
    const {t} = useTranslation()
    return <CheckboxGroup label={t('workshop-designer.tools-label')} defaultValue={['sell-with-likes', 'whiteboard']}>
        <Checkbox value='sell-with-likes' isDisabled>{t('workshop-designer.tool.sell-with-likes')}</Checkbox>
        <Checkbox value='whiteboard' isDisabled>{t('workshop-designer.tool.whiteboard')}</Checkbox>
    </CheckboxGroup>
}
