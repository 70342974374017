import React from 'react'
import {useParams} from 'react-router-dom'
import {selectCurrentPhase, selectHasNextPhase} from 'src/state/phases'
import {useAppSelector} from 'src/state/types'
import {selectIsLeadModerator, selectWorkshopById} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'
import {FinishCurrentPhaseButton} from './workshop/buttons/FinishCurrentPhaseButton'
import {NextPhaseButton} from './workshop/buttons/NextPhaseButton'
import {StartWorkshopButton} from './workshop/buttons/StartWorkshopButton'

export const LeadModeratorControls: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const workshop = useAppSelector(selectWorkshopById(workshopId))
    const isLeadModerator = useAppSelector(selectIsLeadModerator(workshopId))

    const phase = useAppSelector(selectCurrentPhase(workshopId))
    const hasNextPhase: boolean = useAppSelector(selectHasNextPhase(workshopId))

    const shouldShowStartWorkshopButton = workshop?.status === 'not-started'
    const shouldShowFinishCurrentPhaseButton = Boolean(phase)
    const shouldShowNextPhaseButton = !shouldShowStartWorkshopButton && hasNextPhase

    return <>
        {isLeadModerator ?
            <>
                {shouldShowStartWorkshopButton ? <StartWorkshopButton workshopId={workshopId}/> : <></>}
                {shouldShowFinishCurrentPhaseButton ? <FinishCurrentPhaseButton workshopId={workshopId}/> : <></>}
                {shouldShowNextPhaseButton ? <NextPhaseButton workshopId={workshopId}/> : <></>}
            </>
            : <></>
        }
    </>
}
