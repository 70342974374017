import React from 'react'
import {AnonymousAvatar, PictureAvatar} from 'src/container/rooms/cafe/circle'
import {WithParticipant} from 'src/types'

export const ParticipantCafeIcon: React.FC<WithParticipant & {
    canStartCall: boolean
    active: boolean
}>
  = ({participant, canStartCall, active}) => {
      return participant.imageUrl
          ? <PictureAvatar participant={participant} canStartCall={canStartCall} active={active} />
          : <AnonymousAvatar participant={participant} canStartCall={canStartCall} active={active}/>
  }
