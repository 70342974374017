import {Flex, Switch} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {BreakoutToolSelection} from 'src/components/workshops/designer/room/BreakoutToolSelection'
import {ModeOfInteractionSelection} from 'src/components/workshops/designer/selection/ModeOfInteractionSelection'
import {meetingVariantConfig} from 'src/state/phases'
import {setBreakoutEnabledConfiguration, setBreakoutModeOfInteraction} from 'src/state/phases/phases.actions'
import {useAppDispatch} from 'src/state/types'
import {WithPhase} from 'src/types'

export const BreakoutConfiguration: React.FC<WithPhase> = ({phase}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const onChangeEnable = (isEnabled: boolean) => {
        dispatch(setBreakoutEnabledConfiguration({phase, enabled: isEnabled}))
    }
    const enabled = phase.breakout.currentModeOfInteractions !== 'none'
    const options = meetingVariantConfig[phase.meetingVariant.type].breakoutModesOfInteraction
    const enabledOptions = options.filter(mode => mode !== 'none')

    return <>
        <Switch isSelected={enabled} onChange={onChangeEnable}
            isDisabled={enabledOptions.length === 0 || !options.includes('none')}>{enabled ? t('workshop-designer.enabled-label') : t('workshop-designer.disabled-label')}</Switch>
        {enabled ? <Flex direction='row' gap={'size-450'} justifyContent={'start'}>
            <ModeOfInteractionSelection
                selected={phase.breakout.currentModeOfInteractions}
                options={enabledOptions}
                onSelect={modeOfInteraction => dispatch(setBreakoutModeOfInteraction({phase, modeOfInteraction}))}
            />
            <BreakoutToolSelection/>
        </Flex> : <></>}
    </>
}
