import {ModeratedQueueApi} from 'src/api/phases/moderated-queue.api'
import {
    AcceptTopicModel, CloseTopicModel,
    ModeratedQueue,
    ParticipantAndTopicId,
    QueueUpdate
} from 'src/state/moderated-queue/moderated-queue.model'
import {EnqueueTopicModel} from 'src/state/topics'


export class ModeratedQueueApiService {

    public constructor(private api = new ModeratedQueueApi()) {
    }

    async enqueueTopic(model: EnqueueTopicModel): Promise<void> {
        await this.api.enqueueTopic(model.workshopId, model.phaseId, {
            ...model.topic
        })
    }

    async updateQueue(queueUpdate: QueueUpdate): Promise<void> {
        await this.api.updateQueue(queueUpdate.workshopId, queueUpdate.phaseId, {
            active: queueUpdate.active
        })
    }

    async getQueue(workshopId: string, phaseId: string): Promise<ModeratedQueue<ParticipantAndTopicId>> {
        const queueDto = await this.api.getQueue(workshopId, phaseId)
        return {
            id: phaseId,
            active: queueDto.active,
            items: queueDto.items
        }
    }

    async acceptTopic(acceptTopic: AcceptTopicModel): Promise<void> {
        await this.api.acceptTopic(acceptTopic.workshopId, acceptTopic.phaseId, acceptTopic.topicId)
    }

    async closeTopic(closeTopic: CloseTopicModel): Promise<void> {
        await this.api.deleteFromQueue(closeTopic.workshopId, closeTopic.phaseId, closeTopic.topicId)
    }
}
