import {Flex, Text} from '@adobe/react-spectrum'
import {useRosterState} from 'amazon-chime-sdk-component-library-react'
import {RosterType} from 'amazon-chime-sdk-component-library-react/lib/types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ParticipantPictureOrVideo} from 'src/components/roster/video/ParticipantPictureOrVideo'
import {useSpeakingMeetingParticipants} from 'src/hooks'
import {MeetingParticipant} from 'src/state/meetings'
import {selectActiveSpeakerInQueue} from 'src/state/moderated-queue'
import {Participant, selectLeadModerator} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithPhaseId, WithWorkshopId} from 'src/types'

function findMeetingParticipantInRoster(participant: Participant | undefined, roster: RosterType): MeetingParticipant | undefined {
    if (participant) {
        const attendee = Object.values(roster).find(attendee => attendee.externalUserId === participant.id)
        if (attendee) {
            return {...participant, attendeeId: attendee.chimeAttendeeId}
        }
    }
}

export const ModeratedQueueRosterStage: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const participant = useAppSelector(selectActiveSpeakerInQueue(workshopId, phaseId))
    const moderator = useAppSelector(selectLeadModerator(workshopId))
    const {roster} = useRosterState()
    const speakingParticipants = useSpeakingMeetingParticipants(workshopId)
        .filter(meetingParticipant =>
            meetingParticipant.id === moderator?.id
        || meetingParticipant.id === participant?.id)

    const meetingParticipant = speakingParticipants[0]
      || findMeetingParticipantInRoster(participant, roster)
      || findMeetingParticipantInRoster(moderator, roster)

    const {t} = useTranslation()

    return <Flex justifyContent={'center'} alignItems={'center'}>
        {meetingParticipant ? <ParticipantPictureOrVideo
            participant={meetingParticipant}
            size={'stage'}
            role={
                moderator?.id === meetingParticipant?.id ? 'Moderator' : undefined
            }/>
            : <Text UNSAFE_className={'light-text'}>{t('moderated-queue.waiting-for-moderator')}</Text>}</Flex>
}
