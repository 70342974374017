import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {createHashHistory} from 'history'
import {combineReducers} from 'redux'
import {deviceSettingsReducer} from 'src/state/device-settings/device-settings.state'
import {errorsSlice} from 'src/state/error/error.state'
import {moderatedQueueSlice} from 'src/state/moderated-queue/moderated-queue.state'
import {moderatedWithRolesSlice} from 'src/state/moderated-with-roles/moderated-with-roles.state'
import {participantLocationsSlice} from 'src/state/participant-locations/participant-locations.state'
import {topicAssignmentsSlice} from 'src/state/topic-assignments/topic-assignments.state'
import {topicNotesSlice} from 'src/state/topic-notes/topic-notes.state'
import {authSlice} from './auth/auth.state'
import {invitationsSlice} from './invitations/invitations.state'
import {layoutSlice} from './layout/layout.state'
import {meetingsSlice} from './meetings/meetings.state'
import {participantsSlice} from './participants/participants.state'
import {phasesSlice} from './phases/phases.state'
import {scheduleSlice} from './schedule/schedule.state'
import {topicVotesSlice} from './topic-votes/topic-votes.state'
import {topicsSlice} from './topics/topics.state'
import {workshopsSlice, workshopTemplatesSlice} from './workshops/workshops.state'
import {callsSlice} from './calls/calls.state'
import {incomingCallsSlice} from './incoming-calls/incoming-calls.state'

export const history = createHashHistory()

export const reducer = combineReducers({
    router: connectRouter(history),
    layout: layoutSlice.reducer,
    auth: authSlice.reducer,
    calls: callsSlice.reducer,
    incomingCalls: incomingCallsSlice.reducer,
    workshops: workshopsSlice.reducer,
    workshopTemplates: workshopTemplatesSlice.reducer,
    topics: topicsSlice.reducer,
    topicAssignments: topicAssignmentsSlice.reducer,
    topicNotes: topicNotesSlice.reducer,
    topicVotes: topicVotesSlice.reducer,
    phases: phasesSlice.reducer,
    invitations: invitationsSlice.reducer,
    participants: participantsSlice.reducer,
    participantLocations: participantLocationsSlice.reducer,
    meetings: meetingsSlice.reducer,
    schedule: scheduleSlice.reducer,
    moderatedWithRoles: moderatedWithRolesSlice.reducer,
    moderatedQueue: moderatedQueueSlice.reducer,
    errors: errorsSlice.reducer,
    deviceSettings: deviceSettingsReducer,
})

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware().concat(routerMiddleware(history))
})

