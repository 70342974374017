import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {VideoCall} from 'src/components/meetings/VideoCall'
import {CafeTabLayout} from 'src/container/rooms/cafe/CafeTabLayout'
import {CafeTwoColumnLayout} from 'src/container/rooms/cafe/CafeTwoColumnLayout'
import {useMyLocation} from 'src/hooks'
import {
    abortOutgoingCall,
    loadAllCalls,
    selectHasOutgoingCall,
    selectOutgoingCall,
    setOutgoingCall
} from 'src/state/calls'
import {Button, ButtonGroup, Content, Dialog, DialogContainer, Flex, Footer, Heading, Text} from '@adobe/react-spectrum'

import {selectActiveMeeting} from 'src/state/meetings'
import {selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import './CafeRoom.css'
import {IncomingCallsDialogTrigger} from 'src/components/incoming-calls/IncomingCallsDialogTrigger'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {NumberOfParticipantsCircle} from 'src/components/roster/NumberOfParticipantsCircle'
import {EndCallWhiteIcon} from 'src/components/icons/EndCallWhiteIcon'
import {useTranslation} from 'react-i18next'
import {selectLocationsByMeeting} from 'src/state/participant-locations'

export const CafeRoom: React.FC = () => {
    useMyLocation('cafe')
    const dispatch = useAppDispatch()
    const {workshopId} = useParams<WithWorkshopId>()
    const me = useAppSelector(selectMyParticipant)
    const activeMeeting = useAppSelector(selectActiveMeeting)

    useEffect(() => {
        dispatch(loadAllCalls({workshopId}))
    }, [])

    if (!me) {
        return <></>
    }

    return <div className={'cafe'}>
        <VideoCall />
        <IncomingCallsDialogTrigger workshopId={workshopId} />
        <OutgoingCallDialogContainer workshopId={workshopId} />
        {activeMeeting ? <CafeTabLayout workshopId={workshopId} /> : <CafeTwoColumnLayout workshopId={workshopId} />}
    </div>
}

export const OutgoingCallDialogContainer: React.FC<WithWorkshopId> = ({workshopId}) => {
    const hasOutgoingCall = useAppSelector(selectHasOutgoingCall)
    const dispatch = useAppDispatch()
    return <DialogContainer onDismiss={() => dispatch(setOutgoingCall(undefined))}>
        {hasOutgoingCall && <OutgoingCallDialog workshopId={workshopId}/>}
    </DialogContainer>
}

export const OutgoingCallDialog: React.FC<WithWorkshopId> = ({workshopId}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const outgoingCallId = useAppSelector(selectOutgoingCall)
    const participantsByMeeting = useAppSelector(selectLocationsByMeeting('cafe'))
    const me = useAppSelector(selectMyParticipant)

    const participants = outgoingCallId ? participantsByMeeting[outgoingCallId] || [] : []
    const shouldDisplayNumberOfParticipants = participants && participants.length > 3 + 1 // +1 = me
    const displayedParticipants = participants
        .filter(participant => participant.id !== me?.id)
        .slice(0, shouldDisplayNumberOfParticipants ? 2 : 3)

    const abort = () => {
        dispatch(abortOutgoingCall({workshopId}))
    }

    return <Dialog size='M' data-testid='receive-call-dialog' UNSAFE_className={'incoming-call-dialog'}>
        <Heading>
            <Flex justifyContent={'center'} alignItems={'center'} gap="size-100">
                <Text>{t('dialog.outgoing-call.heading')}</Text>
            </Flex>
        </Heading>
        <Content>
            {displayedParticipants ? displayedParticipants.map((caller, index) => <ParticipantBadge key={index} participant={caller}/>) : <></>}
            {shouldDisplayNumberOfParticipants ?
                <NumberOfParticipantsCircle label={`+${participants.length - 1}`}/> : <></>}
        </Content>
        <Footer>
            <ButtonGroup justifySelf={'center'} UNSAFE_className={'buttons'}>
                <Button
                    onPress={abort}
                    variant='secondary'>
                    <EndCallWhiteIcon/>
                </Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}



