import { Form} from '@adobe/react-spectrum'
import React from 'react'

export const RoomConfigurationForm: React.FC = ({children}) => {
    return <div className='room-config-container'>
        <Form>
            <>
                {children}
            </>
        </Form>
    </div>
}
