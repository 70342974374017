import Ajv, {ValidateFunction} from 'ajv'
import schema from '../gen/schema.json'

export const ajv = new Ajv()

ajv.addSchema(schema)

export function getSchema<T>(dtoName: string): ValidateFunction<T> {
    if (!(schema as any).definitions[dtoName]) {
        console.log('Unable to validate schema, definition not found. Update required?: '+dtoName)
        throw Error('Type not found in schema: '+dtoName)
    }
    return ajv.getSchema<T>('#/definitions/' + dtoName)!
}

export function validate<T>(payload: T, validate: ValidateFunction<T>): void {
    const valid = validate(payload)
    if (!valid) {
        console.error('Validation failed ', payload, validate.errors)
        throw new Error('Validation failed')
    }
}

export type ValidationFactory<T> = () => ValidateFunction<T>
