import {useEffect} from 'react'
import {useWebsocketObservable} from 'src/hooks/websocket/use-websocket-observable'

export function useWebsocketSubscription<T>(topic: string | undefined, onMessage: (payload: T) => void): Promise<void> | undefined {
    const {observable, subscriptionReceipt} = useWebsocketObservable(topic)
    
    useEffect(() => {
        const subscription = observable?.subscribe(message => onMessage(JSON.parse(message.body)))
        return () => {
            subscription?.unsubscribe()
        }
    }, [observable])
    return subscriptionReceipt
}
