import {Heading} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {selectNumberOfMeetingsAt, selectParticipantsAtLocation} from 'src/state/participant-locations'
import {useAppSelector} from 'src/state/types'

export const CafeHeader: React.FC = () => {
    const {t} = useTranslation()
    const participants = useAppSelector(selectParticipantsAtLocation('cafe'))
    const numberOfMeetings = useAppSelector(selectNumberOfMeetingsAt('cafe'))

    return <>
        <Heading>{t('participant-map.room-cafe')}</Heading>
        <Heading level={4}>{participants.length} People, {numberOfMeetings} Meetings</Heading>
    </>
}
