import { workshopEventDispatcher} from 'src/state/workshop-event-dispatcher'
import {createAction} from '@reduxjs/toolkit'
import {CallDto} from 'src/api'

workshopEventDispatcher.registerActionCreator({
    type: 'CallUpdated',
    actionCreator: event => CallUpdated({
        ...(event.payload as CallDto),
    })
})

export const CallUpdated = createAction<CallDto>('calls/event/call-updated')
