import {createAsyncThunk} from '@reduxjs/toolkit'
import {WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'
import {Topic} from '../topics'
import {TopicVotesApiService} from './topic-votes.service'
import {Votes} from './topic-votes.model'

export const voteForTopic = createAsyncThunk<Topic & Votes, WithWorkshopId & WithPhaseId & WithTopicId>(
    'topics/voteForTopic',
    async ({workshopId, phaseId, topicId}) => {
        const apiService = new TopicVotesApiService()
        return apiService.voteForTopic(workshopId, phaseId, topicId)
    }
)
