import {createAsyncThunk} from '@reduxjs/toolkit'
import {TopicRoomEventTypeDto} from 'src/websocket/topic-room-event-types.dto'
import {WorkshopEventDto} from 'src/websocket/workshop-event-types.dto'
import {
    validateCallsEventDto, validateInvitationEventDto,
    validatePhaseEventDto,
    validateTopicRoomEventDto,
    validateWorkshopEventDto,
} from '../websocket/types.validation'
import {
    callsEventDispatcher,
    invitationEventDispatcher,
    phaseEventDispatcher,
    topicRoomEventDispatcher,
    workshopEventDispatcher
} from './workshop-event-dispatcher'
import {
    CallsEventType,
    InvitationEventType,
    PhaseEventType,
    TopicRoomEventType,
    WorkshopEventType
} from './workshop-event.model'
import {PhaseEventTypeDto} from 'src/websocket/phase-event-types.dto'
import {CallsEventDto} from 'src/websocket/call-event-types.dto'
import { InvitationEventTypeDto} from 'src/websocket/invitation-event-type.dto'
import {validate} from 'src/tools/validation'

export const PersonalEvent = createAsyncThunk<void, WorkshopEventType | CallsEventType>(
    'personal/event',
    (event, thunkAPI) => {
        if(workshopEventDispatcher.hasEvent(event.type)) {
            validate<WorkshopEventDto>(event as WorkshopEventType, validateWorkshopEventDto())
            if (!workshopEventDispatcher.dispatch(event as WorkshopEventType, thunkAPI.dispatch)) {
                console.warn('unhandled workshop event from backend', event)
            }
            return
        }
        if(callsEventDispatcher.hasEvent(event.type)) {
            validate<CallsEventDto>(event as CallsEventType, validateCallsEventDto())
            if (!callsEventDispatcher.dispatch(event as CallsEventType, thunkAPI.dispatch)) {
                console.warn('unhandled calls event from backend', event)
            }
            return
        }
        console.warn('unknown personal event from backend', event)
    })

export const WorkshopEvent = createAsyncThunk<void, WorkshopEventType>(
    'workshop/event',
    (event, thunkAPI) => {
        validate<WorkshopEventDto>(event, validateWorkshopEventDto())
        if (!workshopEventDispatcher.dispatch(event, thunkAPI.dispatch)) {
            console.warn('unhandled workshop event from backend', event)
        }
    })

export const TopicRoomEvent = createAsyncThunk<void, TopicRoomEventType>(
    'topic-room/event',
    (event, thunkAPI) => {
        validate<TopicRoomEventTypeDto>(event, validateTopicRoomEventDto())
        if (!topicRoomEventDispatcher.dispatch(event, thunkAPI.dispatch)) {
            console.warn('unhandled topic room event from backend', event)
        }
    })

export const PhaseEvent = createAsyncThunk<void, PhaseEventType>(
    'phase/event',
    (event, thunkAPI) => {
        validate<PhaseEventTypeDto>(event, validatePhaseEventDto())
        if (!phaseEventDispatcher.dispatch(event, thunkAPI.dispatch)) {
            console.warn('unhandled phase event from backend', event)
        }
    })

export const InvitationEvent = createAsyncThunk<void, InvitationEventType>(
    'invitation/event',
    (event, thunkAPI) => {
        validate<InvitationEventTypeDto>(event, validateInvitationEventDto())
        if (!invitationEventDispatcher.dispatch(event, thunkAPI.dispatch)) {
            console.warn('unhandled phase event from backend', event)
        }
    })

export const CallsEvent = createAsyncThunk<void, CallsEventType>(
    'calls/event',
    (event, thunkAPI) => {
        validate<CallsEventDto>(event, validateCallsEventDto())
        if (!callsEventDispatcher.dispatch(event, thunkAPI.dispatch)) {
            console.warn('unhandled calls event from backend', event)
        }
    })
