import React from 'react'
import {OfflineParticipantVideo} from 'src/components/roster/OfflineParticipantVideo'
import {MeetingParticipant} from 'src/state/meetings'
import {Participant} from 'src/state/participants'
import {ParticipantPictureOrVideo} from './video/ParticipantPictureOrVideo'

export function hasAttendeeId(participant: Participant & {attendeeId?: string}): participant is MeetingParticipant {
    return participant.attendeeId !== undefined
}

export const ParticipantRosterItem: React.FC<{participant: Participant & {attendeeId?: string}}> = ({participant}) => {
    return <>
        {hasAttendeeId(participant) ? <ParticipantPictureOrVideo
            participant={participant}
            size='roster'
        /> : <OfflineParticipantVideo participant={participant} size={'roster'} />}
    </>
}

