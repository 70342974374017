import {Button, Flex, Heading} from '@adobe/react-spectrum'
import {push} from 'connected-react-router'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {CafeHeader} from 'src/container/rooms/participants/CafeHeader'
import {ParticipantsAtLocation} from 'src/container/rooms/participants/ParticipantsAtLocation'
import {TopicBreakoutCards} from 'src/container/rooms/participants/TopicBreakoutCards'
import {Phase, selectCurrentPhase} from 'src/state/phases'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import './ParticipantMap.css'

export const ParticipantMap: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const {t} = useTranslation()
    const phase: Phase | undefined = useAppSelector(selectCurrentPhase(workshopId))
    const dispatch = useAppDispatch()
    return <div className={'participant-map'}>
        <Flex direction={'row'} gap={'size-130'}>
            <Flex direction={'column'} UNSAFE_className={'two-column'}>
                <ParticipantsAtLocation
                    location={'plenary'}
                    header={
                        <>
                            <Heading>{t('participant-map.room-plenary')}</Heading>
                            {phase ? <Heading level={4}>{phase.name}</Heading> : <></>}
                        </>
                    }
                    buttons={<><Button variant={'secondary'} onPress={() => {
                        dispatch(push(`/workshops/${workshopId}/plenary`))
                    }}>{t('button.join-plenary')}</Button></>}
                />
                <ParticipantsAtLocation
                    location={'cafe'}
                    header={
                        <CafeHeader />
                    }
                    buttons={<><Button variant={'secondary'} onPress={() => {
                        dispatch(push(`/workshops/${workshopId}/cafe`))
                    }}>{t('button.join-cafe')}</Button></>}
                />
            </Flex>
            <Flex UNSAFE_className={'two-column'}>
                <TopicBreakoutCards workshopId={workshopId} />
            </Flex>
        </Flex>
    </div>
}


