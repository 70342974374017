import {invitationEventDispatcher} from '../workshop-event-dispatcher'
import {createAction} from '@reduxjs/toolkit'
import {Invitation} from './invitations.model'
import {toInvitation} from './invitations.service'
import {InvitationDto} from 'src/api'

export const InvitationUpdated = createAction<Invitation>('invitations/events/invitation-updated')

invitationEventDispatcher.registerActionCreator({
    type: 'InvitationUpdated',
    actionCreator: (event) =>
        InvitationUpdated(toInvitation(event.payload as InvitationDto))
})
