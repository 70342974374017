import {Divider, Flex, Heading} from '@adobe/react-spectrum'
import React, {useEffect} from 'react'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {TopicBreakoutCard} from 'src/container/rooms/participants/TopicBreakoutCard'
import {selectHasActiveTopicBreakouts} from 'src/state/participant-locations'
import {selectCurrentPhase} from 'src/state/phases'
import {loadTopics, selectTopicsForWorkshop} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const TopicBreakoutCards: React.FC<WithWorkshopId> = ({workshopId}) => {
    const phase = useAppSelector(selectCurrentPhase(workshopId))
    const topics = useAppSelector(selectTopicsForWorkshop(workshopId))
        .filter(topic => topic.status !== 'CLOSED' && topic.status !== 'DELETED')

    const hasActiveTopicBreakouts = useAppSelector(selectHasActiveTopicBreakouts(topics))

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadTopics({workshopId}))
    }, [])

    return phase && phase.breakout.currentModeOfInteractions !== 'none' && hasActiveTopicBreakouts ?
        <Flex direction={'column'} UNSAFE_className={'topic-cards'}>
            <div className={'header'}>
                <Heading>Breakout Sessions</Heading>
                <Divider size={'M'} marginBottom={'size-130'}/>
            </div>
            <ScrollContainer>
                <>{topics.map((topic, index) => <TopicBreakoutCard key={index} workshopId={workshopId} topic={topic}/>)}</>
            </ScrollContainer>
        </Flex> : <></>
}
