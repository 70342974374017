import React from 'react'
import {OpenMeetingWithRoles} from 'src/components/meetings/open/OpenMeetingWithRoles'
import {ParticipantRolesAdapter, selectLeadModerator} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const LeadModeratorOpenMeeting: React.FC<WithWorkshopId> = ({workshopId}) => {
    const moderator = useAppSelector(selectLeadModerator(workshopId))
    const roles = new ParticipantRolesAdapter(moderator ? {
        [moderator.id]: ['moderator']
    } : {})

    return <OpenMeetingWithRoles workshopId={workshopId} roles={roles}/>
}
