import React from 'react'
import Avatar from 'react-avatar-edit'

export const UserImageUpload: React.FC<{
    image: string | undefined
    setImage(image: string | undefined): void
}> = ({
    setImage
}) => {
    const onCrop = (base64EncodedImage: string) => {
        setImage(base64EncodedImage)
    }

    const onClose = () => {
        setImage(undefined)
    }
    const onBeforeFileLoad = (event: any) => {
        console.log('onBeforeFileLoad', event)
        // TODO check file size etc...
    }

    return <Avatar
        width={390}
        height={295}
        imageHeight={250}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
    />
}
