import React from 'react'
import {InactiveTopicRoom} from 'src/components/meetings/topic-room/topic-breakout/InactiveTopicRoom'
import {TopicRoomForTopicGeneration} from 'src/components/meetings/topic-room/topic-breakout/TopicRoomForTopicGeneration'
import {TopicRoomForTopicSession} from 'src/components/meetings/topic-room/topic-breakout/TopicRoomForTopicSession'
import {useWebsocketSubscription} from 'src/hooks'
import {useAppDispatch} from 'src/state/types'
import {TopicRoomEvent} from 'src/state/workshop.event'
import {WithPhase, WithTopic} from 'src/types'
import {TopicRoomEventTypeDto} from 'src/websocket/topic-room-event-types.dto'
import {topicTopicWithPhase} from 'src/websocket/workshop-topics'

export const TopicRoomWithActivePhase: React.FC<WithTopic & WithPhase> = ({
    topic, phase
}) => {
    const dispatch = useAppDispatch()

    useWebsocketSubscription<TopicRoomEventTypeDto>(topicTopicWithPhase(phase.workshopId, phase.id, topic.id),
        payload => {
            dispatch(TopicRoomEvent({
                ...payload,
                meta: {
                    workshopId: phase.workshopId,
                    phaseId: phase.id,
                    topicId: topic.id
                }
            }))
        })

    switch (phase.meetingVariant.type) {
    case 'topic-generation': {
        return <TopicRoomForTopicGeneration
            phase={phase}
            topic={topic}
        />
    }
    case 'topic-session': {
        return <TopicRoomForTopicSession
            phase={phase}
            topic={topic}
        />
    }
    default:
        return <InactiveTopicRoom
            workshopId={phase.workshopId}
            topic={topic}
        />
    }
}
