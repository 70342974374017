import Cancel from '@spectrum-icons/workflow/Cancel'
import Checkmark from '@spectrum-icons/workflow/Checkmark'
import React from 'react'
import {TopicStatus} from 'src/state/topics'

export const TopicStatusMarker: React.FC<{ topicStatus: TopicStatus }> = ({topicStatus}) => {
    switch (topicStatus) {
    case 'CLOSED': {
        return <div className='topic-room-header-closed'>
            <Cancel/>
        </div>
    }
    case 'ACCEPTED': {
        return <div className='topic-room-header-accepted'>
            <Checkmark/>
        </div>
    }
    default:
        return <></>
    }
}
