import {createSlice} from '@reduxjs/toolkit'
import {loadTopicAssignments, TopicAssignmentsUpdated} from 'src/state/topic-assignments'
import {moderatedWithRolesAdapter} from './moderated-with-roles.adapter'
import {ModeratedWithRoleState} from './moderated-with-roles.model'

export const initialState: ModeratedWithRoleState = moderatedWithRolesAdapter.getInitialState()
export const moderatedWithRolesSlice = createSlice({
    name: 'moderated-with-roles',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadTopicAssignments.fulfilled, (state, {payload: {phaseAndTopicId, activeRole}}) => {
                moderatedWithRolesAdapter.upsertOne(state, {id: phaseAndTopicId, activeRole})
            })
            .addCase(TopicAssignmentsUpdated, (state, {payload: {phaseAndTopicId, activeRole}}) => {
                moderatedWithRolesAdapter.upsertOne(state, {id: phaseAndTopicId, activeRole})
            })
    }
})
