import {createSlice, EntityAdapter} from '@reduxjs/toolkit'
import {loadTopicWithNotes, Topic, TopicCreated, TopicUpdated} from 'src/state/topics'
import {voteForTopic} from './topic-votes.actions'
import {topicVotesAdapter} from './topic-votes.adapter'
import {TopicVotes, TopicVotesState, Votes} from './topic-votes.model'

export const initialState: TopicVotesState = topicVotesAdapter.getInitialState()

export class TopicVoteStateService {
    constructor(private adapter: EntityAdapter<TopicVotes> = topicVotesAdapter) {
    }
    setVotes(state: TopicVotesState, topicWithVotes: Topic & Votes): void {
        this.adapter.upsertOne(state,
            {
                topicId: topicWithVotes.id,
                currentVotes: topicWithVotes.currentVotes,
                neededVotes: topicWithVotes.neededVotes
            })
    }
}

const stateService = new TopicVoteStateService()

export const topicVotesSlice = createSlice({
    name: 'topics',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(voteForTopic.fulfilled, (state, {payload: topicWithVotes}) => {
                stateService.setVotes(state, topicWithVotes)
            })
            .addCase(loadTopicWithNotes.fulfilled, (state, {payload: topicWithVotes}) => {
                stateService.setVotes(state, topicWithVotes)
            })
            .addCase(TopicCreated, (state, {payload: topicWithVotes}) => {
                stateService.setVotes(state, topicWithVotes)
            })
            .addCase(TopicUpdated, (state, {payload: topicWithVotes}) => {
                stateService.setVotes(state, topicWithVotes)
            })
    }
})
