import {createSlice} from '@reduxjs/toolkit'
import {loadInvitations} from './invitations.actions'
import {invitationsAdapter} from './invitations.adapter'
import {InvitationUpdated} from 'src/state/invitations/invitations.events'

export const initialState = invitationsAdapter.getInitialState()
export const invitationsSlice = createSlice({
    name: 'invitations',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadInvitations.fulfilled, (state, {payload: {invitations}}) => {
                invitationsAdapter.setAll(state, invitations)
            })
            .addCase(InvitationUpdated, (state, {payload: invitation}) => {
                invitationsAdapter.upsertOne(state, invitation)
            })
    }
})
