import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React, {useState} from 'react'
import {Time} from 'src/components/common'

export interface ChangeTime {
    hour: number,
    minute: number
}

export const PickableTime: React.FC<{
    time: string
    onChangeTime(changeTime: ChangeTime): void
}> = ({time, onChangeTime}) => {
    const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false)

    return <>
        <a onClick={() => setTimePickerOpen(!timePickerOpen)}><Time dateTime={time}/></a>
        <TimePicker
            open={timePickerOpen}
            onClose={() => setTimePickerOpen(false)}
            onOpen={() => setTimePickerOpen(true)}
            onChange={newTime => onChangeTime({
                hour: newTime.hours(),
                minute: newTime.minutes()
            })}
            defaultOpenValue={moment.utc(time).local()}
            value={moment.utc(time).local()}
            showSecond={false}
            hideDisabledOptions={true}
            clearIcon={<></>}
        />
    </>
}
