import {Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Heading, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {dismissErrors, ErrorType, selectErrors} from 'src/state/error'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export const ErrorDialog: React.FC = () => {
    const dispatch = useAppDispatch()
    const errors = useAppSelector(selectErrors)
    const {t} = useTranslation()
    const open = errors.length > 0

    return <DialogContainer onDismiss={() => dispatch(dismissErrors())}>
        {open && <Dialog>
            <Heading>{t('dialog.error.headline')}</Heading>
            <Divider/>
            <Content>
                <Text>{t('dialog.error.description')}</Text>
                <ol>
                    {errors.map((error, index) => <li key={index}><ErrorDescription error={error}/></li>)}
                </ol>
            </Content>
            <ButtonGroup>
                <Button variant="cta" onPress={() => {
                    dispatch(dismissErrors())
                }}>
                    {t('button.dismiss')}
                </Button>
            </ButtonGroup>
        </Dialog>}
    </DialogContainer>
}


export const ErrorDescription: React.FC<{error: ErrorType}> = ({error}) => {
    return <Text>
        {error.message}
    </Text>
}
