import {createSlice} from '@reduxjs/toolkit'
import {loadPhases, PhaseUpdated} from 'src/state/phases'
import {TopicAssignmentScheduled} from './schedule.events'
import {loadPersonalSchedule} from './schedule.actions'
import {scheduleAdapter} from './schedule.adapter'
import {ScheduleStateService} from './schedule.service'

export const initialState = scheduleAdapter.getInitialState()
const stateService = new ScheduleStateService(scheduleAdapter)

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadPersonalSchedule.fulfilled, (state, {payload: schedule}) => {
            stateService.setPersonalSchedule(schedule, state)
        }).addCase(loadPhases.fulfilled, (state, {payload}) => {
            stateService.setGlobalSchedule(payload, state)
        }).addCase(TopicAssignmentScheduled, (state, {payload}) => {
            stateService.setPersonalSchedule(payload, state)
        }).addCase(PhaseUpdated, (state, {payload}) => {
            stateService.updateSchedule(payload.phase, state)
        })
    }
})
