import React from 'react'
import styled from 'styled-components'

export const MainContent: React.FC = ({children}) => {
    return <div className='main-content'>
        <StyledMainContent>{children}</StyledMainContent>
    </div>
}

const StyledMainContent = styled.div`
padding: 0;
height: 100%;
width: 100%;
`
