import {createAsyncThunk} from '@reduxjs/toolkit'
import {WithWorkshopId} from 'src/types'
import {ScheduleItem} from './schedule.model'
import {ScheduleApiService} from './schedule.service'

const apiService = new ScheduleApiService()

export const loadPersonalSchedule = createAsyncThunk<ScheduleItem[], WithWorkshopId>(
    'schedule/loadPersonalSchedule',
    async ({workshopId}) => {
        return await apiService.loadPersonalSchedule(workshopId)
    }
)
