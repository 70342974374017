import {Divider, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {selectParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithParticipantId, WithWorkshopId} from 'src/types'
import {UpNextParticipant} from './UpNextParticipant'

export const UpNext: React.FC<WithWorkshopId
  & WithParticipantId
  & {
    label?: string
    onPressNext(): void
}> = ({
    workshopId,
    participantId,
    onPressNext,
    label
}) => {
    const participant = useAppSelector(selectParticipant(participantId))
    const {t} = useTranslation()
    return participant ? <div className={'up-next'}>
        <Text>{t('moderated-queue.up-next-label')}</Text>
        <Divider size='S' marginY='size-130'/>
        <UpNextParticipant workshopId={workshopId}
            label={label}
            participant={participant}
            onPressNext={onPressNext}
        />
    </div> : <></>
}


