import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {push} from 'connected-react-router'
import moment from 'moment'
import {WorkshopDto} from 'src/api/workshops/workshops.dto'
import {selectAuthenticatedUser, WithAuthState} from 'src/state/auth'
import {setLeftDrawer} from 'src/state/layout'
import {loadPhases} from 'src/state/phases/phases.actions'
import {selectWorkshopById, WithWorkshopsState, Workshop} from 'src/state/workshops'
import {toWorkshop} from 'src/state/workshops/workshops.service'
import {WithPhaseId, WithWorkshopId} from 'src/types'
import {PhaseDeletedPayload, PhasesUpdatedPayload} from 'src/websocket/workshop-event-types.dto'
import {workshopEventDispatcher} from '../workshop-event-dispatcher'
import {invisiblePhases, Phase, WithPhasesState} from './phases.model'
import {selectPhasesForWorkshop} from './phases.selectors'
import {toWorkshopPhase} from './phases.service'

function didStartPhase(state: WithPhasesState, newPhase: Phase) {
    const oldPhase = state.phases.entities[newPhase.id]
    if (!oldPhase) {
        return newPhase.status === 'started'
    }
    return oldPhase.status != 'started' && newPhase.status === 'started'
}

export const PhasesUpdated = createAsyncThunk<void, PhasesUpdatedPayload & WithWorkshopId>('workshop-phases/event/phases-updated',
    async (phasesUpdatedPayload, thunkAPI) => {
        const isPlenaryPhase = (phase: Phase) => !invisiblePhases.includes(phase.meetingVariant.type)
        const state = thunkAPI.getState() as WithPhasesState & WithWorkshopsState & WithAuthState
        let shouldRedirectToPlenary = false
        let shouldRedirectToCafe = true
        let shouldRedirectToControlCenter = false
        const isOwner = selectWorkshopById(phasesUpdatedPayload.workshopId)(state)?.owner
            === selectAuthenticatedUser(state).id

        phasesUpdatedPayload.content
            .sort((a, b) => moment.utc(a.startTime).valueOf() - moment.utc(b.startTime).valueOf())
            .forEach((phaseDto) => {
                const workshopPhase = toWorkshopPhase(phaseDto, phasesUpdatedPayload.workshopId)
                shouldRedirectToPlenary = shouldRedirectToPlenary
                    || (didStartPhase(state, workshopPhase) && isPlenaryPhase(workshopPhase))
                const currentPhases = selectPhasesForWorkshop(phasesUpdatedPayload.workshopId)
                thunkAPI.dispatch(PhaseUpdated({phase: workshopPhase, currentPhases: currentPhases(state)}))
                if(didStartPhase(state, workshopPhase) && workshopPhase.meetingVariant.type === 'topic-session') {
                    thunkAPI.dispatch(setLeftDrawer('open'))
                }
                if(isPlenaryPhase(workshopPhase) && workshopPhase.status === 'started') {
                    shouldRedirectToCafe = false
                }
                if(isOwner && workshopPhase.status === 'started' && !isPlenaryPhase(workshopPhase)) {
                    shouldRedirectToControlCenter = true
                    shouldRedirectToCafe = false
                }
            })
        if (shouldRedirectToCafe) {
            thunkAPI.dispatch(push(`/workshops/${phasesUpdatedPayload.workshopId}/cafe`))
        }
        if (shouldRedirectToPlenary) {
            thunkAPI.dispatch(push(`/workshops/${phasesUpdatedPayload.workshopId}/plenary`))
        }
        if (shouldRedirectToControlCenter) {
            thunkAPI.dispatch(push(`/workshops/${phasesUpdatedPayload.workshopId}/control-center`))
        }
    })

export type PhaseUpdatedPayload = {phase: Phase, currentPhases: Phase[]}
export const PhaseUpdated = createAction<PhaseUpdatedPayload>('workshop-phases/event/phase-updated')
export const PhaseDeleted = createAction<WithPhaseId & WithWorkshopId>('workshop-phases/event/phase-deleted')

workshopEventDispatcher.registerActionCreator({
    type: 'PhasesUpdated',
    actionCreator: event => PhasesUpdated({
        ...(event.payload as PhasesUpdatedPayload),
        workshopId: event.workshopId
    })
})

workshopEventDispatcher.registerActionCreator({
    type: 'PhaseDeleted',
    actionCreator: event => PhaseDeleted({
        phaseId: (event.payload as PhaseDeletedPayload).phaseId,
        workshopId: event.workshopId
    })
})

workshopEventDispatcher.registerActionCreator({
    type: 'WorkshopStarted',
    actionCreator: event => WorkshopStartedEvent(event.payload as WorkshopDto)
})

export const WorkshopStartedEvent = createAsyncThunk<Workshop, WorkshopDto>(
    'workshop-phases/event/workshop-started',
    (workshop, thunkAPI) => {
        thunkAPI.dispatch(loadPhases(workshop.id))
        return toWorkshop(workshop)
    })
