import {TopicAssignmentRoleDto, TopicAssignmentRoleWithParticipantsDto, TopicAssignmentsApi} from 'src/api'
import {TopicAssignment, TopicAssignmentRole, TopicAssignments} from './topic-assignments.model'

const DEFAULT_TOPIC_ASSIGNMENT_ROLE: TopicAssignmentRole = 'debater'

export class TopicAssignmentsApiService {
    constructor(
      private api: TopicAssignmentsApi = new TopicAssignmentsApi()
    ) {
    }

    async getTopicAssignments(workshopId: string, phaseId: string, topicId: string): Promise<TopicAssignments> {
        const topicAssignments = await this.api.getTopicAssignments(workshopId, phaseId, topicId)
        return this.toModel(phaseId, topicId, topicAssignments)
    }

    async getAllTopicAssignments(workshopId: string): Promise<TopicAssignment[]> {
        const topicAssignments = await this.api.getAllTopicAssignments(workshopId)
        return topicAssignments.map(topicAssignment => <TopicAssignment>{
            id: `${topicAssignment.phaseId}-${topicAssignment.topicId}-${topicAssignment.participantId}`,
            topicId: topicAssignment.topicId,
            phaseId: topicAssignment.phaseId,
            participantId: topicAssignment.participantId,
            role: topicAssignment.role
        })
    }

    public toModel(
        phaseId: string,
        topicId: string,
        topicAssignments: TopicAssignmentRoleWithParticipantsDto[]
    ): TopicAssignments {
        const phaseAndTopicId = `${phaseId}-${topicId}`
        const assignments: TopicAssignment[] = topicAssignments
            .map(dto => this.getAssignments(dto, phaseAndTopicId, phaseId, topicId))
            .reduce((array, assignments) => {
                return [...array, ...assignments]
            }, <TopicAssignment[]>[])
        const activeAssignment = topicAssignments.find(assignment => assignment.active)
        const activeRole = (activeAssignment?.role || DEFAULT_TOPIC_ASSIGNMENT_ROLE) as TopicAssignmentRole
        return {phaseAndTopicId, assignments, activeRole}
    }

    private getAssignments(
        dto: TopicAssignmentRoleWithParticipantsDto,
        phaseAndTopicId: string,
        phaseId: string,
        topicId: string
    ) {
        return dto.participants.map(participantId =>
            this.toTopicAssignment(phaseAndTopicId, participantId, phaseId, topicId, dto.role))
    }

    private toTopicAssignment(
        phaseAndTopicId: string,
        participantId: string,
        phaseId: string,
        topicId: string,
        role: TopicAssignmentRoleDto
    ): TopicAssignment {
        return {
            id: `${phaseAndTopicId}-${participantId}`,
            phaseId,
            topicId,
            participantId,
            role
        }
    }

    async setActiveRole(workshopId: string, phaseId: string, topicId: string, activeRole: TopicAssignmentRole): Promise<void> {
        await this.api.setActiveRole(workshopId, phaseId, topicId, activeRole)
    }
}
