import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {TopicCard} from 'src/components/meetings/plenary/topic-generation/open/content/TopicCard'
import {Topic} from 'src/state/topics'
import {WithWorkshopId} from 'src/types'

export const TopicCards: React.FC<WithWorkshopId & { topics: Topic[] }> = ({
    workshopId,
    topics
}) => {
    return <Flex direction='row' wrap='wrap' gap='size-200' UNSAFE_className={'topic-cards'}>
        {topics.map(topic => <TopicCard key={topic.id} topic={topic} workshopId={workshopId}/>)}
    </Flex>

}
