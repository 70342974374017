import { Reducer } from '@reduxjs/toolkit'
import {AnyAction} from 'redux'

export function loadLocalStorageState<S>(key: string, version: number): S | undefined {
    const item = window.localStorage.getItem(key)
    if (!item) {
        return
    }
    const versionedState: VersionedState<S> = JSON.parse(item)
    return versionedState.version === version ? versionedState.state : undefined // TODO maybe migrate?
}

export type VersionedState<S> = {
    version: number,
    state: S
}

export function createLocalStorageReducer<S>(
    reducer: Reducer<S>,
    key: string,
    version: number,
    serialize = JSON.stringify,
    storage = window.localStorage
): Reducer<S> {
    if (typeof storage !== 'object' || storage === null) {
        return reducer
    }

    return (state: S | undefined, action: AnyAction) => {
        const newState = reducer(state, action)
        if (state !== newState) {
            storage.setItem(key, serialize({version, state: newState}))
        }
        return newState
    }
}
