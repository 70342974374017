import {PhasesApi} from 'src/api/phases/phases.api'
import {BreakoutDto, MeetingVariantDto, PhaseDto} from 'src/api/phases/phases.dto'
import {Breakout, MeetingVariant, Phase} from './phases.model'

function toMeetingVariantModel(meetingVariant: MeetingVariantDto): MeetingVariant {
    return {
        type: meetingVariant.type,
        currentModeOfInteraction: meetingVariant.currentModeOfInteraction,
        availableModeOfInteractions: [meetingVariant.currentModeOfInteraction] // TODO currently only the one available
    }
}

function toBreakoutModel(breakout: BreakoutDto): Breakout {
    return {
        currentModeOfInteractions: breakout.currentModeOfInteraction,
    }
}

export function toWorkshopPhase(phaseDto: PhaseDto, workshopId: string): Phase {
    return {
        id: phaseDto.id,
        workshopId: workshopId,
        name: phaseDto.name,
        description: phaseDto.description,
        startTime: phaseDto.startTime,
        endTime: phaseDto.endTime,
        status: phaseDto.status,
        meetingVariant: toMeetingVariantModel(phaseDto.meetingVariant),
        breakout: toBreakoutModel(phaseDto.breakout)
    }
}

function toMeetingVariantDto(meetingVariant: MeetingVariant): MeetingVariantDto {
    return {
        type: meetingVariant.type,
        currentModeOfInteraction: meetingVariant.currentModeOfInteraction
    }
}

function toBreakoutDto(breakout: Breakout): BreakoutDto {
    return {
        currentModeOfInteraction: breakout.currentModeOfInteractions
    }
}

export function toWorkshopPhaseDto(phase: Phase): PhaseDto {
    return {
        id: phase.id,
        name: phase.name,
        description: phase.description,
        startTime: phase.startTime,
        endTime: phase.endTime,
        status: phase.status,
        meetingVariant: toMeetingVariantDto(phase.meetingVariant),
        breakout: toBreakoutDto(phase.breakout)
    }
}

export class PhasesService {
    constructor(
      private api: PhasesApi = new PhasesApi()
    ) {}

    async getPhases(workshopId: string): Promise<Phase[]> {
        const phaseDtos = await this.api.getPhases(workshopId)
        return phaseDtos.map(phaseDto => toWorkshopPhase(phaseDto, workshopId))
    }

    async updatePhase(phase: Phase): Promise<Phase> {
        const updatedPhase = await this.api.updatePhase(phase.workshopId, toWorkshopPhaseDto(phase))
        return toWorkshopPhase(updatedPhase, phase.workshopId)
    }

    async addPhase(newWorkshopPhase: Phase): Promise<Phase> {
        const newPhase = await this.api.addPhase(newWorkshopPhase.workshopId, toWorkshopPhaseDto(newWorkshopPhase))
        return toWorkshopPhase(newPhase, newWorkshopPhase.workshopId)
    }

    async deletePhase(phase: Phase): Promise<void> {
        await this.api.deletePhase(phase.workshopId, phase.id)
    }
}
