import {createAction} from '@reduxjs/toolkit'
import {ParticipantUpdatedEventDto} from 'src/websocket/workshop-event-types.dto'
import {workshopEventDispatcher} from '../workshop-event-dispatcher'
import {Participant} from './participants.model'
import {toParticipant} from './participants.service'

export const ParticipantUpdated = createAction<Participant>('participants/ws/event/profileUpdated')

workshopEventDispatcher.registerActionCreator({
    type: 'ParticipantUpdated',
    actionCreator: (event) => {
        return ParticipantUpdated(toParticipant(event.workshopId, (event as ParticipantUpdatedEventDto).payload))
    }
})
