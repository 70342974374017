import {WithDeviceSettingsState} from './device-settings.model'

export const selectVideoInputDeviceId: (state: WithDeviceSettingsState) => string | undefined = state =>
    state.deviceSettings.devices.videoInputDeviceId

export const selectAudioInputDeviceId: (state: WithDeviceSettingsState) => string | undefined = state =>
    state.deviceSettings.devices.audioInputDeviceId

export const selectAudioOutputDeviceId: (state: WithDeviceSettingsState) => string | undefined = state =>
    state.deviceSettings.devices.audioOutputDeviceId

export const selectIsMuted: (state: WithDeviceSettingsState) => boolean = state =>
    state.deviceSettings.active.mute

export const selectIsVideoActive: (state: WithDeviceSettingsState) => boolean = state =>
    state.deviceSettings.active.video

export const selectHasDeviceDialogBeenShown: (state: WithDeviceSettingsState) => boolean = state =>
    state.deviceSettings.hasDialogBeenShown
