import {Button, Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {TopicVotingState} from 'src/components/meetings/plenary/topic-generation/open/content/TopicVotingState'
import {selectTopicVotes, voteForTopic} from 'src/state/topic-votes'
import {selectIsTopicOwner} from 'src/state/topics'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId, WithPhaseId} from 'src/types'

export const TopicVotes: React.FC<WithWorkshopId & Partial<WithPhaseId> & WithTopic> = ({workshopId, phaseId, topic}) => {
    const dispatch = useAppDispatch()
    const vote = () => dispatch(voteForTopic({workshopId, phaseId: phaseId!, topicId: topic.id}))
    const {t} = useTranslation()
    const isTopicOwner = useAppSelector(selectIsTopicOwner(topic.id))
    const topicVotes = useAppSelector(selectTopicVotes(topic.id))

    return <View>
        <Flex direction='row' gap='size-200'>
            {topicVotes ?
                <View width='size-2000'><TopicVotingState
                    current={topicVotes.currentVotes}
                    needed={topicVotes.neededVotes}/>
                </View> : <></>}
            <View>{!isTopicOwner && phaseId && topic.status === 'VOTING' &&
            <Button variant='primary' marginTop='size-100' onPress={vote}>{t('button.like')}</Button>
            }</View>
        </Flex>
    </View>
}
