import {TopicRoomEventTypeDto} from 'src/websocket/topic-room-event-types.dto'
import {getSchema, ValidationFactory} from '../tools/validation'
import {WorkshopEventDto} from 'src/websocket/workshop-event-types.dto'
import {PhaseEventTypeDto} from 'src/websocket/phase-event-types.dto'
import {CallsEventDto} from 'src/websocket/call-event-types.dto'
import {InvitationEventTypeDto} from 'src/websocket/invitation-event-type.dto'

export const validateWorkshopEventDto: ValidationFactory<WorkshopEventDto> =
    () => getSchema<WorkshopEventDto>('WorkshopEventDto')
export const validateTopicRoomEventDto: ValidationFactory<TopicRoomEventTypeDto> =
    () => getSchema<TopicRoomEventTypeDto>('TopicRoomEventTypeDto')
export const validatePhaseEventDto: ValidationFactory<PhaseEventTypeDto> =
    () => getSchema<PhaseEventTypeDto>('PhaseEventTypeDto')
export const validateCallsEventDto: ValidationFactory<CallsEventDto> =
    () => getSchema<CallsEventDto>('CallsEventDto')
export const validateInvitationEventDto: ValidationFactory<InvitationEventTypeDto> =
    () => getSchema<InvitationEventTypeDto>('InvitationEventTypeDto')
