import {selectAuthenticatedUser, WithAuthState} from 'src/state/auth'
import {WithIncomingCallId, WithMeetingId, WithParticipantId} from 'src/types'
import {JoinGroupAcceptedEventPayloadDto, JoinGroupRejectedEventPayloadDto} from 'src/websocket/call-event-types.dto'
import {callsEventDispatcher} from '../workshop-event-dispatcher'
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {joinExistingCall, selectIsOutgoingCall, setOutgoingCall, WithCallsState} from 'src/state/calls'

export type IncomingCallDto = {
    participantIds: string[]
    meetingId: string
    requestToJoinGroup?: boolean
}

export const GroupCallRequestedOrReceived = createAsyncThunk<void, IncomingCallDto>(
    'calls/event/calling',
    async (groupCallDto, thunkAPI) => {
        const state = thunkAPI.getState() as WithCallsState
        const hasNoOutgoingCallWithId = !selectIsOutgoingCall(groupCallDto.meetingId)(state)
        if (hasNoOutgoingCallWithId) {
            thunkAPI.dispatch(CallReceived(groupCallDto))
        }}
)
callsEventDispatcher.registerActionCreator({
    type: 'InviteToGroupCall',
    actionCreator: event => GroupCallRequestedOrReceived({
        ...(event.payload as IncomingCallDto)
    })
})

callsEventDispatcher.registerActionCreator({
    type: 'JoinGroupAccepted',
    actionCreator: event => JoinGroupAccepted({
        ...(event.payload as JoinGroupAcceptedEventPayloadDto)
    })
})

callsEventDispatcher.registerActionCreator({
    type: 'JoinGroupRejected',
    actionCreator: event => JoinGroupRejected({
        ...(event.payload as JoinGroupRejectedEventPayloadDto)
    })
})


export const JoinGroupAccepted = createAsyncThunk<void, JoinGroupAcceptedEventPayloadDto>(
    'calls/event/join-group-call-accepted',
    async ({meetingId}, thunkAPI) => {
        const state = thunkAPI.getState() as WithCallsState & WithAuthState
        const participantIds = [...(state.calls.entities[meetingId]?.participantIds || []), selectAuthenticatedUser(state).id]
        thunkAPI.dispatch(joinExistingCall({
            id: meetingId,
            participantIds: participantIds
        }))
        thunkAPI.dispatch(setOutgoingCall(undefined))
    }
)

export const JoinGroupRejected = createAsyncThunk<void, JoinGroupRejectedEventPayloadDto>(
    'calls/event/join-group-call-rejected',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({meetingId}, thunkAPI) => {
        thunkAPI.dispatch(setOutgoingCall(undefined))
    }
)

export const CallReceived = createAction<IncomingCallDto>('calls/event/call-received')

export const JoinGroupRequestHandled = createAction<WithIncomingCallId & WithMeetingId & WithParticipantId>('calls/event/join-group-request-handled')


