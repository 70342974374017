import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {WorkshopPhaseControls} from 'src/components/control/workshop/WorkshopPhaseControls'
import {ContentWithToolbox} from 'src/components/layout/ContentWithToolbox'
import {Toolbox} from 'src/components/layout/Toolbox'
import {selectMyParticipant} from 'src/state/participants'
import {selectAllTopicAssignments, selectTopicAssignmentRoles} from 'src/state/topic-assignments'
import { useAppSelector} from 'src/state/types'
import {WithTopic, WithPhase} from 'src/types'
import {AddNoteButton} from './AddNoteButton'
import {TopicDescription} from './TopicDescription'
import {TopicWhiteBoard} from './TopicRoomForTopicGeneration'
import {TopicVideoConference} from './TopicVideoConference'

export const TopicRoomForTopicSession: React.FC<WithTopic & WithPhase> = ({
    topic,
    phase
}) => {
    const roles = useAppSelector(selectTopicAssignmentRoles(phase.id, topic.id))
    const allTopicAssignments = useAppSelector(selectAllTopicAssignments)

    const me = useAppSelector(selectMyParticipant)
    const isTopicModerator = roles.isModerator(me) || (allTopicAssignments.length === 0 && me?.id === topic.owner)

    const toolbox = isTopicModerator ? <Toolbox>
        <AddNoteButton workshopId={phase.workshopId} topicId={topic.id}/>
        <WorkshopPhaseControls workshopId={phase.workshopId} phase={phase}/>
    </Toolbox> : <></>

    return <ContentWithToolbox toolbox={toolbox}>
        <TopicVideoConference
            phase={phase}
            topic={topic}
        />
        <Flex direction='column' gap={'size-225'}>
            <TopicDescription
                workshopId={phase.workshopId}
                topic={topic}
                showVotes={false}
                showStatus={false}
                canOwnerLeaveTopic={true}
            />
            <TopicWhiteBoard
                workshopId={phase.workshopId}
                topic={topic}
                canEditNotes={isTopicModerator || false}/>
        </Flex>
    </ContentWithToolbox>
}
