import {Button} from '@adobe/react-spectrum'
import React from 'react'
import {Link} from 'react-router-dom'
import {WithWorkshopId} from 'src/types'

export const BackToWorkshopButton: React.FC<WithWorkshopId> = ({workshopId}) => {
    return <Link to={`/workshops/${workshopId}/plenary`}><Button variant='primary'
        UNSAFE_className="return-to-plenary-btn">Back to
        plenary</Button></Link>
}
