import {WithAuthState} from 'src/state/auth'
import {WithModeratedWithRoleState} from 'src/state/moderated-with-roles'
import {ParticipantRolesAdapter} from 'src/state/participants'
import {WithPhasesState} from 'src/state/phases'
import {selectTopicAssignmentRolesById, WithTopicAssignmentsState} from 'src/state/topic-assignments'
import {WithWorkshopsState} from 'src/state/workshops'
import {MeetingDeviceSettings, WithMeetingsState} from '../meetings.model'
import {AbstractDeviceSettingsStrategy} from './abstract-device-settings-strategy'
import {defaultConfig, silence} from './defaults'

export class ModeratedWithRolesDeviceSettingsStrategy extends AbstractDeviceSettingsStrategy<WithMeetingsState
  & WithWorkshopsState
  & WithPhasesState
  & WithModeratedWithRoleState
  & WithTopicAssignmentsState
  & WithAuthState> {

    isResponsible(): boolean {
        return this.modeOfInteraction === 'moderated-with-roles'
    }

    getDeviceSettings(): MeetingDeviceSettings {
        const meeting = this.activeMeeting
        if (!meeting || !this.hasParticipantId()) {
            return defaultConfig
        }
        if (meeting.hasModeratedRolesId()) {
            const roles = selectTopicAssignmentRolesById(meeting.moderatedWithRolesId)(this.state)
            return this.enableDevicesForModeratorOrActiveRole(meeting, roles)
        }
        return defaultConfig
    }

    enableDevicesForModeratorOrActiveRole(
        meeting: { moderatedWithRolesId: string },
        roles: ParticipantRolesAdapter,
    ): MeetingDeviceSettings {
        if (roles.isModerator(this.participantId)) {
            return defaultConfig
        }
        return this.enableDevicesForActiveRole(meeting, roles)
    }

    enableDevicesForActiveRole(
        meeting: { moderatedWithRolesId: string },
        roles: ParticipantRolesAdapter,
    ): MeetingDeviceSettings {
        const moderatedWithRoles = this.state.moderatedWithRoles.entities[meeting.moderatedWithRolesId]
        if (moderatedWithRoles?.activeRole && roles.hasRole(this.participantId, moderatedWithRoles.activeRole)) {
            return defaultConfig
        }
        return {
            ...silence,
            permissionsToSpeakMightBeGranted: roles.hasRole(this.participantId, 'moderator')
              || roles.hasRole(this.participantId, 'debater')
              || roles.hasRole(this.participantId, 'evaluator')
        }
    }
}
