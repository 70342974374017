import {LeftDrawerStatus, MeetingLayout, NotificationDrawerStatus, SearchDrawerStatus} from './layout.model'

export type DrawerConfig = {
    left: Record<LeftDrawerStatus, Size>
    meeting: Record<MeetingLayout, Size>
    search: Record<SearchDrawerStatus, Size>
    notification: Record<NotificationDrawerStatus, Size>
}

export const drawerSize: DrawerConfig = {
    left: {
        open: {value: 302, unit: 'px'},
        collapsed: {value: 49, unit: 'px'},
    },
    meeting: {
        large: {value: 100, unit: 'vw'},
        medium: {value: 461, unit: 'px'},
        small: {value: 218, unit: 'px'},
        hidden: {value: 0}
    },
    search: {
        open: {value: 216, unit: 'px'},
        hidden: {value: 0},
    },
    notification: {
        open: {value: 60, unit: 'px'},
        hidden: {value: 0},
    }
}

export interface Size {
    value: number
    unit?: string
}

export type FooterStatus = 'visible' | 'hidden'

export type LayoutConfig = {
    header: {
        height: Size
    }
    footer: Record<FooterStatus, {
        height: Size
    }>
} & DrawerConfig


export const layoutConfig: LayoutConfig = {
    header: {
        height: {value: 50, unit: 'px'},
    },
    footer: {
        visible: {
            height: {value: 60, unit: 'px'}
        },
        hidden: {
            height: {value: 0}
        }
    },
    ...drawerSize
}
