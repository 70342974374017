export const topicTopic: (workshopId: string, topicId: string) => string
    = (workshopId, topicId) => `/topic/workshops/${workshopId}/topics/${topicId}`

export const topicTopicWithPhase: (workshopId: string, phaseId: string, topicId: string) => string
  = (workshopId,phaseId, topicId) => `/topic/workshops/${workshopId}/phases/${phaseId}/topics/${topicId}`

export const workshopTopic: (id: string | undefined) => string | undefined = id =>
    id ? `/topic/workshops/${id}` : undefined

export const workshopMyTopic: (id: string | undefined, myUserId: string | undefined) => string | undefined =
    (id, myUserId) =>
        (id && myUserId) ? `/topic/workshops/${id}/participants/${myUserId}` : undefined

export const workshopPhaseTopic: (workshopId: string, phaseId: string) => string = (workshopId, phaseId) =>
    `/topic/workshops/${workshopId}/phases/${phaseId}`

