import React from 'react'
import {selectIsWorkshopControlsRequired} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import {LeadModeratorControls} from '../LeadModeratorControls'
import {selectIsWorkshopFinished} from 'src/state/phases'
import {useTranslation} from 'react-i18next'

export const WorkshopControls: React.FC = () => {
    const shouldRender = useAppSelector(selectIsWorkshopControlsRequired)
    const isWorkshopFinished = useAppSelector(selectIsWorkshopFinished)
    const {t} = useTranslation()

    if(isWorkshopFinished)
        return <>{t('text.workshop-ended')}</>
    return shouldRender ? <>
        <LeadModeratorControls />
    </> : <></>
}
