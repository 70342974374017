import {Divider, View} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {BreakoutParticipantIcons} from 'src/components/meetings/plenary/topic-generation/open/content/BreakoutParticipantIcons'
import {selectParticipantsAtLocation} from 'src/state/participant-locations'
import {useAppSelector} from 'src/state/types'
import {WithTopic} from 'src/types'

export const DiscussingParticipants: React.FC<WithTopic> = ({topic}) => {
    const {t} = useTranslation()
    const meetingParticipants = useAppSelector(selectParticipantsAtLocation('topic-breakout', topic.id))
    return meetingParticipants.length > 0 ?
        <>
            <Divider size={'S'} marginTop='size-130' marginBottom='size-130'/>
            <View UNSAFE_className={'topic-discussing-participants'}>
                {t('topic-card.now-discussing-participants')}
                <BreakoutParticipantIcons meetingId={topic.id} participants={meetingParticipants}/>
            </View>
        </> : <></>
}
