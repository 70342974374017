import React from 'react'
import {useParams} from 'react-router-dom'
import {selectCurrentPhase, Phase} from 'src/state/phases'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import {VideoConferenceForId} from './VideoConferenceForId'

export const VideoConferenceForPhase: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const phase: Phase | undefined = useAppSelector(selectCurrentPhase(workshopId))

    return <>
        {phase && phase.meetingVariant.currentModeOfInteraction !== 'none' ? <VideoConferenceForId
            workshopId={workshopId}
            meetingId={phase.id}
            modeOfInteraction={phase.meetingVariant.currentModeOfInteraction}
            meetingObject={{phaseId: phase.id}}
        /> : <></>}
    </>
}
