import {Flex} from '@adobe/react-spectrum'
import {MeetingStatus, useContentShareState, useMeetingStatus} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {AudioInputControl} from 'src/components/control/meeting/active-meeting/AudioInputControl'
import {ContentShareControl} from 'src/components/control/meeting/active-meeting/ContentShareControl'
import {SettingsControl} from 'src/components/control/meeting/active-meeting/SettingsControl'
import {VideoInputControl} from 'src/components/control/meeting/active-meeting/VideoInputControl'
import {WithRoles, WithWorkshopId} from 'src/types'
import {NumberOfParticipantsHeader} from './NumberOfParticipantsHeader'
import {ParticipantBadgeRoster} from './ParticipantBadgeRoster'
import {RosterGrid} from './RosterGrid'
import './RosterGrid.css'
import {RosterGridMeetingControls} from './RosterGridMeetingControls'
import {ModeratorVideo} from './video/ModeratorVideo'

export const PresentationRosterGrid: React.FC<WithWorkshopId & WithRoles & {
    presenter: boolean
}> = ({workshopId, roles, presenter}) => {
    const {tileId: contentShareTileId} = useContentShareState()

    return <RosterGrid roles={roles}
        displayLocalVideo={false}
        header={<NumberOfParticipantsHeader />}
        stage={contentShareTileId !== null ? <Flex justifyContent={'center'} alignItems={'center'}><ModeratorVideo displayAbsent={true} workshopId={workshopId} roles={roles} /></Flex> : undefined}
        participantRoster={<ParticipantBadgeRoster
            workshopId={workshopId}
            roles={roles}
            excludeRoles={['moderator']}
        />}
        meetingControls={presenter ? <RosterGridMeetingControls><PresentationMeetingControls/></RosterGridMeetingControls> : undefined}
    >
    </RosterGrid>
}

export const PresentationMeetingControls: React.FC = () => {
    const meetingStatus = useMeetingStatus()
    return meetingStatus === MeetingStatus.Succeeded ? <div className='meeting-controls'>
        <VideoInputControl/>
        <AudioInputControl/>
        <ContentShareControl/>
        <SettingsControl/>
    </div> : <></>
}
