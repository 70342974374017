import React from 'react'
import styled from 'styled-components'

export const ContentFrame: React.FC = ({children}) => {
    return <StyledContent className='content-frame'>
        {children}
    </StyledContent>
}

const StyledContent = styled.div`
display: flex;
flex-direction: column;
padding:0;
margin-top: 0;
margin-bottom: 0;
transition: all 0.3s linear;
overflow-y: hidden;
`
