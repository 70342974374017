import {ActionButton, ButtonGroup, Divider, Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {Link} from 'react-router-dom'
import {Card} from 'src/components/common'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {CancelIcon} from 'src/components/icons/CancelIcon'
import {CheckIcon} from 'src/components/icons/CheckIcon'
import {DiscussingParticipants} from './DiscussingParticipants'
import {selectParticipant} from 'src/state/participants'
import {selectTopicVotes} from 'src/state/topic-votes'
import {useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId} from 'src/types'
import {TopicVotingState} from './TopicVotingState'

export const TopicCard: React.FC<WithWorkshopId & WithTopic> = ({workshopId, topic}) => {
    const owner = useAppSelector(selectParticipant(topic.owner))
    const topicVotes = useAppSelector(selectTopicVotes(topic.id))

    const topicIcon: () => React.ReactNode = () => {
        switch (topic.status) {
        case 'ACCEPTED':
            return <div className='card-icon-topic-voting card-icon-topic-voting-accepted'><CheckIcon size={'small'} /></div>
        case 'CLOSED':
            return <div className='card-icon-topic-voting card-icon-topic-voting-closed'><CancelIcon size={'small'} /></div>
        default:
            return <div className='card-icon-topic-voting' />
        }
    }

    return <Card className={'topic-card'}>{{
        leftHeaderIcon: topicIcon(),
        rightHeaderIcon: owner ? <ParticipantIconWithCircle participant={owner} className={'card-icon-participant'}/> : <></>,
        icon: <></>,
        title: topic.title,
        width: 'size-3600',
        content: <Flex direction='column' height='size-3000'>
            <View flex UNSAFE_className={'description'}>{topic.description}</View>
            { topicVotes ? <>
                <Divider  size='S' marginTop='size-130' />
                <View UNSAFE_className="voting-row"><TopicVotingState
                    current={topicVotes.currentVotes}
                    needed={topicVotes.neededVotes}/>
                </View>
            </> : <></>}
            <DiscussingParticipants topic={topic} />
        </Flex>,
        buttons: <ButtonGroup>
            <Link to={`/workshops/${workshopId}/topics/${topic.id}`}>
                <ActionButton>Enter Topic</ActionButton>
            </Link>
        </ButtonGroup>
    }}</Card>
}

