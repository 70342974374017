import React from 'react'
import {Card} from 'src/components/common'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {selectParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithTopic} from 'src/types'

export const PitchTopicCard: React.FC<WithTopic> = ({topic}) => {
    const owner = useAppSelector(selectParticipant(topic.owner))

    return <Card className={'topic-card'}>{{
        rightHeaderIcon: owner ?
            <ParticipantIconWithCircle participant={owner} className={'card-icon-participant'}/> : <></>,
        title: topic.title,
        content: <></>,
    }}</Card>
}
