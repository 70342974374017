import React, {ReactNode} from 'react'
import {PersonIcon} from 'src/components/icons'
import './ParticipantVideoAvatar.css'
import {WithParticipant} from 'src/types'

export const ParticipantVideoAvatar: React.FC<WithParticipant & {
    iconSymbol?: ReactNode,
}> = ({participant, iconSymbol}) => {
    return <div className='participant-video-avatar'>
        {iconSymbol ? <div className={'icon-symbol'}>{iconSymbol}</div> : <></>}
        {participant.imageUrl ? <img src={participant.imageUrl} alt='Participant Image'/> : <div className={'avatar-icon-container'}><div><PersonIcon className='avatar-icon' /></div></div>}
    </div>
}
