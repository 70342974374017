import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {CafeMeeting} from './CafeMeeting'
import {selectCurrentCall} from 'src/state/calls'
import {selectCafeBreakoutMeetings} from 'src/state/participant-locations'
import {selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export const CafeMeetings: React.FC<WithWorkshopId> = ({workshopId}) => {
    const currentCall = useAppSelector(selectCurrentCall)
    const meetings = useAppSelector(selectCafeBreakoutMeetings)
    const participantsInCall = new Set(currentCall ? currentCall.participantIds : [])
    const me = useAppSelector(selectMyParticipant)
    const canStartGroupCall = !!me && !participantsInCall.has(me.id)

    return <Flex wrap gap={'size-130'}>
        <>{meetings.map((meeting, index) => <CafeMeeting key={index} meeting={meeting} workshopId={workshopId}
            canStartCall={canStartGroupCall}/>)}</>
    </Flex>
}
