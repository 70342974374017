import {useFilteredParticipants} from './use-filtered-participants'
import {MeetingParticipant} from 'src/state/meetings'
import {ParticipantRolesAdapter, selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import {ParticipantRosterSortComparator} from './sort'

export function useSortedAndFilteredParticipants(
    workshopId: string,
    roles: ParticipantRolesAdapter,
    includeRoles?: string[],
    excludeRoles?: string[],
    participantSortComparator?: ParticipantRosterSortComparator
): MeetingParticipant[] {
    const activeParticipants = useFilteredParticipants(workshopId, roles, includeRoles, excludeRoles)
    const me = useAppSelector(selectMyParticipant)

    if (participantSortComparator && me) {
        activeParticipants
            .sort(participantSortComparator(me))
    }
    return activeParticipants
}

