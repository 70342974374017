import {createAsyncThunk} from '@reduxjs/toolkit'
import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import {Auth} from 'aws-amplify'
import {push} from 'connected-react-router'
import {ProfileApi} from 'src/api'
import {UserProfile, UserProfileUpdateModel, WithAuthState} from './auth.model'

const profileApi = new ProfileApi()

function extractUserData(cognitoUser: CognitoUser): Promise<CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((err, result) => {
            if (err) {
                reject(err)
            } else {
                resolve(result!)
            }
        })
    })
}

async function toUser(cognitoUser: CognitoUser): Promise<Pick<UserProfile, 'name'>> {
    const userData = await extractUserData(cognitoUser)
    return ({
        name: userData.find(data => data.getName() === 'name')!.getValue(),
    })
}

async function loginInBackend(): Promise<UserProfile> {
    const login = await new ProfileApi().getProfile()
    return {
        id: login.id,
        name: login.name,
        biography: login.biography,
        imageUrl: login.imageUrl
    }
}

function hex2a(hex: string): string {
    let str = ''
    for (let i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
    return str
}

export const login = createAsyncThunk<UserProfile | undefined>(
    'user/login',
    async (arg, thunkAPI) => {
        const state: WithAuthState = thunkAPI.getState() as WithAuthState
        if (!state.auth.user) {
            const loginInfo = await getCognitoUser()
            if (loginInfo) {
                const partialUserFromCognito = await toUser(loginInfo.cognitoUser)
                const user = await loginInBackend()
                if (loginInfo.returnUrl) {
                    thunkAPI.dispatch(push(loginInfo.returnUrl))
                }
                return {...partialUserFromCognito, ...user}
            } else {
                await Auth.federatedSignIn({
                    customProvider: 'COGNITO',
                    customState: window.location.hash.substr(1)
                })
            }
        }
    })

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
        return Auth.signOut()
    })

function decodeCustomCognitoState(): string | undefined {
    const state = new URLSearchParams(window.location.search).get('state')
    if (state) {
        const encodedHref = (state?.split('-'))?.[1]
        if (encodedHref) {
            return hex2a(encodedHref)
        }
    }
}

async function getCognitoUser(): Promise<{cognitoUser: CognitoUser, returnUrl: string | undefined} | undefined> {
    try {
        const returnUrl = decodeCustomCognitoState()
        const cognitoUser = await Auth.currentAuthenticatedUser()
        return {cognitoUser, returnUrl}
    } catch (error) {
        return
    }
}

export const updateUserProfile = createAsyncThunk<UserProfile, UserProfileUpdateModel>(
    'user/update-profile',
    (profileUpdate) => profileApi.updateProfile({
        biography: profileUpdate.biography,
        imageData: profileUpdate.image
    }))
