import {match, matchPath} from 'react-router-dom'
import {WithRouterState} from 'src/state/types'
import {WithWorkshopsState} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'
import {ToolbarAction} from './toolbar-action'
import {WithAuthState} from 'src/state/auth'

export class ManageWorkshopToolbarAction implements ToolbarAction {
    canBeUsed(state: WithRouterState & WithWorkshopsState & WithAuthState): boolean {
        if (this.isOnRoute(state, '/workshops/:workshopId/configure')) {
            return false
        }
        const match = this.isOnRoute(state, '/workshops/:workshopId')
        return Boolean(match
          && this.isWorkshopOwner(state, match.params.workshopId))
    }

    private isOnRoute(state: WithRouterState, path: string): match<WithWorkshopId> | null {
        return matchPath<WithWorkshopId>(
            state.router.location.pathname,
            {path}
        )
    }

    private isWorkshopOwner(state: WithRouterState & WithWorkshopsState & WithAuthState, workshopId: string): boolean {
        const workshop = state.workshops.entities[workshopId]
        return Boolean(workshop && state.auth.user && state.auth.user.id === workshop.owner)
    }
}

