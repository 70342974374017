import {API} from 'aws-amplify'
import {Response} from 'src/api/response'
import {validate} from 'src/tools/validation'
import {
    GetAllTopicAssignmentsDto,
    GetPersonalTopicAssignmentsDto,
    GetTopicAssignmentsDto,
    MyTopicAssignmentDto, TopicAssignmentDto,
    TopicAssignmentRoleWithParticipantsDto
} from './topic-assignment.dto'
import {validateTopicAssignmentDto} from './topic-assignments.validation'

export class TopicAssignmentsApi {
    async getAllTopicAssignments(workshopId: string): Promise<TopicAssignmentDto[]> {
        const topicAssignmentsResponse: Response<GetAllTopicAssignmentsDto> =
            await API.get('backend', `api/workshops/${workshopId}/topic-assignments`, {})

        return topicAssignmentsResponse._embedded ? topicAssignmentsResponse._embedded.topicAssignments : []
    }

    async getTopicAssignments(workshopId: string, phaseId: string, topicId: string): Promise<TopicAssignmentRoleWithParticipantsDto[]> {
        const path = `api/workshops/${workshopId}/phases/${phaseId}/topics/${topicId}/moderated-participants`
        const assignments: GetTopicAssignmentsDto = await API.get('backend', path, {})
        validate<GetTopicAssignmentsDto>(assignments, validateTopicAssignmentDto())
        return assignments._embedded?.moderatedParticipants || []
    }

    setActiveRole(workshopId: string, phaseId: string, topicId: string, activeRole: string): Promise<void> {
        return API.post('backend', `api/workshops/${workshopId}/phases/${phaseId}/topics/${topicId}/activate-role`, {
            body: {
                role: activeRole
            }
        })
    }

    async loadMyTopicAssignments(workshopId: string): Promise<MyTopicAssignmentDto[]> {
        const topicAssignmentsResponse: Response<GetPersonalTopicAssignmentsDto> =
          await API.get('backend', `api/workshops/${workshopId}/my-topic-assignments`, {})

        return topicAssignmentsResponse._embedded ? topicAssignmentsResponse._embedded.personalTopicAssignments : []
    }
}
