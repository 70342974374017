import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {
    ModeratedTopicQueueAdapter,
    ParticipantAndTopicId,
    QueueWithCurrentItem,
    selectModeratedQueueBy
} from 'src/state/moderated-queue'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'
import {AcceptQueuedTopicButton} from './AcceptQueuedTopicButton'
import {ActivateQueueButton} from './ActivateQueueButton'
import {CloseQueuedTopicButton} from './CloseQueuedTopicButton'
import {PauseQueueButton} from './PauseQueueButton'

export const ModeratedQueueModeratorControls: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId,
}) => {
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    return <Flex direction={'row'} gap={'size-130'} wrap>
        {queue.active ? <PauseQueueButton workshopId={workshopId} phaseId={phaseId} />
            : <ActivateQueueButton workshopId={workshopId} phaseId={phaseId} />}

        {queue.hasCurrent() ? <ActiveQueueButtons workshopId={workshopId} phaseId={phaseId} queue={queue} /> : <></>}
    </Flex>
}

export const ActiveQueueButtons: React.FC<WithWorkshopId & WithPhaseId & {
    queue: ModeratedTopicQueueAdapter & QueueWithCurrentItem<ParticipantAndTopicId>
}> = ({
    workshopId,
    phaseId,
    queue
}) => {
    return <>
        <AcceptQueuedTopicButton workshopId={workshopId} phaseId={phaseId} queue={queue} />
        <CloseQueuedTopicButton workshopId={workshopId} phaseId={phaseId} queue={queue} />
    </>
}
