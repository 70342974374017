import {Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Heading, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const DeleteConfirmDialog: React.FC<{
    open: boolean,
    setOpen(open: boolean): void
    deleteConfirmed(): void
    headline?: string
    description?: string
    deleteLabel?: string
}> = ({
    open,
    setOpen,
    deleteConfirmed,
    headline,
    description,
    deleteLabel
}) => {
    const {t} = useTranslation()

    return <DialogContainer onDismiss={() => setOpen(false)}>
        {open && <Dialog>
            <Heading>{headline || t('dialog.delete-confirmation.headline')}</Heading>
            <Divider/>
            <Content>
                <Text>{description || t('dialog.delete-confirmation.description')}</Text>
            </Content>
            <ButtonGroup>
                <Button variant="secondary" onPress={() => setOpen(false)} autoFocus>
                    {t('button.cancel')}
                </Button>
                <Button variant="cta" onPress={() => {
                    deleteConfirmed()
                    setOpen(false)
                }}>
                    {deleteLabel || t('button.delete')}
                </Button>
            </ButtonGroup>
        </Dialog>}
    </DialogContainer>
}
