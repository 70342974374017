import React, {useState} from 'react'
import {WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'
import {useTranslation} from 'react-i18next'
import {deleteQueuedTopic} from 'src/state/moderated-queue'
import {Button, Text} from '@adobe/react-spectrum'
import {useDispatch} from 'react-redux'
import {DeleteConfirmDialog} from 'src/components/common/DeleteConfirmDialog'

export const WithdrawTopicButton: React.FC<WithWorkshopId & WithPhaseId & WithTopicId> =
    ({
        workshopId,
        phaseId,
        topicId
    }) => {
        const {t} = useTranslation()
        const dispatch = useDispatch()
        const [confirmOpen, setConfirmOpen] = useState(false)

        const dispatchDeleteQueuedTopic = () => {
            dispatch(deleteQueuedTopic({
                workshopId,
                phaseId,
                topicId: topicId
            }))
        }
        const openConfirmDialog = () => setConfirmOpen(true)
        return <>
            <Button variant={'primary'} onPress={openConfirmDialog}>
                <Text>{t('moderated-queue.button.withdraw-topic')}</Text>
            </Button>
            <DeleteConfirmDialog
                headline={t('moderated-queue.withdraw-topic-dialog.headline')}
                description={t('moderated-queue.withdraw-topic-dialog.description')}
                deleteLabel={t('moderated-queue.button.withdraw-topic')}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                deleteConfirmed={dispatchDeleteQueuedTopic} />
        </>
    }
