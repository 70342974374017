import { createAsyncThunk} from '@reduxjs/toolkit'
import {TopicAssignment, TopicAssignmentRole} from './topic-assignments.model'
import {TopicAssignmentsApiService} from './topic-assignments.service'
import {WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'

const apiService = new TopicAssignmentsApiService()

export const loadTopicAssignments = createAsyncThunk<{
    phaseAndTopicId: string
    assignments: TopicAssignment[]
    activeRole: TopicAssignmentRole
}, WithWorkshopId & WithPhaseId & WithTopicId>(
    'topic-assignments/load',
    ({workshopId, phaseId, topicId}) => {
        return apiService.getTopicAssignments(workshopId, phaseId, topicId)
    }
)

export const loadAllTopicAssignments = createAsyncThunk<TopicAssignment[], WithWorkshopId>(
    'topic-assignments/loadAll',
    ({workshopId}) => apiService.getAllTopicAssignments(workshopId)
)
