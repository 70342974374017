import {useRosterState} from 'amazon-chime-sdk-component-library-react'
import {MeetingParticipant} from 'src/state/meetings'
import {selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'

export const useActiveParticipants = (workshopId: string): MeetingParticipant[] => {
    const workshopParticipants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const {roster} = useRosterState()
    const rosterAttendeeTypes = Object.values(roster)
    return rosterAttendeeTypes
        .filter(attendee => attendee.externalUserId && workshopParticipants[attendee.externalUserId]) // TODO what should happen if there are unexpected guests?
        .map(attendee => ({
            ...workshopParticipants[attendee.externalUserId!]!,
            attendeeId: attendee.chimeAttendeeId
        }))
}
