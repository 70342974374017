import {matchPath} from 'react-router-dom'
import {WithPhasesState} from 'src/state/phases'
import {WithWorkshopsState} from 'src/state/workshops'
import {ToolbarAction} from './toolbar-action'
import {WithRouterState} from 'src/state/types'
import {WithWorkshopId} from 'src/types'

export class AddTopicToolbarAction implements ToolbarAction {
    canBeUsed(state: WithRouterState & WithPhasesState & WithWorkshopsState): boolean {
        const match = matchPath<WithWorkshopId>(
            state.router.location.pathname,
            {path: '/workshops/:workshopId', exact: true}
        ) || matchPath<WithWorkshopId>(
            state.router.location.pathname,
            {path: '/workshops/:workshopId/plenary', exact: true}
        )

        const workshopId = match?.params.workshopId
        return Boolean(match && workshopId && this.currentPhaseIsTopicGeneration(state, workshopId))
    }

    private currentPhaseIsTopicGeneration(state: WithPhasesState & WithWorkshopsState, workshopId: string) {
        const workshop = state.workshops.entities[workshopId]
        if (!workshop) {
            return false
        }
        const currentPhaseId = workshop.phases
            .find(phaseId => state.phases.entities[phaseId]?.status === 'started')
        if (!currentPhaseId) {
            return false
        }
        return state.phases.entities[currentPhaseId]?.meetingVariant.type === 'topic-generation'
    }
}
