import classNames from 'classnames'
import React from 'react'
import {WithParticipant} from 'src/types'
import {ParticipantCafeIcon} from './ParticipantCafeIcon'

export const ParticipantCafeBadge: React.FC<WithParticipant & { canStartCall: boolean, active: boolean}>
  = ({participant, canStartCall, active}) => {
      return <div className={classNames({
          'participant-cafe-badge': true,
          'inactive': !active
      })}>
          <ParticipantCafeIcon participant={participant} canStartCall={canStartCall} active={active} />
          <div className={'participant-name'}>{participant.name}</div>
      </div>
  }
