import {EntityState} from '@reduxjs/toolkit'
import {ActiveQueue, ActiveQueueAdapter, QueueWithCurrentItem, QueueWithNextItem} from './queue.model'
import {Participant} from 'src/state/participants'
import {WithParticipantId, WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'

export type QueueUpdate = WithWorkshopId & WithPhaseId & {
    active: boolean
}

export type AcceptTopicModel = WithWorkshopId & WithPhaseId & WithTopicId
export type CloseTopicModel = WithWorkshopId & WithPhaseId & WithTopicId

export type ParticipantAndTopicId = WithParticipantId & WithTopicId

export interface ModeratedQueue<T> extends ActiveQueue<T> {
    id: string
}

export type ModeratedTopicQueue = ModeratedQueue<ParticipantAndTopicId>

export type ModeratedTopicQueueState = EntityState<ModeratedTopicQueue>
export type WithModeratedQueueState = {
    moderatedQueue: ModeratedTopicQueueState
}

export class ParticipantQueueAdapter<ITEM extends WithParticipantId, QUEUE extends ModeratedQueue<ITEM>>
    extends ActiveQueueAdapter<ITEM, QUEUE> {
    isTurnOf(participant: Participant | string | undefined): this is QueueWithCurrentItem<ITEM> {
        const participantId = typeof participant === 'string' ? participant : participant?.id
        return this.hasCurrent() && Boolean(participantId === this.current.participantId)
    }

    isNextInQueue(participant: Participant | string | undefined): this is QueueWithNextItem<ITEM> {
        const participantId = typeof participant === 'string' ? participant : participant?.id
        return this.hasNextItem() && Boolean(participantId === this.next.participantId)
    }
}

export class ModeratedTopicQueueAdapter extends ParticipantQueueAdapter<ParticipantAndTopicId, ModeratedTopicQueue> {
}
