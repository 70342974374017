import {ActionButton, ButtonGroup, Flex, View} from '@adobe/react-spectrum'
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Card} from 'src/components/common'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {DiscussingParticipants} from 'src/components/meetings/plenary/topic-generation/open/content/DiscussingParticipants'
import {selectMyParticipant, selectParticipant} from 'src/state/participants'
import {Phase, selectCurrentPhase} from 'src/state/phases'
import {loadTopicAssignments, selectAllTopicAssignments, selectTopicAssignmentRoles} from 'src/state/topic-assignments'
import {selectUndefined, useAppDispatch, useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId} from 'src/types'

function isTopicSession(phase: Phase | undefined): phase is Phase {
    return phase !== undefined && phase.meetingVariant.type === 'topic-session'
}

export const WorkshopResultTopicCard: React.FC<WithWorkshopId & WithTopic> = ({workshopId, topic}) => {
    const owner = useAppSelector(selectParticipant(topic.owner))
    const phase = useAppSelector(selectCurrentPhase(workshopId))
    const me = useAppSelector(selectMyParticipant)
    const dispatch = useAppDispatch()

    const topicAssignments = useAppSelector(isTopicSession(phase) ? selectTopicAssignmentRoles(phase.id, topic.id) : selectUndefined)
    const allTopicAssignments = useAppSelector(selectAllTopicAssignments)

    useEffect(() => {
        if (!phase || !isTopicSession(phase)) {
            return
        }
        dispatch(loadTopicAssignments({workshopId, phaseId: phase?.id, topicId: topic.id}))
    }, [workshopId, phase, topic])

    const shouldShowEnterTopicButton = !isTopicSession(phase) || allTopicAssignments.length === 0 || topicAssignments?.isAllowedToJoin(me)
    return <Card className={'topic-card'}>{{
        rightHeaderIcon: owner ? <ParticipantIconWithCircle participant={owner} className={'card-icon-participant'}/> : undefined,
        title: topic.title,
        width: 'size-3600',
        content: <Flex direction='column' height='size-3000'>
            <View flex UNSAFE_className={'description'}>{topic.description}</View>
            <DiscussingParticipants topic={topic} />
        </Flex>,
        buttons: shouldShowEnterTopicButton ? <ButtonGroup>
            <Link to={`/workshops/${workshopId}/topics/${topic.id}`}>
                <ActionButton>Enter Topic</ActionButton>
            </Link>
        </ButtonGroup> : undefined
    }}</Card>
}
