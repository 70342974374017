import React from 'react'
import {selectIsLeftDrawerOpen, setLeftDrawer} from 'src/state/layout'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {Burger} from '../menu/Burger'
import {LeftMenu} from '../menu/LeftMenu'

export const LeftDrawer: React.FC = (props) => {
    const {children} = props
    const open = useAppSelector(selectIsLeftDrawerOpen)
    const dispatch = useAppDispatch()

    const toggle = () => {
        if (open) {
            dispatch(setLeftDrawer('collapsed'))
        } else {
            dispatch(setLeftDrawer('open'))
        }
    }

    return <div className='drawer-left'>
        <LeftMenu className='menu-left'>
            <Burger toggle={toggle}/>
            {children}
        </LeftMenu>
    </div>
}
