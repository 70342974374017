import React from 'react'
import {PersonIcon} from 'src/components/icons'
import {selectAuthenticatedUser} from 'src/state/auth'
import {useAppSelector} from 'src/state/types'

export const AvatarOrProfileIcon: React.FC = () => {
    const user = useAppSelector(selectAuthenticatedUser)
    if (user.imageUrl) {
        return <img src={user.imageUrl} width={40} height={40} alt='Profile'/>
    }
    return <PersonIcon/>
}
