import {AsyncThunkAction} from '@reduxjs/toolkit'
import {Dispatch} from 'react'
import {AnyAction} from 'redux'
import { PhaseEventTypes} from 'src/websocket/phase-event-types.dto'
import {TopicRoomEventTypes} from 'src/websocket/topic-room-event-types.dto'
import {WorkshopEventTypes} from 'src/websocket/workshop-event-types.dto'
import {
    CallsEventType,
    InvitationEventType,
    PhaseEventType,
    TopicRoomEventType,
    WorkshopEventType
} from './workshop-event.model'
import {CallsEventTypes} from 'src/websocket/call-event-types.dto'
import {InvitationEventTypes} from 'src/websocket/invitation-event-type.dto'

export type ActionCreator<EVENT_DTO> = (event: EVENT_DTO, dispatch: Dispatch<any>) => AnyAction | AsyncThunkAction<any, any, any>

export type EventDto = {
    type: string
}

export class WorkshopEventActions<EVENT_TYPES extends string, EVENT_DTO extends EventDto> {
    actions: {
        [type in EVENT_TYPES]?: Array<ActionCreator<EVENT_DTO>>
    } = {}

    registerActionCreator(reducerConfig: { type: EVENT_TYPES } & { actionCreator: ActionCreator<EVENT_DTO> }): void {
        const reducers = this.actions[reducerConfig.type] || []
        this.actions[reducerConfig.type] = [...reducers!, reducerConfig.actionCreator]
    }

    dispatch(event: EVENT_DTO, dispatch: Dispatch<any>): boolean {
        let found = false
        const nullableActions = this.actions![event.type as EVENT_TYPES]
        const actions: Array<ActionCreator<EVENT_DTO>> = nullableActions ? nullableActions! : []
        actions.forEach(action => {
            const actionToDispatch = action(event, dispatch)
            if (actionToDispatch) {
                dispatch(actionToDispatch)
            }
            found = true
        })
        return found
    }

    hasEvent(type: string): boolean {
        return this.actions![type as EVENT_TYPES] !== undefined
    }
}

export const workshopEventDispatcher = new WorkshopEventActions<WorkshopEventTypes, WorkshopEventType>()
export const topicRoomEventDispatcher = new WorkshopEventActions<TopicRoomEventTypes, TopicRoomEventType>()
export const phaseEventDispatcher = new WorkshopEventActions<PhaseEventTypes, PhaseEventType>()
export const callsEventDispatcher = new WorkshopEventActions<CallsEventTypes, CallsEventType>()
export const invitationEventDispatcher = new WorkshopEventActions<InvitationEventTypes, InvitationEventType>()
