import React from 'react'
import {PersonIcon} from 'src/components/icons'
import {WithParticipant} from 'src/types'

export const ParticipantIcon: React.FC<WithParticipant & { className?: string, ref?: React.MutableRefObject<null>, onClick?(): void }> = ({
    participant,
    className,
    ref,
    onClick}) => {
    return <div className={className} ref={ref} onClick={onClick}>
        {participant.imageUrl ? <img src={participant.imageUrl} alt=''/> : <PersonIcon/>}
    </div>
}

