import React, {ReactNode} from 'react'
import './AppLayout.css'
import {NotificationDrawer} from 'src/components/layout/drawer/NotificationDrawer'
import {SearchDrawer} from 'src/components/layout/drawer/SearchDrawer'
import {ContentColumn} from './content/ContentColumn'
import {ContentFrame} from './content/ContentFrame'
import {ContentRow} from './content/ContentRow'
import {MainContent} from './content/MainContent'
import {LeftDrawer} from './drawer/LeftDrawer'
import {MeetingDrawer} from 'src/components/layout/drawer/MeetingDrawer'
import {FooterToolboxIfRequired} from './footer/FooterToolbox'
import {AppBar} from './header/AppBar'

interface Props {
    children: {
        toolbarTitle?: ReactNode
        toolbarContent?: ReactNode
        content: ReactNode
        leftDrawerContent: ReactNode
        rightDrawerContent?: ReactNode
    }
}

export const AppLayout: React.FC<Props> = (props) => {
    const {
        toolbarContent,
        toolbarTitle,
        leftDrawerContent,
        rightDrawerContent,
        content,
    } = props.children

    return (
        <div className='main-frame'>
            <LeftDrawer>{leftDrawerContent}</LeftDrawer>
            <ContentFrame>
                <AppBar title={toolbarTitle} />
                <ContentRow>
                    <ContentColumn>
                        <MainContent>{content}</MainContent>
                    </ContentColumn>
                    <MeetingDrawer>{rightDrawerContent}</MeetingDrawer>
                    <SearchDrawer />
                    <NotificationDrawer />
                </ContentRow>
                <FooterToolboxIfRequired>{toolbarContent}</FooterToolboxIfRequired>
            </ContentFrame>
        </div>
    )
}
