import {useContentShareState, useRosterState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {OfflineParticipantVideo} from 'src/components/roster/OfflineParticipantVideo'
import {ParticipantPictureOrVideo} from 'src/components/roster/video/ParticipantPictureOrVideo'
import {ContentShareTile} from './ContentShareTile'
import {MeetingParticipant} from 'src/state/meetings'
import {Participant} from 'src/state/participants'

export const AvatarOfModerator: React.FC = () => {
    return <><div className={'video-container-presenter-text'}>Waiting for moderator</div></>
}

export const PresenterVideo: React.FC<{
    moderator: Participant
}> = ({moderator}) => {
    const {roster} = useRosterState()
    const {tileId} = useContentShareState()

    const moderatorAttendee = Object.values(roster)
        .find(attendee => attendee.externalUserId === moderator.id)
    if (moderatorAttendee) {
        const participant: MeetingParticipant = {
            ...moderator,
            attendeeId: moderatorAttendee.chimeAttendeeId
        }

        return tileId ? <div className={'video-container-presenter-content-share'}><ContentShareTile tileId={tileId}/></div> : <ParticipantPictureOrVideo
            participant={participant}
            size='presenter'
        />
    }
    return <div>
        <AvatarOfModerator/>
        <OfflineParticipantVideo participant={moderator} size={'presenter'}/>
    </div>
}

