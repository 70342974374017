import {Button, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PlayCircleIcon} from 'src/components/icons/PlayCircleIcon'
import {activateQueue} from 'src/state/moderated-queue'
import {useAppDispatch} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'

export const ActivateQueueButton: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <Button UNSAFE_className={'moderator-button'} flexShrink={0} variant='overBackground' onPress={() => {
        dispatch(activateQueue({
            workshopId,
            phaseId,
        }))
    }}>
        <PlayCircleIcon className={'hover-button-icon'}/><Text>{t('moderated-queue.button.activate-queue')}</Text>
    </Button>
}
