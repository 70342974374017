import {API} from 'aws-amplify'
import {v4 as uuid} from 'uuid'
import {validate} from 'src/tools/validation'
import {Response} from '../response'
import {CreateTopicDto, GetTopicsDto, TopicDto, UpdateTopicDto} from './topics.dto'
import {validateTopicDto} from './topics.validation'

export class TopicsApi {
    async createTopic(workshopId: string, phaseId: string, model: CreateTopicDto): Promise<TopicDto> {
        const topic = await API.post('backend', `api/workshops/${workshopId}/phases/${phaseId}/topics`, {
            body: {
                ...model,
                id: uuid()
            }
        })
        this.validateTopic(topic)
        return topic
    }

    async createPredefinedTopic(workshopId: string, phaseId: string, model: CreateTopicDto): Promise<TopicDto> {
        const topic = await API.post('backend', `api/workshops/${workshopId}/phases/${phaseId}/predefined-topics`, {
            body: {
                ...model,
                id: uuid()
            }
        })
        this.validateTopic(topic)
        return topic
    }

    async loadTopics(workshopId: string): Promise<TopicDto[]> {
        const result: Response<GetTopicsDto> = await API.get('backend', `api/workshops/${workshopId}/topics`, {})
        const topics = result._embedded?.topics || []
        topics.forEach(topic => this.validateTopic(topic))
        return topics
    }

    async loadTopic(workshopId: string, topicId: string): Promise<TopicDto> {
        const topic
            = await API.get('backend', `api/workshops/${workshopId}/topics/${topicId}`, {})
        this.validateTopic(topic)
        return topic
    }

    async voteForTopic(workshopId: string, phaseId: string, topicId: string): Promise<TopicDto> {
        const topic
            = await API.post('backend', `api/workshops/${workshopId}/phases/${phaseId}/topics/${topicId}/votes`, {})
        this.validateTopic(topic)
        return topic
    }

    async closeTopic(workshopId: string, topicId: string): Promise<TopicDto> {
        const topic
            = await API.post('backend', `api/workshops/${workshopId}/topics/${topicId}/close`, {})
        this.validateTopic(topic)
        return topic
    }

    async updateTopic(workshopId: string, updateTopicDto: UpdateTopicDto): Promise<TopicDto> {
        const topic
            = await API.put('backend', `api/workshops/${workshopId}/topics/${updateTopicDto.id}`, {
                body: {
                    ...updateTopicDto
                }
            })
        this.validateTopic(topic)
        return topic
    }

    validateTopic(topic: TopicDto): void {
        validate<TopicDto>(topic, validateTopicDto())
    }
}
