import {Button, ButtonGroup} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {selectAuthenticatedUser} from '../../../state/auth'
import {useAppSelector} from '../../../state/types'
import {Workshop} from '../../../state/workshops'

interface Props {
    workshop: Workshop
}

export const WorkshopCardButtons: React.FC<Props> = ({workshop}) => {
    const {t} = useTranslation()
    const user = useAppSelector(selectAuthenticatedUser)
    const canEditWorkshop = workshop.owner === user.id

    return <ButtonGroup>
        {canEditWorkshop ? <Link to={`/workshops/${workshop.id}/configure`}><Button variant='secondary'
            marginEnd='size-100'>{t('button.view-edit')}</Button></Link> : <></>}
        <Link to={`/workshops/${workshop.id}`}><Button variant='primary'>{t('button.join')}</Button></Link>
    </ButtonGroup>
}
