import {Flex, Text, TextArea} from '@adobe/react-spectrum'
import {DimensionValue} from '@react-types/shared/src/dna'
import React, {useState} from 'react'
import {CheckIcon} from 'src/components/icons/CheckIcon'

export const EditableTextArea: React.FC<{
    value: string
    onSave?(value: string): void
    height?: DimensionValue
}> = ({value, onSave, height}) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState<string>(value)

    const save = () => {
        setEditMode(false)
        if (onSave && value !== currentValue) {
            onSave(currentValue)
        }
    }
    return editMode ? <>
        <Flex gap={'size-50'}>
            <TextArea defaultValue={value} onChange={setCurrentValue} height={height} onFocusChange={(isFocused) => {
                if (!isFocused) {
                    save()
                }
            }} autoFocus/>
            <div><a className="save-icon" onClick={() => save()}><CheckIcon/></a></div>
        </Flex>
    </> : <a onClick={() => setEditMode(!editMode)}><Text>{value}</Text></a>
}
