import React from 'react'
import {TopicGenerationContent} from 'src/components/meetings/plenary/topic-generation/TopicGenerationContent'
import {WorkshopResults} from 'src/container/rooms/plenary/WorkshopResults'
import {WithPhase, WithWorkshopId} from 'src/types'

export const PlenaryContent: React.FC<WithWorkshopId & Partial<WithPhase>> = ({workshopId, phase}) => {
    switch (phase?.meetingVariant!.type) {
    case 'topic-generation':
        return <TopicGenerationContent phase={phase}/>
    case 'presentation':
        return <></>
    default:
        return <WorkshopResults workshopId={workshopId}/>
    }
}
