import {Button, Text} from '@adobe/react-spectrum'
import StartWorkshopIcon from '@spectrum-icons/workflow/Play'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch} from 'src/state/types'
import {startWorkshop} from 'src/state/workshops'

export const StartWorkshopButton: React.FC<{ workshopId: string }> = ({workshopId}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <Button variant='primary' onPress={() => dispatch(startWorkshop(workshopId))}>
        <StartWorkshopIcon/>
        <Text>{t('button.start-workshop')}</Text>
    </Button>
}
