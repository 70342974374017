import {TopicsApi} from 'src/api/topics'
import {Topic} from '../topics'
import {Votes} from './topic-votes.model'

export class TopicVotesApiService {

    constructor(
        private api: TopicsApi = new TopicsApi(),
    ) {
    }

    async voteForTopic(workshopId: string, phaseId: string, topicId: string): Promise<Topic & Votes> {
        return this.api.voteForTopic(workshopId, phaseId, topicId)
    }

}

