import {Participant} from '../../../../state/participants'
import {ParticipantPositions, ParticipantPositionTuple} from './participant-position'
import {Position} from './position'
import {Size} from './size'

interface Config {
  padding?: number
  elementSize?: number
}

const defaultConfig: Required<Pick<Config, 'padding' | 'elementSize'>> = {
    padding: 50,
    elementSize: 100
}


export class RandomPositioningStrategy {
  participantPositions: ParticipantPositions = []
  viewSize: Size = {width: 1, height: 1}

  constructor(private config: Config, private participants: Participant[]) {
      this.participantPositions = participants
          .map(participant => ({
              participant: participant,
              position: {x: 0, y: 0}
          }))
  }

  updateParticipants(newParticipants: Participant[]): ParticipantPositions {
      const newPositions = this.determineNewPositions(newParticipants)
      this.participantPositions = newPositions
      return newPositions
  }

  private determineNewPositions(newParticipants: Participant[]) {
      const existingPositions = this.removeNoLongerExistingParticipants(newParticipants)
          .map(this.updateExistingParticipant(newParticipants))
      this.addNewParticipants(newParticipants, existingPositions)
      return existingPositions
  }

  private updateExistingParticipant(newParticipants: Participant[]): (old: ParticipantPositionTuple) => ParticipantPositionTuple {
      return (existingPosition: ParticipantPositionTuple) => {
          const participant = newParticipants.find(participant => participant.id === existingPosition.participant.id)!
          return {participant, position: existingPosition.position}
      }
  }

  private addNewParticipants(newParticipants: Participant[], existingPositions: ParticipantPositions): void {
      newParticipants.map(participant => {
          const existingPosition = existingPositions.find(participantPosition => participantPosition.participant.id === participant.id)
          if (!existingPosition) {
              existingPositions.push({participant, position: this.findPosition(existingPositions)})
          }
      })
  }

  private removeNoLongerExistingParticipants(newParticipants: Participant[]): ParticipantPositions {
      return this.participantPositions
          .filter(existingPosition => newParticipants.some(participant => participant.id === existingPosition.participant.id))

  }

  getRandomNumber(min: number, max: number): number {
      return Math.random() * (max - min) + min
  }

  isTooCloseTooOtherPositions(position: Position, positions: ParticipantPositions): boolean {
      return Boolean(positions.find(pos => this.isTooClose(pos.position, position)))
  }

  private isTooClose(position1: Position, position2: Position): boolean {
      return Math.abs(position1.x - position2.x) < this.elementSize
      && Math.abs(position1.y - position2.y) < this.elementSize
  }

  findPosition(positions: ParticipantPositions): Position {
      let tries = 0
      const limit = 50
      let position
      do {
          tries++
          position = {
              x: this.getRandomNumber(0, Math.max(0, this.viewSize.width - this.padding)),
              y: this.getRandomNumber(0, Math.max(0, this.viewSize.height - this.padding))
          }
      } while (this.isTooCloseTooOtherPositions(position, positions) && tries < limit)
      return position
  }

  isOutsizeOfView(position: Position): boolean {
      return position.x > (this.viewSize.width - this.padding)
      || position.y > (this.viewSize.height - this.padding)
  }

  isInitialValue(position: Position): boolean {
      return position.x === 0 && position.y === 0
  }

  calculateNewPositions(size: Size): ParticipantPositions {
      this.viewSize = size
      const newParticipantPositions: ParticipantPositions = []
      this.participantPositions.forEach(participantPosition => {
          if (this.isTooCloseTooOtherPositions(participantPosition.position, newParticipantPositions)
        || this.isOutsizeOfView(participantPosition.position)
        || this.isInitialValue(participantPosition.position)
          ) {
              newParticipantPositions.push({
                  participant: participantPosition.participant,
                  position: this.findPosition(newParticipantPositions)
              })
          } else {
              newParticipantPositions.push(participantPosition)
          }
      })
      this.participantPositions = newParticipantPositions
      return newParticipantPositions
  }

  private get padding(): number {
      return this.config.padding || this.config.padding === 0 ? this.config.padding : defaultConfig.padding
  }

  private get elementSize(): number {
      return this.config.elementSize ? this.config.elementSize : defaultConfig.elementSize
  }
}
