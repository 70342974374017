import {Button, Divider, Text} from '@adobe/react-spectrum'
import {push} from 'connected-react-router'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {VideoActiveIcon} from 'src/components/icons'
import {StartAudioCallWhiteIcon} from 'src/components/icons/StartAudioCallWhiteIcon'
import {startAudioCall, startVideoCall} from 'src/state/calls'
import {LocationType} from 'src/state/participant-locations'
import {useAppDispatch} from 'src/state/types'
import {WithParticipant, WithParticipantLocations} from 'src/types'

export const SearchResult: React.FC<WithParticipant & WithParticipantLocations> = ({participant, locations}) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    const locationLabels: Record<LocationType, string> = {
        cafe: t('search.location.cafe'),
        plenary: t('search.location.plenary'),
        'topic-breakout': t('search.location.topic-breakout')
    }

    return <>
        {locations.map((location, index) =>
            <div key={index}>
                {index === 0 ? <Divider size={'S'} marginBottom={'size-130'}/> :
                    <Divider size={'S'} marginTop={'size-130'} marginBottom={'size-130'}/>}
                <div className={'search-result'}>
                    <ParticipantIconWithCircle className={'participant-badge-icon participant-icon'}
                        participant={participant}/>
                    <div className={'description'}>
                        <Text UNSAFE_className={'participant-name'}>{participant.name}</Text>
                        <Text UNSAFE_className={'participant-location'}>{locationLabels[location.location.type]}</Text>
                        {location.location.type === 'cafe' ? <div className={'actions'}>
                            <StartAudioCallWhiteIcon className={'icon-button'} onClick={() => {
                                dispatch(push(`/workshops/${participant.workshopId}/cafe`))
                                dispatch(startAudioCall({
                                    workshopId: participant.workshopId,
                                    participantId: participant.id
                                }))
                            }}/>
                            <VideoActiveIcon className={'icon-button'} onClick={() => {
                                dispatch(push(`/workshops/${participant.workshopId}/cafe`))
                                dispatch(startVideoCall({
                                    workshopId: participant.workshopId,
                                    participantId: participant.id
                                }))
                            }}/>
                        </div> : <></>}
                        {location.location.type === 'topic-breakout' ? <div className={'actions'}>
                            <Button variant={'primary'} onPress={() => {
                                dispatch(push(`/workshops/${participant.workshopId}/topics/${location.location.id}`))
                            }}>{t('button.join-topic')}</Button>
                        </div> : <></>}
                    </div>
                </div>
            </div>
        )}
    </>
}
