import { Content, Dialog, DialogContainer, Divider, Footer, Heading} from '@adobe/react-spectrum'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {DeviceSettings} from './meeting/settings/DeviceSettings'
import './DeviceDialogContainer.css'

export const DeviceDialogContainer: React.FC<{
    open: boolean,
    setOpen(open: boolean): void,
    dismissible: boolean,
    actionButtons?: ReactNode
}> = ({
    open,
    setOpen,
    dismissible,
    actionButtons
}) => {
    const {t} = useTranslation()
    return <DialogContainer onDismiss={() => setOpen(false)} isDismissable={dismissible}>
        {open && <Dialog UNSAFE_className={'device-settings-dialog'}>
            <Heading>{t('dialog.device-settings.headline')}</Heading>
            <Divider/>
            <Content>
                <DeviceSettings/>
            </Content>
            {actionButtons ? <Footer>
                {actionButtons}
            </Footer> : <></>}
        </Dialog>}
    </DialogContainer>
}
