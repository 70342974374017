import {Flex} from '@adobe/react-spectrum'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {BreakoutConfiguration} from 'src/components/workshops/designer/room/BreakoutConfiguration'
import {WithPhase} from 'src/types'
import {RoomConfigurationForm} from './RoomConfigurationForm'
import {RoomConfigurationHeader} from './RoomConfigurationHeader'
import {CreateTopicButton} from 'src/components/meetings/plenary/topic-generation/open/content/controls/CreateTopicButton'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {createPredefinedTopic, loadTopics, selectTopicsForWorkshop} from 'src/state/topics'

export const PredefinedTopics: React.FC<WithPhase> = ({phase}) => {
    const {t} = useTranslation()
    const topics = useAppSelector(selectTopicsForWorkshop(phase.workshopId))
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadTopics({ workshopId: phase.workshopId }))
    }, [])

    return <div className='topic-room-column'>
        <Flex direction='column'>
            <RoomConfigurationHeader>{t('workshop-designer.show-topics-label')}</RoomConfigurationHeader>
            <RoomConfigurationForm>
                {topics.map(topic => <span key={topic.id}>{topic.title}</span>)}
                <CreateTopicButton workshopId={phase.workshopId} phaseId={phase.id} redirectToTopic={false} actionCreator={createPredefinedTopic} />
            </RoomConfigurationForm>
        </Flex>
    </div>
}

export const TopicRoomColumn: React.FC<WithPhase> = ({phase}) => {
    const {t} = useTranslation()
    if(phase.meetingVariant.type === 'define-topics')
        return <PredefinedTopics phase={phase} />
    return <div className='topic-room-column'>
        <Flex direction='column'>
            <RoomConfigurationHeader>{t('workshop-designer.room-breakout-label')}</RoomConfigurationHeader>
            <RoomConfigurationForm>
                <BreakoutConfiguration phase={phase} />
            </RoomConfigurationForm>
        </Flex>
    </div>
}
