import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {ContentWithToolbox} from 'src/components/layout/ContentWithToolbox'
import {Toolbox} from 'src/components/layout/Toolbox'
import {PitchTopicContent} from './PitchTopicContent'
import {Topic} from 'src/state/topics'
import {WithWorkshopId, WithPhase} from 'src/types'
import {CreateAndEnqueueTopicButton} from './controls/CreateAndEnqueueTopicButton'
import './ModeratedQueueTopicGenerationContent.css'
import {MyTopicPreview} from './my-topic-preview/MyTopicPreview'
import {SmallTopicCards} from './SmallTopicCards'
import {UpNextIfPresent} from './up-next/UpNextIfPresent'
import {selectMyParticipant} from 'src/state/participants'
import {EditTopicButton} from './controls/EditTopicButton'
import {useAppSelector} from 'src/state/types'
import {WithdrawTopicButton} from './controls/WithdrawTopicButton'

function useSuggestedTopic(workshopId: string, topics: Topic[]) {
    const me = useAppSelector(selectMyParticipant)
    return topics.filter(it => it.status === 'SUGGESTED').find(it => it.owner === me?.id)
}

export const ModeratedQueueTopicGenerationContent: React.FC<WithWorkshopId & WithPhase & {
    topics: Topic[]
}> = ({
    workshopId,
    phase,
    topics
}) => {
    const phaseId = phase.id
    const acceptedTopics = topics.filter(it => it.status === 'ACCEPTED')
    const mySuggestedTopic = useSuggestedTopic(workshopId, topics)


    const toolbox = <Toolbox>
        {mySuggestedTopic?
            <Flex alignContent={'center'} gap={'size-100'}>
                <EditTopicButton workshopId={workshopId} topic={mySuggestedTopic}/>
                <WithdrawTopicButton workshopId={workshopId} phaseId={phaseId} topicId={mySuggestedTopic.id}/>
            </Flex>:
            <CreateAndEnqueueTopicButton workshopId={workshopId} phaseId={phaseId}/> }
    </Toolbox>

    return <ContentWithToolbox
        toolbox={toolbox}
        className={'moderated-queue-topic-generation'}>
        <Flex direction={'row'}>
            <Flex direction={'row'} wrap><SmallTopicCards topics={acceptedTopics}/></Flex>
            <Flex>
                <Flex direction={'column'} UNSAFE_className={'right-content-column'} gap={'size-130'}>
                    <PitchTopicContent phaseId={phaseId}/>
                    <UpNextIfPresent workshopId={workshopId} phaseId={phaseId}/>
                    <MyTopicPreview phaseId={phaseId} />
                </Flex>
            </Flex>
        </Flex>
    </ContentWithToolbox>
}


