import {defaultTheme, Provider as ThemeProvider} from '@adobe/react-spectrum'
import {I18nProvider} from '@react-aria/i18n'
import React from 'react'
import './App.css'
import {AppRouting} from './AppRouting'
import {Authentication} from './components/Authentication'
import {ErrorDialog} from './components/common/ErrorDialog'
import {ToolBarControls} from './components/control/ToolBarControls'
import {AppLayout} from './components/layout/AppLayout'
import {ToolBarTitle} from './container/breadcrumb/ToolBarTitle'
import {LeftDrawerContent} from './container/navigation/LeftDrawerContent'
import {RightDrawerContent} from './container/RightDrawerContent'
import {StompClientProvider} from './provider/StompClientProvider'

const App: React.FC = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <I18nProvider locale='en-US'>
                <div className='App' data-testid='app'>
                    <Authentication>
                        <StompClientProvider>
                            <AppLayout>
                                {{
                                    toolbarTitle: <ToolBarTitle/>,
                                    toolbarContent: <ToolBarControls/>,
                                    leftDrawerContent: <LeftDrawerContent/>,
                                    rightDrawerContent: <RightDrawerContent/>,
                                    content: <AppRouting/>
                                }}
                            </AppLayout>
                        </StompClientProvider>
                    </Authentication>
                </div>
                <ErrorDialog />
            </I18nProvider>
        </ThemeProvider>
    )
}

export default App
