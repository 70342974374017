import {EntityAdapter, EntityState} from '@reduxjs/toolkit'
import moment from 'moment'
import {WorkshopsApi} from 'src/api'
import {WorkshopDto, WorkshopTemplateDto} from 'src/api/workshops/workshops.dto'
import {Invitation} from '../invitations'
import {Phase} from '../phases/phases.model'
import {CreateWorkshopModel, Workshop, WorkshopTemplate} from './workshops.model'

export function toWorkshop(workshopDto: WorkshopDto): Workshop {
    return {
        id: workshopDto.id,
        name: workshopDto.name,
        description: workshopDto.description,
        start: workshopDto.startTime,
        end: workshopDto.endTime,
        status: workshopDto.status,
        owner: workshopDto.owner,
        phases: [],
        icon: '/workshop192.png',
    }
}

export function toWorkshopDto(workshop: Workshop): WorkshopDto {
    return {
        id: workshop.id,
        name: workshop.name,
        description: workshop.description!,
        startTime: workshop.start,
        endTime: workshop.end,
        status: workshop.status,
        owner: workshop.owner // FIXME do not send or ignore on update in backend
    }
}

export function toWorkshopTemplate(dto: WorkshopTemplateDto): WorkshopTemplate {
    return {
        id: dto.id,
        name: dto.name
    }
}

export class WorkshopsApiService {
    constructor(
        private api: WorkshopsApi = new WorkshopsApi()
    ) {
    }

    async getMyWorkshops(): Promise<Workshop[]> {
        const workshopDtos = await this.api.getMyWorkshops()
        return workshopDtos.map(workshopDto => toWorkshop(workshopDto))
    }

    async getParticipatingWorkshops(): Promise<Workshop[]> {
        const workshopDtos = await this.api.getParticipatingWorkshops()
        return workshopDtos.map(workshopDto => toWorkshop(workshopDto))
    }

    async getWorkshop(workshopId: string): Promise<Workshop> {
        const workshopDto = await this.api.getWorkshop(workshopId)
        return toWorkshop(workshopDto)
    }

    async updateWorkshop(workshop: Workshop): Promise<Workshop> {
        const workshopDto = await this.api.putWorkshop(toWorkshopDto(workshop))
        return toWorkshop(workshopDto)
    }

    async createWorkshop(createWorkshopModel: CreateWorkshopModel): Promise<Workshop> {
        const workshopDto = await this.api.createWorkshop({
            name: createWorkshopModel.name,
            description: createWorkshopModel.description,
            startTime: createWorkshopModel.startTime,
            endTime: createWorkshopModel.endTime,
            workshopTemplate: createWorkshopModel.template
        })
        return toWorkshop(workshopDto)
    }

    startWorkshop = async (workshopId: string): Promise<{workshopId: string}> => {
        await this.api.startWorkshop(workshopId)
        return {workshopId: workshopId}
    }

    getWorkshopTemplates = async (): Promise<WorkshopTemplate[]> => {
        const templateDtos = await this.api.getWorkshopTemplates()
        return templateDtos.map(dto => toWorkshopTemplate(dto))
    }
}

export class WorkshopTemplatesStateService {
    constructor(
        private adapter: EntityAdapter<WorkshopTemplate>
    ) {
    }

    setWorkshopTemplates(templates: WorkshopTemplate[], state: EntityState<WorkshopTemplate>): void {
        this.adapter.upsertMany(state, templates)
    }
}

export class WorkshopsStateService {
    constructor(
      private workshopsAdapter: EntityAdapter<Workshop>
    ) {
    }

    addWorkshops(workshops: Workshop[], state: EntityState<Workshop>): void {
        this.workshopsAdapter.upsertMany(state, workshops)
    }

    addWorkshop(workshop: Workshop, state: EntityState<Workshop>): void {
        this.workshopsAdapter.addOne(state, workshop)
    }

    addOrUpdateWorkshop(workshop: Workshop, state: EntityState<Workshop>): void {
        this.workshopsAdapter.upsertOne(state, workshop)
    }

    setInvitations(workshopId: string, invitations: Invitation[], state: EntityState<Workshop>): void {
        const workshop = state.entities[workshopId]
        if (workshop) {
            const invitationIds = invitations.map(invitation => invitation.email)
            this.workshopsAdapter.updateOne(state, {id: workshopId, changes: {invitations: invitationIds}})
        }
    }

    setPhases(workshopId: string, phases: Phase[], state: EntityState<Workshop>): void {
        const workshop = state.entities[workshopId]
        if (workshop) {
            phases.sort((a, b) => {
                return moment.utc(a.startTime).valueOf() - moment.utc(b.startTime).valueOf()
            })
            const phaseIds = phases.map(phase => phase.id)
            this.workshopsAdapter.updateOne(state, {id: workshop.id, changes: {phases: phaseIds}})
        }
    }

    updatePhase(phase: Phase, currentPhases: Phase[], state: EntityState<Workshop>): void {
        const workshop = state.entities[phase.workshopId]!
        if (!workshop.phases.includes(phase.id)) {
            workshop.phases = [...workshop.phases, phase.id]
        }
        const phaseMap = currentPhases.reduce((map, phase) => {
            map[phase.id] = phase
            return map
        }, <{[id: string]: Phase}>{})
        phaseMap[phase.id] = phase // update

        workshop.phases.sort((phaseId1, phaseId2) => {
            return moment.utc(phaseMap[phaseId1]!.startTime).valueOf() - moment.utc(phaseMap[phaseId2]!.startTime).valueOf()
        })
    }

    deletePhase(state: EntityState<Workshop>, workshopId: string, phaseId: string): void {
        const workshop = state.entities[workshopId]
        if (workshop) {
            this.deletePhaseFromWorkshop(workshop, phaseId)
        }
    }

    private deletePhaseFromWorkshop(workshop: Workshop, phaseId: string) {
        if (workshop.phases.includes(phaseId)) {
            workshop.phases = workshop.phases.filter(id => id !== phaseId)
        }
    }
}
