import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {hasAttendeeId} from 'src/components/roster/ParticipantRosterItem'
import {ParticipantRosterSortComparator, sortParticipantRosterItems} from 'src/hooks/meeting/sort'
import {useActiveAndInactiveParticipants} from 'src/hooks/meeting/use-active-and-inactive-participants'
import {WithRoles, WithWorkshopId} from 'src/types'

export type InactiveRoleRosterProps = WithWorkshopId & WithRoles & {
    showAbsentParticipants?: boolean
    includeRoles?: string[]
    excludeRoles?: string[]
    participantSortComparator?: ParticipantRosterSortComparator
}

export const ParticipantBadgeRoster: React.FC<InactiveRoleRosterProps> = (
    {
        workshopId,
        roles,
        showAbsentParticipants,
        includeRoles,
        excludeRoles,
        participantSortComparator= sortParticipantRosterItems
    }) => {
    const participants = useActiveAndInactiveParticipants(workshopId,
        roles, includeRoles, excludeRoles,
        Boolean(showAbsentParticipants),
        participantSortComparator)

    return <div>
        <Flex direction='column' wrap={false} gap={'size-130'} justifyContent={'center'} alignItems={'center'}>
            <>
                {participants.map((participant, index) =>
                    <ParticipantBadge key={index} participant={participant} active={hasAttendeeId(participant)}/>)}
            </>
        </Flex>
    </div>
}
