import React, {useEffect} from 'react'
import {selectWorkshopPhases, Phase} from 'src/state/phases'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {loadTopics, selectTopicsForWorkshop, Topic} from 'src/state/topics'
import {Flex, View} from '@adobe/react-spectrum'
import {loadAllTopicAssignments, selectAllTopicAssignments, TopicAssignment, TopicAssignmentRole} from 'src/state/topic-assignments'
import {Card} from 'src/components/common'
import {useTranslation} from 'react-i18next'
import {Participant, selectMyParticipant, selectParticipantsForWorkshop} from 'src/state/participants'
import {WithPhase} from 'src/types'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'
import {WorkshopEventDto} from 'src/websocket/workshop-event-types.dto'
import {workshopMyTopic} from 'src/websocket/workshop-topics'
import {useWebsocketSubscription} from 'src/hooks'

function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index
}

export type DisplayTopicWithAssignmentsProps = {
    workshopPhase: Phase
    topic: Topic
    topicAssignments: TopicAssignment[]
    participants: Participant[]
}

const rolesSortOrder: TopicAssignmentRole[] = [
    'moderator',
    'debater',
    'evaluator',
    'networker',
]

export const DisplayTopicWithAssignments: React.FC<DisplayTopicWithAssignmentsProps> = props => {
    const {t} = useTranslation()
    const roleTranslations: Record<TopicAssignmentRole, string> = {
        moderator: t('participant.roles.moderator'),
        networker: t('participant.roles.networker'),
        debater: t('participant.roles.debater'),
        evaluator: t('participant.roles.evaluator'),
    }
    const {topic, workshopPhase, topicAssignments, participants} = props
    const relevantRoles = topicAssignments
        .filter(ta => ta.phaseId === workshopPhase.id)
        .filter(ta => ta.topicId === topic.id)
        .map(ta => ta.role)
        .filter(onlyUnique)
        .sort((a, b) => rolesSortOrder.indexOf(a) - rolesSortOrder.indexOf(b))
    const getUsers = (role: TopicAssignmentRole) => topicAssignments
        .filter(ta => ta.phaseId === workshopPhase.id)
        .filter(ta => ta.topicId === topic.id)
        .filter(ta => ta.role === role)
        .map(ta => ta.participantId)
        .map(id => participants.find(p => p.id === id))
        .filter(participant => !!participant)
        .map(participant => participant!)
        .map(participant => <ParticipantIconWithCircle
            key={participant.id}
            participant={participant}
            data-testid={'participant-circle-' + participant.id}
        />)
    return <Card className={'topic-card'}>{{
        title: topic.title,
        width: 'size-3600',
        content: <Flex direction='column' minHeight='size-3000'>
            <View flex UNSAFE_className={'description'}>{topic.description}</View>
            <>{relevantRoles.map(role => <View key={role} data-testid={'role-' + role}>
                <h4>{roleTranslations[role]}</h4>
                <Flex direction='row' wrap>
                    {getUsers(role)}
                </Flex>
            </View>)}</>
        </Flex>,
    }}</Card>
}

export type TopicsForPhaseProps = {
    workshopPhase: Phase
    topics: Topic[]
    topicAssignments: TopicAssignment[]
    participants: Participant[]
}
export const TopicsForPhase: React.FC<TopicsForPhaseProps> = props => {
    const {workshopPhase, topics, topicAssignments, participants} = props
    const relevantTopics = topicAssignments
        .filter(ta => ta.phaseId === workshopPhase.id)
        .map(ta => ta.topicId)
        .filter(onlyUnique)
        .map(topicId => topics.find(topic => topic.id === topicId))
        .filter(topic => !!topic).map(topic => topic!)
        .sort((a, b) => a.title.localeCompare(b.title))
    return <Flex wrap gap='size-200'>
        {relevantTopics.map(topic => <View key={topic.id}>
            <DisplayTopicWithAssignments
                workshopPhase={workshopPhase}
                topic={topic}
                topicAssignments={topicAssignments}
                participants={participants}
            />
        </View>)}
    </Flex>
}

export const ShowSchedulerResults: React.FC<WithPhase> = ({phase}) => {
    const topics = useAppSelector(selectTopicsForWorkshop(phase.workshopId))
    const topicAssignments = useAppSelector(selectAllTopicAssignments)
    const phases = useAppSelector(selectWorkshopPhases)
    const participants = useAppSelector(selectParticipantsForWorkshop(phase.workshopId))
    const me = useAppSelector(selectMyParticipant)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadTopics({workshopId: phase.workshopId}))
        dispatch(loadAllTopicAssignments({workshopId: phase.workshopId}))
    }, [])

    useWebsocketSubscription<WorkshopEventDto>(workshopMyTopic(phase.workshopId, me?.id),
        () => dispatch(loadAllTopicAssignments({workshopId: phase.workshopId})))

    const relevantPhases = topicAssignments
        .map(ta => ta.phaseId)
        .filter(onlyUnique)
        .map(phaseId => phases.find(phase => phase.id === phaseId))
        .filter(phase => !!phase).map(phase => phase!)
        .sort((a, b) => a.startTime.localeCompare(b.startTime))
    return <div style={{overflow: 'auto', height: '100%'}}>
        <h2>Topic assignments for next sessions ({phase.name})</h2>
        <span>{phase.description}</span>
        <Flex gap='size-200' direction='column'>
            {relevantPhases.map(phase => <View key={phase.id}>
                <h3>{phase.name}</h3>
                <TopicsForPhase
                    workshopPhase={phase}
                    topics={topics}
                    topicAssignments={topicAssignments}
                    participants={participants}
                />
            </View>)}
        </Flex>
    </div>
}
