import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {MeetingsApi} from 'src/api'
import {ModeOfInteraction} from 'src/state/common.model'
import {ParticipantRoles, WithMeetingId, WithModeratedWithRolesId} from 'src/types'
import {ChimeMeeting, MeetingEntity, MeetingObject, WithMeetingsState} from './meetings.model'

export const requestMeeting = createAsyncThunk<MeetingEntity & {chimeMeeting: ChimeMeeting}, WithMeetingId & {
    modeOfInteraction: ModeOfInteraction
    meetingObject?: MeetingObject
}>(
    'meetings/request-meeting',
    async ({meetingId, modeOfInteraction, meetingObject}) => {
        const meetingsApi = new MeetingsApi()
        const chimeMeeting = await meetingsApi.requestMeeting(meetingId)
        const chimeAttendee = await meetingsApi.createAttendee(chimeMeeting.externalMeetingId)
        return {
            id: chimeMeeting.externalMeetingId,
            chimeMeeting,
            chimeAttendee,
            modeOfInteraction,
            topicId: meetingObject?.topicId,
            phaseId: meetingObject?.phaseId
        }
    })

export const leaveMeeting = createAsyncThunk<string, string>(
    'meetings/leave-meeting',
    async (meetingId, thunkAPI) => {
        const state = thunkAPI.getState() as WithMeetingsState
        if (!state.meetings.activeMeeting) {
            return meetingId
        }
        const meeting = state.meetings.entities[state.meetings.activeMeeting]
        if (!meeting) {
            return meetingId
        }
        await new MeetingsApi().removeAttendee(meetingId, meeting.chimeAttendee.attendeeId)
        return meetingId
    })

export const attachModeratedWithRolesToMeeting =
  createAction<WithMeetingId & WithModeratedWithRolesId>('meetings/attachModeratedWithRolesToMeeting')

export const attachRolesToMeeting =
  createAction<WithMeetingId & { roles: ParticipantRoles }>('meetings/attachMeetingRoles')
