import {createSlice} from '@reduxjs/toolkit'
import moment from 'moment'
import {PhaseDeleted, PhaseUpdated, WorkshopStartedEvent} from 'src/state/phases/phases.events'
import {loadInvitations} from '../invitations'
import {loadPhases} from 'src/state/phases'
import {
    createWorkshop,
    loadOwnWorkshops,
    loadParticipatingWorkshops,
    loadWorkshop, loadWorkshopTemplates,
    updateWorkshop
} from './workshops.actions'
import {workshopsAdapter, workshopTemplatesAdapter} from './workshops.adapter'
import {WorkshopsState} from './workshops.model'
import {WorkshopsStateService, WorkshopTemplatesStateService} from './workshops.service'

const stateService = new WorkshopsStateService(workshopsAdapter)
const templatesStateService = new WorkshopTemplatesStateService(workshopTemplatesAdapter)

export const initialState: WorkshopsState = {
    ...workshopsAdapter.getInitialState(),
    myWorkshops: [],
    participatingWorkshops: []
}
export const initialWorkshopTemplatesState = workshopTemplatesAdapter.getInitialState()

export const workshopTemplatesSlice = createSlice({
    name: 'workshopTemplates',
    initialState: initialWorkshopTemplatesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadWorkshopTemplates.fulfilled, (state, {payload}) => {
                templatesStateService.setWorkshopTemplates(payload, state)
            })
    }
})

export const workshopsSlice = createSlice({
    name: 'workshops',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadOwnWorkshops.fulfilled, (state, {payload: workshops}) => {
                stateService.addWorkshops(workshops, state)
                workshops.sort((w1, w2) => moment.utc(w2.start).valueOf() - moment.utc(w1.start).valueOf())
                state.myWorkshops = workshops.map(workshop => workshop.id)
            })
            .addCase(loadParticipatingWorkshops.fulfilled, (state, {payload: workshops}) => {
                stateService.addWorkshops(workshops, state)
                workshops.sort((w1, w2) => moment.utc(w2.start).valueOf() - moment.utc(w1.start).valueOf())
                state.participatingWorkshops = workshops.map(workshop => workshop.id)
            })
            .addCase(loadWorkshop.fulfilled, (state, {payload: workshop}) => {
                stateService.addOrUpdateWorkshop(workshop, state)
            })
            .addCase(createWorkshop.fulfilled, (state, {payload: workshop}) => {
                stateService.addWorkshop(workshop, state)
                state.myWorkshops = [workshop.id, ...state.myWorkshops] // TODO put this to the correct location depending on the start time
            })
            .addCase(updateWorkshop.fulfilled, (state, {payload: workshop}) => {
                stateService.addOrUpdateWorkshop(workshop, state)
            })
            .addCase(loadInvitations.fulfilled, (state, {payload: {workshopId, invitations}}) => {
                stateService.setInvitations(workshopId, invitations, state)
            })
            .addCase(loadPhases.fulfilled, (state, {payload: {workshopId, phases}}) => {
                stateService.setPhases(workshopId, phases, state)
            })
            .addCase(PhaseUpdated, (state, {payload: payload}) => {
                stateService.updatePhase(payload.phase, payload.currentPhases, state)
            })
            .addCase(PhaseDeleted, (state, {payload: {phaseId, workshopId}}) => {
                stateService.deletePhase(state, workshopId, phaseId)
            })
            .addCase(WorkshopStartedEvent.fulfilled, (state, {payload: workshop}) => {
                stateService.addOrUpdateWorkshop(workshop, state)
            })
    }
})


