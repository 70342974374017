import {createAsyncThunk} from '@reduxjs/toolkit'
import {loadPhases} from 'src/state/phases'
import {loadWorkshop} from 'src/state/workshops'

export const loadWorkshopAndPhases = createAsyncThunk<void, string>(
    'workshops/load-workshop-and-phases',
    async (workshopId, thunkAPI) => {
        await thunkAPI.dispatch(loadWorkshop(workshopId))
        await thunkAPI.dispatch(loadPhases(workshopId))
    })
