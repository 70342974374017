import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Footer,
    Form,
    Heading,
    Picker,
    TextArea,
    TextField,
    Item
} from '@adobe/react-spectrum'
import moment, {Moment} from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CreateWorkshopModel, loadWorkshopTemplates, selectWorkshopTemplates} from 'src/state/workshops'
import {DateTimePicker} from 'src/components/common/time/DateTimePicker'
import {useAppDispatch, useAppSelector} from 'src/state/types'

interface Props {
    close(): void
    create(createWorkshopModel: CreateWorkshopModel): void
    initialStartDate?: moment.Moment
    initialEndDate?: moment.Moment
}

export const CreateWorkshopDialog: React.FC<Props> = ({
    close,
    create,
    initialStartDate,
    initialEndDate
}) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState<Moment>(initialStartDate || moment().startOf('day').add(8, 'hours'))
    const [endDate, setEndDate] = useState<Moment>(initialEndDate || moment().startOf('day').add(17, 'hours'))
    const [template, setTemplate] = useState('')
    const workshopTemplates = useAppSelector(selectWorkshopTemplates)
    const isValidTitle = useMemo(() => title.length > 0, [title])
    const isValidDescription = useMemo(() => description.length > 0, [description])
    const isValidTemplate = useMemo(() => workshopTemplates.some(t => t.id === template), [template, workshopTemplates])
    const validEndDate = startDate.isBefore(endDate)
    const isValid = isValidTitle && isValidDescription && validEndDate && isValidTemplate
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadWorkshopTemplates())
    }, [dispatch])

    const handleSubmit = () => isValid && create({
        name: title,
        description,
        startTime: moment.utc(startDate).toISOString(),
        endTime: moment.utc(endDate).toISOString(),
        template: template
    })

    return <Dialog size='L' data-testid='create-workshop-dialog'>
        <Heading>{t('dialog.create-workshop.headline')}</Heading>
        <Divider/>
        <Content>
            <Form isRequired necessityIndicator='label' onSubmit={handleSubmit}>
                <Picker
                    label={t('dialog.create-workshop.template-label')}
                    items={workshopTemplates}
                    selectedKey={template}
                    onSelectionChange={key => setTemplate(key as string)}
                    validationState={isValidTemplate ? 'valid' : 'invalid'}
                    placeholder={t('dialog.create-workshop.template-placeholder')}
                    isRequired>
                    {workshopTemplates.map(template => <Item key={template.id}>{template.name}</Item>)}
                </Picker>
                <TextField label={t('dialog.create-workshop.name-label')}
                    placeholder={t('dialog.create-workshop.name-placeholder')}
                    onChange={setTitle}
                    validationState={isValidTitle ? 'valid' : 'invalid'}
                    autoFocus
                    data-testid='create-workshop-dialog-title'
                />

                <div style={{display: 'flex'}}>
                    <DateTimePicker
                        dateTime={startDate}
                        dateLabel={t('dialog.create-workshop.start-date-label')}
                        timeLabel={t('dialog.create-workshop.start-time-label')}
                        onChangeDateTime={setStartDate}
                        data-testid='create-workshop-dialog-title-startDate'
                    />
                </div>
                <div style={{display: 'flex'}}>
                    <DateTimePicker
                        dateTime={endDate}
                        dateLabel={t('dialog.create-workshop.end-date-label')}
                        timeLabel={t('dialog.create-workshop.end-time-label')}
                        onChangeDateTime={setEndDate}
                        validationState={validEndDate ? 'valid' : 'invalid'}
                        data-testid='create-workshop-dialog-title-endDate'
                    />
                </div>
                <TextArea label={t('dialog.create-workshop.description-label')}
                    placeholder={t('dialog.create-workshop.description-placeholder')}
                    onChange={setDescription}
                    validationState={isValidDescription ? 'valid' : 'invalid'}
                    data-testid='create-workshop-dialog-description'
                    height={'size-2400'}
                />
            </Form>
        </Content>
        <Footer>
            <ButtonGroup>
                <Button
                    variant='secondary'
                    onPress={close}
                    data-testid='create-workshop-button-cancel'>{t('button.cancel')}</Button>
                <Button
                    variant='primary'
                    onPress={handleSubmit}
                    data-testid='create-workshop-button-create'>{t('button.create')}</Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
