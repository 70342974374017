import React, {CSSProperties} from 'react'
import {Date} from './Date'
import {Time} from './Time'

interface Props {
    dateTime: string
    style?: CSSProperties
}

export const DateTime: React.FC<Props> = ({dateTime}) => {
    return (
        <div>
            <Date dateTime={dateTime} />
            <Time dateTime={dateTime} style={{marginLeft: 10}} />
        </div>
    )
}

