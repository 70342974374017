import {Button, ButtonGroup, Flex, TextArea, TextField} from '@adobe/react-spectrum'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {Invitation} from 'src/state/invitations'
import {Participant} from 'src/state/participants'
import {useAppDispatch} from 'src/state/types'
import {updateWorkshop, Workshop} from 'src/state/workshops'
import {Card} from '../common/card/Card'
import {DateTimePicker} from 'src/components/common/time/DateTimePicker'

interface Props {
    workshop: Workshop
    invitations: Invitation[],
    participants: Participant[]
}

export const WorkshopDetailsGeneral: React.FC<Props> = (props) => {
    const {workshop, invitations, participants} = props
    const [editMode, setEditMode] = useState(false)
    const [title, setTitle] = useState(workshop.name)
    const [description, setDescription] = useState(workshop.description)
    const [startDate, setStartDate] = useState(moment(workshop.start))
    const [endDate, setEndDate] = useState(moment(workshop.end))
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const cancelEdit = () => {
        setEditMode(false)
        setTitle(workshop.name)
        setDescription(workshop.description)
        setStartDate(moment(workshop.start))
        setEndDate(moment(workshop.end))
    }
    const save = () => {
        setEditMode(false)
        dispatch(updateWorkshop({
            ...workshop,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            name: title,
            description: description
        }))
    }

    return <ScrollContainer>
        <Flex direction='row'>
            <Flex direction='column'>
                <img className='card-image' src={workshop.icon} />
                <div style={{height: 24}}>
                </div>
                <TextField
                    value={title}
                    label={t('workshop-details-general.title-label')}
                    placeholder={t('workshop-details-general.title-placeholder')}
                    data-testid='workshop-details-general-title'
                    isReadOnly={!editMode}
                    onChange={setTitle}
                    width='size-4600'
                    marginTop='size-200'
                />
                <TextArea
                    value={description}
                    label={t('workshop-details-general.description-label')}
                    placeholder={t('workshop-details-general.description-placeholder')}
                    data-testid='workshop-details-general-description'
                    isReadOnly={!editMode}
                    onChange={setDescription}
                    width='size-4600'
                    marginTop='size-200'
                />
                <Flex direction='row' marginTop='size-200' width='size-4600'>
                    <DateTimePicker
                        dateTime={startDate}
                        dateLabel={t('workshop-details-general.start-date-label')}
                        timeLabel={t('workshop-details-general.start-time-label')}
                        onChangeDateTime={setStartDate}
                        data-testid='workshop-details-general-start-date'
                        isReadOnly={!editMode}
                    />
                </Flex>
                <Flex direction='row' marginTop='size-200' width='size-4600'>
                    <DateTimePicker
                        dateTime={endDate}
                        dateLabel={t('workshop-details-general.end-date-label')}
                        timeLabel={t('workshop-details-general.end-time-label')}
                        onChangeDateTime={setEndDate}
                        data-testid='workshop-details-general-end-date'
                        isReadOnly={!editMode}
                    />
                </Flex>
                <ButtonGroup marginTop='size-400'>
                    {!editMode &&
                <Button variant='primary' onPress={() => setEditMode(true)}>Edit</Button>
                    }
                    {editMode && <>
                        <Button variant='secondary'onPress={() => cancelEdit()}>Cancel</Button>
                        <Button variant='primary'onPress={() => save()}>Save</Button>
                    </>
                    }
                </ButtonGroup>
            </Flex>
            <Flex direction='column' marginStart='size-400'>
                <Card>
                    {{
                        title: 'Attendees',
                        content: <div>
                            <div>Number of invitations: {invitations.length}</div>
                            <div>Number of attendees: {participants.length}</div>
                        </div>
                    }}
                </Card>
            </Flex>
        </Flex>
    </ScrollContainer>
}
