import styled from 'styled-components'

export const InnerCircle = styled.div<{ size: number, backgroundColor: string }>`
  background-color: ${({backgroundColor}) => backgroundColor};
  display: inline-block;
  border-radius: 50%;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  text-align: center;
  margin: 2px 0;
`
