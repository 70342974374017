import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useParams} from 'react-router-dom'
import {Item} from 'src/components/layout/breadcrumb/Breadcrumb'
import {useAppSelector} from 'src/state/types'
import {selectWorkshopById} from 'src/state/workshops'
import {WithWorkshopId} from 'src/types'

export const WorkshopBreadcrumb: React.FC = () => {
    const {workshopId} = useParams<WithWorkshopId>()
    const workshop = useAppSelector(selectWorkshopById(workshopId))
    const {t} = useTranslation()

    return <>
        <Item>{workshop?.name || t('breadcrumb.workshop')}</Item>
        <Route path='/workshops/:workshopId/cafe'><Item>{t('breadcrumb.cafe-room')}</Item></Route>
        <Route path='/workshops/:workshopId/plenary'><Item>{t('breadcrumb.plenary-room')}</Item></Route>
        <Route path='/workshops/:workshopId/configure'><Item>{t('breadcrumb.workshop-configure')}</Item></Route>
    </>
}
