import {DeviceController} from 'amazon-chime-sdk-js'
import {useEffect, useMemo, useRef, useState} from 'react'

type TimerHandler = (...args: any[]) => void;
export type Analyzer = { analyserNode: AnalyserNode, data: Uint8Array }

export function useLocalAudioInputActivity(controller: DeviceController): number {
    const [activity, setActivity] = useState<number>(0)
    const savedCallbackRef = useRef<TimerHandler>()

    let lastDecimal: number

    function analyserNodeCallback() {
        if (!analyzer || !analyzer.analyserNode) {
            return
        }

        analyzer.analyserNode.getByteTimeDomainData(analyzer.data)
        const lowest = 0.01
        let max = lowest
        for (const f of analyzer.data as any) {
            max = Math.max(max, (f - 128) / 128)
        }
        const decimal = (Math.log(lowest) - Math.log(max)) / Math.log(lowest)

        if (lastDecimal !== decimal) {
            lastDecimal = decimal
            setActivity(decimal)
        }
    }

    const analyzer: Analyzer | undefined = useMemo(() => {
        const analyserNode = controller.createAnalyserNodeForAudioInput()
        if (!analyserNode?.getByteTimeDomainData) {
            return
        }
        const data = new Uint8Array(analyserNode.fftSize)
        return {analyserNode, data}
    }, [controller])

    useEffect(() => {
        savedCallbackRef.current = analyserNodeCallback
        const handler = (...args: any[]) => savedCallbackRef.current!(...args)

        const intervalId = setInterval(handler, 100)
        return () => {
            clearInterval(intervalId)
        }
    }, [controller])

    return activity
}
