import React from 'react'
import {Route, Switch} from 'react-router-dom'
import './ToolBarControls.css'
import {WorkshopControls} from './workshop/WorkshopControls'

export const ToolBarControls: React.FC = () => {
    return (
        <Switch>
            <Route path='/workshops/:workshopId'><WorkshopControls/></Route>
        </Switch>
    )
}
