import {Button, DialogTrigger} from '@adobe/react-spectrum'
import {AsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {createTopic, CreateTopicModel, Topic} from 'src/state/topics'
import {WithWorkshopId, WithPhaseId} from 'src/types'
import {CreatedTopic, CreateTopicDialog} from './CreateTopicDialog'

type Props = WithWorkshopId & WithPhaseId & {
    redirectToTopic: boolean
    actionCreator: AsyncThunk<Topic, CreateTopicModel, Record<string, unknown>>
}

export const CreateTopicButton: React.FC<Props> = ({workshopId,phaseId, redirectToTopic, actionCreator}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const history = useHistory()
    const createTopicAndRedirect = async (topic: CreatedTopic) => {
        const result: PayloadAction<Topic> = (await dispatch(actionCreator({
            workshopId,
            phaseId,
            topic,
        }))) as unknown as PayloadAction<Topic>
        if(result.type === createTopic.fulfilled.type && redirectToTopic) {
            history.push(`/workshops/${workshopId}/topics/${result.payload.id}`)
        }
        setOpen(false)
    }
    return (
        <DialogTrigger isDismissable
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}>
            <Button variant='primary'>{t('button.add-topic')}</Button>
            <CreateTopicDialog
                saveLabel={t('button.create')}
                enableDescription={true}
                close={() => {
                    setOpen(false)
                }}
                create={createTopicAndRedirect}/>
        </DialogTrigger>
    )
}
