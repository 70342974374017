import {Attendee} from 'amazon-chime-sdk-js'
import {API} from 'aws-amplify'
import {GetMeetingAttendees} from 'src/api/meetings/meetings.dto'
import {ChimeMeeting} from 'src/state/meetings'

export class MeetingsApi {

    requestMeeting(meetingId: string): Promise<ChimeMeeting> {
        return API.post('backend', `api/meetings/${meetingId}`, {})
    }

    createAttendee(meetingId: string): Promise<Attendee> {
        return API.post('backend', `api/meetings/${meetingId}/attendees`, {})
    }

    async getAttendees(meetingId: string): Promise<Attendee[]> {
        const response: GetMeetingAttendees = await API.get('backend', `api/meetings/${meetingId}/attendees`, {})
        return response._embedded?.attendees || []
    }

    removeAttendee(meetingId: string, attendeeId: string): Promise<void> {
        return API.del('backend', `api/meetings/${meetingId}/attendees/${attendeeId}`, {})
    }
}
