import {Participant} from 'src/state/participants'

export type ParticipantRosterSortComparator = (me?: Participant) => (participant1: Participant, participant2: Participant) => number
export const sortParticipantRosterItems: ParticipantRosterSortComparator = (me) => (a, b) => {
    if (a.id === me?.id) {
        return -1
    }
    if (b.id === me?.id) {
        return 1
    }
    return a.name.localeCompare(b.name)
}
