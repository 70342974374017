import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {WithAuthState} from 'src/state/auth'
import {WithParticipantsState} from 'src/state/participants'
import {ParticipantLocationUpdate} from './participant-locations.events'
import {LocationType, WithParticipantLocationState} from './participant-locations.model'

export interface LocationUpdate {
    location?: {
        type: LocationType
        id?: string
    }
    meetingId?: string
}

export const updateLocation = createAsyncThunk<ParticipantLocationUpdate, LocationUpdate>(
    'participant-locations/update',
    (model, thunkAPI) => {
        const state = thunkAPI.getState() as WithAuthState & WithParticipantsState & WithParticipantLocationState
        return {
            ...model,
            participantId: state.auth.user!.id,
            sessionId: state.participantLocations.sessionId,
            lastSeen: new Date().getTime()
        }
    }
)

export const removeOutdatedParticipantLocations = createAction('participant-locations/remove-outdated')
