import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {LayoutConfigState, selectLayoutConfigState} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import {toCss} from '../util'

export const AppBar: React.FC<{ title: ReactNode }> = ({title}) => {
    const layout = useAppSelector(selectLayoutConfigState)
    return <StyledAppBar layout={layout} className='app-bar'>
        <div className='app-bar-container'>
            {title}
        </div>
    </StyledAppBar>
}

const StyledAppBar = styled.div<{
    layout: LayoutConfigState
}>`
transition: all 0.3s linear;
-webkit-box-shadow: 0px 1px 4px #00000026;
box-shadow:0px 1px 4px #00000026;
background-color: #EBEBEB;
height: calc(${({layout}) => toCss(layout.header.height)} - 2px);
flex: none;
display: flex;

h1 {
    margin-top: -5px;
    margin-bottom: 5px;
    display: flex;
}

div.app-bar-container {
    display: flex;
}

div.controls {
    margin-left: auto;
    margin-top: -5px;
}
`
