import {Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {StickyNote} from 'src/components/meetings/topic-room/topic-breakout/tools/StickyNote'
import {NoteForTopic} from 'src/state/topic-notes/topic-notes.model'

export const Whiteboard: React.FC<{
    notes: NoteForTopic[],
    onUpdateNote(note: NoteForTopic): void
    onDeleteNote(note: NoteForTopic): void
    canEditNotes: boolean
}> = ({
    notes,
    canEditNotes,
    onUpdateNote,
    onDeleteNote
}) => {
    return <View>
        <Flex direction='row' gap='size-200' wrap>
            {notes.map(note => <StickyNote
                key={note.id}
                note={note}
                canEdit={canEditNotes}
                onUpdate={onUpdateNote}
                onDelete={onDeleteNote}
            />)}
        </Flex>
    </View>
}

