import {AsyncThunk, createAsyncThunk} from '@reduxjs/toolkit'
import {WithModeratedWithRoleState} from './moderated-with-roles.model'
import {TopicAssignmentRole} from 'src/state/topic-assignments'
import {TopicAssignmentsApiService} from 'src/state/topic-assignments/topic-assignments.service'
import {WithModeratedWithRolesId, WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'

export type SetActiveRoleParam = WithWorkshopId & WithPhaseId & WithTopicId & {
    activeRole: TopicAssignmentRole
}

export const setActiveRole = createAsyncThunk<void, SetActiveRoleParam>(
    'moderated-with-roles/set-active-role',
    ({workshopId, phaseId, topicId, activeRole}) => {
        return new TopicAssignmentsApiService().setActiveRole(workshopId, phaseId, topicId, activeRole)
    }
)

export const toggleModeratedWithRolesRole = createAsyncThunk<
    void,
    WithWorkshopId &
    WithPhaseId &
    WithTopicId &
    WithModeratedWithRolesId &
    {setActiveRoleThunk?: AsyncThunk<any, any, any>}
        >(
            'moderated-with-roles/toggle-active-role',
            ({
                workshopId,
                phaseId,
                topicId,
                moderatedWithRolesId,
                setActiveRoleThunk= setActiveRole
            },
            thunkAPI) => {
                const state: WithModeratedWithRoleState = thunkAPI.getState() as WithModeratedWithRoleState
                const activeRole = state.moderatedWithRoles.entities[moderatedWithRolesId]?.activeRole
                const newRole = activeRole === 'debater' ? 'evaluator' : 'debater'
                thunkAPI.dispatch(setActiveRoleThunk({workshopId, phaseId, topicId, activeRole: newRole}))
            }
        )
