import {WithAuthState} from 'src/state/auth'
import {topicsAdapter} from './topics.adapter'
import {Topic, WithTopicsState} from './topics.model'

const topicsSelectors = topicsAdapter.getSelectors<WithTopicsState>(
    (state) => state.topics
)

export const selectTopicsForWorkshop: (id: string) => (state: WithTopicsState) => Topic[] = () => state =>
    topicsSelectors.selectAll(state)

export const selectTopic: (topicId: string) => (state: WithTopicsState) => Topic | undefined = topicId => state =>
    topicsSelectors.selectById(state, topicId)

export const selectIsTopicOwner: (topicId: string) => (state: WithTopicsState & WithAuthState) => boolean = topicId => state => {
    const topic = selectTopic(topicId)(state)
    return topic?.owner === state.auth.user?.id
}
