import React from 'react'
import {BurgerIcon} from '../../icons/BurgerIcon'
import {StyledBurger} from './StyledBurger'

export const Burger: React.FC<{toggle(): void }> = ({toggle}) => {
    return (
        <StyledBurger>
            <a onClick={() => toggle()}><BurgerIcon /></a>
        </StyledBurger>
    )
}
