import {IMessage} from '@stomp/stompjs'
import { useMemo} from 'react'
import { Observable} from 'rxjs'
import {v4} from 'uuid'
import {useStomp} from 'src/hooks/websocket/use-stomp'

export const useWebsocketObservable = (topic: string | undefined): {
    observable: Observable<IMessage> | undefined
    subscriptionReceipt: Promise<void> | undefined
} => {
    const stompClient = useStomp()
    const {observable, subscriptionReceipt} = useMemo(() => {
        if (!topic || !stompClient) {
            return {observable: undefined, subscriptionReceipt: undefined}
        }
        const receiptId = v4()
        const promise = (new Promise<void>((resolve) => {
            stompClient.watchForReceipt(receiptId, function() {
                console.log('subscription established', topic, receiptId)
                resolve()
            })
        }))

        const watch = stompClient.watch({
            destination: topic,
            subHeaders: {receipt: receiptId}
        })
        return {observable: watch, subscriptionReceipt: promise}
    }, [stompClient, topic])
    
    return {observable, subscriptionReceipt}
}
