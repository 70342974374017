import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {
    ParticipantRosterSortComparator,
    sortParticipantRosterItems,
    useActiveAndInactiveParticipants
} from 'src/hooks'
import {Participant} from 'src/state/participants'
import {ParticipantRoles, WithRoles, WithWorkshopId} from 'src/types'
import './ParticipantRoster.css'
import {ParticipantRosterItem} from './ParticipantRosterItem'

export type ParticipantRosterProps = WithWorkshopId & WithRoles & {
    showAbsentParticipants?: boolean
    includeRoles?: string[]
    excludeRoles?: string[]
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
    wrap?: boolean | 'wrap' | 'nowrap' | 'wrap-reverse'
    className?: string,
    participantSortComparator?: ParticipantRosterSortComparator
}

export function hasRole(participantRoles: ParticipantRoles, participant: Participant, roles: string[]): boolean {
    return (participantRoles[participant.id] || []).some(role => roles.includes(role))
}

export const ParticipantRoster: React.FC<ParticipantRosterProps> = (
    {
        workshopId,
        direction,
        roles,
        showAbsentParticipants,
        includeRoles,
        excludeRoles,
        wrap,
        className,
        participantSortComparator= sortParticipantRosterItems
    }) => {
    const participants = useActiveAndInactiveParticipants(workshopId,
        roles, includeRoles, excludeRoles,
        Boolean(showAbsentParticipants),
        participantSortComparator)

    return <div className={className}>
        <Flex direction={direction} wrap={wrap} columnGap={'size-25'} rowGap={'size-25'} >
            <>
                {participants.map((participant, index) =>
                    <ParticipantRosterItem key={index} participant={participant}/>)}
            </>
        </Flex>
    </div>
}
