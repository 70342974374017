import {Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {BackToWorkshopButton} from 'src/components/meetings/topic-room/topic-breakout/header/BackToWorkshopButton'
import {TopicStatusMarker} from 'src/components/meetings/topic-room/topic-breakout/header/TopicStatusMarker'
import {TopicStatusText} from 'src/components/meetings/topic-room/topic-breakout/header/TopicStatusText'
import {selectIsTopicOwner} from 'src/state/topics'
import {useAppSelector} from 'src/state/types'
import {WithTopic, WithWorkshopId} from 'src/types'

export const TopicRoomHeaderInfo: React.FC<WithWorkshopId & WithTopic & {
    showStatus: boolean
    canOwnerLeaveTopic: boolean
}> = ({
    workshopId,
    topic,
    showStatus,
    canOwnerLeaveTopic
}) => {
    const isTopicOwner = useAppSelector(selectIsTopicOwner(topic.id))
    if(showStatus && (topic.status === 'ACCEPTED' || topic.status === 'CLOSED'))
        return  <Flex direction='row'>
            <View>
                <BackToWorkshopButton workshopId={workshopId} />
            </View>
            <View>
                <TopicStatusMarker topicStatus={topic.status}/>
            </View>
            <View>
                <TopicStatusText topicStatus={topic.status} />
            </View>
        </Flex>

    if(!isTopicOwner || canOwnerLeaveTopic)
        return <BackToWorkshopButton workshopId={workshopId} />
    return <></>
}

