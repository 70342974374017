import {API} from 'aws-amplify'
import {CallDto, GetCallsResult} from 'src/api/calls/calls.dto'

export class CallsApi {
    async getAllCalls(workshopId: string): Promise<CallDto[]> {
        const result: GetCallsResult = await API.get('backend', `api/workshops/${workshopId}/calls`, {})
        return result._embedded?.calls || []
    }

    async startCall(workshopId: string, invitedParticipantId: string, meetingId: string): Promise<CallDto> {
        return await API.post('backend', `api/workshops/${workshopId}/calls`, {
            body: {
                invitedParticipant: invitedParticipantId,
                meetingId: meetingId
            }
        })
    }

    async addParticipant(workshopId: string, invitedParticipantId: string, callId: string): Promise<CallDto> {
        return await API.put('backend', `api/workshops/${workshopId}/calls/${callId}/participants/${invitedParticipantId}`, {})
    }

    async leaveCall(workshopId: string, meetingId: string, participantId: string): Promise<void> {
        await API.del('backend',
            `api/workshops/${workshopId}/calls/${meetingId}/participants/${participantId}`, {})
    }
}
