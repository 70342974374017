import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {NoActivePhasePlaceholder} from 'src/container/rooms/plenary/NoActivePhasePlaceholder'
import {selectTopicsForWorkshop} from 'src/state/topics'
import {useAppSelector} from 'src/state/types'
import {WithWorkshopId} from 'src/types'
import { WorkshopResultTopicCard } from './WorkshopResultTopicCard'

export const WorkshopResults: React.FC<WithWorkshopId> = ({workshopId}) => {
    const topics = useAppSelector(selectTopicsForWorkshop(workshopId))
        .filter(topic => topic.status === 'ACCEPTED')

    return topics.length > 0 ? <Flex direction='row' wrap='wrap' gap='size-200' UNSAFE_className={'topic-cards'}>
        {topics.map(topic => <WorkshopResultTopicCard key={topic.id} topic={topic} workshopId={workshopId}/>)}
    </Flex> : <NoActivePhasePlaceholder/>
}
