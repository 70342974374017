import React from 'react'
import {ScheduleItem} from 'src/state/schedule'
import {Icon, Time} from 'src/components/common'
import CheckedIcon from 'src/components/icons/svg/check.small.disabled.svg'
import {MenuItem} from 'src/components/layout/menu/MenuItem'
import {Flex, Button} from '@adobe/react-spectrum'
import {useTranslation} from 'react-i18next'
import './ScheduleMenuItem.css'
import {TopicAssignmentRole} from 'src/state/topic-assignments'

export interface ScheduleMenuItemProps {
    variant: 'icon' | 'full'
    item: ScheduleItem
}

export const ScheduleMenuItem: React.FC<ScheduleMenuItemProps> = (
    {
        variant,
        item
    }) => {
    const {t} = useTranslation()

    const roleTranslations: Record<TopicAssignmentRole, string> = {
        moderator: t('participant.roles.moderator'),
        networker: t('participant.roles.networker'),
        debater: t('participant.roles.debater'),
        evaluator: t('participant.roles.evaluator'),
    }
    const createContent = () => {
        if(item.status === 'started') {
            switch (item.type) {
            case 'Global':
                return <Flex direction='column'>
                    <span className='schedule-menu-item-title' title={item.name}>{item.name}</span>
                    <Button variant='primary'>{t('button.join-plenary')}</Button>
                </Flex>
            case 'LeadModerator':
                return <Flex direction='column'>
                    <span className='schedule-menu-item-title' title={item.name}>{item.name}</span>
                    <Button variant='primary'>{t('button.join-control-center')}</Button>
                </Flex>
            case 'Personal':
                return <Flex direction='column'>
                    <span className='schedule-menu-item-title' title={item.phase}>{item.phase}</span>
                    <span className='schedule-menu-item-title' title={item.name}>{item.name}</span>
                    <span className='schedule-menu-item-role'>Your role: {roleTranslations[item.role || 'networker']}</span>
                    <Button variant='primary'>{t('button.join-topic')}</Button>
                </Flex>
            }
        }
        switch (item.type) {
        case 'Personal':
            return <Flex direction='column'>
                <span className='schedule-menu-item-title' title={item.phase}>{item.phase}</span>
                <span className='schedule-menu-item-title' title={item.name}>{item.name}</span>
                <span className='schedule-menu-item-role'>Your role: {roleTranslations[item.role || 'networker']}</span>
            </Flex>
        }
        return <span className='schedule-menu-item-title' title={item.name}>{item.name}</span>
    }

    return <MenuItem variant={variant} to={item.url} key={item.phaseId} className={item.status === 'started' ? 'active-phase' : 'inactive-phase'}>
        {{
            icon: item.status === 'finished' ? <div className='schedule-menu-item-done'><Icon size='small' icon={CheckedIcon} /></div>
                : <div className='schedule-menu-item-time-container'><div className='schedule-menu-item-time'><Time dateTime={item.start} title={item.name}/></div></div>,
            content: createContent(),
            disabled: item.status !== 'started'
        }}
    </MenuItem>
}
