import {createSlice} from '@reduxjs/toolkit'
import {loadPhases} from './phases.actions'
import {phasesAdapter} from './phases.adapter'
import {PhaseDeleted, PhaseUpdated} from './phases.events'

export const initialState = phasesAdapter.getInitialState()
export const phasesSlice = createSlice({
    name: 'phases',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => builder
        .addCase(loadPhases.fulfilled, (state, {payload}) => {
            phasesAdapter.upsertMany(state, payload.phases)
        })
        .addCase(PhaseUpdated, (state, {payload}) => {
            phasesAdapter.upsertOne(state, payload.phase)
        })
        .addCase(PhaseDeleted, (state, {payload}) => {
            phasesAdapter.removeOne(state, payload.phaseId)
        })
})
