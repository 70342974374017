import {lightTheme, MeetingProvider} from 'amazon-chime-sdk-component-library-react'
import {LogLevel} from 'amazon-chime-sdk-js'
import {ConnectedRouter} from 'connected-react-router'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import {Provider as ReactReduxProvider} from 'react-redux'
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components'
import {configureAmplify} from './tools/amplify'
import App from './App'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-times/css/material/default.css'
import 'rc-time-picker/assets/index.css'
import reportWebVitals from './report-web-vitals'
import {store, history} from './state/store'
import './tools/i18n'

configureAmplify()

ReactDOM.render(
    <ReactReduxProvider store={store}>
        <ConnectedRouter history={history}>
            <StyledComponentsThemeProvider theme={lightTheme}>
                <MeetingProvider logLevel={LogLevel.INFO}>
                    <Suspense fallback="loading">
                        <App/>
                    </Suspense>
                </MeetingProvider>
            </StyledComponentsThemeProvider>
        </ConnectedRouter>
    </ReactReduxProvider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
