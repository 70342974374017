import React from 'react'
import {TopicPreview} from './TopicPreview'
import {selectModeratedQueueBy} from 'src/state/moderated-queue'
import {selectMyParticipant} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'
import { WithPhaseId} from 'src/types'

export const MyTopicPreview: React.FC<WithPhaseId> = ({
    phaseId
}) => {
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    const me = useAppSelector(selectMyParticipant)

    if (!queue.isNextInQueue(me)) {
        return <></>
    }

    return <TopicPreview
        topicId={queue.next.topicId}
    />
}

