import {API} from 'aws-amplify'
import {Response} from '../response'
import {CreateNoteDto, GetNotesDto, NoteDto} from './topic-notes.dto'
import {validateNoteDto} from './topic-notes.validation'
import {validate} from 'src/tools/validation'
import {v4 as uuid} from 'uuid'

export class NotesForTopicApi {
    async createNote(model: CreateNoteDto): Promise<NoteDto> {
        const note = await API.post('backend', `api/workshops/${model.workshopId}/topics/${model.topicId}/notes`, {
            body: {
                id: uuid(),
                note: model.note
            }
        })
        this.validateNote(note)
        return note
    }

    async loadNotes(workshopId: string, topicId: string): Promise<NoteDto[]> {
        const result: Response<GetNotesDto> = await API.get('backend', `api/workshops/${workshopId}/topics/${topicId}/notes`, {})
        result._embedded?.notes.forEach(note => this.validateNote(note))
        return result._embedded?.notes || []
    }

    validateNote(note: NoteDto): void {
        validate<NoteDto>(note, validateNoteDto())
    }

    deleteNote(workshopId: string, topicId: string, noteId: string): Promise<void> {
        return API.del('backend', `api/workshops/${workshopId}/topics/${topicId}/notes/${noteId}`, {})
    }

    async updateNote(workshopId: string, topicId: string, noteDto: NoteDto): Promise<NoteDto> {
        return API.put('backend', `api/workshops/${workshopId}/topics/${topicId}/notes/${noteDto.id}`, {
            body: noteDto
        })
    }
}
