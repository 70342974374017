import styled from 'styled-components'

export const OuterCircle = styled.div<{
  size: number
  backgroundColor: string
  borderColor: string
}>`
  background-color: ${({backgroundColor}) => backgroundColor};
  display: inline-block;
  border-radius: 50%;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  text-align: center;
  border: 1px solid ${({borderColor}) => borderColor};
  -webkit-box-shadow: -4px 5px 6px -5px #000000; 
  box-shadow: -4px 5px 6px -5px #000000;
  :hover {
    cursor: pointer;
  }
`
