import {Button, ButtonGroup} from '@adobe/react-spectrum'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DeviceDialogContainer} from 'src/components/control/DeviceDialogContainer'
import {useMeeting} from 'src/hooks'
import {ModeOfInteraction} from 'src/state/common.model'
import { setDeviceDialogShown, setMute, setVideoActive} from 'src/state/device-settings'
import {
    MeetingAdapter,
    MeetingObject,
    requestMeeting, selectActiveMeeting, selectShouldShowDeviceSettingsDialog
} from 'src/state/meetings'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithMeetingId, WithWorkshopId, WithPhaseId} from 'src/types'

export const VideoConferenceForId: React.FC<WithWorkshopId & WithMeetingId & {
    modeOfInteraction: ModeOfInteraction,
    meetingObject?: MeetingObject
}> = ({
    workshopId,
    meetingId,
    modeOfInteraction,
    meetingObject
}) => {
    const activeMeeting = useAppSelector(selectActiveMeeting)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const startMeeting = async () => {
            await dispatch(requestMeeting({meetingId, modeOfInteraction, meetingObject}))
        }
        startMeeting()
    }, [meetingId])

    return <>
        {activeMeeting ? <Meeting
            workshopId={workshopId}
            phaseId={meetingId}
            meeting={activeMeeting}
        /> : <></>}
    </>
}

export const Meeting: React.FC<WithWorkshopId & WithPhaseId & {
    meeting: MeetingAdapter
}> = ({meeting}) => {
    useMeeting(meeting)
    const dispatch = useAppDispatch()
    const shouldShowDeviceSettingsDialog = useAppSelector(selectShouldShowDeviceSettingsDialog)
    const [deviceDialogOpen, setDeviceDialogOpen] = useState<boolean>(shouldShowDeviceSettingsDialog)
    const {t} = useTranslation()

    return meeting ? <>
        <DeviceDialogContainer open={deviceDialogOpen} setOpen={(open) => {
            setDeviceDialogOpen(open)
            if (!open) {
                dispatch(setDeviceDialogShown())
            }
        }} dismissible={false}
        actionButtons={<ButtonGroup>
            <Button
                variant='secondary'
                onPress={() => {
                    dispatch(setMute(true))
                    dispatch(setVideoActive(false))
                    dispatch(setDeviceDialogShown())
                    setDeviceDialogOpen(false)
                }}
                data-testid='create-workshop-button-cancel'>{t('button.cancel')}</Button>
            <Button
                variant='primary'
                data-testid='create-workshop-button-create'
                onPress={() => {
                    dispatch(setDeviceDialogShown())
                    setDeviceDialogOpen(false)
                }}
            >{t('dialog.device-settings.button.join')}</Button>
        </ButtonGroup>}
        />
    </> : <>Meeting not yet started</>
}
