import {useLocalVideo} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {VideoActiveIcon, VideoInactiveIcon} from 'src/components/icons'
import {setVideoActive} from 'src/state/device-settings'
import {errorOccurred} from 'src/state/error'
import {useAppDispatch} from 'src/state/types'
import {DeviceControl} from './DeviceControl'

export const VideoInputControl: React.FC = () => {
    const {isVideoEnabled, toggleVideo} = useLocalVideo()
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    return <DeviceControl
        active={isVideoEnabled}
        activeIcon={<VideoActiveIcon />}
        inactiveIcon={<VideoInactiveIcon />}
        toggle={() => {
            dispatch(setVideoActive(!isVideoEnabled))
            toggleVideo().catch((error) => {
                console.error(error)
                const message = isVideoEnabled ? t('error.failed-to-stop-video') : t('error.failed-to-start-video')
                dispatch(errorOccurred({message: message}))
            })
        }}
    />
}
