import {Auth} from 'aws-amplify'

export const config = {
    aws_project_region: 'eu-central-1',
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'eu-central-1:5d0a1131-edbf-48d2-a4f5-d563dad15548',
        region: 'eu-central-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID || 'eu-central-1_deGvHKCi7',
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '71pl05d7q0e7gj9dujel2qaf0s',

        oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN || 'login.dev.onemind.ai',
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN || 'http://localhost:3000/,https://dev.onemind.ai/',
            redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT || 'http://localhost:3000/,https://dev.onemind.ai/',
            responseType: 'code'
        },
        federationTarget: 'COGNITO_USER_POOLS',
    },
    API: {
        endpoints: [
            {
                name: 'backend',
                endpoint: process.env.REACT_APP_API_GATEWAY,
                custom_header: async (): Promise<{ [key: string]: string }> => {
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                }
            },
        ]
    },
}

