import {
    MeetingStatus,
    useAudioOutputs,
    useMeetingManager,
    useMeetingStatus
} from 'amazon-chime-sdk-component-library-react'
import {useEffect} from 'react'
import {isDeviceAvailable} from 'src/hooks/devices/utils'
import {selectAudioOutputDeviceId, setAudioOutputDeviceId} from 'src/state/device-settings'
import {useAppDispatch, useAppSelector} from 'src/state/types'

export function useConfiguredAudioOutput(): void {
    const meetingStatus = useMeetingStatus()
    const meetingManager = useMeetingManager()
    const { devices: speakerDevices, selectedDevice } = useAudioOutputs()
    const configuredDevice = useAppSelector(selectAudioOutputDeviceId)
    const dispatch = useAppDispatch()

    useEffect(() => {
        function selectDevice(deviceId: string) {
            meetingManager.selectAudioOutputDevice(deviceId)
                .catch(error => console.error('Failed to configure audio output device', deviceId, error))
        }

        function selectConfiguredOrFirstDevice() {
            if (selectedDevice === configuredDevice) {
                return
            }
            const firstDevice = speakerDevices[0]?.deviceId
            if (configuredDevice && isDeviceAvailable(speakerDevices, configuredDevice)) {
                selectDevice(configuredDevice)
            } else if (firstDevice) {
                selectDevice(firstDevice)
                dispatch(setAudioOutputDeviceId(firstDevice))
            }
        }

        if (meetingStatus === MeetingStatus.Succeeded) {
            selectConfiguredOrFirstDevice()
        }
    }, [meetingStatus, configuredDevice, selectedDevice])
}
