import {Button, DialogTrigger} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useAppDispatch} from 'src/state/types'
import {AddInvitationDialog} from './AddInvitationDialog'
import {addInvitation} from 'src/state/invitations'

export const AddInvitationButton: React.FC<{workshopId: string}> = (props) => {
    const { workshopId } = props
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false)

    return (
        <DialogTrigger isDismissable
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}>
            <Button variant='primary'>Add New</Button>
            <AddInvitationDialog close={() => {
                setOpen(false)
            }} add={(model) => {
                dispatch(addInvitation({ workshopId: workshopId, email: model.email, nickname: model.nickname }))
                setOpen(false)
            }}/>
        </DialogTrigger>
    )
}
