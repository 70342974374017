import {createAsyncThunk} from '@reduxjs/toolkit'
import {loadNotesForTopic} from 'src/state/topic-notes'
import {Votes} from 'src/state/topic-votes'
import {WithTopicId, WithWorkshopId} from 'src/types'
import {CreateTopicModel, Topic, UpdateTopicModel, WithTopicsState} from './topics.model'
import {selectTopic} from './topics.selectors'
import {TopicsApiService} from './topics.service'

const apiService = new TopicsApiService()

export const createTopic = createAsyncThunk<Topic, CreateTopicModel>(
    'topics/createTopic',
    (createTopicModel) => apiService.createTopic(createTopicModel)
)

export const createPredefinedTopic = createAsyncThunk<Topic, CreateTopicModel>(
    'topics/createPredefinedTopic',
    (createTopicModel) => apiService.createPredefinedTopic(createTopicModel)
)

export const closeTopicIfVoting = createAsyncThunk<Topic | undefined, WithWorkshopId & WithTopicId>(
    'topics/closeTopicIfVoting',
    ({workshopId,topicId}, thunkAPI) => {
        const topic = selectTopic(topicId)(thunkAPI.getState() as WithTopicsState)
        if(topic?.status === 'VOTING')
            return apiService.closeTopic(workshopId, topicId)

        return topic
    }
)

export const closeTopic = createAsyncThunk<Topic, WithWorkshopId & WithTopicId>(
    'topics/closeTopic',
    ({workshopId,topicId}) => apiService.closeTopic(workshopId, topicId)
)

export const updateTopic = createAsyncThunk<Topic, UpdateTopicModel>(
    'topics/updateTopic',
    (updateModel) => apiService.updateTopic(updateModel)
)

export const loadTopics = createAsyncThunk<Topic[], {workshopId: string}>(
    'topics/loadTopics',
    ({workshopId}) => apiService.loadTopics(workshopId)
)

export const loadTopicWithNotes = createAsyncThunk<Topic & Votes, WithWorkshopId & WithTopicId>(
    'topics/loadTopic',
    async ({workshopId, topicId}, thunkAPI) => {
        const topic = await apiService.loadTopic(workshopId, topicId)
        thunkAPI.dispatch(loadNotesForTopic({workshopId, topicId}))
        return topic
    }
)
