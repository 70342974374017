import {createAction} from '@reduxjs/toolkit'
import {Votes} from 'src/state/topic-votes'
import {Topic} from './topics.model'
import {workshopEventDispatcher} from '../workshop-event-dispatcher'
import {toTopicWithVotes} from './topics.service'
import {TopicDto} from 'src/api'

export const TopicCreated = createAction<Topic & Votes>('topics/ws/event/topicCreated')
export const TopicUpdated = createAction<Topic & Votes>('topics/ws/event/topicUpdated')

workshopEventDispatcher.registerActionCreator({
    type: 'TopicCreated',
    actionCreator: event => TopicCreated(toTopicWithVotes(event.payload as TopicDto))
})

workshopEventDispatcher.registerActionCreator({
    type: 'TopicUpdated',
    actionCreator: event => TopicUpdated(toTopicWithVotes(event.payload as TopicDto))
})


