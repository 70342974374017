import React from 'react'
import {Workshop} from 'src/state/workshops'
import {Card} from 'src/components/common'
import {WorkshopCardButtons} from './WorkshopCardButtons'
import {WorkshopCardContent} from './WorkshopCardContent'

interface Props {
    workshop: Workshop
}

export const WorkshopCard: React.FC<Props> = (props) => {
    const {workshop} = props
    return (
        <Card>
            {{
                title: workshop.name,
                content: <WorkshopCardContent workshop={workshop}/>,
                buttons: <WorkshopCardButtons workshop={workshop}/>,
                icon: '/workshop192.png',
                width: 'size-3000'
            }}
        </Card>
    )
}
