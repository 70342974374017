import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {ContentWithToolbox} from 'src/components/layout/ContentWithToolbox'
import {Toolbox} from 'src/components/layout/Toolbox'
import {WorkshopListControls} from 'src/components/workshops/WorkshopListControls'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {
    loadOwnWorkshops,
    loadParticipatingWorkshops,
    selectMyWorkshops,
    selectParticipatingWorkshops
} from 'src/state/workshops'
import {WorkshopCards} from 'src/components/workshops/WorkshopCards'
import './WorkshopsPage.css'

export const WorkshopsPage: React.FC = () => {
    const myWorkshops = useAppSelector(selectMyWorkshops)
    const participatingWorkshops = useAppSelector(selectParticipatingWorkshops)
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(loadOwnWorkshops())
        dispatch(loadParticipatingWorkshops())
    }, [dispatch])

    return <ContentWithToolbox toolbox={<Toolbox><WorkshopListControls/></Toolbox>}>
        <WorkshopCards title={t('title.my-workshops')} workshops={myWorkshops}/>
        <WorkshopCards title={t('title.participating-workshops')} workshops={participatingWorkshops.filter(workshop => !myWorkshops.includes(workshop))}/>
    </ContentWithToolbox>
}
