import React, {useState} from 'react'
import {Button, DialogTrigger} from '@adobe/react-spectrum'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {addNote} from 'src/state/topic-notes'
import {CreateNoteDialog} from './CreateNoteDialog'

interface Props {
    workshopId: string
    topicId: string
}

export const AddNoteButton: React.FC<Props> = (props) => {
    const {workshopId, topicId} = props
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    return (
        <DialogTrigger
            isDismissable
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}>
            <Button variant='primary'>{t('button.add-note')}</Button>
            <CreateNoteDialog close={() => {
                setOpen(false)
            }} create={note => {
                dispatch(addNote({
                    workshopId,
                    topicId,
                    note
                }))
                setOpen(false)
            }}/>
        </DialogTrigger>
    )
}
