import React, {useMemo, useState} from 'react'
import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Footer,
    Form,
    Heading,
    TextArea
} from '@adobe/react-spectrum'
import {useTranslation} from 'react-i18next'
interface Props {
    close(): void

    create(note: string): void
}

export const CreateNoteDialog: React.FC<Props> = ({close, create}) => {
    const [note, setNote] = useState('')
    const isValidNote = useMemo(() => note.length > 0, [note])
    const {t} = useTranslation()
    const handleSubmit = () => isValidNote && create(note)
    return <Dialog size='L' data-testid='create-note-dialog' height={'size-4000'}>
        <Heading>{t('dialog.create-note.headline')}</Heading>
        <Divider/>
        <Content>
            <Form isRequired necessityIndicator='label' onSubmit={handleSubmit}>
                <TextArea label={t('dialog.create-note.note-label')}
                    placeholder={t('dialog.create-note.note-placeholder')}
                    onChange={setNote}
                    validationState={isValidNote ? 'valid' : 'invalid'}
                    autoFocus
                    data-testid='create-topic-dialog-title'
                    height={'size-2000'}
                />
            </Form>
        </Content>
        <Footer>
            <ButtonGroup>
                <Button
                    variant='secondary'
                    onPress={close}
                    data-testid='create-topic-button-cancel'>{t('button.cancel')}</Button>
                <Button
                    variant='primary'
                    onPress={handleSubmit}
                    data-testid='create-topic-button-create'>{t('button.create')}</Button>
            </ButtonGroup>
        </Footer>
    </Dialog>
}
