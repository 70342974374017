import React, {useEffect} from 'react'
import {PresentationRosterGrid} from 'src/components/roster/PresentationRosterGrid'
import {PresenterVideo} from './PresenterVideo'
import {attachRolesToMeeting} from 'src/state/meetings'
import {selectMyParticipant, selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithMeetingId, WithRoles, WithWorkshopId} from 'src/types'

export const PresentationMeetingWithRoles: React.FC<WithMeetingId & WithWorkshopId & WithRoles> = ({
    workshopId,
    meetingId,
    roles
}) => {
    const moderatorId = roles.findParticipantWithRole('moderator')
    const participants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const me = useAppSelector(selectMyParticipant)
    const moderator = moderatorId ? participants[moderatorId] : undefined
    const isPresenter = Boolean((me || moderator) && me?.id === moderator?.id)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(attachRolesToMeeting({meetingId, roles: roles.roles}))
    }, [])

    return <>
        <div className='presentation-meeting-container'>
            <div>
                <div className='video-container'>
                    {moderator ? <PresenterVideo moderator={moderator}/> : <></>}
                </div>
            </div>
            <div>
                <div className='presentation-meeting-roster-container'>
                    <PresentationRosterGrid
                        workshopId={workshopId}
                        roles={roles}
                        presenter={isPresenter}
                    />
                </div>
            </div>
        </div>
    </>
}
