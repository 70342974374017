import React from 'react'
import {useMyLocation} from 'src/hooks'
import {MeetingAdapter} from 'src/state/meetings'
import {ParticipantRolesAdapter} from 'src/state/participants'
import {SidebarOpenMeeting} from '../open/SidebarOpenMeeting'
import {PresentationMeetingWithRoles} from '../plenary/presentation/PresentationMeetingWithRoles'
import {Phase, selectCurrentPhase} from 'src/state/phases'
import {selectTopic} from 'src/state/topics'
import {selectUndefined, useAppSelector} from 'src/state/types'
import { WithTopicId, WithWorkshopId} from 'src/types'
import {TopicModeratedWithRolesMeeting} from './moderated-with-roles/TopicModeratedWithRolesMeeting'
import {selectTopicAssignmentRoles} from 'src/state/topic-assignments'

export const TopicBreakoutMeeting: React.FC<WithWorkshopId & { meeting: MeetingAdapter & WithTopicId}> = ({workshopId, meeting}) => {
    const topic = useAppSelector(selectTopic(meeting.topicId))!
    const phase: Phase | undefined = useAppSelector(selectCurrentPhase(workshopId))
    const topicAssignmentRoles = useAppSelector(phase ? selectTopicAssignmentRoles(phase.id, topic.id) : selectUndefined)

    useMyLocation('topic-breakout', topic.id)

    switch (meeting?.modeOfInteraction) {
    case 'presentation': {
        return <PresentationMeetingWithRoles
            meetingId={meeting.id}
            workshopId={workshopId}
            roles={new ParticipantRolesAdapter({
                [topic.owner]: ['moderator']
            })}/>
    }
    case 'open': {
        const roles = phase?.meetingVariant?.type === 'topic-session'
            ? topicAssignmentRoles!
            : new ParticipantRolesAdapter({
                [topic.owner]: ['moderator']
            })
        return <SidebarOpenMeeting workshopId={workshopId} roles={roles}/>
    }
    case 'moderated-with-roles':
        return phase ? <TopicModeratedWithRolesMeeting
            meetingId={meeting.id}
            workshopId={workshopId}
            phaseId={phase.id}
            topicId={meeting.topicId}/> : <>There is no running phase</>
    default:
        return <>Unknown Meeting type</>
    }
}

