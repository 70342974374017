import React, {PropsWithChildren, useState} from 'react'
import {matchPath, useHistory} from 'react-router-dom'
import {TabItemProps} from 'src/components/layout/tab/TabItem'
import {WithWorkshopId} from 'src/types'
import {Tab} from './Tab'
import './tab.css'

type TabsProps = {
    routerPath?: string
}

type ChildProps = TabItemProps & {
    children: []
}

type WithTabChilds = React.ReactElement<ChildProps>[]

export const Tabs: React.FC<TabsProps> = (props: PropsWithChildren<unknown> & TabsProps) => {
    const history = useHistory()

    const children: WithTabChilds = (React.Children.toArray(props.children) as WithTabChilds)
    if(children.length == 0)
        return <div />

    const [activeTab, setActiveTab] = useState(children[0].props.tabKey)
    const onClickTabItem = (tab: string, route: string | undefined) => {
        setActiveTab(tab)
        if (route) {
            history.push(route)
        }
    }

    const isUsingRouter = () => props.routerPath && children.some(child => child.props.route)
    const findMatchingChildByRoute = (children: WithTabChilds, routerPath: string) => children.find(child => {
        const {route} = child.props
        return route && matchPath<WithWorkshopId>(
            routerPath,
            {path: route, exact: true}
        )
    })
    const determineCurrentTab: () => string = () => {
        if (isUsingRouter()) {
            const tab = findMatchingChildByRoute(children, props.routerPath!)
            return tab?.props.tabKey || activeTab
        }
        return activeTab
    }

    const currentTab = determineCurrentTab()

    return (
        <div className="tabs">
            <ol className="tab-list">
                {children.map((child, index) => {
                    const { label, tabKey, route } = child.props
                    return (
                        <Tab
                            key={index}
                            activeTab={currentTab}
                            tabKey={tabKey}
                            label={label}
                            route={route}
                            onClick={onClickTabItem}
                        />
                    )
                })}
            </ol>
            <div className="tab-content">
                {children.map((child) => {
                    if (child.props.tabKey !== currentTab) return undefined
                    return child.props.children
                })}
            </div>
        </div>
    )
}
