import {Flex} from '@adobe/react-spectrum'
import moment from 'moment'
import React from 'react'
import {DeleteButton} from 'src/components/common/DeleteButton'
import {Duration} from 'src/components/common/time'
import {ChangeTime, PickableTime} from './PickableTime'
import {deletePhase, setPhaseEndTime, setPhaseStartTime} from 'src/state/phases/phases.actions'
import {useAppDispatch} from 'src/state/types'
import {WithPhase} from 'src/types'

function applyNewTime(dateTimeAsUtcString: string, newTime: ChangeTime): string {
    return moment.utc(dateTimeAsUtcString)
        .local()
        .hours(newTime.hour)
        .minutes(newTime.minute)
        .utc()
        .toISOString()
}

export const TimeColumn: React.FC<WithPhase> = ({phase}) => {
    const dispatch = useAppDispatch()
    return <div className='time-column'>
        <Flex direction='column'>
            <div className='time'>
                <PickableTime
                    time={phase.startTime}
                    onChangeTime={(newTime) => {
                        dispatch(setPhaseStartTime({
                            phase,
                            startTime: applyNewTime(phase.startTime, newTime)
                        }))
                    }}/>
            </div>
            <div className='duration'>
                <Duration start={phase.startTime} end={phase.endTime}/>
            </div>
            <div className='time'>
                <PickableTime
                    time={phase.endTime}
                    onChangeTime={(newTime) => {
                        dispatch(setPhaseEndTime({
                            phase,
                            endTime: applyNewTime(phase.endTime, newTime)
                        }))
                    }}/>
            </div>
            <div className='controls'>
                <DeleteButton onDelete={() => dispatch(deletePhase({workshopId: phase.workshopId, phase}))} />
            </div>
        </Flex>
    </div>
}
