import styled from 'styled-components'

export const StyledBurger = styled.div`
  color: white;
  left: 0.75rem;
  border: none;
  cursor: pointer;
  padding-left: 8px;
  margin-top: 4px;
  width: 40px;
  height: 40px;
  

  &:focus {
    outline: none;
  }
`
