import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Card} from 'src/components/common/card/Card'
import {Participant} from 'src/state/participants'
import {ParticipantIconWithCircle} from 'src/components/common/ParticipantIconWithCircle'

export const ParticipantManager: React.FC<{ participants: Participant[] }> = ({participants}) => {
    const {t} = useTranslation()

    return <Card>
        {{
            title: t('workshop-details-attendees.participants'),
            content: <Flex direction='row' gap={'size-130'} wrap>
                {participants.map(participant => <Flex direction='column' alignItems="center" key={participant.id}>
                    <ParticipantIconWithCircle className={'participant-icon'} participant={participant}/>
                    <span>{participant.name}</span>
                </Flex>)
                }
            </Flex>
        }}
    </Card>
}
