import {Button, DialogTrigger} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useAppDispatch} from 'src/state/types'
import {createWorkshop} from 'src/state/workshops'
import {CreateWorkshopDialog} from './CreateWorkshopDialog'
import {useTranslation} from 'react-i18next'

export const CreateWorkshopButton: React.FC = () => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    return (
        <DialogTrigger
            type='modal'
            onOpenChange={setOpen}
            isOpen={open}
        >
            <Button variant='primary'>{t('button.create-workshop')}</Button>
            <CreateWorkshopDialog close={() => {
                setOpen(false)
            }} create={(model) => {
                dispatch(createWorkshop(model))
                setOpen(false)
            }}/>
        </DialogTrigger>
    )
}
