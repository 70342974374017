import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {SmallTopicCard} from './SmallTopicCard'
import {Topic} from 'src/state/topics'

export const SmallTopicCards: React.FC<{ topics: Topic[] }> = ({
    topics
}) => {
    return <Flex direction='row' wrap='wrap' gap='size-200' UNSAFE_className={'topic-cards'}>
        {topics.map(topic => <SmallTopicCard key={topic.id} topic={topic} />)}
    </Flex>

}
