import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Heading,
    Text, Flex, ActionButton
} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ParticipantIcon} from './common/ParticipantIcon'
import {Participant} from '../state/participants'
import {StartVideoCallIcon} from 'src/components/icons/StartVideoCallIcon'
import {StartAudioCallIcon} from 'src/components/icons/StartAudioCallIcon'
import {useAppDispatch} from 'src/state/types'
import {startAudioCall, startVideoCall} from 'src/state/calls/calls.actions'
import {RenderedBiography} from './RenderedBiography'

interface Props {
    participant: Participant
    close(): void
    canStartCall?: boolean
}

export const ParticipantInfoPopover: React.FC<Props> = ({participant, close, canStartCall}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const dispatchStartVideoCall = () => {
        dispatch(startVideoCall({ participantId: participant.id, workshopId: participant.workshopId }))
        close()
    }
    const dispatchStartAudioCall = () => {
        dispatch(startAudioCall({ participantId: participant.id, workshopId: participant.workshopId }))
        close()
    }

    return <Dialog size='S' data-testid='participant-info-popover'>
        <Heading>
            <Flex alignItems="center" gap="size-100">
                <Text>{participant.name}</Text>
                <ParticipantIcon
                    className={'participant-icon'}
                    participant={participant}/>
            </Flex>
        </Heading>
        <Divider/>
        <Content>
            <RenderedBiography biography={participant.biography || ''}/>
        </Content>
        <ButtonGroup>
            {canStartCall && <ActionButton marginEnd='size-200' onPress={dispatchStartVideoCall}><StartVideoCallIcon /></ActionButton>}
            {canStartCall && <ActionButton marginEnd='size-600' onPress={dispatchStartAudioCall}><StartAudioCallIcon /></ActionButton>}
            <Button variant='secondary' onPress={close}>{t('button.close')}</Button>
        </ButtonGroup>
    </Dialog>
}
