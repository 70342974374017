import {useApplyVideoObjectFit, useAudioVideo} from 'amazon-chime-sdk-component-library-react'
import {BaseSdkProps} from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base'
import {VideoTileState} from 'amazon-chime-sdk-js'
import React, {HTMLAttributes, useEffect, useRef} from 'react'
import CustomVideoTile from './CustomVideoTile'

interface Props
    extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
        BaseSdkProps {
    tileId: number
}

export const CustomRemoteVideo: React.FC<Props> = ({
    className,
    tileId,
}) => {
    const audioVideo = useAudioVideo()
    const videoEl = useRef<HTMLVideoElement>(null)
    useApplyVideoObjectFit(videoEl)

    useEffect(() => {
        if (!audioVideo || !videoEl.current) {
            return
        }

        audioVideo.bindVideoElement(tileId, videoEl.current)

        return () => {
            const tile = audioVideo.getVideoTile(tileId)
            if (tile) {
                audioVideo.unbindVideoElement(tileId)
            }
        }
    }, [audioVideo, tileId])

    useEffect(() => {
        if (!audioVideo) {
            return
        }

        const observer = {
            videoTileDidUpdate: (tileState: VideoTileState) => {
                if (!tileState.boundAttendeeId ||
                  tileState.localTile ||
                  !tileState.tileId ||
                  !videoEl.current
                ) {
                    return
                }
                /*if (tileId === tileState.tileId) {
                    cloneStreamIfRequired(tileState, videoEl)
                }*/
            },
        }

        audioVideo.addObserver(observer)

        return () => audioVideo.removeObserver(observer)
    }, [audioVideo])


    return (
        <CustomVideoTile
            ref={videoEl}
            className={`ch-remote-video--${tileId} ${className || ''}`}
        />
    )
}

export default CustomRemoteVideo
