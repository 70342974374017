import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {InvitationManager} from './InvitationManager'
import './ParticipantAndInvitationManager.css'
import {ParticipantManager} from './ParticipantManager'
import {Invitation} from 'src/state/invitations'
import {Participant} from 'src/state/participants'
import {WithWorkshop} from 'src/types'

interface Props {
    invitations: Invitation[],
    participants: Participant[]
}

export const ParticipantAndInvitationManager: React.FC<WithWorkshop & Props> = ({
    workshop,
    invitations,
    participants
}) => {

    return <Flex direction='row' gap='size-130' UNSAFE_className={'participant-invitation-manager'}>
        <ScrollContainer> <InvitationManager workshop={workshop} invitations={invitations}/> </ScrollContainer>
        <ScrollContainer> <ParticipantManager participants={participants}/></ScrollContainer>
    </Flex>

}
