import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {WorkshopCard} from 'src/components/workshops/card/WorkshopCard'
import {Workshop} from 'src/state/workshops'

export const WorkshopCards: React.FC<{
    title: string
    workshops: Workshop[]
}> = ({title, workshops}) => {
    return workshops.length > 0 ? <>
        <h2 id="workshop-overview-title">{title}</h2>
        <Flex direction='row' wrap gap="size-300" marginBottom={'size-300'}>
            {workshops.map(workshop => <div key={workshop.id}>
                <WorkshopCard workshop={workshop}/>
            </div>)}
        </Flex>
    </> : <></>

}
