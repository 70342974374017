import React from 'react'
import {Route} from 'react-router-dom'
import {WithWorkshopId, WithPhase} from 'src/types'
import {TopicGenerationControls} from 'src/components/meetings/plenary/topic-generation/open/content/controls/TopicGenerationControls'
import {CurrentRoomControls} from './CurrentRoomControls'

export const WorkshopPhaseControls: React.FC<WithWorkshopId & WithPhase> = (
    {workshopId, phase}) => {
    return <>
        <CurrentRoomControls />
        { phase.meetingVariant?.type === 'topic-generation' &&
        <Route exact path='/workshops/:workshopId/plenary'><TopicGenerationControls workshopId={workshopId} phaseId={phase.id} /></Route>
        }
    </>
}

