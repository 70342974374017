import {ActionButton, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {toggleModeratedWithRolesRole} from 'src/state/moderated-with-roles'
import {useAppDispatch} from 'src/state/types'
import {WithModeratedWithRolesId, WithTopicId, WithWorkshopId, WithPhaseId} from 'src/types'

export const ModeratedWithRolesControls: React.FC<WithWorkshopId & WithPhaseId & WithTopicId & WithModeratedWithRolesId> = ({
    workshopId,
    phaseId,
    topicId,
    moderatedWithRolesId
}) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    return <>
        <ActionButton onPress={() => {
            dispatch(toggleModeratedWithRolesRole({
                workshopId,
                phaseId,
                topicId,
                moderatedWithRolesId
            }))
        }}>
            <Text>{t('button.switch-roles')}</Text>
        </ActionButton>
    </>
}
