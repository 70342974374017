import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {
    selectMeetingLayout
} from 'src/state/layout'
import {useAppSelector} from 'src/state/types'
import styled from 'styled-components'

export const Toolbox: React.FC = (props) => {
    const drawerRight = useAppSelector(selectMeetingLayout)
    const canShowToolbox = drawerRight !== 'large'
    return canShowToolbox && props.children ? (
        <StyledToolbar {...props}>
            <div className='toolbar-container'>
                <div><Flex gap={'size-130'} direction={'row'}><>{props.children}</></Flex></div>
            </div>
        </StyledToolbar>
    ) : <></>
}

const StyledToolbar = styled.div`
display: flex;
transition: all 0.3s linear;
margin-bottom: 12px;
margin-left: -12px;
margin-right: -12px;
background-color: white;
border: 1px solid #00000000;
border-radius: 4px;
height: 56px;
-webkit-box-shadow: 0px 1px 4px #00000026; 
box-shadow: 0px 1px 4px #00000026;
justify-content: center;
align-items: center;

div.toolbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

div.toolbar-container > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
`
