import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {useDeviceController} from 'src/hooks/devices/use-device-controller'
import {useDevices} from 'src/hooks/devices/use-devices'

export const VIDEO_INPUT = {
    NONE: 'None',
    BLUE: 'Blue',
    SMPTE: 'SMPTE Color Bars',
}

export const additionalVideoDevices: DeviceType[] = Object.entries(VIDEO_INPUT)
    .map(device => ({
        deviceId: device[0].toLocaleLowerCase(),
        label: device[1]
    }))

export function useVideoDevices(): DeviceType[] {
    const controller = useDeviceController()

    return useDevices(() => {
        return controller.listVideoInputDevices()
            .then(devices => [...devices, ...additionalVideoDevices])
    })
}
