import {Button, Text} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DeleteConfirmDialog} from 'src/components/common/DeleteConfirmDialog'
import {CloseCircleIcon} from 'src/components/icons/CloseCircleIcon'
import {
    deleteQueuedTopic,
    ModeratedTopicQueueAdapter,
    ParticipantAndTopicId,
    QueueWithCurrentItem
} from 'src/state/moderated-queue'
import {useAppDispatch} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'

export const CloseQueuedTopicButton: React.FC<WithWorkshopId & WithPhaseId & {
    queue: ModeratedTopicQueueAdapter & QueueWithCurrentItem<ParticipantAndTopicId>
}> = ({
    workshopId,
    phaseId,
    queue
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

    return <>
        <Button UNSAFE_className={'moderator-button'} flexShrink={0} variant='overBackground' onPress={() => setConfirmOpen(true)}>
            <CloseCircleIcon className={'hover-button-icon'}/>
            <Text>{t('moderated-queue.button.close-topic')}</Text>
        </Button>
        <DeleteConfirmDialog
            headline={t('moderated-queue.close-topic-dialog.headline')}
            description={t('moderated-queue.close-topic-dialog.description')}
            deleteLabel={t('moderated-queue.button.close-topic')}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            deleteConfirmed={() => {
                dispatch(deleteQueuedTopic({
                    workshopId,
                    phaseId,
                    topicId: queue.current.topicId
                }))
            }} />
    </>
}
