import {CSSProperties} from 'react'
import styled from 'styled-components'

export type IconSize = 'small' | 'medium'

export type IconProps = {
    className?: string
    size?: IconSize
    onClick?(): void
    style?: CSSProperties
}

export const Svg = styled.div<{
    icon: string
    className?: string
    onClick?(): void
    style?: CSSProperties
}>`
display: inline-block;
background: url(${({icon}) => icon});
background-repeat: no-repeat;
background-position: center center;
background-size: contain;
`
