import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {useDeviceController} from 'src/hooks/devices/use-device-controller'
import {useDevices} from '../use-devices'

export const AUDIO_INPUT = {
    NONE: 'None',
    440: '440 Hz',
}

export const additionalAudioInputDevices: DeviceType[] = Object.entries(AUDIO_INPUT)
    .map(device => ({
        deviceId: device[0].toLocaleLowerCase(),
        label: device[1]
    }))

export function useAudioInputDevices(): DeviceType[] {
    const controller = useDeviceController()

    return useDevices(() => {
        return controller.listAudioInputDevices()
            .then(devices => [...devices, ...additionalAudioInputDevices])
    })
}

