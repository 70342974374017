import React from 'react'
import {selectIsTopicOwner, selectTopic} from 'src/state/topics'
import {WithTopicId, WithWorkshopId} from 'src/types'
import {CloseTopicButton} from './controls/CloseTopicButton'
import {useParams} from 'react-router-dom'
import {useAppSelector} from 'src/state/types'

export const TopicMeetingControls: React.FC = () => {
    const {workshopId, topicId} = useParams<WithWorkshopId & WithTopicId>()
    const isTopicOwner = useAppSelector(selectIsTopicOwner(topicId))
    const topic = useAppSelector(selectTopic(topicId))

    if(!topic || !isTopicOwner || topic.status !== 'VOTING') return <></>
    return <CloseTopicButton workshopId={workshopId} topic={topic} />
}
