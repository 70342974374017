import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {WorkshopCommunication} from './WorkshopCommunication'

export const RightDrawerContent: React.FC = () => {
    return <>
        <Switch>
            <Route path='/workshops/:workshopId'><WorkshopCommunication /></Route>
        </Switch>
    </>
}
