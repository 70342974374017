import {Divider, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {AcceptQueuedTopicButton} from 'src/components/meetings/plenary/topic-generation/moderated-queue/meeting/moderator-controls/AcceptQueuedTopicButton'
import {acceptQueuedTopic, selectModeratedQueueBy} from 'src/state/moderated-queue'
import {selectLeadModerator, selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'
import {UpNext} from './UpNext'


export const UpNextIfPresent: React.FC<WithWorkshopId & WithPhaseId> = ({
    workshopId,
    phaseId
}) => {
    const queue = useAppSelector(selectModeratedQueueBy(phaseId))
    const me = useAppSelector(selectMyParticipant)
    const moderator = useAppSelector(selectLeadModerator(workshopId))
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    if (!queue.isTurnOf(me)) {
        return <></>
    }

    if (queue.hasNextItem()) {
        return <UpNext
            workshopId={workshopId}
            participantId={queue.next.participantId}
            onPressNext={() => dispatch(acceptQueuedTopic({
                workshopId,
                phaseId,
                topicId: queue.current.topicId
            }))}
        />
    }


    if (moderator) {
        if (me?.id === moderator.id) {
            return <div className={'up-next'}>
                <Text>{t('moderated-queue.up-next-label')}</Text>
                <Divider size='S' marginY='size-130'/>
                <AcceptQueuedTopicButton
                    workshopId={workshopId}
                    phaseId={phaseId}
                    queue={queue}
                    variant={'primary'}/>
            </div>
        }

        return <UpNext
            label={t('moderated-queue.button.pass-mic-to-moderator')}
            workshopId={workshopId}
            participantId={moderator?.id}
            onPressNext={() => dispatch(acceptQueuedTopic({
                workshopId,
                phaseId,
                topicId: queue.current.topicId
            }))}
        />
    }
    return <></>
}
