import {MeetingStatus, useMeetingStatus,} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {selectMeetingDeviceSettings} from 'src/state/meetings'
import {useAppSelector} from 'src/state/types'
import {AudioInputControl} from './AudioInputControl'
import {SettingsControl} from './SettingsControl'
import {VideoInputControl} from './VideoInputControl'
import './MeetingControls.css'

export const MeetingControls: React.FC = () => {
    const meetingStatus = useMeetingStatus()
    const meetingDeviceSettings = useAppSelector(selectMeetingDeviceSettings)

    if (meetingStatus !== MeetingStatus.Succeeded) {
        return <></>
    }

    if (!meetingDeviceSettings.isAllowedToSpeak && !meetingDeviceSettings.isVideoAllowed) {
        return <></>
    }

    return <div className='meeting-controls'>
        {meetingDeviceSettings.isVideoAllowed ? <VideoInputControl /> : <></>}
        {meetingDeviceSettings.isAllowedToSpeak ? <AudioInputControl /> : <></>}
        <SettingsControl />
    </div>
}
