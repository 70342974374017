import {ActionButton} from '@adobe/react-spectrum'
import React, {ReactElement} from 'react'

export const DeviceControl: React.FC<{
    active: boolean
    disabled?: boolean
    activeIcon: ReactElement<{ onClick(): void }>
    inactiveIcon: ReactElement<{ onClick(): void }>
    toggle(): void
}> = ({
    active,
    disabled,
    toggle,
    activeIcon,
    inactiveIcon,
}) => {
    const isDisabled = disabled === undefined ? false : disabled
    return <>
        <ActionButton isQuiet onPress={toggle} isDisabled={isDisabled}>
            {active ? activeIcon : inactiveIcon}
        </ActionButton>
    </>

}
