import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import {MapIcon} from 'src/components/icons/MapIcon'
import {MenuItem} from 'src/components/layout/menu/MenuItem'
import {WithWorkshopId} from 'src/types'

export const WorkshopParticipantsMenuItem: React.FC<{
    variant: 'full' | 'icon'
}> = ({variant}) => {
    const {t} = useTranslation()
    const {workshopId} = useParams<WithWorkshopId>()
    const location = useLocation()

    return <MenuItem variant={variant} to={`/workshops/${workshopId}/participants`} clickable
        active={/.*[^configure]\/participants$/.test(location.pathname)}>
        {{
            icon: <MapIcon/>,
            content: t('navigation.participants')
        }}
    </MenuItem>
}
