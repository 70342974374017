import {useActiveParticipants} from 'src/hooks/meeting/use-active-participants'
import {MeetingParticipant} from 'src/state/meetings'
import {ParticipantRolesAdapter} from 'src/state/participants'

export function useFilteredParticipants(
    workshopId: string,
    roles: ParticipantRolesAdapter,
    includeRoles: string[] | undefined,
    excludeRoles: string[] | undefined
): MeetingParticipant[] {
    return useActiveParticipants(workshopId)
        .filter(participant => includeRoles ? roles.hasAtLeastOneRole(participant, includeRoles) : true)
        .filter(participant => excludeRoles ? !roles.hasAtLeastOneRole(participant, excludeRoles) : true)
}
