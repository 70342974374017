import {createAsyncThunk} from '@reduxjs/toolkit'
import {ParticipantsApi} from 'src/api'
import {Participant} from './participants.model'

export const loadParticipants = createAsyncThunk<Participant[], string>(
    'participants/loadParticipants',
    async (workshopId: string) => {
        const participantDtos = await new ParticipantsApi().getParticipantsForWorkshop(workshopId)
        return participantDtos.map(participantDto => ({
            ...participantDto,
            workshopId
        }))
    })
export const loadMyParticipant = createAsyncThunk<Participant, string>(
    'participants/loadMyParticipant',
    async (workshopId) => {
        const participantDto = await new ParticipantsApi().getMe(workshopId)
        return {...participantDto, workshopId}
    }
)
