import {Flex, Item, Picker} from '@adobe/react-spectrum'
import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import React, {ReactNode} from 'react'

interface Props {
    icon: ReactNode;
    ariaLabel: string
    notFoundMsg: string;
    devices: DeviceType[];
    selectedDeviceId: string | undefined;
    onChange: (deviceId: string) => void;
}

export const DeviceInput: React.FC<Props> = (
    {
        onChange,
        icon,
        ariaLabel,
        devices,
        selectedDeviceId,
        notFoundMsg,
    }) => {
    const outputOptions = devices.map((device) => ({
        value: device.deviceId,
        label: device.label,
    }))

    const options = outputOptions.length
        ? outputOptions
        : [
            {
                value: 'not-available',
                label: notFoundMsg,
            },
        ]

    function selectDevice(deviceId: string) {
        if (deviceId === 'not-available') {
            return
        }
        onChange(deviceId)
    }

    return <Flex direction={'row'} gap={'size-130'}>
        <>{icon}</>
        <Picker
            aria-label={ariaLabel}
            selectedKey={selectedDeviceId ? selectedDeviceId : undefined}
            onSelectionChange={selection => {
                selectDevice(selection as string)
            }}>
            {options.map(option => <Item key={option.value}>{option.label}</Item>)}
        </Picker>
    </Flex>
}
