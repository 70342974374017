import {RxStomp} from '@stomp/rx-stomp'
import {API, Auth} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import SockJS from 'sockjs-client'

export const stompClient = async (): Promise<RxStomp> => {
    const endpointUrl = await API.endpoint('backend') + 'ws'
    const stomp = new RxStomp()
    stomp.configure({
        webSocketFactory() {
            return new SockJS(endpointUrl, {
            })
        },
        debug(msg) {
            console.debug(msg)
        },
        beforeConnect(client) {
            return Auth.currentSession().then(session =>
                client.configure({
                    connectHeaders: {
                        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
                    }
                })
            )
        }
    })
    stomp.activate()
    return stomp
}

export const StompContext = React.createContext<RxStomp | undefined>(undefined)

export const StompClientProvider: React.FC = ({children}) => {
    const [client, setClient] = useState<RxStomp | undefined>(undefined)
    useEffect(() => {
        const initClient = async () => {
            setClient(await stompClient())
        }
        initClient().catch(error => console.error('Failed to to create websocket client', error))
    }, [])
    return <StompContext.Provider value={client}>{children}</StompContext.Provider>
}

