import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {EditableText, EditableTextArea} from 'src/components/common'
import {setPhaseDescription, setPhaseName} from 'src/state/phases/phases.actions'
import {useAppDispatch} from 'src/state/types'
import {WithPhase} from 'src/types'

export const TitleColumn: React.FC<WithPhase> = ({phase}) => {
    const dispatch = useAppDispatch()
    return <div className='title-column'>
        <Flex direction='column'>
            <div>
                <h3><EditableText value={phase.name} onSave={(newName) => {
                    dispatch(setPhaseName({phase, name: newName}))
                }}/></h3>
            </div>
            <div>
                <EditableTextArea value={phase.description} onSave={(newDescription) => {
                    dispatch(setPhaseDescription({phase, description: newDescription}))
                }} height={'size-2000'}/>
            </div>
        </Flex>
    </div>
}
