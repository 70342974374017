import React from 'react'
import './Breadcrumb.css'

export const Item: React.FC = ({children}) => {
    return <div className='breadcrumb-item'>{children}</div>
}

export const Breadcrumb: React.FC = ({children}) => {
    return <div className='breadcrumb' data-testid='breadcrumb'>{children}</div>
}
