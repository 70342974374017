import {EntityState} from '@reduxjs/toolkit'
import {MyTopicAssignmentDto} from 'src/api'
import {StatusDto} from 'src/api/common.dto'
import {Phase} from 'src/state/phases'
import {TopicAssignmentRole} from 'src/state/topic-assignments'

export type ScheduleItemType = 'Global' | 'Personal' | 'Break' | 'LeadModerator'

export interface ScheduleItem {
    phaseId: string,
    phase?: string,
    name: string,
    start: string,
    url: string,
    status: StatusDto,
    type: ScheduleItemType,
    role?: TopicAssignmentRole
}

export function createScheduleItemForPhase(phase: Phase): ScheduleItem {
    return {
        phaseId: phase.id,
        name: phase.name,
        start: phase.startTime,
        url: `/workshops/${phase.workshopId}/plenary`,
        status: phase.status,
        type: 'Global'
    }
}

export function createScheduleItemForLeadModerator(phase: Phase): ScheduleItem {
    return {
        phaseId: phase.id,
        name: phase.name,
        start: phase.startTime,
        url: `/workshops/${phase.workshopId}/control-center`,
        status: phase.status,
        type: 'LeadModerator'
    }
}

export function createScheduleItemForTopic(workshopId: string, assignment: MyTopicAssignmentDto): ScheduleItem {
    return {
        phaseId: assignment.phaseId,
        start: assignment.startTime,
        name: assignment.name,
        url: `/workshops/${workshopId}/topics/${assignment.topicId}`,
        status: 'not-started',
        type: 'Personal',
        role: assignment.role
    }
}

export function createScheduleItemForBreak(workshopId: string, start: string, status: StatusDto): ScheduleItem {
    return {
        phaseId: start,
        name: 'Break',
        start: start,
        url: `/workshops/${workshopId}/cafe`,
        status: status,
        type: 'Break'
    }
}

export type WithScheduleState = {
    schedule: EntityState<ScheduleItem>
}
