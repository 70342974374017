import {useActiveSpeakersState, useRosterState} from 'amazon-chime-sdk-component-library-react'
import {MeetingParticipant} from 'src/state/meetings'
import {selectParticipantsForWorkshopAsMap} from 'src/state/participants'
import {useAppSelector} from 'src/state/types'

export function useSpeakingMeetingParticipants(workshopId: string): MeetingParticipant[] {
    const activeAttendeeIds = useActiveSpeakersState()
    const participants = useAppSelector(selectParticipantsForWorkshopAsMap(workshopId))
    const {roster} = useRosterState()

    return activeAttendeeIds
        .filter(attendeeId => {
            if (!roster[attendeeId]) {
                return false
            }
            const participantId = roster[attendeeId].externalUserId
            return participantId && participants[participantId]
        })
        .map(attendeeId => {
            return {
                ...participants[roster[attendeeId]!.externalUserId!]!,
                attendeeId
            }
        })

}
