import {createAction} from '@reduxjs/toolkit'
import {createScheduleItemForTopic, ScheduleItem} from './schedule.model'
import {workshopEventDispatcher} from '../workshop-event-dispatcher'
import {TopicAssignmentScheduledEventDto, TopicAssignmentScheduledPayload} from 'src/websocket/workshop-event-types.dto'

export const TopicAssignmentScheduled = createAction<ScheduleItem[]>('participants/ws/event/topicAssignmentScheduled')

workshopEventDispatcher.registerActionCreator({
    type: 'TopicAssignmentScheduled',
    actionCreator: (event) => {
        function toScheduleItems(workshopId: string, payload: TopicAssignmentScheduledPayload) {
            return (payload.content || [])
                .map(topicAssignment => createScheduleItemForTopic(workshopId, topicAssignment))
        }

        return TopicAssignmentScheduled(toScheduleItems(event.workshopId, (event as TopicAssignmentScheduledEventDto).payload))
    }
})
