import React from 'react'
import {WorkshopPhaseControls} from 'src/components/control/workshop/WorkshopPhaseControls'
import {ContentWithToolbox} from 'src/components/layout/ContentWithToolbox'
import {Toolbox} from 'src/components/layout/Toolbox'
import {TopicCards} from './TopicCards'
import {Topic} from 'src/state/topics'
import {WithWorkshopId, WithPhase} from 'src/types'

export const DefaultTopicGenerationContent: React.FC<WithWorkshopId & WithPhase & {
    topics: Topic[]
}> = ({
    workshopId,
    phase,
    topics
}) => {
    return <ContentWithToolbox toolbox={
        <Toolbox>
            <WorkshopPhaseControls
                workshopId={workshopId}
                phase={phase}/>
        </Toolbox>}>
        <TopicCards workshopId={workshopId} topics={topics}/>
    </ContentWithToolbox>
}
