import {EntityAdapter} from '@reduxjs/toolkit'
import {ProfileDto} from 'src/api'
import {Participant, ParticipantsState} from './participants.model'

export function toParticipant(workshopId: string, participant: ProfileDto): Participant {
    return {...participant, workshopId}
}

export class ParticipantStateService {
    constructor(
      private participantsAdapter: EntityAdapter<Participant>
    ) {
    }

    setParticipants(participants: Participant[], state: ParticipantsState): void {
        this.participantsAdapter.setAll(state, participants)
        state.byWorkshop = {}
        participants.forEach((participant) => {
            this.updateParticipant(participant, state)
        })
    }

    addMe(participant: Participant, state: ParticipantsState): void {
        this.updateParticipant(participant, state)
    }

    updateParticipant(participant: Participant, state: ParticipantsState): void {
        this.participantsAdapter.upsertOne(state, participant)
        this.updateByWorkshop(participant, state)
    }

    private updateByWorkshop(participant: Participant, state: ParticipantsState): void {
        if (!state.byWorkshop[participant.workshopId]) {
            state.byWorkshop[participant.workshopId] = [participant.id]
        } else if (!state.byWorkshop[participant.workshopId].includes(participant.id)) {
            state.byWorkshop[participant.workshopId] = [...state.byWorkshop[participant.workshopId], participant.id]
        }
    }
}
