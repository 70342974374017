import {DeviceType} from 'amazon-chime-sdk-component-library-react/lib/types'
import {useEffect, useState} from 'react'

export function useDevices(deviceLoader: () => Promise<DeviceType[]>): DeviceType[] {
    const [devices, setDevices] = useState<DeviceType[]>([])

    useEffect(() => {
        const loadDevices = async () => {
            setDevices(await deviceLoader())
        }
        loadDevices().catch(error => {
            console.error('Failed to fetch devices', error)
        })
    }, [])
    return devices
}
