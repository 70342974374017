import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route} from 'react-router-dom'
import {Item} from 'src/components/layout/breadcrumb/Breadcrumb'
import {WorkshopBreadcrumb} from './WorkshopBreadcrumb'

export const WorkshopsBreadcrumb: React.FC = () => {
    const {t} = useTranslation()

    return <>
        <Item>{t('breadcrumb.workshops')}</Item>
        <Route path='/workshops/:workshopId'><WorkshopBreadcrumb/></Route>
    </>
}
