import {AlertDialog, Button, ButtonGroup, DialogTrigger, Item, ListBox} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Card} from 'src/components/common/card/Card'
import {AddInvitationButton} from 'src/components/invitations/AddInvitationButton'
import {
    deleteInvitations,
    Invitation,
    sendInvitationEmails,
    sendSingleInvitationsEmail,
    uploadInvitationsFile
} from 'src/state/invitations'
import {useAppDispatch} from 'src/state/types'
import {WithWorkshop} from 'src/types'
import moment from 'moment'

export const InvitationManager: React.FC<WithWorkshop & { invitations: Invitation[] }> = ({
    workshop,
    invitations
}) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const [selectedInvitations, setSelectedInvitations] = useState<string[]>([])
    const invitationsToNonParticipants = invitations.filter(it => !it.participant)

    const deleteSelectedInvitations = async () => {
        const param = {workshopId: workshop.id, invitations: selectedInvitations}
        await dispatch(deleteInvitations(param))
        setSelectedInvitations([])
    }

    const submitFile = async (files: FileList | null) => {
        if (files && files.length > 0) {
            await dispatch(uploadInvitationsFile({workshopId: workshop.id, file: files[0]}))
        }
    }

    const sendInvitations = () => {
        dispatch(sendInvitationEmails(workshop.id))
    }

    const sendInvitationsToSelected = () => {
        selectedInvitations.forEach(invitation => {
            const param = {workshopId: workshop.id, email: invitation}
            return dispatch(sendSingleInvitationsEmail(param))
        })
    }

    function appendNicknameIfSet(nickname: string) {
        return nickname && nickname.trim() !== '' ? ' - "' + nickname + '"' : ''
    }

    const lastMailDateLabel = t('workshop-details-attendees.last-email-send-label')

    function appendLastMailDate(lastMailDate: string | undefined) {
        if (lastMailDate) {
            const timestamp = moment.utc(lastMailDate).local()
            return ' - ' + lastMailDateLabel + ': ' + timestamp.format('DD.MM.YYYY - HH:mm')
        }
        return ''
    }

    return <Card>
        {{
            title: t('workshop-details-attendees.invitations'),
            content: <ListBox
                selectionMode='multiple' aria-label="invitations" selectedKeys={selectedInvitations}
                onSelectionChange={(selection: any) => setSelectedInvitations(Array.from([...selection]))}>
                {invitationsToNonParticipants.map(invitation =>
                    <Item key={invitation.email}>
                        {invitation.email + appendNicknameIfSet(invitation.nickname) + appendLastMailDate(invitation.lastMailDate)}
                    </Item>
                )}
            </ListBox>,
            buttons: <ButtonGroup>
                <AddInvitationButton workshopId={workshop.id}/>
                <label className='spectrum-Button _spectrum-Button_7a745 _spectrum-Button--primary_7a745'
                    style={{marginLeft: 16, marginRight: 16}}>
                    <input
                        type='file' accept='.csv' multiple={false}
                        onChange={e => submitFile(e?.target?.files)} style={{display: 'none'}}/>
                    {t('workshop-details-attendees.upload-csv')}
                </label>
                <Button variant='secondary'
                    onPress={deleteSelectedInvitations}>{t('workshop-details-attendees.delete')}</Button>
            </ButtonGroup>,
            additionalButtons: <ButtonGroup>
                <DialogTrigger>
                    {selectedInvitations.length > 0 ?
                        <Button variant='primary'
                            onPress={sendInvitationsToSelected}>{t('workshop-details-attendees.send-emails-to-selected')}</Button>
                        : <Button variant='primary'
                            onPress={sendInvitations}>{t('workshop-details-attendees.send-emails-to-all')}</Button>}
                    <AlertDialog
                        variant="information"
                        title="Success!"
                        primaryActionLabel="Ok">
                        Invitation-Emails have been successfully sent to all invited emails
                    </AlertDialog>
                </DialogTrigger>
            </ButtonGroup>
        }}
    </Card>
}
