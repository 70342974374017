import {createEntityAdapter} from '@reduxjs/toolkit'
import {WithParticipantId} from 'src/types'
import {ParticipantLocation} from './participant-locations.model'

export function getIdOfLocation(model: WithParticipantId & {sessionId: string}): string {
    return model.participantId + '#' + model.sessionId
}

export const participantLocationsAdapter = createEntityAdapter<ParticipantLocation>({
    selectId: (model) => getIdOfLocation(model)
})
