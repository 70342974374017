import {createReducer} from '@reduxjs/toolkit'
import {createLocalStorageReducer, loadLocalStorageState} from 'src/state/local-storage'
import {
    setAudioInputDeviceId,
    setAudioOutputDeviceId, setDeviceDialogShown,
    setMute,
    setVideoActive,
    setVideoInputDeviceId
} from './device-settings.actions'
import {DeviceSettingsState} from './device-settings.model'

export const VERSION = 1

export const initialState: DeviceSettingsState = loadLocalStorageState<DeviceSettingsState>('device-settings', VERSION) || {
    devices: {},
    active: {
        video: false,
        mute: true,
    },
    hasDialogBeenShown: false
}
initialState.hasDialogBeenShown = false // overwrite from local storage

export const deviceSettingsReducer = createLocalStorageReducer(createReducer(initialState, builder => builder
    .addCase(setMute, (state, {payload: mute}) => {
        state.active.mute = mute
    })
    .addCase(setVideoActive, (state, {payload: videoActive}) => {
        state.active.video = videoActive
    })
    .addCase(setVideoInputDeviceId, (state, {payload: deviceId}) => {
        state.devices.videoInputDeviceId = deviceId
    })
    .addCase(setAudioOutputDeviceId, (state, {payload: deviceId}) => {
        state.devices.audioOutputDeviceId = deviceId
    })
    .addCase(setAudioInputDeviceId, (state, {payload: deviceId}) => {
        state.devices.audioInputDeviceId = deviceId
    })
    .addCase(setDeviceDialogShown, (state) => {
        state.hasDialogBeenShown = true
    })
), 'device-settings', VERSION)
