import {Flex} from '@adobe/react-spectrum'
import React from 'react'
import {WithRoles, WithWorkshopId} from 'src/types'
import {ActiveSpeakerVideo} from './video/ActiveSpeakerVideo'
import {ModeratorVideo} from './video/ModeratorVideo'

export const RosterStage: React.FC<WithWorkshopId & WithRoles & {
    displayAbsentParticipants: boolean
    displayActiveSpeaker: boolean
}> = ({
    workshopId,
    roles,
    displayAbsentParticipants,
    displayActiveSpeaker
}) => {
    return <Flex direction='row' justifyContent={'center'} gap={'size-130'} alignItems={'center'}>
        {displayActiveSpeaker ? <Flex>
            <ActiveSpeakerVideo workshopId={workshopId}
                roles={roles}
                excludeRoles={['moderator']}/>
        </Flex> : <></>}
        <Flex>
            <ModeratorVideo
                displayAbsent={displayAbsentParticipants}
                workshopId={workshopId}
                roles={roles}/>
        </Flex>
    </Flex>
}
