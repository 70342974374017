import {useContentShareControls, useContentShareState} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {ContentShareActiveIcon} from 'src/components/icons/ContentShareActiveIcon'
import {ContentShareInactiveIcon} from 'src/components/icons/ContentShareInactiveIcon'
import {DeviceControl} from './DeviceControl'

export const ContentShareControl: React.FC = () => {
    const {toggleContentShare} = useContentShareControls()
    const {tileId} = useContentShareState()

    return <DeviceControl
        active={tileId !== null}
        activeIcon={<ContentShareActiveIcon/>}
        inactiveIcon={<ContentShareInactiveIcon/>}
        toggle={() => {
            toggleContentShare().catch(error => {
                console.error('Failed to share content', error)
            })
        }}
    />
}
