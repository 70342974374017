import React, {useEffect} from 'react'
import {login, selectAuthenticatedUser} from '../state/auth'
import {useAppDispatch, useAppSelector} from '../state/types'

export const Authentication: React.FC = (props) => {
    const {children} = props
    const user = useAppSelector(selectAuthenticatedUser)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!user) {
            dispatch(login())
        }
    }, [user, dispatch])

    return <>
        {user ? children : <></>}
    </>
}
