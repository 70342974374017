import {createSlice} from '@reduxjs/toolkit'
import {loadAllTopicAssignments, loadTopicAssignments} from './topic-assignments.actions'
import {topicAssignmentsAdapter} from './topic-assignments.adapter'
import {TopicAssignmentsUpdated} from './topic-assignments.events'
import {TopicAssignmentsState} from './topic-assignments.model'

export const initialState: TopicAssignmentsState = {
    ...topicAssignmentsAdapter.getInitialState(),
    byPhaseAndTopic: {}
}

export const topicAssignmentsSlice = createSlice({
    name: 'topic-assignments',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadTopicAssignments.fulfilled, (state, {payload: {phaseAndTopicId, assignments}}) => {
                topicAssignmentsAdapter.upsertMany(state, assignments)
                state.byPhaseAndTopic[phaseAndTopicId] = assignments.map(assignment => assignment.id)
            })
            .addCase(TopicAssignmentsUpdated, (state, {payload: {phaseAndTopicId, assignments}}) => {
                topicAssignmentsAdapter.upsertMany(state, assignments)
                state.byPhaseAndTopic[phaseAndTopicId] = assignments.map(assignment => assignment.id)
            })
            .addCase(loadAllTopicAssignments.fulfilled, (state, {payload: assignments}) => {
                topicAssignmentsAdapter.upsertMany(state, assignments)
            })
    }
})
