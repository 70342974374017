import {ActionButton, DialogTrigger} from '@adobe/react-spectrum'
import React, {useEffect, useState} from 'react'
import {UserProfileDialog} from 'src/components/profile/UserProfileDialog'
import {updateUserProfile} from 'src/state/auth'
import {Participant, selectMyParticipant} from 'src/state/participants'
import {useAppDispatch, useAppSelector} from 'src/state/types'

function shouldProvideProfile(me: Participant | undefined): boolean {
    return Boolean(me && (!me.biography || !me.imageUrl))
}

export const UserProfileDialogTrigger: React.FC = () => {
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(true)
    const me: Participant | undefined = useAppSelector(selectMyParticipant)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setProfileDialogOpen(shouldProvideProfile(me))
    }, [me])

    return <DialogTrigger type='modal' isOpen={isProfileDialogOpen}>
        <ActionButton isHidden={true}/>
        <UserProfileDialog
            close={() => setProfileDialogOpen(false)}
            save={(userProfileModel) => {
                dispatch(updateUserProfile(userProfileModel))
                setProfileDialogOpen(false)
            }}/>
    </DialogTrigger>
}
