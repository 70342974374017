import React from 'react'
import {ParticipantBadge} from 'src/components/common/ParticipantBadge'
import {Participant} from 'src/state/participants'

const sizeToClasses = {
    roster: {
        videoContainer: 'video-container-participants offline',
    },
    stage: {
        videoContainer: 'video-container-stage offline',
    },
    presenter: {
        videoContainer: 'video-container-presenter offline',
    }
}

export const OfflineParticipantVideo: React.FC<{
    participant: Participant & { attendeeId?: string }
    size: 'roster' | 'presenter' | 'stage'
    role?: string
}> = ({participant, size, role}) => {
    return <div className={sizeToClasses[size].videoContainer}>
        {role ? <div className={'video-role-container'}>
            <div className={'video-role'}>{role}</div>
        </div> : <></>}
        <div className='participant-video-avatar'>
            <ParticipantBadge participant={participant} active={false}/>
        </div>

    </div>
}
