import {createAction} from '@reduxjs/toolkit'
import {toNoteForTopicModel} from 'src/state/topic-notes/topic-notes.service'
import {NoteForTopic} from './topic-notes.model'
import {topicRoomEventDispatcher} from '../workshop-event-dispatcher'
import {
    TopicNoteCreatedEventDto,
    TopicNoteDeletedEventDto,
    TopicNoteUpdatedEventDto
} from 'src/websocket/topic-room-event-types.dto'
import {WithTopicId, WithWorkshopId} from 'src/types'

export const TopicNoteCreated = createAction<WithWorkshopId & WithTopicId & { note: NoteForTopic }>(
    'topics/ws/event/topicNoteCreated')
export const TopicNoteUpdated = createAction<WithWorkshopId & WithTopicId & { note: NoteForTopic }>(
    'topics/ws/event/topicNoteUpdated')
export const TopicNoteDeleted = createAction<WithWorkshopId & WithTopicId & { noteId: string }>(
    'topics/ws/event/topicNoteDeleted')

topicRoomEventDispatcher.registerActionCreator({
    type: 'NoteCreated',
    actionCreator: event => {
        const dto = event as TopicNoteCreatedEventDto
        return TopicNoteCreated({
            note: toNoteForTopicModel(dto.payload),
            workshopId: event.meta.workshopId,
            topicId: event.meta.topicId
        })
    }
})

topicRoomEventDispatcher.registerActionCreator({
    type: 'NoteUpdated',
    actionCreator: event => {
        const dto = event as TopicNoteUpdatedEventDto
        return TopicNoteUpdated({
            note: toNoteForTopicModel(dto.payload),
            workshopId: event.meta.workshopId,
            topicId: event.meta.topicId
        })
    }
})

topicRoomEventDispatcher.registerActionCreator({
    type: 'NoteDeleted',
    actionCreator: event => {
        const dto = event as TopicNoteDeletedEventDto
        return TopicNoteDeleted({
            noteId: dto.payload.id,
            workshopId: event.meta.workshopId,
            topicId: event.meta.topicId
        })
    }
})
