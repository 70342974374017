import {Button, Text} from '@adobe/react-spectrum'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CheckCircleIcon} from 'src/components/icons/CheckCircleIcon'
import {
    acceptQueuedTopic, ModeratedTopicQueueAdapter,
    ParticipantAndTopicId,
    QueueWithCurrentItem
} from 'src/state/moderated-queue'
import {useAppDispatch} from 'src/state/types'
import {WithWorkshopId, WithPhaseId} from 'src/types'

export const AcceptQueuedTopicButton: React.FC<WithWorkshopId & WithPhaseId & {
    queue: ModeratedTopicQueueAdapter & QueueWithCurrentItem<ParticipantAndTopicId>
    variant?: 'cta' | 'overBackground' | 'primary' | 'secondary' | 'negative'
}> = ({
    workshopId,
    phaseId,
    queue,
    variant
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    return <Button UNSAFE_className={'moderator-button accept-topic'} flexShrink={0} variant={variant || 'overBackground'} onPress={() => {
        dispatch(acceptQueuedTopic({
            workshopId,
            phaseId,
            topicId: queue.current.topicId
        }))
    }}>
        <CheckCircleIcon className={!variant || variant==='overBackground' ? 'hover-button-icon' : ''}/><Text>{t('moderated-queue.button.accept-topic')}</Text>
    </Button>
}
