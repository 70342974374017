import {Flex, Text, TextField} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {CheckIcon} from 'src/components/icons/CheckIcon'

export const EditableText: React.FC<{
    value: string
    onSave?(value: string): void
    onChange?(value: string): void
}> = ({value, onSave, onChange}) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState<string>(value)

    const save = () => {
        setEditMode(false)
        if (onSave && value !== currentValue) {
            onSave(currentValue)
        }
    }
    const change = (changedValue: string) => {
        setCurrentValue(changedValue)
        if (onChange && value !== changedValue) {
            onChange(changedValue)
        }
    }
    return editMode ? <>
        <Flex gap={'size-50'}>
            <TextField defaultValue={value} onChange={change} onFocusChange={(isFocused) => {
                if (!isFocused) {
                    save()
                }
            }} autoFocus/>
            <div><a className="save-icon" onClick={() => save()}><CheckIcon/></a></div>
        </Flex>
    </> : <a onClick={() => setEditMode(!editMode)}><Text>{value}</Text></a>
}
