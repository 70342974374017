import React, {ReactNode} from 'react'
import {Link} from 'react-router-dom'

export interface MenuItemProps {
    variant: 'icon' | 'full'
    className?: string
    children: {
        icon?: ReactNode
        content: ReactNode
        disabled?: boolean
    }
    to?: string
    active?: boolean
    clickable?: boolean

    onClick?(): void
}

export const MenuItem: React.FC<MenuItemProps> = (
    {
        variant,
        onClick,
        to,
        children,
        className,
        active,
        clickable
    }) => {
    const Wrapper: React.FC<{ to?: string, className: string, onClick?(): void }> = (wrapperProps) =>
        wrapperProps.to ? <Link {...wrapperProps} to={wrapperProps.to}/> : <a {...wrapperProps} />
    if(children.disabled) {
        return <div className={'menu-item menu-variant-'+variant+' menu-item-disabled ' + (className ? ' '+className : '')}>
            <div className={'menu-item-icon icon-active menu-item-icon-'+variant}>{children.icon}</div>
            {variant === 'full' ? <div className='menu-item-content'>{children.content}</div> : <></>}
        </div>
    }

    return <div className={'menu-item menu-variant-'+variant + (className ? ' '+className : '')}>
        <div className={`menu-item-icon ${active ? 'clickable-icon icon-active' : ''} ${clickable ? 'clickable-icon' : ''} `}><Wrapper onClick={onClick} to={to} className='menu-item-link'>{children.icon}</Wrapper></div>
        {variant === 'full' ? <div className='menu-item-content'><Wrapper onClick={onClick} to={to} className='menu-item-link'>{children.content}</Wrapper></div> : <></>}
    </div>
}
