import classNames from 'classnames'
import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import {SearchParticipants} from './SearchParticipants'
import {ScrollContainer} from 'src/components/layout/ScrollContainer'
import {toCalcCss} from 'src/components/layout/util'
import {LayoutConfigState, selectLayoutConfigState, selectSearchDrawerStatus, setSearchDrawer} from 'src/state/layout'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import styled from 'styled-components'
import './SearchDrawer.css'

const DrawerContent = styled.div<{
    layout: LayoutConfigState
}>`
  width: ${({layout}) => toCalcCss(layout.drawer.search)};
  transition: all 0.3s linear;
`

export const SearchDrawer: React.FC = () => {
    const layout = useAppSelector(selectLayoutConfigState)
    const drawerStatus = useAppSelector(selectSearchDrawerStatus)

    return <div className={classNames({
        'menu-right search-drawer': drawerStatus !== 'hidden',
    })}>
        <DrawerContent layout={layout} className='menu-right-content'>
            {drawerStatus === 'open' ?
                <ScrollContainer>
                    <Switch>
                        <Route path='/workshops/:workshopId'>
                            <SearchParticipants />
                        </Route>
                        <Route>
                            <CloseSearchDrawer />
                        </Route>
                    </Switch>
                </ScrollContainer>
                : <></>}
        </DrawerContent>
    </div>
}

export const CloseSearchDrawer: React.FC = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setSearchDrawer('hidden'))
    }, [])
    return <></>
}

