import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useLocation} from 'react-router-dom'
import {Icon} from 'src/components/common'
import WorkshopsIcon from 'src/components/icons/svg/desk.svg'
import LogoutIcon from 'src/components/icons/svg/power.svg'
import {MenuItem} from 'src/components/layout/menu/MenuItem'
import {logout, selectAuthenticatedUser} from 'src/state/auth'
import {selectIsLeftDrawerOpen} from 'src/state/layout'
import {useAppDispatch, useAppSelector} from 'src/state/types'
import {AvatarOrProfileIcon} from './AvatarOrProfileIcon'
import {SettingsMenuItem} from './SettingsMenuItem'
import {WorkshopMenu} from './WorkshopMenu'
import {WorkshopParticipantsMenuItem} from './WorkshopParticipantsMenuItem'
import {WorkshopScheduleMenu} from './WorkshopScheduleMenu'

export const LeftDrawerContent: React.FC = () => {
    const dispatch = useAppDispatch()
    const open = useAppSelector(selectIsLeftDrawerOpen)
    const {t} = useTranslation()
    const me = useAppSelector(selectAuthenticatedUser)
    const location = useLocation()

    const variant = open ? 'full' : 'icon'
    return <>
        <div className='menu-top'>
            <div className='menu-content-top'>
                <MenuItem variant={variant} to='/workshops' clickable active={/.*\/workshops$/.test(location.pathname)}>
                    {{
                        icon: <Icon icon={WorkshopsIcon} />,
                        content: t('navigation.workshops')
                    }}
                </MenuItem>
                <Route path='/workshops/:workshopId'><WorkshopMenu variant={variant} /></Route>
            </div>
        </div>
        <div className='menu-middle'>
            <Route path='/workshops/:workshopId'><WorkshopScheduleMenu variant={variant} /></Route>
        </div>
        <div className='menu-bottom'>
            <div className='menu-content-bottom'>
                <Route path='/workshops/:workshopId'>
                    <WorkshopParticipantsMenuItem variant={variant}/>
                </Route>
                <MenuItem variant={variant} to='/profile' className="profile-icon">
                    {{
                        icon: <AvatarOrProfileIcon />,
                        content: me.name
                    }}
                </MenuItem>
                <SettingsMenuItem variant={variant} />
                <MenuItem variant={variant} onClick={() => dispatch(logout())} className={'logout-button'}>
                    {{
                        icon: <Icon icon={LogoutIcon} />,
                        content: t('navigation.logout')
                    }}
                </MenuItem>
            </div>
        </div>
    </>
}

