import {Divider, Flex, View} from '@adobe/react-spectrum'
import React from 'react'
import {TopicVotes} from './tools/TopicVotes'
import {TopicRoomHeaderInfo} from 'src/components/meetings/topic-room/topic-breakout/header/TopicRoomHeaderInfo'
import {WithTopic, WithWorkshopId, WithPhaseId} from 'src/types'

export const TopicDescription: React.FC<WithWorkshopId & Partial<WithPhaseId> & WithTopic & {
    showVotes: boolean,
    showStatus: boolean,
    canOwnerLeaveTopic: boolean
}> = ({
    workshopId,
    phaseId,
    topic,
    showVotes,
    showStatus,
    canOwnerLeaveTopic
}) => {
    return <Flex direction='column' flexShrink={1.25}>
        <View>
            <TopicRoomHeaderInfo topic={topic}
                workshopId={workshopId}
                showStatus={showStatus}
                canOwnerLeaveTopic={canOwnerLeaveTopic}/>
        </View>
        <View><h2>{topic.title}</h2></View>
        <View><span>{topic.description}</span></View>
        {showVotes ? <>
            <View><Divider size='S' marginY='size-200'/></View>
            <TopicVotes workshopId={workshopId} phaseId={phaseId} topic={topic}/>
            <View><Divider size='S' marginY='size-200'/></View>
        </> : <></>}
    </Flex>
}
