import React from 'react'
import styled from 'styled-components'

export const ContentColumn: React.FC = (props) => {
    return (
        <StyledContent {...props}>
            {props.children}
        </StyledContent>
    )
}


const StyledContent = styled.div`
padding:0;
margin-top: 0;
margin-bottom: 0;
transition: all 0.3s linear;
overflow-y: hidden;
width: 100%;
`
