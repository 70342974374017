import {Button, ButtonGroup} from '@adobe/react-spectrum'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Icon} from 'src/components/common'
import {DeviceDialogContainer} from 'src/components/control/DeviceDialogContainer'
import SettingsIcon from 'src/components/icons/svg/settings.svg'
import {MenuItem} from 'src/components/layout/menu/MenuItem'
import {setDeviceDialogShown} from 'src/state/device-settings'
import {useAppDispatch} from 'src/state/types'

export const SettingsMenuItem: React.FC<{
    variant: 'full' | 'icon'
}> = ({
    variant
}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState<boolean>(false)
    return <><MenuItem variant={variant} clickable onClick={() => setOpen(!open)}>
        {{
            icon: <Icon icon={SettingsIcon}/>,
            content: t('navigation.settings')
        }}
    </MenuItem>
    <DeviceDialogContainer
        open={open}
        setOpen={setOpen}
        dismissible={true}
        actionButtons={
            <ButtonGroup>
                <Button
                    variant='primary'
                    data-testid='create-workshop-button-create'
                    onPress={() => {
                        dispatch(setDeviceDialogShown())
                        setOpen(false)
                    }}
                >{t('dialog.device-settings.button.ok')}</Button>
            </ButtonGroup>}
    />
    </>
}
